
import * as L from 'leaflet';
import { Component, OnInit } from '@angular/core';
import {TokenService} from '../Services/token.service'
import { environment } from '../../environments/environment.prod';
import * as mapboxgl from 'mapbox-gl';
import { stringify } from 'querystring';
import { UserService } from '../Services/user.service';


@Component({
  selector: 'app-location-users',
  templateUrl: './location-users.component.html',
  styleUrls: ['./location-users.component.scss']
})
export class LocationUsersComponent implements OnInit {

  map: mapboxgl.Map;

  constructor(
    private user: UserService, private token: TokenService  
  ) {
    (mapboxgl as any).accessToken = 'pk.eyJ1IjoicXVhci1hc2lzdGVjb21jb2xvbWJpYSIsImEiOiJja3RmeXhsc2gwZDRrMnZvbGJhaXliZTJ1In0.iOPzIjud93dOOsM7Czmh3w';
  }
  coords: any;

  
  

  form = {
    IdUser: this.token.getUserId()
  }

  buildMap() {
    var string;

    /* this.map = new mapboxgl.Map({
       container: 'map',
       style: 'mapbox://styles/mapbox/streets-v11',
       center: [-75.5754173, 6.2722904],
       zoom: 10
     });*/

    let centerLatlng = new mapboxgl.LngLat(-75.574828, 6.244747);
     this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: centerLatlng,
      zoom: 10
    });
    this.map.addControl(new mapboxgl.NavigationControl());


   
this.markers();


/* geojson.features.forEach(function (marker) {
  
  new mapboxgl.Marker({
    draggable: true
  })
  .setLngLat([-75.5754173, 6.2722904])
  .addTo(this.map)
  
})*/
}

markersL: any;
currentMarkers=[];

markers() {
  if (this.currentMarkers!==null) {
    for (var i = this.currentMarkers.length - 1; i >= 0; i--) {
      this.currentMarkers[i].remove();
    }
  }
  this.user.getLocations(this.form).subscribe(
    
    data => {
      var json = [
      ]
      var json2 = [
      ]
      
      console.log("json"+ json)
        console.log(json)
        // console.log("user location :" + data['usersLocation'])
        data['usersLocation'].forEach(element => {
       var    string = element.ubicacion;
       if(string.includes(',')){
         this.coords = string.split(",")
       } else {
        this.coords = string.split(" ")
       }
        // alert(this.coords)
          json.push({
            "name": element.name,
            "address1": element.ubicacion,
            "lng": this.coords[1],
            "lat": this.coords[0]
          });
          console.log(json)
        });


        console.log("user unidad :" + data['unidadLocation'])

        data['unidadLocation'].forEach(element2 => {

          
          if(element2.Latitude){

            json2.push({
              "name": element2.NombreUnidad,
              "address1": element2.Serial,
              "lng": element2.Longitude,
              "lat": element2.Latitude
            });
          }
          });

          for (var i = 0; i < json.length; i++) {
            var obj = json[i];
            console.log(obj)
            let myLatlng = new mapboxgl.LngLat(obj.lng, obj.lat);
            let marker = new mapboxgl.Marker()
              .setLngLat(myLatlng)
              .setPopup(new mapboxgl.Popup({ offset: 25 })
                .setHTML('<p>' + obj.name + '</p><p>' + obj.address1 + '</p>'))
              .addTo(this.map);

              this.currentMarkers.push(marker);
          }
          

        // for (var i = 0; i < json.length; i++) {
        //   var marker = new mapboxgl.Marker().addTo(this.map);
        //   marker.remove();
        // }
        

        for (var i = 0; i < json2.length; i++) {
          var obj = json2[i];
          let myLatlng = new mapboxgl.LngLat(obj.lng, obj.lat);
            // create a HTML element for each feature
  const el = document.createElement('div');
  el.className = 'marker';
  
          let marker = new mapboxgl.Marker()
            .setLngLat(myLatlng)
            
            .setPopup(new mapboxgl.Popup({ offset: 25 })
              .setHTML('<p>' + obj.name + '</p><p>' + obj.address1 + '</p>'))
            .addTo(this.map);
            this.currentMarkers.push(marker);
        }
        console.log(this.currentMarkers);
      },
      
      error => console.log(error)
    )
    
  }
  data: any;



  // private map;

  ngOnInit(): void {

    if(parseInt(this.token.getUserAdministrador()) > 0){
      this.form.IdUser = this.token.getUserAdministrador()
    } else {
      this.form.IdUser = this.token.getUserId();
    }
    // this.initializeMapOptions();
    this.buildMap()
    
    setInterval(() => {
      
      this.markers()

     }, 10000);
  }





}
