import { error } from 'protractor';
import { TokenService } from 'src/app/Services/token.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SedesService } from '../Services/sedes.service';
import { UnidadNegocioService } from '../Services/unidad-negocio.service';
declare var jQuery: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  active: string;
  constructor( private unidad:UnidadNegocioService ,private sede: SedesService, private token: TokenService, private router: Router, private users: UserService, private toastr: ToastrService) { }
  codigo;
  admin;

  user = {
    id: this.token.getUserId(),
    Usuario: this.token.getUserId()
  }

  accesosusuarios;
  usermod = {
    accesos: null,
    idUser: this.token.getUserId()
  }

  accesosbarra = [
    { id: "activos", text: "Activos fijos" },
    { id: "control-ingreso", text: "Control de visitas y grupos" },
    { id: "control-vigilancia", text: "Control de vigilancia / unidades de negocio" }
  ];


  modulos() {
    this.users.updateModulo(this.usermod).subscribe(
      data => {
        this.toastr.success("Exito", "Se ha actualizado exitosamente la información");
        localStorage.setItem("accesos", this.token.utf8_to_b64(data['accesos']));
        this.accesosusuarios = data['accesos'];
        jQuery('#accesos').modal('hide');
      },
      error => console.log("Error", error)
    )
  }



  logout() {
    this.token.remove();
    this.router.navigate(['/login']);
  }
  name = this.token.getUserName();
  email = this.token.getUserEmail();
  puede = false;

  form = {
    NombreSede: null,
    IdUsuarioInmo: ""
  }

  sedes;

  loading = false;
  IdSede = localStorage.getItem('IdSede')

  cargarSede(event) {
    // alert(event.target.value)
    localStorage.setItem('IdSede', event.target.value)
    location.reload()
  }

online = true;
  ngOnInit() {


    if (parseInt(this.token.getUserId()) == 415) {
      this.puede = true;
    } else {
      if (parseInt(this.token.getUserId()) == 663) {
        this.puede = true;
      }
    }



    if (parseInt(this.token.getUserAdministrador()) > 0) {
      this.form.IdUsuarioInmo = this.token.getUserAdministrador();
      this.user.Usuario  = this.token.getUserAdministrador();
    } else {
      this.admin = true;
      this.form.IdUsuarioInmo = this.token.getUserId();
      this.user.Usuario = this.token.getUserId();
    }


        // Ver https://developer.mozilla.org/es/docs/Web/API/NavigatorOnLine/onLine
if(navigator.onLine) {
  this.online = true;
  if(localStorage.getItem("observacion")){

    const array = JSON.parse(localStorage.getItem("observacion"));
    if(localStorage.getItem("foto")){

      this.unidad.upload(JSON.parse(localStorage.getItem("foto"))).subscribe(res => {
        this.loading = false;
        // this.toastr.success("Se regsitró exitosamente el cambio en el activo", "Historial de Archivos")
        // this.novedad(res);
        // this.sr
        array.Foto = res['url'];
        this.unidad.novedad(array).subscribe(
      data => {
        localStorage.removeItem("observacion");
        localStorage.removeItem("foto");
        this.toastr.success("Se sincronizó en segundo plano")
        this.loading  = false;
      },
      error => console.log(error)
      )
    },
    error => {
      this.loading = false;
      
    })
  } else {
    this.unidad.novedad(array).subscribe(
      data => {
        localStorage.removeItem("observacion");
        localStorage.removeItem("foto");
        this.toastr.success("Se sincronizó en segundo plano")
        this.loading  = false;
      },
      error => console.log(error)
      )
  }
  }


  this.unidad.get(this.user).subscribe(
    data => {
        localStorage.setItem("unidades", JSON.stringify(data));
        
        console.log()
    },
    error => {
      alert(error.error)
      console.log(error.error)
    }
  )

} else {
  // el navegador NO está conectado a la red
  // alert("not online")
this.online = false;
}


    this.sede.getSedes(this.form).subscribe(data => {
      this.sedes = data['sedes'];
      this.loading = false;
      // this.toastr.success("Se ha cargado la información de la sede", "Mensaje")
    },
      error => {
        console.log(error);
        this.loading = false;
      })

    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.codigo = this.token.utf8_to_b64(this.token.getUserAdministrador());
    } else {
      this.admin = true;
      this.codigo = this.token.utf8_to_b64(this.token.getUserId());
    }
    this.active = this.router.url;




    this.users.estado(this.user).subscribe(data => {
      // this.estado = data['estado'];
      if (data['accesoscount'] > 0) {
        this.accesosusuarios = data['accesos'];
        console.log(this.accesosusuarios)
      } else {
        // alert("no tiene");
        this.accesosusuarios = data['modulos'];
        console.log(data['modulos'])
        // data['modulos'].forEach(element => {
        // console.log(element.Ali);
        // alert(element.Alias);
        // this.accesosbarra.push({
        //   id: element.NombreModulo,
        //   text: element.Alias
        // });
        // });
        // console.log(this.accesosbarra);
        jQuery('#accesos').modal('show')
      }
    })
  }



  options = {
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
    width: '100%'
  };


}



