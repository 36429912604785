<script>
    serial = "";
</script>
<ng-container *ngIf="!found">
    <main>
        <div class="container">
            <div class="row">

                <div class="col-md-6 align-self-center">
                    <h1>{{serial.serial}}</h1>
                    <h2>Este código no ha sido activado o no existe por favor verificalo</h2>
                    <p>
                        Contáctate con el administrador de la plataforma si crees que es un error.
                    </p>
                    <button class="btn green">Inicio</button>
                </div>
            </div>
        </div>
    </main>
</ng-container>


<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>



<div class="content" *ngIf="found">

    <div class="preloader-wrapper" style="display:none;">
        <div class="preloader">
            <!-- <img src="{{asset('img/loading.gif')}}" alt="NILA"> -->
        </div>
        <div class="alert cell alert-warning">Se esta enviando tu solicitud, espera un momento</div>
    </div>

    <header>
        <nav class="navbar navbar-light bg-light">
            <a class="navbar-brand" href="#">
                <img src="../../assets/img/logo.png" width="120" class="d-inline-block align-top" alt="">
            </a>
        </nav>
    </header>
    <hr>
    <br>
    <section class="container" *ngIf="normal">
        <div class="row activo-profile">
            <div data-toggle="modal" data-target="#general" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="icon-PROFILE"></i></div>
                <div class="activo-profile__item__title">
                    Datos Generales
                </div>
            </div>

            <div *ngIf="tipo === 'Computador'" data-toggle="modal" data-target="#tecnicos" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="icon-DETAILS"></i></div>
                <div class="activo-profile__item__title">
                    Datos Tecnicos
                </div>
            </div>

            <div *ngIf="tipo === 'Computador'" data-toggle="modal" data-target="#software" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="fab fa-windows"></i></div>
                <div class="activo-profile__item__title">
                    Datos de Software
                </div>
            </div>

            <div data-toggle="modal" data-target="#red" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="icon-registrar_mantenimiento"></i></div>
                <div class="activo-profile__item__title">
                    Red y Mantenimientos
                </div>
            </div>
            <div data-toggle="modal" data-target="#tickets" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="icon-registrar_mantenimiento"></i></div>
                <div class="activo-profile__item__title">
                    Tickets
                </div>
            </div>
        </div>
    </section>
    <section class="container" *ngIf="!normal">
        <div class="row activo-profile">
            <div data-toggle="modal" data-target="#general2" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="icon-PROFILE"></i></div>
                <div class="activo-profile__item__title">
                    Datos del vehiculo
                </div>
            </div>

            <div class="modal fade" id="general2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Datos del vehiculo</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- <p>Datos del vehiculo</p> -->
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="d">Marca: </label>
                                    <input type="text" class="form-control" disabled value="SPARK GT">
                                </div>

                                <div class="col-md-6">
                                    <label for="d">Modelo: </label>
                                    <input type="text" class="form-control" disabled value="2020">
                                </div>

                                <div class="col-md-6">
                                    <label for="d">Nombre vehiculo: </label>
                                    <input type="text" class="form-control" disabled value="SPARK GT">
                                </div>

                                <div class="col-md-6">
                                    <label for="d">Color vehiculo: </label>
                                    <input type="text" class="form-control" disabled value="ROJO">
                                </div>

                                <div class="col-md-12">
                                    <label for="d">Foto: </label>
                                    <img src="https://imganuncios.mitula.net/chevrolet_spark_2017_gasolina_chevrolet_spark_2017_5_puertas_3850049593174593295.jpg"
                                        style="width:100%" alt="">
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="tipo === 'Computador'" data-toggle="modal" data-target="#tecnicos" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="icon-DETAILS"></i></div>
                <div class="activo-profile__item__title">
                    Datos Tecnicos
                </div>
            </div>

            <div data-toggle="modal" data-target="#software2" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="fas fa-route"></i></div>

                <div class="activo-profile__item__title">
                    Observaciones del vehiculo
                </div>
            </div>

            <div class="modal fade" id="software2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Observaciones</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- <p>Datos del vehiculo</p> -->
                            <div class="card card-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Detalle de observación</th>
                                            <th scope="col">Fecha</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Cambio de aceite del vehiculo y cambio de sensor de temperatura</td>
                                            <td>18/06/2021</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Se lavó el vehiculo en la sede de localiza</td>
                                            <td>18/06/2021</td>
                                        </tr>

                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Cambio de pintura vehiculo </td>
                                            <td>01/01/2020</td>
                                        </tr>

                                        <tr>
                                            <th scope="row">4</th>
                                            <td>
                                                Inspección detallada del vehiculo
                                            </td>
                                            <td>01/01/2020</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>


            <div data-toggle="modal" data-target="#red2" class="activo-profile__item">
                <div class="activo-profile__item__icon"><i class="far fa-address-card"></i></div>
                <div class="activo-profile__item__title">
                    Tarjeta de propiedad, SOAT y impuestos
                </div>
            </div>

            <div class="activo-profile__item">

                <img style="width: 100px;" src="https://www.iconpacks.net/icons/1/free-user-support-icon-301-thumb.png"
                    alt="">
                <div class="activo-profile__item__title">
                    Solicitar soporte
                </div>
            </div>



            <!-- Modal -->
            <div class="modal fade" id="exampleModalSoporte" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Solicitar Soporte</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form #soporteForm=ngForm (ngSubmit)="onSubmit()">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="">Nombre</label>
                                            <input type="text" class="form-control" name="NombreCliente"
                                                [(ngModel)]="soporte.NombreCliente">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Teléfono</label>
                                            <input type="email" class="form-control" name="CorreoCliente"
                                                [(ngModel)]="soporte.CorreoCliente">
                                        </div>
                                        <div class="col-md-12">
                                            <label for="">Asunto</label>
                                            <input type="text" class="form-control" name="Asunto"
                                                [(ngModel)]="soporte.Asunto">
                                        </div>
                                        <div class="col-md-12">
                                            <br>
                                            <hr>
                                            <br>
                                            <label for="">Mensaje</label>
                                            <textarea class="form-control" name="Mensaje"
                                                [(ngModel)]="soporte.Mensaje"></textarea>

                                        </div>

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-dismiss="modal">Cancelar</button>
                                    <button type="submit" class="btn btn-primary">Enviar Solicitud</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>



            <div class="modal fade" id="red2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">

                            <h5 class="modal-title">Tarjeta de propiedad y SOAT</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- <p>Datos del vehiculo</p> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="d">Fecha pago de impuestos: </label>
                                    <input type="text" class="form-control" name="impuesto" value="21 de marzo del 2022"
                                        disabled>
                                    <p>

                                        <button class="btn btn-primary cell" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            Historial de pago de impuesto
                                        </button>
                                    </p>
                                    <div class="collapse" id="collapseExample">
                                        <div class="card card-body">
                                            <label for="">Pago de impuesto sistematización: 21 de Julio del 2021</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="d">Tarjeta de propiedad: </label>
                                    <img src="https://campanas-servicio.co/frontend/img/skoda/tarjetapropiedad.jpg"
                                        style="width:100%" alt="">
                                </div>

                                <div class="col-md-12">
                                    <br>
                                    <label for="d">SOAT:</label>
                                    <br>
                                    <object width="100%" height="500px" data="../../../assets/img/images.pdf"
                                        type="application/pdf">
                                        <embed src="../../../assets/img/images.pdf" type="application/pdf" />
                                    </object>
                                    <iframe frameborder="0" style="width: 100%;"></iframe>
                                </div>



                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="card-body">

        <ng-container *ngFor="let general of activoinfo.activo">
            <div class="modal fade" id="general" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Datos Generales</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">

                            <div class="row">
                                <ng-container *ngIf="tipo == 'Maquina'">

                                    <div class="col-md-6">
                                        <label>Nombre del Activo</label>
                                        <input disabled type="text" class="form-control" name="NombreActivo"
                                            [(ngModel)]="general.NombreActivo">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Marca</label>
                                        <input disabled type="text" class="form-control" name="Marca"
                                            [(ngModel)]="general.Marca">

                                    </div>
                                    <div class="col-md-6">
                                        <label>Modelo
                                        </label>
                                        <input disabled type="text" class="form-control" name="Modelo"
                                            [(ngModel)]="general.Modelo">
                                    </div>

                                    <div class="col-md-6">
                                        <label>Tipo de maquina</label>
                                        <input disabled class="form-control" name="TipoDispositivo"
                                            [(ngModel)]="general.TipoDispositivo" />
                                    </div>
                                    <div class="col-md-6">
                                        <label>Serial</label>
                                        <input disabled type="text" class="form-control" name="Serial"
                                            [(ngModel)]="general.Serial">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Fecha compra</label>
                                        <input disabled type="date" class="form-control" name="FechaCompra"
                                            [(ngModel)]="general.FechaCompra">
                                    </div>

                                    <div class="col-md-6">
                                        <label>Número Factura</label>
                                        <input disabled type="text" class="form-control" name="NumeroFactura"
                                            [(ngModel)]="general.NumeroFactura">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Valor del Activo</label>
                                        <input disabled type="text" class="form-control" name="ValorActivo"
                                            [(ngModel)]="general.ValorActivo">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Número de Póliza</label>
                                        <input disabled type="text" class="form-control" name="NumeroPoliza"
                                            [(ngModel)]="general.NumeroPoliza">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Contacto soporte</label>
                                        <input disabled type="text" class="form-control" name="Observaciones"
                                            [(ngModel)]="general.ContactoSoporte" />
                                    </div>
                                    <div class="col-md-6">
                                        <label>Número soporte</label>
                                        <input disabled class="form-control" name="Observaciones"
                                            [(ngModel)]="general.NumeroSoporte" />
                                    </div>
                                    <div class="col-md-12">
                                        <label>Observaciones</label>
                                        <textarea disabled class="form-control" name="Observaciones"
                                            [(ngModel)]="general.Observaciones"></textarea>
                                    </div>
                                    <div class="col-md-12">
                                        <br>
                                    </div>
                                    <div class="col-md-12">
                                        <br>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="tipo != 'Maquina'">
                                    <div class="col-md-12">
                                        <label>Nombre Asignado</label>
                                        <input disabled type="text" class="form-control" name="NombreAsignado"
                                            [(ngModel)]="empleados">
                                    </div>
                                    <div class="col-md-12">
                                        <label>Nombre Sede</label>
                                        <input disabled type="text" class="form-control" name="sedes"
                                            [(ngModel)]="sedes">
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr">
                                            <p><b>Nombre</b><br /> <input class="form-control" type="text"
                                                    value="{{general.NombreActivo}}" disabled></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">

                                        <!-- <div class="address-hr" *ngIf="tipo == 'Maquina'"> -->
                                        <p><b>Valor Activo</b><br /> <input type="text" class="form-control" disabled
                                                value="{{general.ValorActivo}}"></p>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                            <p><b>Número de Factura</b><br /> <input type="text" class="form-control"
                                                    disabled value="{{general.NumeroFactura}}"></p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row" *ngIf="tipo != 'Maquina'">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                    <div class="address-hr">
                                        <p><b>Fecha Compra</b><br /> <input class="form-control" type="text" disabled
                                                value="{{general.FechaCompra}}"></p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                    <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                        <p><b>Número Poliza</b><br /> <input class="form-control" type="text" disabled
                                                value="{{general.NumeroPoliza}}"></p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                    <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                        <p><b>Marca</b><br /> <input type="text" class="form-control" disabled
                                                value="{{general.Marca}}"></p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                    <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                        <p><b>Modelo</b><br /> <input type="text" class="form-control" disabled
                                                value="{{general.Modelo}}"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="address-hr">
                                        <p><b>Serial</b><br /> <input class="form-control" type="text" disabled
                                                value="{{general.Serial}}"></p>

                                        <br>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="tecnicos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Datos Tecnicos</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="row">
                                <div class="col-12">
                                    <ng-container *ngFor="let tipo of activoinfo.tipocomputador">

                                        <h6>Tipo de computador</h6>
                                        <input type="text" class="form-control" disabled value="{{tipo.TipoPc}}" name=""
                                            id="">
                                        <hr>
                                    </ng-container>
                                </div>
                                <div class="col-12">
                                    <h6>Procesador</h6>
                                    <input type="text" class="form-control" disabled value="{{general.Procesador}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-7">
                                    <h6>Tamaño Pantalla</h6>
                                    <input type="text" class="form-control" disabled value="{{general.TamanoPantalla}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-12">
                                    <hr style="background: #3770ba">
                                    <i>Memoria</i>
                                    <hr style="background: #3770ba">
                                </div>

                                <div class="col-6">

                                    <ng-container *ngFor="let memoria of activoinfo.tipomemoria">

                                        <h6>Tipo de memoria</h6>
                                        <input type="text" class="form-control" disabled value="{{memoria.TipoMemoria}}"
                                            name="" id="">
                                        <hr>
                                    </ng-container>
                                </div>
                                <div class="col-6">
                                    <h6>Modulos de Ram</h6>
                                    <input type="text" class="form-control" disabled value="{{general.CantidadModulos}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-3">
                                    <h6>Modulo 1</h6>
                                    <input type="text" class="form-control" disabled value="{{general.CantMemoria1}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-3">
                                    <h6>Modulo 2</h6>
                                    <input type="text" class="form-control" disabled value="{{general.CantMemoria2}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-3">
                                    <h6>Modulo 3</h6>
                                    <input type="text" class="form-control" disabled value="{{general.CantMemoria3}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-3">
                                    <h6>Modulo 4</h6>
                                    <input type="text" class="form-control" disabled value="{{general.CantMemoria4}}"
                                        name="" id="">
                                    <hr>
                                </div>
                                <div class="col-12">
                                    <hr style="background: #3770ba">
                                    <i>Disco duro</i>
                                    <hr style="background: #3770ba">
                                    <div class="box-body">
                                        <table class="tabla no-margin" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Tipo de disco</th>
                                                    <th>Capacidad de disco</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let row of activoinfo.discos">
                                                    <td>{{row.TipoDisco}}</td>
                                                    <td>{{row.CapacidadDisco}}
                                                    </td>
                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>




                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-secondary"
                                    data-dismiss="modal">Cerrar</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>








            <div class="modal fade" id="software" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Datos Tecnicos</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <ng-container *ngFor="let s of activoinfo.soft">
                                <div class="row">
                                    <div class="col-md-6">
                                        <br>
                                        <ng-container *ngFor="let os of activoinfo.siste">
                                            <div class="col-12">
                                                <h6>Sistema Operativo</h6>
                                                <input type="text" class="form-control" disabled
                                                    value="{{os.NombreOs}}">
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>Versión Sistema Operativo</h6>
                                            <input type="text" class="form-control" disabled value="{{s.VersionOs}}">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <br>
                                        <ng-container *ngFor="let li of activoinfo.liOs">
                                            <div class="col-12">
                                                <h6>Tipo licencia del Sistema Operativo</h6>
                                                <input type="text" class="form-control" disabled
                                                    value="{{li.NombreLicencia}}">
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>Licencia Sistema Operativo</h6>
                                            <input type="text" class="form-control" disabled
                                                value="{{s.NumeroLicenciaOs}}">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>
                                                Licencia Office</h6>
                                            <input type="text" class="form-control" disabled
                                                value="{{s.NumeroLicenciaOffice}}">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>Office</h6>
                                            <input type="text" class="form-control" disabled value="{{s.Office}}">
                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>Edición Office</h6>
                                            <input type="text" class="form-control" disabled value="{{s.IdTipoOffice}}">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>Antivirus</h6>
                                            <input type="data" class="form-control" disabled value="{{s.Antivirus}}">
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <br>
                                        <div class="col-12">
                                            <h6>Vencimiento Antivirus</h6>
                                            <input type="data" class="form-control" disabled
                                                value="{{s.VencimientoAntivirus}}">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <br>
                                        <div class="col-12">
                                            <h6>Otros Programas </h6>
                                            <textarea type="data" class="form-control" disabled
                                                style="height:15vh !important;margin-bottom:1em;">{{s.OtrosProgrmas}}</textarea>
                                        </div>
                                    </div>
                                    <br>

                                </div>
                            </ng-container>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="red" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Red y Mantenimientos</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="row" *ngIf="tipo == 'Maquina'">
                                <div class="col-md-6">

                                    <label>Ciclo de mantenimiento general</label>
                                    <select required id="Ciclo" disabled name="CicloMantenimiento"
                                        [(ngModel)]="general.CiclosMantenimiento" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="ProximoMantenimiento"
                                        [(ngModel)]="general.ProximoMantenimiento">
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento lubricación</label>
                                    <select required disabled id="Ciclo" name="CicloMantenimiento"
                                        [(ngModel)]="general.Lubricacion" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled required class="form-control"
                                        name="ProximoMantenimiento" [(ngModel)]="general.LubricacionFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento mecánico</label>
                                    <select required id="Ciclo" disabled name="Mecanico" [(ngModel)]="general.Mecanico"
                                        class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="MecanicoFecha"
                                        [(ngModel)]="general.MecanicoFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento eléctrico</label>
                                    <select required disabled id="Ciclo" name="Electrico"
                                        [(ngModel)]="general.Electrico" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="ElectricoFecha"
                                        [(ngModel)]="general.ElectricoFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento electromecánico</label>
                                    <select required id="Ciclo" disabled name="ElectroMecanico"
                                        [(ngModel)]="general.ElectroMecanico" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="ElectroMecanicoFecha"
                                        [(ngModel)]="general.ElectroMecanicoFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                            </div>
                            <div class="row" *ngIf="tipo != 'Maquina'">
                                <div class="col-12">
                                    <ng-container *ngFor="let ciclo of activoinfo.ciclos">
                                        <h6>Ciclo de Mantenimiento</h6>
                                        <input type="text" class="form-control" disabled value="{{ciclo.NombreCiclo}}"
                                            name="" id="">
                                        <hr>
                                    </ng-container>
                                </div>
                                <div class="col-12">
                                    <h6>Proximo Mantenimiento</h6>
                                    <input type="text" class="form-control" disabled
                                        value="{{general.ProximoMantenimiento}}" name="" id="">
                                    <hr>
                                </div>

                                <br>
                            </div>
                            <div class="col-12 ">
                                <div id="historialContent">
                                    <div class="text-center">

                                        <button class="btn btn-primary">HISTORIAL DE
                                            MANTENIMIENTOS</button>

                                        <br>

                                        <br> {{Serial}}
                                        <a class="btn btn-danger cell" *ngIf="tipo != 'Maquina'"
                                            href="/mantenimiento/{{Serial}}" id="mantenimientos">Registrar un nuevo
                                            mantenimiento</a>
                                        <a class="btn btn-danger cell" *ngIf="tipo == 'Maquina'"
                                            href="/mantenimientomaquina/{{Serial}}" id="mantenimientos">Registrar un
                                            nuevo mantenimiento</a>

                                    </div>
                                    <div class="row">
                                        <ng-container *ngFor="let m of activoinfo.man">
                                            <br>
                                            <div class="col-md-12 card-item">
                                                <div class="card-border">
                                                    <div class="card-header">
                                                        <div class="">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <ng-container *ngFor="let u of users">
                                                                        <p class="user" *ngIf="u.id == m.NombreUsuario">

                                                                            {{u.name}}
                                                                        </p>

                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    Fecha: {{m.FechaMantenimiento}}
                                                                </div>
                                                                <div class="col-md-6">

                                                                    <p style="color:red;"
                                                                        *ngIf="m.TipoMantenimiento == 'Correctivo'">
                                                                        Tipo: {{m.TipoMantenimiento}}</p>
                                                                    <p style="color:green;"
                                                                        *ngIf="m.TipoMantenimiento == 'Preventivo'">
                                                                        Tipo: {{m.TipoMantenimiento}}</p>
                                                                </div>

                                                                <div class="card-content">
                                                                    <div class="col-md-12">
                                                                        <h2 style="text-transform:uppercase;">
                                                                            {{m.NombreActivo}}</h2>
                                                                        {{m.Descripcion}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>

                                </div>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="tickets" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Tickes</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                     
                        <div class="col-12 ">
                            <div>
                                <div class="row">
                                    <ng-container *ngFor="let m of allTickets">
                                        <br>
                                        <div class="col-md-12 card-item">
                                            <div class="card-border">
                                                <div class="card-header">
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                
                                                            </div>
                                                            
                                                            <div class="card-content" style="text-align: left !important;">
                                                                <div class="col-md-12">
                                                                    <div [innerHtml]="m.Mensaje"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>
            <div class="table">
                <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci delectus sint asperiores quasi nostrum velit ullam? Quidem facere numquam ut nam pariatur, perferendis dolorem laborum fugiat recusandae atque amet? Assumenda. -->
                <!-- <h1>Insumos</h1> -->
            </div>


            <div class="col-12">
                <div class="fixed-bottom">
                    <div class="row">
                        <div class="col-6">
                            <a href="https://soporte.quar.com.co/ticket?serial={{general.Codigo}}">
                            <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#exampleModal"
                                style="width:100%">
                                Soporte
                            </button>
                        </a>
                        </div>
                        <div class="col-6">
                            <!-- {{tipo}} -->
                            <a *ngIf="tipo == 'Computador'" class="btn btn-primary cell" style="width:100%"
                                href="/editar/computador/{{general.Codigo}}">Editar</a>
                            <a *ngIf="tipo == 'Impresora'" class="btn btn-primary cell" style="width:100%"
                                href="/editar/impresora/{{general.Codigo}}">Editar</a>
                            <a *ngIf="tipo == 'Dispositivos'" class="btn btn-primary cell" style="width:100%"
                                href="/editar/dispositivosred/{{general.Codigo}}">Editar</a>

                            <a *ngIf="tipo == 'Maquina'" class="btn btn-primary cell" style="width:100%"
                                routerLink="/historicomaquina/{{Serial}}">Registrar histórico</a>

                        </div>
                    </div>
                </div>
            </div>


            <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Solicitud de soporte</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form #soporteForm=ngForm (ngSubmit)="onSubmit()">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">Nombre</label>
                                        <input type="text" class="form-control" name="NombreCliente"
                                            [(ngModel)]="soporte.NombreCliente">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Correo</label>
                                        <input type="email" class="form-control" name="CorreoCliente"
                                            [(ngModel)]="soporte.CorreoCliente">
                                    </div>
                                    <div class="col-md-12">
                                        <label for="">Asunto</label>
                                        <input type="text" class="form-control" name="Asunto"
                                            [(ngModel)]="soporte.Asunto">
                                    </div>
                                    <div class="col-md-12">
                                        <br>
                                        <hr>
                                        <br>
                                        <label for="">Mensaje</label>
                                        <textarea class="form-control" name="Mensaje"
                                            [(ngModel)]="soporte.Mensaje"></textarea>

                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="submit" class="btn btn-primary">Enviar Solicitud</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>




        <!-- <script src="{{asset('js/appprofile.js')}}"></script> -->


    </div>