<!-- CSS Just for demo purpose, don't include it in your project -->

<div class="wrapper">
    <app-layout></app-layout>
    <div class="main-panel">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg  navbar-transparent  navbar-absolute bg-primary fixed-top">
            <div class="container-fluid">
                <div class="navbar-wrapper">
                    <div class="navbar-toggle">
                        <button type="button" class="navbar-toggler">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <a class="navbar-brand">{{tittle}}</a>
                </div>
                <app-navbar></app-navbar>
            </div>
        </nav>
        <!-- End Navbar -->
        <div class="panel-header panel-header-lg">
            <canvas id="bigDashboardChart"></canvas>
        </div>
        <div class="content">
            <div class="row">
                <div class="col-md-12" *ngIf="admin">
                    <div class="card card-tasks panel-activos">

                        <div class="card-header">
                            <h2 class="card-category"></h2>
                            <h1>Activación de Cuenta</h1>
                        </div>
                        <div class="card-body panel-activos__body text-center">
                            <img style="width: 150px;" src="https://image.flaticon.com/icons/svg/726/726623.svg" alt="">
                            <p>Debes activar tu cuenta para continuar realizando la gestión de tus activos</p>
                            <p>Si no te llego el correo da click al siguiente botón</p>
                            <button class="btn btn-primary" (click)="sendEmail()">Enviar Correo electrónico</button>
                        </div>


                        <div class="card-footer ">
                            <hr>
                            <div class="stats ">
                                <i class="now-ui-icons loader_refresh spin "></i>Actualizando
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="!admin">
                    <div class="card card-tasks panel-activos">

                        <div class="card-header">
                            <h2 class="card-category"></h2>
                            <h1>Activación de Cuenta</h1>
                        </div>
                        <div class="card-body panel-activos__body text-center">
                            <img style="width: 150px;" src="https://image.flaticon.com/icons/svg/2705/2705118.svg" alt="">
                            <h1>Debes solicitar a tu administrador que realice la activación de tu cuenta para continuar</h1>
                            <h3>Si ya hicieron la activación da click en el siguiente botón</h3>
                            <button routerLink="/home" class="btn btn-priamary">Comprobar</button>
                        </div>


                        <div class="card-footer ">
                            <hr>
                            <div class="stats ">
                                <i class="now-ui-icons loader_refresh spin "></i>Actualizando
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>