import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otrosdispositivos',
  templateUrl: './otrosdispositivos.component.html',
  styleUrls: ['./otrosdispositivos.component.scss']
})
export class OtrosdispositivosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
