import { ToastrService } from 'ngx-toastr';
import { MantenimientosService } from './../Services/mantenimientos.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { UserService } from '../Services/user.service';
import { TokenService } from '../Services/token.service';
import { ComputadorService } from '../Services/Activos/computador.service';
import swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NgxPaginationModule } from 'ngx-pagination';
import { FineUploaderBasic } from 'fine-uploader/lib/core';

@Component({
  selector: 'app-computador',
  templateUrl: './computador.component.html',
  styleUrls: ['./computador.component.scss']
})
export class ComputadorComponent implements OnInit {
  numberperpage = 10;
  term: string;
  mostrar(event) {
    // alert(event.target.value);
    this.numberperpage = event.target.value;
  }



  exportexcel(): void
    {
       /* table id is passed over here */
       let element = document.getElementById('excel-table');
       const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.computador);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, "Computadores.xlsx");

    }

  mantenimientosactivo: any;
  sidebaractive: string = 'ficha';
  filesToUpload: Array<File> = [];
  loading = false;
  detail = {
    id_user: this.Token.getUserId(),
    serial: null,
    tipoPC: null,
    tipoMemoria: null

  };
  detailactivo: any;
  p: number = 1;
  tittle: string = "gestión de computadores";
  idActivo: any;
  serial: string;
  mensaje: string;

  pasos = "uno";
  detalle = false;
  licencias: any;
  tipocomputador: any;
  ciclosmantenimiento: any;
  sistemaoperativo: any;
  tipomemorias: any;
  data = {
    id: this.Token.getUserId(),
    idactivo: this.idActivo,
    serial: this.serial,
    IdSede: localStorage.getItem("IdSede")
  }

  IdExcel:any;
  view: string = 'lista';
  computador: any;
  tickets: number;
  dataActivo: any;


  public form = {
    id_user: this.Token.getUserId(),
    NombreActivo: null,
    NumeroFactura: null,
    FechaCompra: null,
    NumeroPoliza: null,
    Marca: null,
    Modelo: null,
    Serial: null,
    idTipoPc: 1,
    Valor: null,
    Procesador: null,
    TamanoPantalla: null,
    idTipoMemoria: "1020493468",
    CantidadModulos: null,
    CantMemoria1: null,
    CantMemoria2: null,
    CantMemoria3: null,
    CantMemoria4: null,
    IdCicloMantenimiento: 1,
    ProximoMantenimiento: null,
    FechaUltimoMantenimiento: null,
    Codigo: null,
    Red_Nombre_PC: null,
    MAC: null,
    IP: null,
    Password: null,
    Puerta_de_Enlace: null,
    IdComputador: null,
    IdSistemaOperativo: "1020493468",
    VersionOs: 1020493468,
    IdTipoLicenciaOs: 4,
    NumeroLicenciaOs: null,
    IdTipoOffice: "NO TIENE",
    IdLicenciaOffice: null,
    NumeroLicenciaOffice: null,
    FechaVenOffce: null,
    Antivirus: null,
    OtrosProgrmas: null,
    Office: "NO TIENE",
    VencimientoAntivirus: null,
    IdSede: localStorage.getItem('IdSede'),
    asignado:null
  }



  public loggedIn;
  constructor(
    private toastr: ToastrService,
    private mantenimiento: MantenimientosService,
    private Auth: AuthService, private user: UserService,
    private Token: TokenService, private activo: ComputadorService) { }

  details(id, tipo, memoria) {
    this.detail.serial = id;
    this.detail.tipoPC = tipo;
    this.detail.tipoMemoria = memoria;
    this.loading = true;

    this.activo.detail(this.detail).subscribe(
      data => {
        // console.log(data)
        console.log(data);
        this.novedad(data)
        this.loading = false;
        this.detailactivo = data; this.detalle = true;
      },
      error => this.toastr.error(error.message, "Error")
    )

  }


  validarNombre() {
    if (this.form.NombreActivo == null || this.form.NombreActivo == "") {
      this.toastr.error("El campo nombre del activo es obligatorio", "Mensaje de alerta");
    } else {
      this.pasos = 'dos';
    }
  }



  onSubmit(submit: NgForm) {
    this.loading = true;
    console.log(submit);
    console.log(this.form)
    this.activo.create(this.form).subscribe(
      data => {
        this.loading = false;
        this.createcomputadores(data),
          submit.reset()
      },
      error => {
        this.loading = false;

        this.toastr.error('Error', error.message, {
          timeOut: 3000
        });

      }
    );

  }

  deleteComputadorResponse(data) {
    this.toastr.success("Se ha eliminado exitosamente", 'Eliminar activo', {
      timeOut: 3000
    });
    this.computador = data.activo;
  }

  filtrar(event) {
    console.log(event);
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  admin;

  ngOnInit() {

    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      this.data.id = this.Token.getUserAdministrador();
    } else {
      this.admin = true;
      this.data.id = this.Token.getUserId();
    }

    if(localStorage.getItem("IdSede")){
      this.IdExcel = localStorage.getItem("IdSede")
    } else{
      this.IdExcel = 0;
    }
    this.loading = true;
    this.user.computadorModule(this.data).subscribe(
      data => {
        this.loading = false;
        this.computadores(data)
      },
      erro => {
        this.loading = false;
        console.log(erro)
        this.toastr.error(erro.message)
      }
    );
    this.loggedIn = this.Auth.authStatus.subscribe(value => this.loggedIn = value);
  }

  discos = {
    IdComputador: this.Token.getUserId(),
    TipoDisco: "RIGIDO",
    CapacidadDisco: "0 Gb"
  }
  discodata = {
    id_user: this.Token.getUserId(),
    id: null
  }
  discosarray: any;
  getDiscos(data) {
    this.discosarray = data.discos;
  }

  eliminardisco(id) {
    this.discodata.id = id;
    console.log(this.discodata);
    this.activo.discodelete(this.discodata).subscribe(
      data => this.getDiscos(data),
      error => {
        this.toastr.error("Ha ocurrido un error", "No se ha podido eliminar el disco");
      }
    )
  }
  addDisk() {
    if (this.discos.CapacidadDisco == "0" || this.discos.CapacidadDisco == null) {
      this.toastr.info("Campos vacios", 'Debes agregar mínimo 1 en la cantidad');
    } else {

      console.log(this.discos);
      this.activo.discos(this.discos).subscribe(
        data => {
          this.toastr.success("Registro de discos", "Se registro el disco exitosamente");
          this.getDiscos(data);
        }
      )
    }
  }



  createcomputadores(data) {

    this.toastr.success('Registro de Activo', 'Se ha registrado el activo',
      { timeOut: 2000 });;

    this.pasos = "uno";
    // alert(data.mensaje);
    this.view = 'lista';
    this.mensaje = data.mensaje;
    console.log(data);
    this.computador = data.activo;
    this.licencias = data.licencias;
    this.tipocomputador = data.tipopc;
    this.ciclosmantenimiento = data.ciclosmantenimientos;
    this.sistemaoperativo = data.sistemaoperativos;
    this.tipomemorias = data.tipomemorias;
  }

  verificar = {
    serial: null
  }
  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }
  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }

  sedes: any;
  empleados:any;
  computadores(data) {
    console.log(data);
    this.computador = data.activo;
    this.empleados = data.empleados;
    this.licencias = data.licencias;
    this.tipocomputador = data.tipopc;
    this.ciclosmantenimiento = data.ciclosmantenimientos;
    this.sistemaoperativo = data.sistemaoperativos;
    this.tipomemorias = data.tipomemorias;
    this.sedes = data.sedes;
  }

  editarSede(data){
      this.toastr.success("Mensaje", "Se ha cambiado la sede del activo");
  }

  onChangeAsignado(id, event) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Se realizará el cambio de la asignación",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.dataActivo = {
          serial: id,
          idAsignado: event.target.value
        }
        this.activo.cambiarAsignado(this.dataActivo).subscribe(
          data => this.editarSede(data),
          error => {
            this.toastr.error("Ha ocurrido un error", 'Eliminar activo', {
              timeOut: 3000
            });
          }
        )

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })

  }

  onChange(serial, event) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Se realizará el cambio de la sede",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.dataActivo = {
          serial: serial,
          IdSede: event.target.value
        }
        this.activo.cambiarSede(this.dataActivo).subscribe(
          data => this.editarSede(data),
          error => {
            this.toastr.error("Ha ocurrido un error", 'Elimnar activo', {
              timeOut: 3000
            });
          }
        )

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })

  }

  deleteComputador(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.dataActivo = {
          id: id,
          serial: serial,
          idUser: this.Token.getUserId()
        }
        this.activo.deleteComputador(this.dataActivo).subscribe(
          data => this.deleteComputadorResponse(data),
          error => {
            this.toastr.error("Ha ocurrido un error", 'Elimnar activo', {
              timeOut: 3000
            });
          }
        )

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })

  }

  // Subir archivos

  archivo = {
    // id:this.detailactivo[0].idActivo,
    serial: null,
    observacion: null,
    nombre: "archivo",
    nombreArchivo: null,
    base64textString: null
  };
  change(event) {
    console.log(event);
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(this);
      reader.readAsBinaryString(file);
    }
  }


  _handleReaderLoader(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.archivo.base64textString = btoa(binaryString);
  }

  novedades: any;

  novedad(res) {
    this.novedades = res.files;
    console.log(this.novedades)
  }

  upload() {
    this.archivo.serial = this.detailactivo.activo[0].Codigo;
    this.loading = true;
    this.activo.upload(this.archivo).subscribe(res => {
      this.loading = false;
      this.toastr.success("Se regsitró exitosamente el cambio en el activo", "Historial de Archivos")
      this.novedad(res);
    },
      error => console.log(error));
  }
  // let elemento = event.target[0].files;
  // console.log(elemento)
  //   // alert(elemento.files.lenght);
  //   if(elemento.files.lenght > 0){
  //     let formData = new FormData();
  //     formData.append('file', elemento.file[0]);
  //   }




  // Duplicar activo
  activodupl = {
    id_user: this.Token.getUserId(),
    id: null,
    serial: null,
    nuevoserial: null,
    tipo: "computador",
  }

  handleDuplicar(data) {
    this.computador = data.activo;
    this.toastr.success("Se duplicó el activo exitosamente", "Duplicar");
    console.log(data)
  }

  preduplicar(id, codigo) {
    this.activodupl.id = id;
    this.activodupl.serial = codigo;
  }

  duplicar() {
    this.activo.duplicar(this.activodupl).subscribe(
      data => {
        this.handleDuplicar(data);
      }
    )
  }

  alertsDuplicate(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.activodupl.nuevoserial = null;
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");

      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.activodupl.nuevoserial = null;
    }
  }

  serialVerifyDuplicar() {
    this.verificar.serial = this.activodupl.nuevoserial;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alertsDuplicate(data);
    })
  }

}






