import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BiomedicosService {

  private baseurl = "https://quar.com.co/api"
  deleteComputador(data) {
    return this.http.post(`${this.baseurl}/delete/computador`, data)
  }

  getActivos(data) {
    return this.http.post(`${this.baseurl}/delete/computador`, data)
  }

  deleteActivo(data) {
    return this.http.post(`${this.baseurl}/delete/computador`, data)
  }


  serial(data) {
    // console.log(data);
    return this.http.post(`${this.baseurl}/verificar/serial`, data)
  }



  create(data) {
    return this.http.post(`${this.baseurl}/create/biomedico`, data)
  }

  detail(data) {
    return this.http.post(`${this.baseurl}/detail/biomedico`, data)
  }

  constructor(private http: HttpClient) { }
}
