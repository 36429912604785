<app-layout></app-layout>
<div class="main-panel">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg  navbar-transparent  navbar-absolute bg-primary fixed-top">
        <div class="container-fluid">
            <div class="navbar-wrapper">
                <div class="navbar-toggle">
                    <button type="button" class="navbar-toggler">
                        <span class="navbar-toggler-bar bar1"></span>
                        <span class="navbar-toggler-bar bar2"></span>
                        <span class="navbar-toggler-bar bar3"></span>
                    </button>
                </div>
                <a class="navbar-brand">{{tittle}}</a>
            </div>
            <app-navbar></app-navbar>
        </div>
    </nav>
    <!-- End Navbar -->
    <div class="panel-header panel-header-lg">
        <canvas id="bigDashboardChart"></canvas>
    </div>
    <div class="content">

        <div class="preloader-wrapper" style="display:none;">
            <div class="preloader">
                <!-- <img src="{{asset('img/loading.gif')}}" alt="NILA"> -->
            </div>
            <div class="alert cell alert-warning">Se esta enviando tu solicitud, espera un momento</div>
        </div>
        <hr>
        <br>
        <section class="card card-tasks panel-activos" *ngIf="!buscar">
            <h1>No se ha encontrado información para la busqueda de: {{Serial}}</h1>
        </section>

        <section class="card card-tasks panel-activos" *ngIf="buscar">
            <ng-container *ngFor="let general of general">
                <div class="col-12">
                    <div class="">
                        <div class="row">
                            <!-- <div class="col-6"> -->
                            <button type="button" routerLink="/mantenimiento/{{Serial}}" class="cell btn btn-danger" style="width:100%">
                            Mantenimiento
                        </button>
                            <!-- </div> -->
                            <!-- <div class="col-6"> -->
                            <!-- <a class="btn btn-primary cell" style="width:100%" href="/editar/computador/{{general.Codigo}}">Editar</a> -->
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row activo-profile">

                <div data-toggle="modal" data-target="#general" class="activo-profile__item">
                    <div class="activo-profile__item__icon"><i class="icon-PROFILE"></i></div>
                    <div class="activo-profile__item__title">
                        Datos Generales
                    </div>
                </div>

                <div *ngIf="tipo === 'Computador'" data-toggle="modal" data-target="#tecnicos" class="activo-profile__item">
                    <div class="activo-profile__item__icon"><i class="icon-DETAILS"></i></div>
                    <div class="activo-profile__item__title">
                        Datos Tecnicos
                    </div>
                </div>

                <div *ngIf="tipo === 'Computador'" data-toggle="modal" data-target="#software" class="activo-profile__item">
                    <div class="activo-profile__item__icon"><i class="fab fa-windows"></i></div>
                    <div class="activo-profile__item__title">
                        Datos de Software
                    </div>
                </div>

                <div data-toggle="modal" data-target="#red" class="activo-profile__item">
                    <div class="activo-profile__item__icon"><i class="icon-registrar_mantenimiento"></i></div>
                    <div class="activo-profile__item__title">
                        Red y Mantenimientos
                    </div>
                </div>
            </div>
        </section>


        <div class="card-body" card card-tasks panel-activos>

            <ng-container *ngFor="let general of general">

                <div class="modal fade" id="general" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Datos Generales</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                            </div>
                            <div class="modal-body text-center">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr">
                                            <p><b>Nombre</b><br /> <input class="form-control" type="text" value="{{general.NombreActivo}}" disabled></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr">
                                            <p><b>Valor Activo</b><br /> <input type="text" class="form-control" disabled value="{{general.Valor}}"></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                            <p><b>Número de Factura</b><br /> <input type="text" class="form-control" disabled value="{{general.NumeroFactura}}"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr">
                                            <p><b>Fecha Compra</b><br /> <input class="form-control" type="text" disabled value="{{general.FechaCompra}}"></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                            <p><b>Número Poliza</b><br /> <input class="form-control" type="text" disabled value="{{general.NumeroPoliza}}"></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                            <p><b>Marca</b><br /> <input type="text" class="form-control" disabled value="{{general.Marca}}"></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
                                        <div class="address-hr tb-sm-res-d-n dps-tb-ntn">
                                            <p><b>Modelo</b><br /> <input type="text" class="form-control" disabled value="{{general.Modelo}}"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="address-hr">
                                            <p><b>Serial</b><br /> <input class="form-control" type="text" disabled value="{{general.Serial}}"></p>

                                            <br>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="modal fade" id="tecnicos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Datos Tecnicos</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                            </div>
                            <div class="modal-body text-center">
                                <div class="row">
                                    <div class="col-12">
                                        <ng-container *ngFor="let tipo of activoinfo.tipocomputador">

                                            <h6>Tipo de computador</h6>
                                            <input type="text" class="form-control" disabled value="{{tipo.TipoPc}}" name="" id="">
                                            <hr>
                                        </ng-container>
                                    </div>
                                    <div class="col-12">
                                        <h6>Procesador</h6>
                                        <input type="text" class="form-control" disabled value="{{general.Procesador}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-7">
                                        <h6>Tamaño Pantalla</h6>
                                        <input type="text" class="form-control" disabled value="{{general.TamanoPantalla}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-12">
                                        <hr style="background: #3770ba">
                                        <i>Memoria</i>
                                        <hr style="background: #3770ba">
                                    </div>

                                    <div class="col-6">

                                        <ng-container *ngFor="let memoria of activoinfo.tipomemoria">

                                            <h6>Tipo de memoria</h6>
                                            <input type="text" class="form-control" disabled value="{{memoria.TipoMemoria}}" name="" id="">
                                            <hr>
                                        </ng-container>
                                    </div>
                                    <div class="col-6">
                                        <h6>Modulos de Ram</h6>
                                        <input type="text" class="form-control" disabled value="{{general.CantidadModulos}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-3">
                                        <h6>Modulo 1</h6>
                                        <input type="text" class="form-control" disabled value="{{general.CantMemoria1}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-3">
                                        <h6>Modulo 2</h6>
                                        <input type="text" class="form-control" disabled value="{{general.CantMemoria2}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-3">
                                        <h6>Modulo 3</h6>
                                        <input type="text" class="form-control" disabled value="{{general.CantMemoria3}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-3">
                                        <h6>Modulo 4</h6>
                                        <input type="text" class="form-control" disabled value="{{general.CantMemoria4}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="col-12">
                                        <hr style="background: #3770ba">
                                        <i>Disco duro</i>
                                        <hr style="background: #3770ba">
                                        <div class="box-body">
                                            <table class="tabla no-margin" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo de disco</th>
                                                        <th>Capacidad de disco</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr *ngFor="let row of activoinfo.discos">
                                                        <td>{{row.TipoDisco}}</td>
                                                        <td>{{row.CapacidadDisco}}
                                                        </td>
                                                    </tr>



                                                </tbody>
                                            </table>
                                        </div>




                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div class="modal fade" id="software" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Datos Tecnicos</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                            </div>
                            <div class="modal-body text-center">
                                <ng-container *ngFor="let s of activoinfo.soft">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <br>
                                            <ng-container *ngFor="let os of activoinfo.siste">
                                                <div class="col-12">
                                                    <h6>Sistema Operativo</h6>
                                                    <input type="text" class="form-control" disabled value="{{os.NombreOs}}">
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>Versión Sistema Operativo</h6>
                                                <input type="text" class="form-control" disabled value="{{s.VersionOs}}">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <br>
                                            <ng-container *ngFor="let li of activoinfo.liOs">
                                                <div class="col-12">
                                                    <h6>Tipo licencia del Sistema Operativo</h6>
                                                    <input type="text" class="form-control" disabled value="{{li.NombreLicencia}}">
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>Licencia Sistema Operativo</h6>
                                                <input type="text" class="form-control" disabled value="{{s.NumeroLicenciaOs}}">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>
                                                    Licencia Office</h6>
                                                <input type="text" class="form-control" disabled value="{{s.NumeroLicenciaOffice}}">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>Office</h6>
                                                <input type="text" class="form-control" disabled value="{{s.Office}}">
                                            </div>
                                        </div>


                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>Edición Office</h6>
                                                <input type="text" class="form-control" disabled value="{{s.IdTipoOffice}}">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>Antivirus</h6>
                                                <input type="data" class="form-control" disabled value="{{s.Antivirus}}">
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <br>
                                            <div class="col-12">
                                                <h6>Vencimiento Antivirus</h6>
                                                <input type="data" class="form-control" disabled value="{{s.VencimientoAntivirus}}">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <br>
                                            <div class="col-12">
                                                <h6>Otros Programas </h6>
                                                <textarea type="data" class="form-control" disabled style="height:15vh !important;margin-bottom:1em;">{{s.OtrosProgrmas}}</textarea>
                                            </div>
                                        </div>
                                        <br>

                                    </div>
                                </ng-container>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="modal fade" id="red" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Red y Mantenimientos</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                            </div>
                            <div class="modal-body text-center">
                                <div class="row">
                                    <div class="col-12">
                                        <ng-container *ngFor="let ciclo of activoinfo.ciclos">
                                            <h6>Ciclo de Mantenimiento</h6>
                                            <input type="text" class="form-control" disabled value="{{ciclo.NombreCiclo}}" name="" id="">
                                            <hr>
                                        </ng-container>
                                    </div>
                                    <div class="col-12">
                                        <h6>Proximo Mantenimiento</h6>
                                        <input type="text" class="form-control" disabled value="{{general.ProximoMantenimiento}}" name="" id="">
                                        <hr>
                                    </div>
                                    <div class="text-center">
                                        <button class="historial btn cell btn-primary">HISTORIAL DE
                                    MANTENIMIENTOS</button>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-12 ">
                                    <div id="historialContent">
                                        <div class="text-center">

                                            <br> {{Serial}}
                                            <a class="btn btn-danger cell" href="/mantenimiento/{{Serial}}" id="mantenimientos">Registrar un nuevo mantenimiento</a>
                                        </div>
                                        <div class="row">
                                            <ng-container *ngFor="let m of activoinfo.man">
                                                <br>
                                                <div class="col-md-12">
                                                    <div class="card-border">
                                                        <div class="card-header">
                                                            <div class="">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        Fecha: {{m.FechaMantenimiento}}
                                                                    </div>
                                                                    <div class="col-md-6">

                                                                        <p style="color:red;" *ngIf="m.TipoMantenimiento == 'Correctivo'">Tipo: {{m.TipoMantenimiento}}</p>
                                                                        <p style="color:green;" *ngIf="m.TipoMantenimiento == 'Preventivo'">Tipo: {{m.TipoMantenimiento}}</p>
                                                                    </div>

                                                                    <div class="card-content">
                                                                        <div class="col-md-12">
                                                                            <h2 style="text-transform:uppercase;">
                                                                                {{m.NombreActivo}}</h2>
                                                                            {{m.Descripcion}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cerrar</button>

                            </div>
                        </div>
                    </div>
                </div>





                <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Solicitud de soporte</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                            </div>
                            <form #soporteForm=ngForm (ngSubmit)="onSubmit()">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="">Nombre</label>
                                            <input type="text" class="form-control" name="NombreCliente" [(ngModel)]="soporte.NombreCliente">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Correo</label>
                                            <input type="email" class="form-control" name="CorreoCliente" [(ngModel)]="soporte.CorreoCliente">
                                        </div>
                                        <div class="col-md-12">
                                            <label for="">Asunto</label>
                                            <input type="text" class="form-control" name="Asunto" [(ngModel)]="soporte.Asunto">
                                        </div>
                                        <div class="col-md-12">
                                            <br>
                                            <hr>
                                            <br>
                                            <label for="">Mensaje</label>
                                            <textarea class="form-control" name="Mensaje" [(ngModel)]="soporte.Mensaje"></textarea>

                                        </div>

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                    <button type="submit" class="btn btn-primary">Enviar Solicitud</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>




            <!-- <script src="{{asset('js/appprofile.js')}}"></script> -->



            <div class="loading" *ngIf="loading"></div>