import { DispositivosOtrosService } from './../../Services/dispositivos-otros.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ImpresorasService } from './../../Services/impresoras.service';
import { TokenService } from './../../Services/token.service';
import swal from 'sweetalert2';
import { AuthService } from 'src/app/Services/auth.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-editardispositivos',
  templateUrl: './editardispositivos.component.html',
  styleUrls: ['./editardispositivos.component.scss']
})
export class EditardispositivosComponent implements OnInit {
  loading = false;
  mantenimientosactivo:any;
  sidebaractive:string = 'ficha';
  filesToUpload: Array<File> = [];
  
  detail = {
    id_user:this.Token.getUserId(),
    serial: null,
    tipoPC:null,
    tipoMemoria:null

  };
  detailactivo:any;
  p: number = 1;
  tittle: string = "editar impresora " + this.route.snapshot.paramMap.get("serial");
  idActivo: number;
  serial: string;
  mensaje:string;

  pasos:number = 1;

  licencias:any;
  tipocomputador:any;
  ciclosmantenimiento:any;
  sistemaoperativo:any;
  tipomemorias:any;
  data = {
    id: this.Token.getUserId(),
    serial: this.route.snapshot.paramMap.get("serial"),
    tipo: "Impresora"
  }
  
  computador: any;
  tickets: number;
  dataActivo: any;


  public form = {
  id: null,
  id_user:this.Token.getUserId(),
  NombreActivo:null,
  Marca:null,
  Modelo:null,
  TipoImpresora:null,
  Color:null,
  Multifuncion:null,
  TipoDispositivo:null,
  Red:null,
  Serial:null,
  FechaCompra:null,
  Contrasenas:null,
  DireccionIp:null,
  NumeroFactura:null,
  ValorActivo:null,
  NumeroPoliza:null,
  Observaciones:null,
  CiclosMantenimiento:null,
  ProximoMantenimiento:null
}


  
  public loggedIn;
  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private Auth: AuthService, private user: UserService, private Token: TokenService, private activo: DispositivosOtrosService) { }

  details(id,tipo,memoria){
    this.detail.serial = id;
    this.detail.tipoPC = tipo;
    this.detail.tipoMemoria = memoria;
    

    this.activo.detail(this.detail).subscribe(
        data => {
          console.log(data); this.detailactivo = data},
        error => console.log(error) 
    )
    
  }

  

  onSubmit(){
    this.loading = true;
        this.activo.editar(this.form).subscribe(
          data => {
            this.createcomputadores(data)
          },
          error => {this.loading = false}
        );     
  }

  deleteComputadorResponse(data) {
    this.computador = data.activo;
  }

  ngOnInit() {
    this.activo.getActivo(this.data).subscribe(
      data => this.computadores(data),
      erro => console.log(erro)
    );
    this.loggedIn = this.Auth.authStatus.subscribe(value => this.loggedIn = value);
  }


  createcomputadores(data) {
     this.loading = false;
    this.pasos=1;
    swal.fire(
      'Excelente!',
      'Hemos actualizado la información de tu activo',
      'success'
    )
    // alert(data.mensaje);
    this.router.navigate(['/dispositivos-red']);
    
 
    this.computador = data.activo;
 
  }

  computadores(datos) {
    console.log(datos);
    this.form.id = datos.activo[0].IdActivo;
    this.form.NombreActivo = datos.activo[0].NombreActivo;
    this.form.Marca = datos.activo[0].Marca;
    this.form.Modelo = datos.activo[0].Modelo;
    this.form.TipoImpresora = datos.activo[0].TipoImpresora;
    this.form.Contrasenas = datos.activo[0].Contrasenas;
    this.form.Color = datos.activo[0].Color;
    this.form.Multifuncion = datos.activo[0].Multifuncion;
    this.form.Red = datos.activo[0].Red;
    this.form.Serial = datos.activo[0].Serial;
    this.form.DireccionIp = datos.activo[0].DireccionIp;
    this.form.FechaCompra = datos.activo[0].FechaCompra;
    this.form.NumeroFactura = datos.activo[0].NumeroFactura;
    this.form.ValorActivo = datos.activo[0].ValorActivo;
    this.form.NumeroPoliza = datos.activo[0].NumeroPoliza;
    this.form.Observaciones = datos.activo[0].Observaciones;
    this.form.CiclosMantenimiento = datos.activo[0].CiclosMantenimiento;
    this.form.TipoDispositivo = datos.activo[0].TipoDispositivo;
    this.form.ProximoMantenimiento = datos.activo[0].ProximoMantenimiento;

  }
  view = "lista";
  deleteImpresora(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        console.log(id);
        this.dataActivo = {
          id: id,
          serial: serial,
          idUser: this.Token.getUserId(),
          tipo: "Impresora"
        }
        this.activo.deleteActivo(this.dataActivo).subscribe(
          data => this.deleteComputadorResponse(data),
          error => console.log(error)
        )
        swalWithBootstrapButtons.fire(
          'Se eliminó!',
          'Se ha eliminado tu activo.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })

  }
}