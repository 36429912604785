import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private iss = {
    login: "https://quar.com.co/apilogin",
    signup: "https://quar.com.co/apisignup"
  }

  private baseurl = "https://quar.com.co/api"

  constructor(private http: HttpClient) { }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  generarVisitasCodigo(data){
    return this.http.post(`${this.baseurl}/visitascodigo`,data);
  }


  errorEmail(data){
    return this.http.post(`${this.baseurl}/emailerror`,data);
  }

  handle(token){
      this.set(token);
      console.log(this.isValid())
  }

  handleUser(id){
    localStorage.setItem('id', id)
  }

  handleName(name){
    localStorage.setItem('name', name)
  }

  handleSede(name){
    localStorage.setItem('sede', name)
  }

  handleEmail(email){
    localStorage.setItem('email', email)
  }

  handleAdmin(user){
    console.log(user);
    localStorage.setItem('user', user)
  }


  set(token){
    localStorage.setItem('token', token)
  }

  get(){
    return localStorage.getItem('token');
  }

  getUserId(){
    return localStorage.getItem('id');
  }

  getUserName(){
    return localStorage.getItem('name');
  }

  getUserEmail(){
    return localStorage.getItem('email');
  }

  getUserSede(){
    return localStorage.getItem('sede');
  }

  getUserAdministrador(){
    return localStorage.getItem('user');
  }


  remove(){
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('email');
    localStorage.removeItem('user');
  }

  isValid(){
      const token = this.get();
      if(token){
        const payload = this.payload(token);
        if(payload){
          // return  (payload.iss == 'https://joulepublicidad.synology.me/api/login') ? true: false;
          return Object.values(this.iss).indexOf(payload.iss) > -1 ? true:false;
        }
      }
      return false;

  }

  payload(token){
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload){
    return JSON.parse(atob(payload));
  }


  loggedIn(){
    return this.isValid();
  }

}
