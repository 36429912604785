<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div *ngIf="detalle" class="info-slide">
    <div class="info-slide__container">
        <!-- Aquí va el contenido -->
        <div class="sidebarinfo__close">
            <i id="close_sidebar" (click)="detalle = false" class="fas fa-times"></i>
        </div>
        <div class="sidebarinfo__container">
            <div class="sidebarinfo__container__title">
                <h5 id="title_ficha"></h5>
            </div>
            <div class="sidebarinfo__container__content">
                <ul class="sidebarinfo__container__content__tabs">
                    <li class="sidebarinfo__container__content__tabs__item" id="ficha"
                        (click)="sidebaractive = 'ficha'">Ficha Técnica</li>
                    <li class="sidebarinfo__container__content__tabs__item" id="trazabilidad"
                        (click)="sidebaractive = 'notas'">Historial de Archivos</li>
                    <li class="sidebarinfo__container__content__tabs__item" id="manten"
                        (click)="sidebaractive = 'mantenimientos'">Historial de Mantenimientos</li>
                </ul>
                <div class="sidebarinfo__container__content__tabs__content__uno">
                    <!-- {{detailactivo | json }} -->
                    <div id="fichatecnica" class="sidebarinfo__container__content__tabs__content__uno__details">
                        <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha"
                            *ngIf="sidebaractive == 'ficha'">
                            <a href="http://localhost:8000/pdf/{{detail.serial}}" target="_blank"><button
                                    class=" cell btn btn-primary">Exportar Ficha técnica
                                    al
                                    PDF</button></a>
                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos">
                                <div
                                    class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__title">
                                    <h4>Datos Generales</h4>
                                </div>
                                <ng-container *ngFor="let general of detailactivo.activo;">
                                    <div
                                        class="form-group sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items row">
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Nombre del activo</label><input
                                                value="{{general.NombreActivo}}" type="text" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Valor del Activo</label><input type="text"
                                                name="" value="{{general.Valor}}" id="" class="form-control" disabled>
                                        </div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Número de Factura</label><input type="text"
                                                value="{{general.NumeroFactura}}" name="" id="" class="form-control"
                                                disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Fecha Compra</label><input
                                                value="{{general.FechaCompra}}" type="text" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Número Poliza</label><input type="text"
                                                value="{{general.NumeroPoliza}}" name="" id="" class="form-control"
                                                disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Marca</label><input type="text" name="" id=""
                                                value="{{general.Marca}}" class="form-control" disabled>
                                        </div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Modelo</label><input type="text" name="" id=""
                                                value="{{general.Modelo}}" class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Serial</label><input type="text" name="" id=""
                                                value="{{general.Serial}}" class="form-control" disabled></div>
                                    </div>

                                    <div
                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__title">
                                        <h4>Datos de Técnicos</h4>
                                    </div>
                                    <div
                                        class="form-group sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items row">
                                        <ng-container *ngFor="let tipoNombre of detailactivo.tipocomputadordetail;">
                                            <div
                                                class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Tipo de Computador</label><input
                                                    value="{{tipoNombre.TipoPc}}" type="text" name="" id=""
                                                    class="form-control" disabled></div>
                                        </ng-container>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Procesador</label><input type="text" name=""
                                                id="" value="{{general.Procesador}}" class="form-control" disabled>
                                        </div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Tamaño de pantalla</label><input
                                                value="{{general.TamanoPantalla}}" type="text" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-12">
                                            <br><br>
                                            <hr style="background: #3770ba">
                                            <h3>Memoria</h3>
                                            <hr style="background: #3770ba">
                                        </div>
                                        <ng-container *ngFor="let memoriati of detailactivo.tipomemoriadetail">
                                            <div
                                                class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Tipo de Memoria</label><input type="text"
                                                    name="" id="" value="{{memoriati.TipoMemoria}}" class="form-control"
                                                    disabled></div>
                                        </ng-container>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Modulos de Ram</label><input type="text" name=""
                                                id="" value="{{general.CantidadModulos}}" class="form-control" disabled>
                                        </div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-3">
                                            <label for="" class="label">Módulo 1</label><input type="text"
                                                value="{{general.CantMemoria1}}" name="" id="" class="form-control"
                                                disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-3">
                                            <label for="" class="label">Módulo 2</label><input type="text" name="" id=""
                                                value="{{general.CantMemoria2}}" class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-3">
                                            <label for="" class="label">Módulo 3</label><input type="text" name="" id=""
                                                value="{{general.CantMemoria3}}" class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-3">
                                            <label for="" class="label">Módulo 4</label><input type="text" name="" id=""
                                                value="{{general.CantMemoria4}}" class="form-control" disabled></div>
                                        <div *ngIf="detailactivo.discos"
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-12">
                                            <br><br>
                                            <hr style="background: #3770ba">
                                            <h3>Discos</h3>
                                            <hr style="background: #3770ba">
                                        </div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-12 row">
                                            <ng-container *ngFor="let discos of detailactivo.discos">
                                                <div
                                                    class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos col-md-4">
                                                    <div
                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__title">
                                                        {{discos.TipoDisco}}</div>
                                                    <div
                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__icon">
                                                        <i class="far fa-save"></i></div>
                                                    <div
                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__capacidad">
                                                        {{discos.CapacidadDisco}} GB</div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>
                                </ng-container>
                                <hr>
                                <div
                                    class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__title">
                                    <h4>Datos de Software</h4>
                                </div>
                                <div
                                    class="form-group sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items row">
                                    <ng-container *ngFor="let software of detailactivo.softwaredetail">
                                        <ng-container *ngFor="let sistem of detailactivo.sistemaoperativo">
                                            <div
                                                class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Sistema Operativo</label><input
                                                    value="{{sistem.NombreOs}}" type="text" name="" id=""
                                                    class="form-control" disabled></div>
                                        </ng-container>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Versión sistema Operativo</label><input
                                                value="{{software.VersionOs}}" type="text" name="" id=""
                                                class="form-control" disabled></div>
                                        <ng-container *ngFor="let tipoli of detailactivo.tipolicencia">
                                            <div
                                                class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Tipo Licencia Sistema
                                                    Operativo</label><input type="text" name=""
                                                    value="{{tipoli.NombreLicencia}}" id="" class="form-control"
                                                    disabled></div>
                                        </ng-container>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Licencia Sistema Operativo</label><input
                                                type="text" value="{{software.NombreLicencia}}" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Licencia Office</label><input type="text"
                                                value="{{software.NumeroLicenciaOffice}}" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Office</label><input type="text" name="" id=""
                                                value="{{software.Office}}" class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Edición Office</label><input
                                                value="{{software.IdTipoOffice}}" type="text" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Antivirus</label><input type="text" name=""
                                                id="" value="{{software.Antivirus}}" class="form-control" disabled>
                                        </div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                            <label for="" class="label">Vencimiento Antivirus</label><input type="text"
                                                value="{{software.VencimientoAntivirus}}" name="" id=""
                                                class="form-control" disabled></div>
                                        <div
                                            class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-12">
                                            <label for="" class="label">Otros Programas</label><textarea name="" id=""
                                                class="form-control" disabled
                                                style="height:15vh !important;margin-bottom:1em;">{{software.OtrosProgrmas}}</textarea>
                                        </div>
                                    </ng-container>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="sidebaractive === 'notas'" class="sidebarinfo__container__content__tabs__content__dos">
                    <div class="sidebarinfo__container__content__tabs__content__dos__container__new ">

                        <h4>Registrar novedad al activo</h4>
                        <label for="">Este archivo contiene:</label>
                        <textarea class="form-control" [(ngModel)]="archivo.observacion" name="descripcion"></textarea>
                        <hr>

                        <input type="file" name="invima" (change)="change($event)">
                        <button class="btn btn-primary cell" type="button" (click)="upload()">Adjuntar Archivo</button>

                        <hr>
                        <br>
                        <div class="input-group mb-2 mr-sm-2">
                            <input type="search" class="form-control" id="inlineFormInputGroupUsername2"
                                placeholder="Buscar Archivo">
                            <div class="input-group-prepend">
                                <div class="input-group-text btn-primary text"
                                    style="width: 50px; text-align: center !important;"><i class="fas fa-search"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card card-novedad" ([ngModel])="novedades" *ngFor="let file of novedades">
                            <div class="card-novedad__container">
                                <div class="row card-novedad__container__observacion">
                                    <!-- {{novedades}} -->
                                    <div class="col-md-12 text-center card-novedad__container__observacion__fecha">
                                        {{file.fecha_creacion}}
                                    </div>

                                    <div class="col-md-12 text-center card-novedad__container__observacion__text">
                                        <br>
                                        <br>
                                        <h3>Descripción del Archivo</h3>
                                        <p>{{file.Observacion}}</p>
                                    </div>
                                    <div class="col-md-12 card-novedad__container__observacion__text">
                                        <table class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Archivo</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{file.File}}</td>
                                                    <td>
                                                        <a target="_self"
                                                            href="http://localhost:8000/files/computador/logoredaf-2.png"
                                                            download="http://localhost:8000/files/computador/{{file.File}}">
                                                            <button class="btn btn-primary">
                                                                <i class="fas fa-download"></i>
                                                            </button>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <img src="http://localhost:8000/files/computador/logoredaf-2.png" /> -->
                    </div>
                </div>
                <div class="sidebarinfo__container__content__tabs__content__tres" id="mantenimientos"
                    *ngIf="sidebaractive == 'mantenimientos'">
                    <br>
                    <hr>
                    <ng-container *ngFor="let m of detailactivo.mantenimientosactivo" class="card">
                        <div class="sidebarinfo__container__content__tabs__content__uno__details__item">
                            <div class="sidebarinfo__container__content__tabs__content__uno__details__item__user">
                                <div
                                    class="sidebarinfo__container__content__tabs__content__uno__details__item__user__name">
                                    <i class="far fa-user-circle"></i>  {{m.nombre}}
                                    <hr>
                                    <ng-container *ngIf="m.TipoMantenimiento == 'Correctivo'">
                                        <p style="color:red;">Tipo: {{m.TipoMantenimiento}}</p>
                                    </ng-container>
                                    <ng-container *ngIf="m.TipoMantenimiento == 'Preventivo'">
                                        <p style="color:green;">Tipo: {{m.TipoMantenimiento}}</p>
                                    </ng-container>
                                </div>
                                <div
                                    class="sidebarinfo__container__content__tabs__content__uno__details__item__user__date">
                                    {{m.FechaMantenimiento}}
                                </div>
                            </div>
                            <div class="sidebarinfo__container__content__tabs__content__uno__details__item__update">
                                {{m.Descripcion}}
                            </div>
                        </div>
                    </ng-container>



                </div>
            </div>
        </div>

    </div>
</div>

<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view='lista'">Computadores</li>
                                <li class="breadcrumb-item active" *ngIf="view=='serial'">Serial</li>
                                <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a (click)="view='serial'" class="btn btn-primary text-white" target="_blank">Agregar
                            Computador</a>
                        <!-- <a href="https://quar.com.co/api/excel/computadores/{{IdExcel}}/{{data.id}}" class="btn btn-danger text-white"
                            target="_blank">Exportar excel</a> -->
                            <a  (click)="exportexcel()" class="btn btn-danger text-white"
                            target="_blank">Exportar tabla</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div *ngIf="view!='lista'" class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro de activo</h4>
                                    <h5 class="card-subtitle" *ngIf="view=='serial'">Asignar Serial</h5>
                                    <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario de registro</h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <form *ngIf="view=='registro'" class="content-registro__container__form"
                                        #computadorForm="ngForm" (ngSubmit)="onSubmit(computadorForm)">
                                        <div class="row">
                                            <div class="col-md-12 container__datos_generales" *ngIf="pasos=='uno'">
                                                <div class="row">
                                                    <div class="col-lg-12 ">
                                                        <h2>¿Estás listo? ...Arranquemos</h2>
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="10" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 10%">
                                                            </div>
                                                        </div>
                                                        <h4>Datos Generales</h4>
                                                        <hr>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <input type="text" placeholder="Nombre del Activo"
                                                            class="form-control" [(ngModel)]="form.NombreActivo"
                                                            name="NombreActivo" required>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select class="form-control" name="idTipoPc"
                                                            [(ngModel)]="form.idTipoPc">
                                                            <option value="" selected>Tipo de Computador</option>
                                                            <ng-container *ngFor="let tipo of tipocomputador;">
                                                                <option value="{{tipo.IdTipoPc}}">{{tipo.TipoPc}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Marca" class="form-control"
                                                            [(ngModel)]="form.Marca" name="Marca">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Modelo"
                                                            [(ngModel)]="form.Modelo" class="form-control"
                                                            name="Modelo">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Serial" class="form-control"
                                                            [(ngModel)]="form.Serial" name="Serial">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="date" placeholder="Fecha de Compra"
                                                            [(ngModel)]="form.FechaCompra" class=" form-control "
                                                            name=FechaCompra>
                                                    </div>
                                                    <div class="col-md-6 ">
                                                        <input type="text " placeholder="Número de Factura"
                                                            class="form-control " [(ngModel)]="form.NumeroFactura"
                                                            name="NumeroFactura">
                                                    </div>
                                                    <div class="col-md-6 ">
                                                        <input type="text " placeholder="Valor del Activo"
                                                            class="form-control " [(ngModel)]="form.Valor" name="Valor">
                                                    </div>
                                                    <div class="col-md-6 ">
                                                        <input type="text " placeholder="Procesador"
                                                            class="form-control " [(ngModel)]="form.Procesador"
                                                            name="Procesador">
                                                    </div>
                                                    <div class="col-md-6 ">
                                                        <input type="text " placeholder="Tamaño Pantalla (Pulgadas)"
                                                            [(ngModel)]="form.TamanoPantalla" class="form-control "
                                                            name="TamanoPantalla">
                                                    </div>
                                                    <div class="col-md-12 ">
                                                        <textarea name="" class="form-control" id=""
                                                            [(ngModel)]="form.Password" placeholder="Contraseñas"
                                                            name="Password"></textarea>
                                                    </div>
                                                </div>
                                                <button (click)="validarNombre()" type="button"
                                                    class="btn btn-primary">Continuar <i
                                                        class="fas fa-arrow-right"></i></button>

                                            </div>

                                            <!-- Datos de Memoria -->
                                            <div class="col-md-12" *ngIf="pasos=='dos'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 25%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h3>Datos Memoria</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="idTipoMemoria" [(ngModel)]="form.idTipoMemoria"
                                                            aria-placeholder="Selecciona una opción"
                                                            class="form-control">
                                                            <option value="1020493468" selected>Tipo de Memoria
                                                            </option>
                                                            <ng-container *ngFor="let tipo of tipomemorias;">
                                                                <option value="{{tipo.IdTipoMemoria}}">
                                                                    {{tipo.TipoMemoria}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" [(ngModel)]="form.CantidadModulos"
                                                            name="CantidadModulos" placeholder="Cantidad de Módulos"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 1"
                                                            [(ngModel)]="form.CantMemoria1" name="CantMemoria1"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 2"
                                                            [(ngModel)]="form.CantMemoria2" name="CantMemoria2"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 3"
                                                            [(ngModel)]="form.CantMemoria3" name="CantMemoria3"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 4"
                                                            class="form-control" [(ngModel)]="form.CantMemoria4"
                                                            name="CantMemoria4">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h3>Datos Discos</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">Tipo de Disco</label>
                                                        <select class="form-control" name="TipoDisco"
                                                            [(ngModel)]="discos.TipoDisco">
                                                            <option value="RIGIDO">RIGIDO</option>
                                                            <option value="SSHD">SSHD</option>
                                                            <option value="SOLIDO">SOLIDO</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">Capacidad del disco</label>
                                                        <input type="text" placeholder="Cantidad de Disco"
                                                            class="form-control" name="capacidadDisco"
                                                            [(ngModel)]="discos.CapacidadDisco">
                                                    </div>
                                                    <div class="col-md-12 text-center">
                                                        <div class="row">
                                                            <ng-container ([ngModel])="discosarray"
                                                                *ngFor="let discos of discosarray" class="col-md-2">
                                                                <div
                                                                    class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos col-md-4">
                                                                    <div
                                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__title">
                                                                        {{discos.TipoDisco}}</div>
                                                                    <div
                                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__icon">
                                                                        <i class="far fa-save"></i></div>
                                                                    <div
                                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__capacidad">
                                                                        {{discos.CapacidadDisco}} GB</div>
                                                                    <button class="btn btn-alert" type="button"
                                                                        (click)="eliminardisco(discos.IdDisco)">Eliminar</button>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <button class="btn btn-primary" type="button"
                                                            (click)="addDisk()">Registrar Disco</button>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                        <button (click)="pasos='uno'" class="btn btn-secondary"> <i
                                                                class="fas fa-arrow-left"></i> Anterior</button>
                                                        <button (click)="pasos='tres'" class="btn btn-primary">Continuar
                                                            <i class="fas fa-arrow-right"></i></button>


                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Fin datos de memoria -->
                                            <!-- Software -->
                                            <div class="col-md-12" *ngIf="pasos=='tres'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="50" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 50%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h3>Datos de Software</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="IdSistemaOperativo"
                                                            [(ngModel)]="form.IdSistemaOperativo" class="form-control">
                                                            <option value="1020493468">Sistema Operativo</option>
                                                            <ng-container *ngFor="let tipo of sistemaoperativo;">
                                                                <option value="{{tipo.idOs}}">{{tipo.NombreOs}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="VersionOs" [(ngModel)]="form.VersionOs"
                                                            class="form-control">
                                                            <option value="1020493468">Edición Sistema Operativo
                                                            </option>
                                                            <option value="Profesional">Profesional</option>
                                                            <option value="Home">Home</option>
                                                            <option value="Single">Single</option>
                                                            <option value="Ultimate">Ultimate</option>
                                                            <option value="Starter">Starter</option>
                                                            <option value="Enterprise">Enterprise</option>
                                                            <option value="Education">Education</option>
                                                            <option value="Standard">Standard</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="IdTipoLicenciaOs"
                                                            [(ngModel)]="form.IdTipoLicenciaOs" class="form-control">
                                                            <option value="4">Tipo de Licencia</option>
                                                            <ng-container *ngFor="let tipo of licencias;">
                                                                <option value="{{tipo.idLicencia}}">
                                                                    {{tipo.NombreLicencia}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.NumeroLicenciaOs" name="NumeroLicenciaOs"
                                                            placeholder="Licencia Sistema Operativo">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select [(ngModel)]="form.Office" name="Office"
                                                            class="form-control">

                                                            <option value="NO TIENE">Versión de Office</option>
                                                            <option value="Open Office">Open Office</option>
                                                            <option value="365 Hogar">365 Hogar</option>
                                                            <option value="365 Personal">365 Personal</option>
                                                            <option value="365 Empresa">365 Empresa</option>
                                                            <option value="365 Empresa Premium Empresa">365 Empresa
                                                                Premium Empresa
                                                            </option>
                                                            <option value="365 Empresa Essentials">365 Empresa
                                                                Essentials</option>
                                                            <option value="2019">2019</option>
                                                            <option value="2016">2016</option>
                                                            <option value="2013">2013</option>
                                                            <option value="2010">2010</option>
                                                            <option value="2007">2007</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="IdTipoOffice" [(ngModel)]="form.IdTipoOffice"
                                                            class="form-control">
                                                            <option value="NO TIENE">Edición de Office</option>
                                                            <option value="Starter">Starter</option>
                                                            <option value="Hogar y Estudiantes">Hogar y Estudiantes
                                                            </option>
                                                            <option value="Hogar y Pequeños Negocios">Hogar y
                                                                Pequeños Negocios
                                                            </option>
                                                            <option value="Standard">Standard</option>
                                                            <option value="Profesional">Profesional</option>
                                                            <option value="Profesional Plus">Profesional Plus
                                                            </option>
                                                            <option value="Academico">Academico</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Licencia Office"
                                                            [(ngModel)]="form.NumeroLicenciaOffice"
                                                            name="NumeroLicenciaOs" class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Antivirus"
                                                            [(ngModel)]="form.Antivirus" name="Antivirus"
                                                            class=" form-control ">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">Fecha de Vencimiento de Office</label>
                                                        <input type="date" placeholder="Fecha de Vencimiento Office"
                                                            [(ngModel)]="form.FechaVenOffce" name="FechaVenOffce"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6 ">
                                                        <label for="">Fecha de Vencimiento de Antivirus</label>
                                                        <input type="date" [(ngModel)]="form.VencimientoAntivirus"
                                                            name="VencimientoAntivirus"
                                                            placeholder="Vencimiento de Antivirus"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-12 ">
                                                        <textarea name="" [(ngModel)]="form.OtrosProgrmas"
                                                            name="OtrosProgrmas" class="form-control" id=""
                                                            placeholder="Otros Programas"></textarea>
                                                    </div>
                                                </div>
                                                <br>
                                                <button (click)="pasos='dos'" class="btn btn-secondary"> <i
                                                        class="fas fa-arrow-left"></i> Anterior</button>
                                                <button (click)="pasos='cuatro'" class="btn btn-primary">Continuar <i
                                                        class="fas fa-arrow-right"></i></button>

                                            </div>
                                            <!-- Red y Mantenimientos -->
                                            <div class="col-md-12" *ngIf="pasos=='cuatro'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 75%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h3>Red y Mantenimientos</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.Red_Nombre_PC" name="Red_Nombre_PC"
                                                            placeholder="Nombre del PC en Red">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" [(ngModel)]="form.MAC"
                                                            placeholder="Dirección MAC" name="MAC">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" [(ngModel)]="form.IP"
                                                            placeholder="Dirección IP" name="IP">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.Puerta_de_Enlace" name="Puerta_de_Enlace"
                                                            placeholder="Puerta de Enlace">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">Ciclos de Mantenimiento</label>
                                                        <select [(ngModel)]="form.IdCicloMantenimiento"
                                                            name="IdCicloMantenimiento" class="form-control">
                                                            <ng-container *ngFor="let tipo of ciclosmantenimiento;">
                                                                <option value="{{tipo.IdCiclo}}">
                                                                    {{tipo.NombreCiclo}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">Fecha Proximo Mantenimiento</label>
                                                        <input type="date" [(ngModel)]="form.ProximoMantenimiento"
                                                            name="ProximoMantenimiento"
                                                            placeholder="Proximo Mantenimiento" class="form-control">
                                                    </div>

                                                </div>
                                                <button (click)="pasos='tres'" class="btn btn-secondary"> <i
                                                        class="fas fa-arrow-left"></i> Anterior</button>
                                                <button type="submit" class="btn btn-primary"
                                                    [disabled]="!computadorForm.valid">Registrar Computador</button>
                                            </div>
                                            <!-- Fin de Software -->

                                            <div class="col-lg-12">
                                            </div>
                                        </div>
                                    </form>
                                    <div class="content-serial" *ngIf="view == 'serial'">

                                        <div class="container">
                                            <div class="form group">
                                                <input type="text" placeholder="Serial del código" class="form-control"
                                                    name="Codigo" [(ngModel)]="form.Codigo">
                                            </div>
                                            <br>
                                            <div class="form-group">
                                                <button class="btn btn-danger cell" (click)="serialVerify()">Verificar
                                                    serial</button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a
                                                    Computadores</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button class="btn btn-primary cell" (click)="view='registro'">Quiero
                                                    continuar sin serial</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Activos informáticos</h4>
                                    <h5 class="card-subtitle">Lista de computadores</h5>
                                </div>
                                <div class="ml-auto">

                                    <div class="dl row">
                                        <div class="form-group">
                                            <label for="">Mostrar</label>
                                            <select class="form-control" (change)="mostrar($event)" id="">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                            </select>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="">Buscar computador<input type="search" [(ngModel)]="term"
                                                        (keydown)="filtrar($event)" class="form-control"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>

                        <div class="table-responsive">
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Serial</th>
                                        <th class="border-top-0">Nombre del Activo</th>
                                        <th class="border-top-0">Sede</th>
                                        <th class="border-top-0">Asignado</th>
                                        <th class="border-top-0">Proximo Mantenimiento</th>
                                        <th class="border-top-0">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ([ngModel])="computador"
                                        class="table-activo__container table-striped __head__body__item"
                                        *ngFor="let item of computador | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <ng-container>
                                            <td (click)="details(item.Codigo,item.IdTipoPc,item.idTipoMemoria)">
                                                {{item.Codigo}}
                                            </td>
                                            <td (click)="details(item.Codigo,item.IdTipoPc,item.idTipoMemoria)">
                                                {{item.NombreActivo}}
                                            </td>
                                            <td>

                                                <select name="sede" class="form-control" (change)="onChange(item.Codigo,$event)" [(ngModel)]="item.IdSede">
                                                        <option value="null">No tiene sede</option>
                                                        <option *ngFor="let sede of sedes"  value="{{sede.IdSede}}">{{sede.NombreSede}}</option>

                                                </select>
                                            </td>
                                            <td>
                                            
                                                <select name="asignado" class="form-control" (change)="onChangeAsignado(item.Codigo,$event)" [(ngModel)]="item.id_asignado">
                                                        <option value="null">No tiene asignado</option>
                                                        <option *ngFor="let em of empleados"  value="{{em.IdEmpleado}}">{{em.Nombre}} {{em.Apellido}}</option>
                                                </select>
                                            </td>
                                            <td (click)="details(item.Codigo,item.IdTipoPc,item.idTipoMemoria)"
                                                class="text-left">
                                                {{item.ProximoMantenimiento}}</td>

                                            <td class="td-actions text-left">
                                                <button routerLink="/editar/computador/{{item.Codigo}}" type="button"
                                                    rel="tooltip" title=""
                                                    class="btn btn-round btn-icon btn-icon-mini btn-edit"
                                                    data-original-title="Edit Task">
                                                    <i class="icon-edit"></i>
                                                </button>
                                                <button type="button" rel="tooltip" title=""
                                                    (click)="deleteComputador(item.idActivo, item.Codigo)"
                                                    class="btn btn-round btn-icon btn-icon-mini btn-delete"
                                                    data-original-title="Remove">
                                                    <i class="icon-REMOVE"></i>
                                                </button>
                                                <button type="button" title="remove" class="btn btn-primary"
                                                    routerLink="/mantenimiento/{{item.Codigo}}" data-toggle="modal"
                                                    data-target="#exampleModal">
                                                    <i class="icon-mantenimiento">Nuevo Mantenimiento</i>
                                                </button>
                                                <button type="button" title="remove" class="btn btn-primary"
                                                    (click)="preduplicar(item.idActivo, item.Codigo)"
                                                    data-toggle="modal" data-target="#exampleModal"
                                                    class="btn btn-round btn-secondary">
                                                    DUPLICAR
                                                </button>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="panel-activos__body__pagination text-center">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>





<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Duplicar activo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="">
                    Si tienes un seríal ingresalo en el siguiente campo
                </label>
                <input type="text" class="form-control cell" placeholder="Digite un serial" name="serial"
                    [(ngModel)]="activodupl.nuevoserial" (change)="serialVerifyDuplicar()">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" (click)="duplicar()" class="btn btn-secondary">No tengo serial</button>
                <button type="button" [disabled]="activodupl.nuevoserial == null" (click)="duplicar()"
                    class="btn btn-primary">Duplicar con el serial</button>
            </div>
        </div>
    </div>
</div>
