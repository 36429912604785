import { ToastrService } from 'ngx-toastr';
import { TokenService } from './../Services/token.service';
import { Component, OnInit } from '@angular/core';
import { CorreoService } from '../Services/correo.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-correspondencia',
  templateUrl: './correspondencia.component.html',
  styleUrls: ['./correspondencia.component.scss']
})
export class CorrespondenciaComponent implements OnInit {

  tittle = "gestión Correspondencia"
  constructor(private correo:CorreoService, private Token:TokenService, private toastr: ToastrService) { }
  admin;

  correos;
  form = {
    // id_user: this.Token.getUserId(),
    id:null,
    Destino:null,Ubicacion:null	,Recibio:this.Token.getUserName(), Entrego: this.Token.getUserName()	,IdSede:this.Token.getUserSede(),Observacion:null,Admin:""
  }
  term;
  loading = false;
  onSubmit(){
    this.loading = true;
    this.correo.create(this.form).subscribe(
        data => {
          this.toastr.success("Se registró exitosamente la observación")
          this.form.Destino = "";
          this.form.Observacion = "";
          this.loading = false;
          this.correos = data['correo'];
        }, error => {
          this.loading = false;
          console.log(error);
        }
    )
  }

  entregar(id){
    this.form.id = id;
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Entregar',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       
        this.correo.update(this.form).subscribe(
          data => {this.correos = data['correo']
            this.toastr.success("Se ha actualizado la entrega")
        },
          error => {
            this.toastr.error("Ha ocurrido un error", 'Actualizar correo activo', {
              timeOut: 3000
            });
          }
        )

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'No se ha actualizado',
          'error'
        )
      }
    })
  }

  ngOnInit(): void {
    if(parseInt(this.Token.getUserAdministrador()) > 0){
      console.log("Has Admin");
      this.form.Admin = this.Token.getUserAdministrador();
      this.admin = false;
    } else{
      this.admin = true;
      this.form.Admin = this.Token.getUserId();
      
      
    }
      this.correo.correo(this.form).subscribe(
        data => {this.correos = data['correo']},
        error => {console.log(error)}
      )
  }

}
