import { UserService } from 'src/app/Services/user.service';
import { TokenService } from 'src/app/Services/token.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {


  view = false;

  menuinitial(){
    
    // alert()
  }


  menu() {
    // alert(this.menubool)
    jQuery("#main-wrapper").removeAttr("data-sidebartype");
    jQuery("#asidesidebar").toggleClass('hola');
  }
  menu2() {
      // alert(this.menubool)
      jQuery("#main-wrapper").attr("data-sidebartype");
      jQuery("#asidesidebar").toggleClass('hola');

    // if (!localStorage.getItem("menu")) {
    //   jQuery("#main-wrapper").toggleClass("show-sidebar");
    //   jQuery("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
    //   // jQuery(".page-wrapper").css("margin-left",'250px');
    //   jQuery(".nav-toggler i").toggleClass("ti-menu");
    //   // localStorage.removeItem("menu");
    //   localStorage.setItem("menu", "menu");
    // } else {
    //   jQuery("#main-wrapper").toggleClass("show-sidebar");
    //   jQuery("#main-wrapper").attr("data-sidebartype", "full");


    //   jQuery(".page-wrapper").css("margin-left", '0');
    //   jQuery("#asidesidebar").css('display', 'none');
    //   jQuery(".nav-toggler i").toggleClass("ti-menu");
    //   localStorage.removeItem("menu");
    //   // localStorage.setItem("menu", "false");
    // }
    // // jQuery(".nav-toggler").on('click', function () {

    // });
    // // jQuery(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
    //   jQuery(".app-search").toggle(200);
    //   jQuery(".app-search input").focus();
    // });

  }


  constructor(private toastr:ToastrService, private token: TokenService, private router: Router, private users: UserService, private params: ActivatedRoute) { }

  logout() {
    this.token.remove();
    this.router.navigate(['/login']);
  }

  name = this.token.getUserName();

  serialbusqueda;

  search() {
    // alert();
    this.router.navigate(['/buscar/' + this.serialbusqueda.toUpperCase()]);
  }
  codigo;
  admin = false;

  user = {
    id: this.token.getUserId()
  }
  estado: any;

  

  ngOnInit() {
    this.menuinitial()
    // jQuery("#main-wrapper").removeAttr("data-sidebartype");
    // $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
    localStorage.setItem("menu", "menu");
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.codigo = this.token.utf8_to_b64(this.token.getUserAdministrador());
    } else {
      this.admin = true;
      this.codigo = this.token.utf8_to_b64(this.token.getUserId());
    }

    if (!this.token.getUserId()) {
      localStorage.setItem('url', this.router.url);
      this.router.navigateByUrl('/login');
    } else {
      // this.menu();
      // if (!localStorage.getItem("menu")) {
      //   // jQuery(".nav-toggler i").toggleClass("ti-menu");
      //   jQuery("#main-wrapper").toggleClass("show-sidebar");
      //   jQuery("#main-wrapper").attr("data-sidebartype", "full");
      //   // jQuery(".page-wrapper").css("margin-left",'0');
      //   jQuery("#asidesidebar").css('display', 'initial');
      //   // jQuery(".nav-toggler i").toggleClass("ti-menu");
      //   localStorage.removeItem("menu");
      //   // localStorage.setItem("menu", "false");
      //   // alert();
      //   // localStorage.setItem("menu",'false');
      // } else {
      //   jQuery("#asidesidebar").css('display', 'none');
      //   jQuery(".nav-toggler i").toggleClass("ti-menu");

      // }
      this.users.estado(this.user).subscribe(data => {
        this.estado = data['estado'];
        if (this.estado[0].estado == 0) {
          if (this.params.snapshot.paramMap.get("email")) {
            console.log("ruta");
            this.users.changeEstado(this.user).subscribe(data => this.router.navigateByUrl('/home'),
              error => console.log(error));
          } else {
            this.router.navigateByUrl('/info');
          }
        } else {
          this.params.snapshot.paramMap.get("email");
        }
      })
    }
    // jQuery("#main-wrapper").removeAttr("data-sidebartype");
  }
 


}


