import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ComputadorService } from '../Services/Activos/computador.service';
import { TokenService } from '../Services/token.service';
import { UnidadNegocioService } from '../Services/unidad-negocio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import Swal from 'sweetalert2';
import { UploadService } from '../Services/upload.service';
declare var jQuery: any;

@Component({
  selector: 'app-referencia-unidad',
  templateUrl: './referencia-unidad.component.html',
  styleUrls: ['./referencia-unidad.component.scss']
})
export class ReferenciaUnidadComponent implements OnInit {
  loading = false;
  title = "Detalle de unidad de negocio";
  constructor(private uploadService: UploadService, private route: ActivatedRoute, private token: TokenService, private activo: UnidadNegocioService, private toastr: ToastrService, private router: Router) { }

  form = {
    IdUnidad: null,
    Serial: this.route.snapshot.paramMap.get("serial"),
    Unidad: null,
    Foto: null,
    Tipo: null,
    CodigoReferencia: this.route.snapshot.paramMap.get("serial"),
    NombrePuntoReferencia: null,
    QuienRegistro: this.token.getUserName(),
    Novedad: null,
    UsuarioAdmin: null,
    Latitude: null,
    Correo:null,
    Longitude: null
  }

  toFile;

  submit() {
    console.log("uploading")
    const file = this.toFile.item(0);
    // this.change(file)
    if(navigator.onLine){
    this.archivo.nombreArchivo = file.name;

    // alert(file.name)
    this.uploadService.fileUpload(file, this.idUser)
    this.src = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + file.name;
    this.archivo.base64textString = this.src;
    this.form.Foto  = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + file.name;
    this.loading = false;
    } else {
      console.log(file)
      localStorage.setItem("file", this.token.utf8_to_b64((file)))
      console.log(this.token.b64_to_utf8(localStorage.getItem("file")));
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(file);
      this.loading = false;
    }

  }

  onChange(event) {
    this.loading = true;
    this.toFile = event.target.files;
    this.change(event)
    this.submit();

  }

  data = {
    userEncriptado: null
  }

  verificar = {
    serial: null
  }



  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Serial = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      // this.form.Serial = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Serial = "";
    }
  }
  serialVerify() {
    this.verificar.serial = this.form.Serial;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }
  archivo = {
    // id:this.detailactivo[0].idActivo,
    serial: null,
    observacion: null,
    nombre: "archivo",
    nombreArchivo: null,
    base64textString: null
  };

  change(event) {
    this.loading = true;
    console.log(event);
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(this);
      reader.readAsBinaryString(file);
    }

  }
  // Subir archivos
  imagen;
  _handleReaderLoader(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.imagen = binaryString;
    this.archivo.base64textString = btoa(binaryString);
    // alert(window.btoa(binaryString));

    if(navigator.onLine){
        
    } else {
        localStorage.setItem("foto", JSON.stringify(this.archivo))
    }
    // this.imagenSubir();
  }

  foto(event) {
    console.log(event);
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(this);
      reader.readAsBinaryString(file);

    }

  }

  public src;
  imagenSubir() {
    console.log("upload")
    // this.loading = false;

    this.activo.upload(this.archivo).subscribe(res => {
      this.loading = false;
      // this.toastr.success("Se regsitró exitosamente el cambio en el activo", "Historial de Archivos")
      // this.novedad(res);
      this.src = res['url']
    },
      error => {
        this.loading = false;

      });
  }

  onSubmit() {

    this.loading = true;
    if(navigator.onLine){
      this.form.Latitude = localStorage.getItem('lat');
      this.form.Longitude = localStorage.getItem('long');
      this.form.IdUnidad = this.unidades[0].IdUnidad;
        // this.form.Foto =;
    if (this.form.Novedad == "" || this.form.Novedad == null) {
      // alert()
      this.form.Novedad = "No se registró ninguna observación";
    }
    this.form.Unidad = this.unidades[0].NombreUnidad;
    if (this.referencias[0]) {
      this.form.NombrePuntoReferencia = this.referencias[0].NombreReferencia;

    } else {
      this.form.NombrePuntoReferencia = this.unidades[0].NombreUnidad;
    }
    console.log(this.form)
    // alert(this.form.Unidad)
    // alert(this.form.NombrePuntoReferencia)
    this.activo.novedad(this.form).subscribe(data => {
      this.novedades = data['novedad']
      this.toastr.success("Se registró", "Se ha enviado una nueva actualización al punto de referencia")
      this.form.Novedad = "";
      this.archivo.nombreArchivo = "";
      this.archivo.base64textString = "";
      this.src = "";
      console.log(this.novedades)
      Swal.fire({
        title: 'Ya se ha registrado la novedad',
        text: "Se cerrará el punto de referencia",
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Continuar!'
      }).then((result) => {
        if (result.value) {
          this.loading = false;
          // alert();
          // this.router.navigateByUrl('/home');
          // window.open("", "_self");
        }
      })
      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error)
    });
    } else {
      this.unidades.forEach(element => {
        // alert(element['Correo'])
      this.form.IdUnidad = element['IdUnidad']
        // this.form.Foto =;
    if (this.form.Novedad == "" || this.form.Novedad == null) {
      // alert()
      this.form.Novedad = "No se registró ninguna observación";
    }
    this.form.Unidad = element['NombreUnidad'];
    // if (this.referencias[0]) {
      // this.form.NombrePuntoReferencia = element['NombreUnidad'];
      this.form.Correo = element['Correo']

    // } else {
      this.form.NombrePuntoReferencia = element['NombreUnidad'];
    // }
          console.log(this.form)
          this.loading = false;
          localStorage.setItem("observacion", JSON.stringify(this.form));
          Swal.fire({
            title: 'Ya se ha agregado a la lista sin conexión',
            text: "Se cerrará el punto de referencia",
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Continuar!'
          }).then((result) => {
            if (result.value) {
              this.loading = false;
              this.form.Novedad = "";
              // alert();
              // this.router.navigateByUrl('/home');
              // window.open("", "_self");
            }
          })


      });

    }


  }

  // src;

  idUser;
  admin;
  unidades;
  referencias;
  novedades;

  showPosition2(position) {
    // alert();
    localStorage.removeItem('lat')
    localStorage.removeItem('long')
    localStorage.setItem('lat', position.coords.latitude)
    localStorage.setItem('long', position.coords.longitude)
    // this.form.Latitude = position.coords.latitude;
    // this.form.Longitude =  position.coords.longitude;
    console.log(position)


    // alert();
    this.form.Longitude = localStorage.getItem('longitude')
    this.form.Latitude = localStorage.getItem('latitude')

   console.log(this.form)
 }

 ngAfterViewInit(){
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(this.showPosition2);
  } else {
   console.log("Geolocation is not supported by this browser.");
  }
}

online = true;

  ngOnInit() {


    this.form.Longitude = localStorage.getItem('longitude')
    this.form.Latitude = localStorage.getItem('latitude')

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition2);
    } else {
     console.log("Geolocation is not supported by this browser.");
    }
    // alert(this.form.Latitude)
    // alert(this.form.Longitude)


    if (this.token.getUserId()) {
      Swal.fire({
        title: 'Usuario en sesión: ' + this.token.getUserName(),
        text: "Sí no es tu usuario por favor cambia de usuario",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'Sí, es mi usuario',
        confirmButtonText: 'Cambiar de usuario'
      }).then((result) => {
        if (result.value) {
          localStorage.setItem('url', this.router.url);
          this.token.remove();
          this.router.navigate(['/login']);

        }
      })
    }
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.idUser = this.token.getUserAdministrador()
    } else {
      this.admin = true;
      this.idUser = this.token.getUserId();
    }
    this.form.UsuarioAdmin = this.idUser;

    // this.form.Usuario = this.idUser;
    console.log("datos");
    if(navigator.onLine){
      // alert();
      this.online = true;

      this.activo.detailUp(this.form).subscribe(
        data => {
          console.log(data)
          this.unidades = data['unidad'];
          console.log(this.unidades[0].Correo)
          this.form.Correo = this.unidades[0].Correo;

          this.referencias = data['referencias'];
          this.novedades = data['novedad'];
          if (data['count'] > 0) {
            // alert("Si es un punto de referencia")
            this.form.Tipo = "Punto de Referencia"
          } else {
            // alert("No es un punto de referencia")
            this.form.Tipo = "Unidad";
          }
        },
        error => console.log(error)
      )
    } else {
      this.online = false;
      var array = localStorage.getItem('unidades');
// Se parsea para poder ser usado en js con JSON.parse :)
      array = JSON.parse(array);
      // alert(words)
      console.log(array)
      this.unidades = array['unidad'];
      this.unidades = this.unidades.filter(word => word.Serial  == this.form.Serial );
      // array['unidad'].forEach(element => {
      //   if(element['Serial'] == this.form.Serial){
      //     this.unidades = element;
      //   }
      //   // alert(element['Correo'])
      // //     // console.log(element)
      // //     // this.unidades = element;
      // //     // console.log(this.unidades[0].Correo)
      // //     // this.form.Correo = this.unidades[0].Correo;
      // //     // alert(this.unidades)
      // });

      // const result = words['unidad'].filter(word => word.length > 6);
      // console.log(result);
    }

  }
}
