<aside class="left-sidebar" id="asidesidebar" data-sidebarbg="skin6">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
    <!-- Sidebar navigation-->
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <!-- User Profile-->
        <li>
          <!-- User Profile-->
          <div class="user-profile d-flex no-block dropdown">
            <div class="user-pic">
              <img
                src="../../assets/images/users/1.jpg"
                alt="users"
                class="rounded-circle"
                width="40"
              />
            </div>
            <div class="user-content hide-menu m-l-10">
              <a
                href="javascript:void(0)"
                class=""
                id="Userdd"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h5 class="m-b-0 user-name font-medium">
                  {{ name }} <i class="fa fa-angle-down"></i>
                </h5>
                <span style="font-size: 12px" class="op-5 user-email">{{
                  email
                }}</span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="Userdd"
              >
                <a class="dropdown-item" routerLink="/config"
                  ><i class="ti-user m-r-5 m-l-5"></i> Mi cuenta</a
                >
                <a class="dropdown-item" routerLink="/usuarios"
                  ><i class="ti-wallet m-r-5 m-l-5"></i> Usuarios</a
                >
                <a class="dropdown-item" href="/sedes"
                  ><i class="ti-email m-r-5 m-l-5"></i> Sedes</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"
                  ><i class="fa fa-power-off m-r-5 m-l-5"></i> Cerrar sesión</a
                >
              </div>
            </div>
          </div>
          <!-- End User Profile-->
        </li>
        <li class="">
          <h5>SELECCIONA LA SEDE:</h5>
          <select
            (change)="cargarSede($event)"
            class="form-control"
            [(ngModel)]="IdSede"
          >
            <option value="">Todas las sedes</option>
            <ng-container *ngFor="let se of sedes">
              <option value="{{ se.IdSede }}">{{ se.NombreSede }}</option>
            </ng-container>
          </select>
        </li>
        <hr />
        <!-- <li class="p-15 m-t-10"><a href="javascript:void(0)"
                        class="btn btn-block create-btn text-white no-block d-flex align-items-center"><i
                            class="fa fa-plus-square"></i> <span class="hide-menu m-l-5">Create New</span> </a></li> -->
        <!-- User Profile-->
        <li class="sidebar-item">
          <a
            class="sidebar-link waves-effect waves-dark sidebar-link"
            routerLink="/home"
            aria-expanded="false"
            ><i class="icon-home"></i><span class="hide-menu">INICIO</span></a
          >
        </li>

        <li *ngIf="puede" class="sidebar-item">
          <a
            class="sidebar-link waves-effect waves-dark sidebar-link"
            routerLink="/visitasgrupo"
            aria-expanded="false"
            ><i class="icon-home"></i
            ><span class="hide-menu">VISITANTES POR GRUPOS</span></a
          >
        </li>

        <ng-container *ngFor="let m of accesosusuarios">
          <ng-container *ngIf="m.NombreAcceso == 'activos'">
            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/tickets"
                aria-expanded="false"
                ><i class="icon-tiket"></i
                ><span class="hide-menu">Tickets</span></a
              >
            </li>
            

            <li class="sidebar-item dropdown">
              <a
                class="
                  sidebar-link
                  waves-effect waves-dark
                  sidebar-link
                  dropdown-toggle
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="icon-PC-equipment"></i
                ><span class="hide-menu">Equipos Informáticos</span>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" routerLink="/computadores"
                  >Computadores</a
                >
                <a class="dropdown-item" routerLink="/impresoras">Impresoras</a>
                <a class="dropdown-item" routerLink="/dispositivos-red"
                  >Otros dispositivos</a
                >
              </div>
            </li>
            <li class="sidebar-item dropdown">
              <a
                class="
                  sidebar-link
                  waves-effect waves-dark
                  sidebar-link
                  dropdown-toggle
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="icon-depreciation"></i
                ><span class="hide-menu">Maquinaria y equipos</span>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" routerLink="/maquinaria-industrial"
                  >Maquinaria industrial</a
                >
              </div>
            </li>

            <hr />
          </ng-container>
          <ng-container *ngIf="m.NombreAcceso == 'control-ingreso'">
            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/visitantes"
                aria-expanded="false"
                ><i class="icon-chatbot"></i
                ><span class="hide-menu">Ingreso de visitas</span></a
              >
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/empleados"
                aria-expanded="false"
                ><i class="icon-chatbot"></i
                ><span class="hide-menu">Gestión de empleados</span></a
              >
            </li>
            <!-- <li class="sidebar-item"> <a class="sidebar-link waves-effect waves-dark sidebar-link" -->
            <!-- routerLink="/cronograma" aria-expanded="false"><i class="icon-chatbot"></i><span -->
            <!-- class="hide-menu">Cronograma</span></a></li> -->
            <hr />
          </ng-container>
          <ng-container *ngIf="m.NombreAcceso == 'control-vigilancia'">
            <li class="sidebar-item" *ngIf="admin == true">
                <a
                  class="sidebar-link waves-effect waves-dark sidebar-link"
                  routerLink="/sgr"
                  aria-expanded="false"
                  ><i class="icon-tiket"></i
                  ><span class="hide-menu">Gestión de novedades</span></a
                >
              </li>

              <li class="sidebar-item"> 
                <a
                  class="sidebar-link waves-effect waves-dark sidebar-link"
                  routerLink="/sgr-supervisor"
                  aria-expanded="false"
                  ><i class="icon-tiket"></i
                  ><span class="hide-menu">Novedades supervisor</span></a
                >
              </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/vigilantes"
                aria-expanded="false"
                ><i class="icon-chatbot"></i
                ><span class="hide-menu">Gestión de vigilantes</span></a
              >
            </li>

            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/vigilantes/supervisores"
                aria-expanded="false"
                ><i class="icon-chatbot"></i
                ><span class="hide-menu">Gestión de supervisores</span></a
              >
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/vigilantes/tiemporeal"
                aria-expanded="false"
                ><i class="icon-chatbot"></i
                ><span class="hide-menu">Observaciones tiempo real</span></a
              >
            </li>

            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                routerLink="/vigilantes/tiemporeal"
                aria-expanded="false"
                ><i class="icon-chatbot"></i
                ><span class="hide-menu">Registro de visitas</span></a
              >
            </li>

            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                [class.active]="active === '/unidad-negocio'"
                routerLink="/unidad-negocio"
                aria-expanded="false"
                ><i class="icon-photo-user"></i
                ><span class="hide-menu">UNIDAD DE NEGOCIO</span></a
              >
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link waves-effect waves-dark sidebar-link"
                [class.active]="active === '/correspondencia'"
                routerLink="/correspondencia"
                aria-expanded="false"
                ><i class="icon-photo-user"></i
                ><span class="hide-menu">CORRESPONDENCIA</span></a
              >
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nav>
    <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
</aside>

<!-- Modal -->
<div
  class="modal fade"
  id="accesos"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          Selecciona los módulos que deseas utilizar
        </h5>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
      </div>
      <div class="modal-body">
        <div class="form-gorup col-md-12">
          <ng-select2
            [options]="options"
            name="accesos"
            [(ngModel)]="usermod.accesos"
            [data]="accesosbarra"
            [placeholder]="'Selecciona los modulos'"
          >
          </ng-select2>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" (click)="modulos()" class="btn btn-primary">
          Actualizar módulos
        </button>
      </div>
    </div>
  </div>
</div>
