import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VisitantesService } from '../Services/usuarios/visitantes.service';

@Component({
  selector: 'app-vsitante-observaciones',
  templateUrl: './vsitante-observaciones.component.html',
  styleUrls: ['./vsitante-observaciones.component.scss']
})
export class VsitanteObservacionesComponent implements OnInit {

  listica: any;
  title = "Registro de observación";
  loading = false;
  form = {
    NumeroIdentificacion: this.route.snapshot.paramMap.get("id"),
    Nombre: null,
    Correo: null,
    Telefono: null,
    Servicio: null,
    Temperatura: null,
    observacion: "no",
    observacion2: null
  }

  dataInfo(data) {
    // alert(JSON.stringify(data));
    this.listica = data.lista;
    console.log(data.lista)
    this.form.Nombre = this.listica[0].Nombre;
    this.form.Correo = this.listica[0].Correo;
    this.form.Telefono = this.listica[0].Telefono;
    this.form.Servicio = this.listica[0].NombreServicio;

    // alert(this.listica[0].Nombre)
  }
  enviar;
  observacionesAlerta(event) {
    if (event.target.value === "Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19") {
      this.toastr.error("INFORMAR AL COORDINADOR SST", "IMPORTANTE");
    }
    this.enviar = true;
  }

  sintomas = false;
  observaciones2Alerta(event) {
    if (event.target.value === "Sí") {
      this.toastr.info("Seleccione cuales son los sintomas", "IMPORTANTE");
      this.sintomas = true;
    } else {
      this.sintomas = false;
    }
    this.enviar = true;
  }

  sintomaselect;

  temperatura(event) {
    console.log(event);
    var temp = (this.form.Temperatura / 10).toFixed(1);
    console.log(parseFloat(temp))
    if (event.target.value < 3 || event.target.value > 4) {
      if (parseFloat(temp) < 3.4 || parseFloat(temp) > 41.5) {
        this.form.Temperatura = "";
      } else {
        if (parseFloat(temp) > 41.0) {
          this.form.Temperatura = "";
        }
        if (parseFloat(temp) > 34.0) {
          this.form.Temperatura = temp;
        }
        if (parseFloat(temp) === 3.7) {
          this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
        } else {
          if (parseFloat(temp) >= 37.0 && parseFloat(temp) < 37.9) {
            this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
          }
        }
        if (parseFloat(temp) === 3.8) {
          this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');
        } else {
          if (parseFloat(temp) > 38.0 && parseFloat(temp) < 38.9) {
            this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');

          }

        }
        if (parseFloat(temp) === 3.9) {
          this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
        } else {
          if (parseFloat(temp) > 39.0) {
            this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
          }
        }
      }
    } else {

    }


  }


  ngOnInit(): void {
    this.visita.getData(this.form).subscribe(
      data => {
        console.log(data)
        this.dataInfo(data);

      },
      error => console.log(error)
    );
  }


  submit() {

    if (this.form.observacion2 != null) {
      this.form.observacion2 = this.form.observacion2.toString();
    } else {
      this.form.observacion2 = "No se ha reportado ningún sintoma";
    }

    // alert(this.form)
    console.log(this.form)
    this.loading = true;
    // console.log(this.obser);
    this.visita.registroPost(this.form).subscribe(
      data => {
        this.sintomaselect = "Nono";
        this.sintomas = false;
        this.loading = false;
        // this.view = 'lista';
        this.form.Temperatura = "";
        this.form.observacion = null;
        this.form.observacion2 = null;
        this.enviar = false;
        this.toastr.success("Se han actualizado los datos de tu empleado en el sistema", "Mensaje")

      },
      error => {
        this.toastr.error(error.error.message)
        this.loading = false;
        console.log(error)
      }

    )


  }


  constructor(private visita: VisitantesService, private route: ActivatedRoute, private toastr: ToastrService) { }


}



