import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmpleadosService } from 'src/app/Services/empleados.service';
import { TokenService } from 'src/app/Services/token.service';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
declare var jQuery:any;

@Component({
  selector: 'app-vigilanteobservacion',
  templateUrl: './vigilanteobservacion.component.html',
  styleUrls: ['./vigilanteobservacion.component.scss']
})
export class VigilanteobservacionComponent implements OnInit {
  loading;
  form = {
    Foto: null,
    IdEmpleado: null,
    Cedula: null,
    Nombre: null,
    Apellido: null,
    Puesto: null,
    Telefono: null,
    Direccion: null,
    Ciudad: null,
    Arl: null,
    Eps: null,
    RH: null,
    NombreContacto: null,
    TelefonoContacto: null,
    Codigo: null,
    Compania: this.Token.getUserId(),
    id_user: this.Token.getUserId(),

  }

  detalle = true;

  detailResponse(data) {

  }

  detail(IdEmpleado, Cedula, Nombre, Apellido, Puesto, Telefono, Direccion, Ciudad, Arl, Eps, RH, NombreContacto, TelefonoContacto) {
    this.form.IdEmpleado = IdEmpleado;
    this.form.Cedula = Cedula;
    this.form.Nombre = Nombre;
    this.form.Apellido = Apellido;
    this.form.Puesto = Puesto;
    this.form.Telefono = Telefono;
    this.form.Direccion = Direccion;
    this.form.Ciudad = Ciudad;
    this.form.Arl = Arl;
    this.form.Eps = Eps;
    this.form.RH = RH;
    this.form.NombreContacto = NombreContacto;
    this.form.TelefonoContacto = TelefonoContacto;

    // this.empleado.detail(this.form).subscribe(data=>this.detailResponse(data),error=>this.toastr.error(error.message, 'Error'));
  }

  view = 'lista';

  id;
  empleados: any;
  visitascount;
  generarCodigo;
  codigo = false;
  tittle = "Control de observacion";
  data = {
    serial: this.router.snapshot.paramMap.get("user"),
    id_user: this.Token.getUserId(),
    userEncriptado: this.Token.utf8_to_b64(this.Token.getUserId())
  }

  obser = {
    Foto:null,
    Placa:null,
    Arma:null,
    SerieArma:null,
    Radio:null,
    SerieRadio:null,
    PresentacionPersonal:null,
    Recursos:null,
    EstadoVigilante:null,
    CumplimientoFunciones:null,
    Cursos:null,
    Carnet:null,
    Observaciones:null,
    Empleado: this.form.IdEmpleado,
    temperatura: null,
    observacion: null,
    observacion2: null,
    Puesto:null,
    Ubicacion:null,
    Supervisor: this.Token.getUserName()
  }

  generaCod(data) {
    if (data.data) {
      this.codigo = true;
    } else {

    }
  }

  generatecodigo() {
    this.Token.generarVisitasCodigo(this.data).subscribe(
      data => {
        this.generaCod(data);
      },
      error => console.log(error)
    )
  }
  sintomaselect;
  onSubmit() {
    this.obser.Foto = this.archivo.nombreArchivo;
    this.obser.Ubicacion = localStorage.getItem('latitude')+","+localStorage.getItem('logintude');
    // alert(this.obser.Ubicacion);
    console.log(this.obser);
    
    if (this.obser.observacion2 == null) {
      this.obser.observacion2 = "No se ha reportado ninguna novedad";
      console.log(this.form)
      this.loading = true;
      console.log(this.obser);
      this.empleado.observacion(this.obser).subscribe(
        data => {
          this.obser.Foto=null;
          this.obser.Placa=null;
          this.obser.Arma=null;
          this.obser.SerieArma=null;
          this.obser.Radio=null;
          this.obser.SerieRadio=null;
          this.obser.PresentacionPersonal=null;
          this.obser.Recursos=null;
          this.obser.EstadoVigilante=null;
          this.obser.CumplimientoFunciones=null;
          this.obser.Cursos=null;
          this.obser.Carnet=null;
          this.obser.Observaciones=null;
          this.obser.Empleado= this.form.IdEmpleado;
          this.obser.temperatura= null;
          this.obser.observacion= null;
          this.obser.observacion2= null;
          this.obser.Puesto=null;
          this.obser.Ubicacion=null;
          this.src = "";
          this.obser.observacion = "";
          this.sintomaselect = "Nono";
          this.sintomas = false;
          this.loading = false;
          // this.view = 'lista';
          this.obser.temperatura = "";
          this.obser.observacion = null;
          this.obser.observacion2 = null;
          this.enviar = false;
          this.toastr.success("Se han actualizado los datos de tu empleado en el sistema", "Mensaje")
          this.handleResponse2(data);
        },
        error => {
          this.toastr.error(error.error.message)
          this.loading = false;
          console.log(error)}
          
      )
    } else {
      // if (this.obser.temperatura == null) {
        // this.toastr.error("La temperatura es obligatoria", "Campos obligatorios")
        // } else {
          
          console.log(this.form)
      this.loading = true;
      console.log(this.obser);
      this.empleado.observacion(this.obser).subscribe(
        data => {
          this.obser.Foto=null;
          this.obser.Placa=null;
          this.obser.Arma=null;
          this.obser.SerieArma=null;
          this.obser.Radio=null;
          this.obser.SerieRadio=null;
          this.obser.PresentacionPersonal=null;
          this.obser.Recursos=null;
          this.obser.EstadoVigilante=null;
          this.obser.CumplimientoFunciones=null;
          this.obser.Cursos=null;
          this.obser.Carnet=null;
          this.obser.Observaciones=null;
          this.obser.Empleado= this.form.IdEmpleado;
          this.obser.temperatura= null;
          this.obser.observacion= null;
          this.obser.observacion2= null;
          this.obser.Puesto=null;
          this.obser.Ubicacion=null;
          this.src = "";
          this.obser.observacion = "";
          this.sintomaselect = "Nono";
          this.sintomas = false;
          this.loading = false;
          // this.view = 'lista';
          this.obser.temperatura = "";
          this.obser.observacion = null;
          this.obser.observacion2 = null;
          this.enviar = false;
          this.toastr.success("Se han actualizado los datos de tu empleado en el sistema", "Mensaje")
          this.handleResponse2(data);
        },
        error => {
          this.toastr.error(error.error.message)
          this.loading = false;
          console.log(error)}
          
      )
    }

  }

  verificar = {
    serial: null
  }


  deleteempleado(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.form.IdEmpleado = id;
        this.form.Codigo = serial;
        this.loading = true;
        this.empleado.deleteActivo(this.form).subscribe(
          data => {
            this.toastr.success("Se ha eliminado exitosamente el empleado", "Eliminar empleado")
            this.loading = false;
            this.handleResponse(data)
          },
          error => { this.loading = false; this.toastr.error(error.message, 'Error en el proceso') }
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })
  }
  enviar = false;
  cerrar() {
    this.form.IdEmpleado = "";
    this.form.Cedula = "";
    this.form.Nombre = "";
    this.form.Apellido = "";
    this.form.Puesto = "";
    this.form.Telefono = "";
    this.form.Direccion = "";
    this.form.Ciudad = "";
    this.form.Arl = "";
    this.form.Eps = "";
    this.form.RH = "";
    this.form.NombreContacto = "";
    this.form.TelefonoContacto = "";

  }
  observaciones;
  handleResponse2(data) {
    this.toastr.success('Se ha registrado la observación', 'Mensaje');
    this.observaciones = data.observaciones;
  }

  observacionesAlerta(event) {
    if (event.target.value === "Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19") {
      this.toastr.error("INFORMAR AL COORDINADOR SST", "IMPORTANTE");
    }
    this.enviar = true;
  }

  sintomas = false;
  observaciones2Alerta(event) {
    if (event.target.value === "Sí") {
      this.toastr.info("Seleccione cuales son los sintomas", "IMPORTANTE");
      this.sintomas = true;
    } else {
      this.sintomas = false;
    }
    this.enviar = true;
  }










  // Subir archivos 
  imagen;
  _handleReaderLoader(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.imagen = binaryString;
    this.archivo.base64textString = btoa(binaryString);
    // alert(window.atob(binaryString));
    this.imagenSubir();
  }

  archivo = {
    // id:this.detailactivo[0].idActivo,
    serial: null,
    observacion: null,
    nombre: "archivo",
    nombreArchivo: null,
    base64textString: null
  };
  change(event) {
    console.log(event);
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(this);
      reader.readAsBinaryString(file);
      
    }
   
  }
  
  src;
  imagenSubir(){
    console.log("upload")
    this.empleado.upload(this.archivo).subscribe(res => {
      this.loading = false;
      // this.toastr.success("Se regsitró exitosamente el cambio en el activo", "Historial de Archivos")
      // this.novedad(res);
      this.src =  "http://qaractivo.local/files/vigilantes/observaciones/"+ this.archivo.nombreArchivo
    },
    error=> console.log(error));
  }

  srcUpdate(nombreArchivo){
    this.src =  "http://qaractivo.local/files/vigilantes/observaciones/"+ nombreArchivo;
  }













  temperatura(event) {
    console.log(event);
    var temp = (this.obser.temperatura / 10).toFixed(1);
    console.log(parseFloat(temp))
    if (event.target.value < 3 || event.target.value > 4) {
      if (parseFloat(temp) < 3.0 || parseFloat(temp) > 41.5) {
        this.obser.temperatura = "";
      } else {
        if (parseFloat(temp) > 41.0) {
          this.obser.temperatura = "";
        }
        if (parseFloat(temp) > 34.0) {
          this.obser.temperatura = temp;
        }
        if (parseFloat(temp) === 3.7) {
          this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
        } else {
          if (parseFloat(temp) >= 37.0 && parseFloat(temp) < 37.9) {
            this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
          }
        }
        if (parseFloat(temp) === 3.8) {
          this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');
        } else {
          if (parseFloat(temp) > 38.0 && parseFloat(temp) < 38.9) {
            this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');

          }

        }
        if (parseFloat(temp) === 3.9) {
          this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
        } else {
          if (parseFloat(temp) > 39.0) {
            this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
          }
        }
      }
    } else {

    }


  }

  count = "";
Foto ;
  handleResponse(data) {
    this.Foto =  data.empleados[0].Foto;
    this.count = data.count;
    this.observaciones = data.observaciones;
    this.obser.Empleado = data.empleados[0].IdEmpleado;
    this.form.IdEmpleado = data.empleados[0].IdEmpleado;
    this.form.Cedula = data.empleados[0].Cedula;
    this.form.Nombre = data.empleados[0].Nombre;
    this.form.Apellido = data.empleados[0].Apellido;
    this.form.Puesto = data.empleados[0].Puesto;
    this.obser.Puesto = data.empleados[0].Puesto;
    this.form.Telefono = data.empleados[0].Telefono;
    this.form.Direccion = data.empleados[0].Direccion;
    this.form.Ciudad = data.empleados[0].Ciudad;
    this.form.Arl = data.empleados[0].Arl;
    this.form.Eps = data.empleados[0].Eps;
    this.form.RH = data.empleados[0].RH;
    this.form.NombreContacto = data.empleados[0].NombreContacto;
    this.form.TelefonoContacto = data.empleados[0].TelefonoContacto;
  }

  seriearma = false;
  arma(event){
    var valor = event.target.value
    if(valor == "Sí"){
      this.seriearma = true;
    } else {
      this.seriearma = false;
    }
  }

  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }

  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.empleado.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }



  showPosition(position) {
    console.log(position)
   console.log()
   console.log(position.coords.longitude)
   localStorage.setItem('latitude', position.coords.latitude);
   localStorage.setItem('logintude', position.coords.longitude);
  //  this.obser.Ubicacion = position.coords.latitude+","+position.coords.longitude;
    // this.positionubi()
 }


 

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private toastr: ToastrService,
    private empleado: EmpleadosService, private Token: TokenService) { }
  ngOnInit(): void {
    if (this.Token.getUserId()) {
      Swal.fire({
        title: 'Usuario en sesión: ' + this.Token.getUserName(),
        text: "Sí no es tu usuario por favor cambia de usuario",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'Sí, es mi usuario',
        confirmButtonText: 'Cambiar de usuario'
      }).then((result) => {
        if (result.value) {
          localStorage.setItem('url', this.route.url);
          this.Token.remove();
          this.route.navigate(['/login']);
        }
      })
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else { 
     console.log("Geolocation is not supported by this browser.");
    }
    this.empleado.getEmpleado(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )
  }

  cedula() {

  }


  // tittle = ""
  detailempleado: any;
  sidebaractive = "ficha";
  p = 1;
}
