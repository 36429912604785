import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VisitantesService } from '../Services/usuarios/visitantes.service';
import swal from 'sweetalert2';
import { EventoformService } from '../Services/eventos/eventoform.service';
import { TokenService } from '../Services/token.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-visitas-por-grupo-formulario',
  templateUrl: './visitas-por-grupo-formulario.component.html',
  styleUrls: ['./visitas-por-grupo-formulario.component.scss']
})
export class VisitasPorGrupoFormularioComponent implements OnInit {

  loading = false;
  form = {
    NumeroIdentificacion: null,
    Nombre: null,
    Correo: null,
    Telefono: null,
    Servicio: 0,
    FechaServicio: 0,
    NombreServicio: null
  }


  codigo() {
    swal.fire({
      title: 'Ingresar cedula para consultar código',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Consultar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {

        // if .isConfirmed) {
        swal.fire({
          title: "Si hay un código asignado lo podrás ver en la imagen",

          imageUrl: "http://localhost:8000/img/codigos/" + login + ".png"
        })
        // }


      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {

    })
  }


  mensaje = false;

  procesar(data) {
    // this.loading = true;
    console.log(data)
    if (data.user[0].Nombre) {
      this.toastr.success("Se cargo la información de " + data.user[0].Nombre)
      this.form.Nombre = data.user[0].Nombre;
      this.form.Correo = data.user[0].Correo;
      this.form.Telefono = data.user[0].Telefono;
      this.loading = false;
    }
    this.loading = false;

  }

  getCedula() {
    this.loading = true;
    this.visitas.getDato(this.form).subscribe(
      data => {
        this.loading = false;
        this.procesar(data)
      },
      error => {

        console.log(error)
        this.loading = false;
      }
    )
  }

  lista;

  clickOption(event) {
    // alert(event.target.value);
    this.form.Servicio = event.target.value;
    this.form.NombreServicio = event.target.value;
  }

  registro() {
    // <option value="2"></option>
    // <option value="3"></option>
    console.log(this.form)
    this.loading = true;
    this.visitas.registroform(this.form).subscribe(
      data => {

        this.toastr.success("Mensaje", data['mensaje'])
        this.mensaje = false;
        // alert(data['codigo'])

        console.log(data)
        if (data['mensaje'] == "Quedará en una lista de espera") {
          swal.fire({

            title: "Mensaje",
            text: "Se agotaron los cupos para las celebraciones en las que tendremos apertura inicialmente, déjanos tus datos que acá pedimos para considerar la apertura de otra celebración el domingo a las 8 a.m. A medida que la demanda aumente, abriremos más celebraciones. En caso de apertura de otra celebración, te llamaremos.  Serás redireccionado a otro formulario"
            // imageUrl: 'http://localhost:8000/img/codigos/' + data['codigo'],
            // imageHeight: 
            // imageAlt: 'Código'
          })
            .then(function (result) {
              // window.location.href =""
              window.location.href = "https://forms.gle/cWE6Xw6pyNi8tmNB6";
            });
        } else if(data['mensaje'] == 'Quedó en una lista de espera') {
          this.loading = false; 
          swal.fire({
            title: "No hay disponibilidad para este evento.",
          })
        } else {
          this.loading = false;
          swal.fire({
            title: "Toma una captura de este código",
            imageUrl: 'http://localhost:8000/img/codigos/' + data['codigo'],
            // imageHeight: 
            imageAlt: 'A tall image',


          })
        }
        this.form.Nombre = ""
        this.form.NumeroIdentificacion = "";
        this.form.Servicio = 0;
        this.form.Correo = "";
        this.form.Telefono = "";
        // this.toastr.info("Mensaje", "Se ha enviado una código qr a tu correo electrónico")
      }, error => {
        this.loading = false;
        this.toastr.error('Debes verificar tus datos');
      }
    )

  }
  idUser;

  data = {
    idUser: this.route.snapshot.paramMap.get("id")
  }


  constructor(private visitas: VisitantesService, private toastr: ToastrService, private evento: EventoformService, private Token: TokenService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.loading = true;
    this.evento.getEventos2(this.data).subscribe(
      data => {
        this.lista = data['lista']
      },
      error => console.log(error)
    )
  }

}
