import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseurl = "https://quar.com.co/api"
  // private baseurl = "https://quar.com.co/api"


  activarUsuario(data) {
    return this.http.post(`${this.baseurl}/activarcuenta`, data)
  }

  getLocations(data) {
    return this.http.post(`${this.baseurl}/location/getbyuser`, data)
  }

  deleteUsuariosCompania(idUser) {
    return this.http.post(`${this.baseurl}/userinformation`, idUser)
  }


  createUsuariosCompania(idUser) {
    return this.http.post(`${this.baseurl}/create/usercompania`, idUser)
  }

  getUsuariosCompania(idUser) {
    return this.http.post(`${this.baseurl}/obtener/usuarioscompania`, idUser)
  }

  updateModulo(data){
    return this.http.post(`${this.baseurl}/modulose/user`, data)
  }


  getUsers(idUser) {
    return this.http.post(`${this.baseurl}/userinformation`, idUser)
  }

  updateUser(user) {
    return this.http.post(`${this.baseurl}/update/user`, user)
  }


  estado(user) {
    return this.http.post(`${this.baseurl}/estado/user`, user);
  }
  changeEstado(user) {
    return this.http.post(`${this.baseurl}/change/estado`, user);
  }

  sendEmail(user) {
    return this.http.post(`${this.baseurl}/activateAccount`, user);
  }

  computadorModule(idUser) {
    return this.http.post(`${this.baseurl}/tabla`, idUser)
  }


  constructor(private http: HttpClient) { }
}
