import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/Services/token.service';
import { EmpleadosService } from './../Services/empleados.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-empleadoprofi',
  templateUrl: './empleadoprofi.component.html',
  styleUrls: ['./empleadoprofi.component.scss']
})
export class EmpleadoprofiComponent implements OnInit {
  loading;
  form = {
    IdEmpleado: null,
    Cedula: null,
    Nombre: null,
    Apellido: null,
    Cargo: null,
    Telefono: null,
    Direccion: null,
    Ciudad: null,
    Arl: null,
    Eps: null,
    RH: null,
    NombreContacto: null,
    TelefonoContacto: null,
    Codigo: null,
    Compania: this.Token.getUserId(),
    id_user: this.Token.getUserId(),

  }

  detalle = true;

  detailResponse(data) {

  }

  detail(IdEmpleado, Cedula, Nombre, Apellido, Cargo, Telefono, Direccion, Ciudad, Arl, Eps, RH, NombreContacto, TelefonoContacto) {
    this.form.IdEmpleado = IdEmpleado;
    this.form.Cedula = Cedula;
    this.form.Nombre = Nombre;
    this.form.Apellido = Apellido;
    this.form.Cargo = Cargo;
    this.form.Telefono = Telefono;
    this.form.Direccion = Direccion;
    this.form.Ciudad = Ciudad;
    this.form.Arl = Arl;
    this.form.Eps = Eps;
    this.form.RH = RH;
    this.form.NombreContacto = NombreContacto;
    this.form.TelefonoContacto = TelefonoContacto;

    // this.empleado.detail(this.form).subscribe(data=>this.detailResponse(data),error=>this.toastr.error(error.message, 'Error'));
  }

  view = 'lista';

  id;
  empleados: any;
  visitascount;
  generarCodigo;
  codigo = false;
  tittle = "Control de empleados";
  data = {
    serial: this.router.snapshot.paramMap.get("user"),
    id_user: this.Token.getUserId(),
    userEncriptado: this.Token.utf8_to_b64(this.Token.getUserId())
  }

  obser = {
    Empleado: this.form.IdEmpleado,
    temperatura: null,
    observacion: null,
    observacion2: null,
  }

  generaCod(data) {
    if (data.data) {
      this.codigo = true;
    } else {

    }
  }

  generatecodigo() {
    this.Token.generarVisitasCodigo(this.data).subscribe(
      data => {
        this.generaCod(data);
      },
      error => console.log(error)
    )
  }
  sintomaselect;
  onSubmit() {
    if (this.obser.observacion2 != null) {
      this.obser.observacion2 = this.obser.observacion2.toString();
    } else {
      this.obser.observacion2 = "No se ha reportado ningún sintoma";
    }
    if (this.obser.temperatura == null) {
      this.toastr.error("La temperatura es obligatoria", "Campos obligatorios")
    } else {

      this.loading = true;
      console.log(this.obser);
      this.empleado.observacion(this.obser).subscribe(
        data => {
          this.sintomaselect = "Nono";
          this.sintomas = false;
          this.loading = false;
          // this.view = 'lista';
          this.obser.temperatura = "";
          this.obser.observacion = null;
          this.obser.observacion2 = null;
          this.enviar = false;
          this.toastr.success("Se han actualizado los datos de tu empleado en el sistema", "Mensaje")
          this.handleResponse2(data);
        },
        error => {
          this.toastr.error(error.error.message)
          this.loading = false;
          console.log(error)}
          
      )
    }

  }

  verificar = {
    serial: null
  }


  deleteempleado(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.form.IdEmpleado = id;
        this.form.Codigo = serial;
        this.loading = true;
        this.empleado.deleteActivo(this.form).subscribe(
          data => {
            this.toastr.success("Se ha eliminado exitosamente el empleado", "Eliminar empleado")
            this.loading = false;
            this.handleResponse(data)
          },
          error => { this.loading = false; this.toastr.error(error.message, 'Error en el proceso') }
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })
  }
  enviar = false;
  cerrar() {
    this.form.IdEmpleado = "";
    this.form.Cedula = "";
    this.form.Nombre = "";
    this.form.Apellido = "";
    this.form.Cargo = "";
    this.form.Telefono = "";
    this.form.Direccion = "";
    this.form.Ciudad = "";
    this.form.Arl = "";
    this.form.Eps = "";
    this.form.RH = "";
    this.form.NombreContacto = "";
    this.form.TelefonoContacto = "";

  }
  observaciones;
  handleResponse2(data) {
    this.toastr.success('Se ha registrado la observación', 'Mensaje');
    this.observaciones = data.observaciones;
  }

  observacionesAlerta(event) {
    if (event.target.value === "Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19") {
      this.toastr.error("INFORMAR AL COORDINADOR SST", "IMPORTANTE");
    }
    this.enviar = true;
  }

  sintomas = false;
  observaciones2Alerta(event) {
    if (event.target.value === "Sí") {
      this.toastr.info("Seleccione cuales son los sintomas", "IMPORTANTE");
      this.sintomas = true;
    } else {
      this.sintomas = false;
    }
    this.enviar = true;
  }


  temperatura(event) {
    console.log(event);
    var temp = (this.obser.temperatura / 10).toFixed(1);
    console.log(parseFloat(temp))
    if (event.target.value < 3 || event.target.value > 4) {
      if (parseFloat(temp) < 3.4 || parseFloat(temp) > 41.5) {
        this.obser.temperatura = "";
      } else {
        if (parseFloat(temp) > 41.0) {
          this.obser.temperatura = "";
        }
        if (parseFloat(temp) > 34.0) {
          this.obser.temperatura = temp;
        }
        if (parseFloat(temp) === 3.7) {
          this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
        } else {
          if (parseFloat(temp) >= 37.0 && parseFloat(temp) < 37.9) {
            this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
          }
        }
        if (parseFloat(temp) === 3.8) {
          this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');
        } else {
          if (parseFloat(temp) > 38.0 && parseFloat(temp) < 38.9) {
            this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');

          }

        }
        if (parseFloat(temp) === 3.9) {
          this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
        } else {
          if (parseFloat(temp) > 39.0) {
            this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
          }
        }
      }
    } else {

    }


  }

  count = "";

  handleResponse(data) {
    this.count = data.count;
    this.observaciones = data.observaciones;
    this.obser.Empleado = data.empleados[0].IdEmpleado;
    this.form.IdEmpleado = data.empleados[0].IdEmpleado;
    this.form.Cedula = data.empleados[0].Cedula;
    this.form.Nombre = data.empleados[0].Nombre;
    this.form.Apellido = data.empleados[0].Apellido;
    this.form.Cargo = data.empleados[0].Cargo;
    this.form.Telefono = data.empleados[0].Telefono;
    this.form.Direccion = data.empleados[0].Direccion;
    this.form.Ciudad = data.empleados[0].Ciudad;
    this.form.Arl = data.empleados[0].Arl;
    this.form.Eps = data.empleados[0].Eps;
    this.form.RH = data.empleados[0].RH;
    this.form.NombreContacto = data.empleados[0].NombreContacto;
    this.form.TelefonoContacto = data.empleados[0].TelefonoContacto;
  }

  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }

  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.empleado.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }

  constructor(
    private router: ActivatedRoute,
    private toastr: ToastrService,
    private empleado: EmpleadosService, private Token: TokenService) { }
  ngOnInit(): void {
    this.empleado.getEmpleado(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )
  }

  cedula() {

  }


  // tittle = ""
  detailempleado: any;
  sidebaractive = "ficha";
  p = 1;
}

