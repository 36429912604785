<div class="login-container">
  <div class="login-container__form">
    <div class="login-container__form__contaner">
      <div class="login-container__form__header">
        <img src="../../assets/img/logo.png" alt="Logo" />
        <h5>Bienvenidos</h5>
      </div>

      <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="login-container__form__alert" [hidden]="!error">
          {{ error }}
        </div>

        <!-- <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label> -->

        <div class="form-group">
          <input
            type="email"
            name="email"
            placeholder="Escribe tu mail"
            class="form-control"
            id="inputEmail3"
            [(ngModel)]="form.email"
            required
          />
        </div>

        <!-- <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label> -->

        <div class="form-group">
          <input
            type="password"
            placeholder="Y tu contraseña..."
            name="password"
            class="form-control"
            id="inputPassword3"
            [(ngModel)]="form.password"
            required
          />
        </div>

        <div class="form-group">
          <a type="button" data-toggle="modal" data-target="#exampleModal">
            Olvidaste tu contraseña?
          </a>
          <br />
        </div>

        <div class="form-group text-center register_form">
          <button
            type="button"
            routerLink="/register"
            class="btn btn-secondary"
          >
            Registrate
          </button>
          <button
            type="submit"
            class="btn btn-danger"
            [disabled]="!loginForm.valid"
          >
            Ingresa
          </button>
        </div>
        <div class="form-group text-center register_form">
          <!-- Button trigger modal -->
        </div>
      </form>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Olvidaste tu contraseña
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      
        <form #olvistasteTcontrasena="ngForm" (ngSubmit)="onSubmitPassword()">
          <div class="login-container__form__alert" [hidden]="!error">
            {{ error }}
          </div>

          <!-- <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label> -->

          <div class="form-group">
            <input
              type="email"
              name="email"
              placeholder="Escribe tu mail"
              class="form-control"
              id="inputEmail3"
              [(ngModel)]="formPassword.email"
              required
            />
          </div>

          <div class="form-group text-center register_form">
            <button
              type="submit"
              class="btn btn-danger"
              [disabled]="!olvistasteTcontrasena.valid"
            >
              Recuperar contraseña
            </button>
          </div>
          <div class="form-group text-center register_form">
            <!-- Button trigger modal -->
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
