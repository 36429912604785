import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DispositivosOtrosService {
  private baseurl = "https://quar.com.co/api"
  deleteActivo(data){
      return this.http.post(`${this.baseurl}/delete/dispositivos`,data)
  }

  serial(data) {
    // console.log(data);
    return this.http.post(`${this.baseurl}/verificar/serial`, data)
  }


  cambiarSede(data) {
    return this.http.post(`${this.baseurl}/sede/dispositivo`, data)
  }


  getActivo(data){
    return this.http.post(`${this.baseurl}/editar/activo`,data)
  }

  getActivos(data){
    return this.http.post(`${this.baseurl}/tabla/dispositivos-red`,data)
  }

  create(data){
    return this.http.post(`${this.baseurl}/create/dispositivo-red`,data)
  }


  editar(data){
    return this.http.post(`${this.baseurl}/update/dispositivos-red`,data)
  }


  actualizar(data){
    return this.http.post(`${this.baseurl}/editar/computador`,data)
  }

  detail(data){
    console.log(data);
    return this.http.post(`${this.baseurl}/ficha/activo`,data)
  }

  constructor(private http:HttpClient) {  }
}
