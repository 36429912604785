import swal from 'sweetalert2';
import { UserService } from 'src/app/Services/user.service';
import { TokenService } from 'src/app/Services/token.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  tittle = "Activación de cuenta";
  userd = {
    email: this.token.getUserEmail()
  }
  constructor(private token: TokenService, private user:UserService) { }
  loading = false;
  sendEmail(){
    this.loading = true;
    this.user.sendEmail(this.userd).subscribe(data => {
      console.log(data);
      this.loading = false;
      swal.fire(
        'Excelente!',
        'Se ha enviado la notificación',
        'success'
      )
    }, error=> {
      this.loading = false;
      swal.fire(
        'Lamentamos los inconvenientes!',
        error,
        'error'
      )
    })
  }

  admin;
  codigo;
  ngOnInit() {
    if(parseInt(this.token.getUserAdministrador()) > 0){
      console.log("Has Admin");
      this.codigo = this.token.utf8_to_b64(this.token.getUserAdministrador());
    } else{
      this.admin = true;
      this.codigo = this.token.utf8_to_b64(this.token.getUserId());
    }
  }

}
