<!-- CSS Just for demo purpose, don't include it in your project -->


<div class="wrapper">
    <app-layout></app-layout>
    <div *ngIf="" class="info-slide">
        <div class="info-slide__container">
            <!-- Aquí va el contenido -->
            <div class="sidebarinfo__close">
                <i id="close_sidebar" (click)="detailactivo = false" class="fas fa-times"></i>
            </div>
            <div class="sidebarinfo__container">
                <div class="sidebarinfo__container__title">
                    <h5 id="title_ficha"></h5>
                </div>
                <div class="sidebarinfo__container__content">
                    <ul class="sidebarinfo__container__content__tabs">
                        <li class="sidebarinfo__container__content__tabs__item" id="ficha" (click)="sidebaractive = 'ficha'">Ficha Técnica</li>
                        <li class="sidebarinfo__container__content__tabs__item" id="trazabilidad" (click)="sidebaractive = 'notas'">Historial de Archivos</li>
                        <li class="sidebarinfo__container__content__tabs__item" id="manten" (click)="sidebaractive = 'mantenimientos'">Historial de Mantenimientos</li>
                    </ul>
                    <div class="sidebarinfo__container__content__tabs__content__uno">
                        <!-- {{detailactivo | json }} -->
                        <div id="fichatecnica" class="sidebarinfo__container__content__tabs__content__uno__details">
                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha" *ngIf="sidebaractive == 'ficha'">
                                <a href="https://quar.com.co/api/pdf/{{detail.serial}}" target="_blank"><button class=" cell btn btn-primary">Exportar Ficha técnica
                                    al
                                    PDF</button></a>
                                <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos">
                                    <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__title">
                                        <h4>Datos Generales</h4>
                                    </div>

                                    <ng-container *ngFor="let general of detailactivo.activo;">

                                        <div class="form-group sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items row">
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Nombre del activo</label><input value="{{general.NombreActivo}}" type="text" name="" id="" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Marca</label><input type="text" name="" id="" value="{{general.Marca}}" class="form-control" disabled>
                                            </div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Modelo</label><input type="text" name="" id="" value="{{general.Modelo}}" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Tipo de Dispositivo</label><input type="text" name="" id="" value="{{general.TipoDispositivo}}" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Direccion IP</label><input type="text" name="" id="" value="{{general.DireccionIp}}" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Serial</label><input type="text" name="" id="" value="{{general.Serial}}" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Fecha de Compra</label><input type="text" name="" id="" value="{{general.FechaCompra}}" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Valor del Activo</label><input type="text" name="" value="{{general.Valor}}" id="" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6">
                                                <label for="" class="label">Número de Factura</label><input type="text" value="{{general.NumeroFactura}}" name="" id="" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-12"><label for="" class="label">Número Poliza</label><input type="text" value="{{general.NumeroPoliza}}" name="" id="" class="form-control" disabled></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6"><label for="" class="label">Observaciones</label><textarea class="form-control" name="" id="" class="form-control" disabled>{{general.Observaciones}}</textarea></div>
                                            <div class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input col-md-6"><label for="" class="label">Contraseñas</label><textarea class="form-control" name="" id="" class="form-control" disabled>{{general.Contrasenas}}</textarea></div>

                                        </div>


                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="sidebaractive === 'notas'" class="sidebarinfo__container__content__tabs__content__dos">
                        <div class="sidebarinfo__container__content__tabs__content__dos__container__new">
                            <form enctype="multipart/form-data">
                                <label for="">Añadir observaciones a este activo</label>
                                <textarea class="form-control"></textarea>
                                <hr>
                                <div class="form-group">
                                    <div class="file-loading">
                                        <input id="file-5" class="file" type="file" multiple data-preview-file-type="any" data-upload-url="https://quar.com.co/apiupload" data-theme="fas">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="sidebarinfo__container__content__tabs__content__tres" id="mantenimientos" *ngIf="sidebaractive == 'mantenimientos'">
                        <ng-container *ngFor="let m of detailactivo.man">
                            <div class="sidebarinfo__container__content__tabs__content__uno__details__item">
                                <div class="sidebarinfo__container__content__tabs__content__uno__details__item__user">
                                    <div class="sidebarinfo__container__content__tabs__content__uno__details__item__user__name">
                                        <i class="far fa-user-circle"></i>{{m.nombre}}
                                        <hr>
                                        <ng-container *ngIf="m.TipoMantenimiento == 'Correctivo'">
                                            <p style="color:red;">Tipo: {{m.TipoMantenimiento}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="m.TipoMantenimiento == 'Preventivo'">
                                            <p style="color:green;">Tipo: {{m.TipoMantenimiento}}</p>
                                        </ng-container>
                                    </div>
                                    <div class="sidebarinfo__container__content__tabs__content__uno__details__item__user__date">
                                        {{m.FechaMantenimiento}}
                                    </div>
                                </div>
                                <div class="sidebarinfo__container__content__tabs__content__uno__details__item__update">
                                    {{m.Descripcion}}
                                </div>
                            </div>
                        </ng-container>



                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-panel">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg  navbar-transparent  navbar-absolute bg-primary fixed-top">
            <div class="container-fluid">
                <div class="navbar-wrapper">
                    <div class="navbar-toggle">
                        <button type="button" class="navbar-toggler">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <a class="navbar-brand">{{tittle}}</a>
                </div>
                <app-navbar></app-navbar>
            </div>
        </nav>
        <!-- End Navbar -->
        <div class="panel-header panel-header-lg">
            <!-- <canvas id="bigDashboardChart"></canvas -->
        </div>
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-tasks panel-activos">

                        <div class="card-header">
                            <h2 class="card-category">Maquinaria y equipos</h2>
                            <h1>Maquinaria Biomédica</h1>

                        </div>
                        <div class="card-body panel-activos__body">

                            <ng-container *ngIf="view == 'lista'">
                                <div class="container text-center" *ngIf="vacio == ''">
                                    <img src="../../assets/img/loading.gif" alt=""><br>
                                    <h2>Aún estamos a la espera de tu activo</h2>

                                    <button (click)="view='registro'" class="btn btn-secondary">Registrar Otro dispositivo</button>
                                </div>
                                <div class="table-full-width table-responsive scroll" *ngIf="vacio == 'No'">
                                    <table class="table-activo__container table-striped  table-hover table-bordered panel-activos__body__table">
                                        <thead class="table-activo__container table-striped __head">
                                            <tr>
                                                <th>Serial</th>
                                                <th>Nombre del Activo</th>
                                                <th>Estado</th>
                                                <th>Proximo Mantenimiento</th>
                                                <th>Opciones</th>

                                            </tr>
                                        </thead>
                                        <tbody class="table-activo__container table-striped __head__body">
                                            <tr ([ngModel])="computador" class="table-activo__container table-striped __head__body__item" *ngFor="let item of computador | paginate: { itemsPerPage: 10, currentPage: p }">
                                                <ng-container>
                                                    <td (click)="details(item.Codigo)">
                                                        {{item.Codigo}}
                                                    </td>
                                                    <td (click)="details(item.Codigo)">
                                                        {{item.NombreActivo}}
                                                    </td>
                                                    <td (click)="details(item.Codigo)">
                                                        {{item.NombreActivo}}
                                                    </td>
                                                    <td (click)="details(item.Codigo)" class="text-left">
                                                        {{item.ProximoMantenimiento}}</td>

                                                    <td class="td-actions text-left">
                                                        <button routerLink="/editar/dispositivosred/{{item.Codigo}}" type="button" rel="tooltip" title="" class="btn btn-round btn-icon btn-icon-mini btn-edit" data-original-title="Edit Task">
                                                            <i class="icon-edit"></i>
                                                        </button>
                                                        <button type="button" rel="tooltip" title="" (click)="deleteImpresora(item.id, item.Codigo)" class="btn btn-round btn-icon btn-icon-mini btn-delete" data-original-title="Remove">
                                                            <i class="icon-REMOVE"></i>
                                                        </button>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                            <div class="content-serial" *ngIf="view == 'serial'">
                                <div class="card" role="alert">
                                    <div class="container">
                                        <br>
                                        <hr>
                                        <h3>Verificación de serial</h3>
                                        <input type="text" placeholder="Serial del código" class="form-control" name="Codigo" [(ngModel)]="form.Codigo">
                                        <button class="btn btn-complementary cell" (click)="serialVerify()">Verificar serial</button></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a Computadores</button>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-primary cell" (click)="view='registro'">Quiero continuar sin serial</button>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="view == 'registro'">
                                <div class="content-registro">
                                    <div class="content-registro__container ">
                                        <form class="content-registro__container__form" #dispositivosForm="ngForm" (ngSubmit)="onSubmit()">
                                            <div class="col-md-12 container__datos_generales">
                                                <div class="row">
                                                    <div class="col-lg-12 ">
                                                        <h2>¿Estás listo? ...Arranquemos</h2>
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 10%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <!-- <h4>Datos Generales</h4> -->
                                                        <h4>Información del Otro dispositivo</h4>

                                                    </div>


                                                    <div class="row">
                                                        <input type="hidden" id="serial" name="codigo">
                                                        <div class="col-md-4">
                                                            <label>Nombre del Activo</label>
                                                            <input type="text" class="form-control" name="NombreActivo" [(ngModel)]="form.NombreActivo">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Tipo de dispositivo</label>
                                                            <input type="text" class="form-control" name="TipoDispositivo" [(ngModel)]="form.TipoDispositivo">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Marca</label>
                                                            <input type="text" class="form-control" name="Marca" [(ngModel)]="form.Marca">

                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Modelo
                                                        </label>
                                                            <input type="text" class="form-control" name="Modelo" [(ngModel)]="form.Modelo">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Serial
                                                        </label>
                                                            <input type="text" class="form-control" name="Serial" [(ngModel)]="form.Serial">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Fecha compra</label>
                                                            <input type="date" class="form-control" name="FechaCompra" [(ngModel)]="form.FechaCompra">
                                                        </div>
                                                        <div class="col-md-12">
                                                            <br>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Número Factura</label>
                                                            <input type="text" class="form-control" name="NumeroFactura" [(ngModel)]="form.NumeroFactura">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Valor del Activo</label>
                                                            <input type="text" class="form-control" name="ValorActivo" [(ngModel)]="form.ValorActivo">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Número de Póliza</label>
                                                            <input type="text" class="form-control" name="NumeroPoliza" [(ngModel)]="form.NumeroPoliza">
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label>Dirección IP</label>
                                                            <input type="text" class="form-control" name="DireccionIp" [(ngModel)]="form.DireccionIp">
                                                        </div>
                                                        <div class="col-md-12">
                                                            <br>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Observaciones</label>
                                                            <textarea class="form-control" name="Observaciones" [(ngModel)]="form.Observaciones"></textarea>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Contraseñas</label>
                                                            <textarea class="form-control" name="Observaciones" [(ngModel)]="form.Contrasenas"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <br>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Ciclos de mantenimiento</label>
                                                            <select required id="Ciclo" name="CicloMantenimiento" [(ngModel)]="form.CiclosMantenimiento" class="form-control">
                                                                <option value="" disabled selected>Selecciona una opción
                                                                </option>
                                                                <option value="1">MENSUAL</option>
                                                                <option value="2">BIMESTRAL</option>
                                                                <option value="3">TRIMESTRAL</option>
                                                                <option value="4">SEMESTRAL</option>
                                                                <option value="5">ANUAL</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Proximo Mantenimiento</label>
                                                            <input type="date" class="form-control" name="ProximoMantenimiento" [(ngModel)]="form.ProximoMantenimiento">
                                                        </div>
                                                        <br>
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary">Registrar Otro dispositivo</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="panel-activos__body__pagination">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>

                            <div class="panel-activos__body__create">
                                <button *ngIf="view == 'lista'" class="btn btn-primary" (click)="view='serial'">Agregar Equipo Biomédico</button>
                                <a href="https://quar.com.co/api/excel/computador"><button *ngIf="view == 'lista'" class="btn btn-secondary" (click)="view='registro'">Exportar a excel</button></a>
                                <button *ngIf="view == 'registro'" class="btn btn-secondary" (click)="view='serial'"><i class="fas fa-arrow-circle-left"></i> Cambiar Serial</button>
                                <button *ngIf="view == 'registro'" class="btn btn-primary" (click)="view='lista'">Ver equipos Biomédicos</button>
                            </div>



                        </div>
                        <div class="card-footer ">
                            <hr>
                            <div class="stats ">
                                <i class="now-ui-icons loader_refresh spin "></i>Actualizando
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
