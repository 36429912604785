<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active">Computadores</li>
                                <li class="breadcrumb-item active">Detalle</li>
                                <!-- <li class="breadcrumb-item active" *ngIf="view=='serial'">Serial</li> -->
                                <!-- <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li> -->
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a (click)="view='serial'" class="btn btn-primary text-white" target="_blank">Agregar
                            Computador</a>
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Exportar excel</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div  class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Editar activo  {{serial}}</h4>
                                    <h5 class="card-subtitle">Formulario</h5>
                                    <!-- <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario de registro</h5> -->
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">

                                    <form class="content-registro__container__form" #editarForm=ngForm
                                        (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-md-12 container__datos_generales" *ngIf="pasos=='uno'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <!-- <h2>¿Estás listo? ...Arranquemos</h2> -->
                                                        <h4>Datos Generales</h4>
                                                        <hr>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <input type="hidden" placeholder="Nombre del Activo"
                                                            class="form-control" required [(ngModel)]="form.Codigo"
                                                            [ngModelOptions]="{standalone: true}" name="Codigo">
                                                        <input type="text" placeholder="Nombre del Activo"
                                                            class="form-control" required
                                                            [(ngModel)]="form.NombreActivo"
                                                            [ngModelOptions]="{standalone: true}" name="Nombre">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <!-- {{form.idTipoPc}} -->
                                                        <select class="form-control" name="idTipoPc"
                                                            [(ngModel)]="form.idTipoPc">
                                                            <option *ngFor="let tipo of tipocomputador;"
                                                                value="{{tipo.IdTipoPc}}">{{tipo.TipoPc}}
                                                            </option>


                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Marca" class="form-control"
                                                            [(ngModel)]="form.Marca" name="Marca">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Modelo"
                                                            [(ngModel)]="form.Modelo" class="form-control"
                                                            name="Modelo">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Serial" class="form-control"
                                                            [(ngModel)]="form.Serial" name="Serial">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="date" placeholder="Fecha de Compra"
                                                            [(ngModel)]="form.FechaCompra" class=" form-control"
                                                            name=FechaCompra>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Número de Factura"
                                                            class="form-control" [(ngModel)]="form.NumeroFactura"
                                                            name="NumeroFactura">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Valor del Activo"
                                                            class="form-control" [(ngModel)]="form.Valor" name="Valor">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Procesador" class="form-control"
                                                            [(ngModel)]="form.Procesador" name="Procesador">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Tamaño Pantalla (Pulgadas)"
                                                            [(ngModel)]="form.TamanoPantalla" class="form-control"
                                                            name="TamanoPantalla">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <textarea name="" class="form-control" id=""
                                                            [(ngModel)]="form.Password" placeholder="Contraseñas"
                                                            name="Password"></textarea>
                                                    </div>
                                                </div>
                                                <button (click)="pasos='dos'" class="btn btn-primary">Continuar <i
                                                        class="fas fa-arrow-right"></i></button>

                                            </div>

                                            <!-- Datos de Memoria -->
                                            <div class="col-md-12" *ngIf="pasos=='dos'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 25%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h3>Datos Memoria</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="idTipoMemoria" [(ngModel)]="form.idTipoMemoria"
                                                            aria-placeholder="Selecciona una opción"
                                                            class="form-control">
                                                            <ng-container *ngFor="let tipo of tipomemorias;">
                                                                <option value="{{tipo.IdTipoMemoria}}">
                                                                    {{tipo.TipoMemoria}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" [(ngModel)]="form.CantidadModulos"
                                                            name="CantidadModulos" placeholder="Cantidad de Módulos"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 1"
                                                            [(ngModel)]="form.CantMemoria1" name="CantMemoria1"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 2"
                                                            [(ngModel)]="form.CantMemoria2" name="CantMemoria2"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 3"
                                                            [(ngModel)]="form.CantMemoria3" name="CantMemoria3"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Memoria módulo 4"
                                                            class="form-control" [(ngModel)]="form.CantMemoria4"
                                                            name="CantMemoria4">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h3>Datos Discos</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select class="form-control" name="TipoDisco"
                                                            [(ngModel)]="discos.TipoDisco">
                                                            <option value="SSH">SSH</option>
                                                            <option value="SOLIDO">SOLIDO</option>
                                                            <option value="RIGIDO">RIGIDO</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Cantidad de Disco"
                                                            class="form-control" name="capacidadDisco"
                                                            [(ngModel)]="discos.CapacidadDisco">
                                                    </div>
                                                    <div class="col-md-12 text-center">
                                                        <div class="row">
                                                            <ng-container ([ngModel])="discosarray"
                                                                *ngFor="let discos of discosarray" class="col-md-2">
                                                                <div
                                                                    class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos col-md-4">
                                                                    <div
                                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__title">
                                                                        {{discos.TipoDisco}}</div>
                                                                    <div
                                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__icon">
                                                                        <i class="far fa-save"></i></div>
                                                                    <div
                                                                        class="sidebarinfo__container__content__tabs__content__uno__details__ficha__datos__items__input__discos__capacidad">
                                                                        {{discos.CapacidadDisco}} GB</div>
                                                                    <button class="btn btn-alert" type="button"
                                                                        (click)="eliminardisco(discos.IdDisco)">Eliminar</button>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <button class="btn btn-tertiary" type="button"
                                                            (click)="addDisk()">Registrar Disco</button>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <br>
                                                        <button (click)="pasos='uno'" class="btn btn-secondary"> <i
                                                                class="fas fa-arrow-left"></i> Anterior</button>
                                                        <button (click)="pasos='tres'" class="btn btn-primary">Continuar
                                                            <i class="fas fa-arrow-right"></i></button>

                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Fin datos de memoria -->
                                            <!-- Software -->
                                            <div class="col-md-12" *ngIf="pasos=='tres'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="50" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 50%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h3>Datos de Software</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select [(ngModel)]="form.IdSistemaOperativo"
                                                            name="IdSistemaOperativo" class="form-control">
                                                            <option value="null">Sistema Operativo</option>
                                                            <ng-container *ngFor="let tipo of sistemaoperativo;">
                                                                <option value="{{tipo.idOs}}">{{tipo.NombreOs}}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="VersionOs" [(ngModel)]="form.VersionOs"
                                                            class="form-control">
                                                            <option value="1020493468">Edición Sistema Operativo
                                                            </option>
                                                            <option value="Profesional">Profesional</option>
                                                            <option value="Home">Home</option>
                                                            <option value="Single">Single</option>
                                                            <option value="Ultimate">Ultimate</option>
                                                            <option value="Starter">Starter</option>
                                                            <option value="Enterprise">Enterprise</option>
                                                            <option value="Education">Education</option>
                                                            <option value="Standard">Standard</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="IdTipoLicenciaOs"
                                                            [(ngModel)]="form.IdTipoLicenciaOs" class="form-control">
                                                            <option value="4">Tipo de Licencia</option>
                                                            <ng-container *ngFor="let tipo of licencias;">
                                                                <option value="{{tipo.idLicencia}}">
                                                                    {{tipo.NombreLicencia}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.NumeroLicenciaOs" name="NumeroLicenciaOs"
                                                            placeholder="Licencia Sistema Operativo">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select name="IdTipoOffice" [(ngModel)]="form.IdTipoOffice"
                                                            class="form-control">
                                                            <option value="">Seleccione una opción</option>
                                                            <option value="Starter">Starter</option>
                                                            <option value="Hogar y Estudiantes">Hogar y Estudiantes
                                                            </option>
                                                            <option value="Hogar y Pequeños Negocios">Hogar y
                                                                Pequeños Negocios
                                                            </option>
                                                            <option value="Standard">Standard</option>
                                                            <option value="Profesional">Profesional</option>
                                                            <option value="Profesional Plus">Profesional Plus
                                                            </option>
                                                            <option value="Academico">Academico</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select [(ngModel)]="form.Office" name="Office"
                                                            class="form-control">

                                                            <option value="">Seleccione una opción</option>
                                                            <option value="Open Office">Open Office</option>
                                                            <option value="365 Hogar">365 Hogar</option>
                                                            <option value="365 Personal">365 Personal</option>
                                                            <option value="365 Empresa">365 Empresa</option>
                                                            <option value="365 Empresa Premium Empresa">365 Empresa
                                                                Premium Empresa
                                                            </option>
                                                            <option value="365 Empresa Essentials">365 Empresa
                                                                Essentials</option>
                                                            <option value="2019">2019</option>
                                                            <option value="2016">2016</option>
                                                            <option value="2013">2013</option>
                                                            <option value="2010">2010</option>
                                                            <option value="2007">2007</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Licencia Office"
                                                            [(ngModel)]="form.NumeroLicenciaOffice"
                                                            name="NumeroLicenciaOs" class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Fecha de Vencimiento Office"
                                                            [(ngModel)]="form.FechaVenOffce" name="FechaVenOffce"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" placeholder="Antivirus"
                                                            [(ngModel)]="form.Antivirus" name="Antivirus"
                                                            class=" form-control">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="date" [(ngModel)]="form.VencimientoAntivirus"
                                                            name="VencimientoAntivirus"
                                                            placeholder="Vencimiento de Antivirus" class="form-control">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <textarea name="" [(ngModel)]="form.OtrosProgrmas"
                                                            name="OtrosProgrmas" class="form-control" id=""
                                                            placeholder="Otros Programas"></textarea>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                        <button (click)="pasos='dos'" class="btn btn-secondary"> <i
                                                                class="fas fa-arrow-left"></i> Anterior</button>
                                                        <button (click)="pasos='cuatro'"
                                                            class="btn btn-primary">Continuar <i
                                                                class="fas fa-arrow-right"></i></button>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- Red y Mantenimientos -->
                                            <div class="col-md-12" *ngIf="pasos=='cuatro'">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="progress" style="height:4px">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                                aria-valuemax="100" style="width: 75%">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h3>Red y Mantenimientos</h3>
                                                        <hr>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.Red_Nombre_PC" name="Red_Nombre_PC"
                                                            placeholder="Nombre del PC en Red">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" [(ngModel)]="form.MAC"
                                                            placeholder="Dirección MAC" name="MAC">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" [(ngModel)]="form.IP"
                                                            placeholder="Dirección IP" name="IP">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.Puerta_de_Enlace" name="Puerta_de_Enlace"
                                                            placeholder="Puerta de Enlace">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select [(ngModel)]="form.IdCicloMantenimiento"
                                                            name="IdCicloMantenimiento" class="form-control">
                                                            <option value="null" selected>Ciclo de Mantenimiento
                                                            </option>
                                                            <ng-container *ngFor="let tipo of ciclosmantenimiento;">
                                                                <option value="{{tipo.IdCiclo}}">
                                                                    {{tipo.NombreCiclo}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="date" [(ngModel)]="form.ProximoMantenimiento"
                                                            name="ProximoMantenimiento"
                                                            placeholder="Proximo Mantenimiento" class="form-control">
                                                    </div>

                                                </div>
                                                <button (click)="pasos='tres'" class="btn btn-secondary"> <i
                                                        class="fas fa-arrow-left"></i> Anterior</button>
                                                <button type="submit" class="btn btn-primary"
                                                    [disabled]="!editarForm.valid">Actualizar Computador</button>
                                            </div>
                                            <!-- Fin de Software -->

                                            <div class="col-lg-12">
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
           

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>