<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">Panel admnistrativo</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Panel de control</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="content">
                <div class="jumbotron" style="background-image: none !important;">
                    <div class="form-correspondencia" *ngIf="!admin">
                        <h1>Registrar nueva correspondencia</h1>
                        <form #correoForm="ngForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="">A donde va la correspondencia</label>
                                    <input type="text" [(ngModel)]="form.Destino" name="destino" class="form-control">
                                </div>
                                <!-- <div class="col-md-6">
                                <label for="">Ubicación en porteria</label>
                                <input type="text" name="ubicacion" [(ngModel)]="form.Ubicacion" class="form-control">
                            </div> -->
                                <div class="col-md-12">
                                    <label for="">Observación de la correspondencia</label>
                                    <textarea [(ngModel)]="form.Observacion" name="observacion"
                                        class="form-control"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <br>
                                    <button type="submit" class="btn btn-primary cell">
                                        Agregar correspondencia
                                    </button>
                                    <br>
                                </div>
                            </div>
                        </form>
                    </div>
                    <h1 class="display-4">Correspondencia</h1>
                    
                    <div class="buscar">
                        <label for="">Buscar correspondencia</label>
                        <input type="search" [(ngModel)]="term" class="form-control">
                        <br>
                    </div>
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Observación</th>
                                <th scope="col">Destino</th>
                                <th scope="col">Recibió</th>
                                <th scope="col">Entregó</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container ([ngModel])="correos" *ngFor="let item of correos | filter:term">
                
                                <tr>
                                    <th scope="row">{{item.Observacion}}</th>
                                    <td>{{item.Destino}}</td>
                                    <td>
                                        {{item.Recibio}}
                                        <hr> {{item.FechaRecibo}}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.Entrego">
                                            {{item.Entrego}}
                                            <hr> {{item.FechaEntrega}}
                                        </ng-container>
                                        <ng-container *ngIf="!item.Entrego">
                                            <button *ngIf="!admin" (click)="entregar(item.IdCorrespondencia)"
                                                class="btn btn-info">
                                                Entregar
                                            </button>
                                            <p *ngIf="admin" class="btn btn-info">
                                                No ha entregado aún
                                            </p>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                
                        </tbody>
                    </table>
                </div>
                <div class="row" *ngIf="!admin">
                
                
                
                
                
                </div>
                
                
                
                
                </div>
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
            
            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->