import { ActivatedRoute, Router } from '@angular/router';
import { MantenimientosService } from './../../Services/mantenimientos.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { formatDate } from '@angular/common';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-crearmantenimientos',
  templateUrl: './crearmantenimientos.component.html',
  styleUrls: ['./crearmantenimientos.component.scss']
})
export class CrearmantenimientosComponent implements OnInit {
  signatureImg: string;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': "280",
    'canvasHeight': "450" };
  date;
  user = {
    id_user: this.Token.getUserId(),
    serial: this.route.snapshot.paramMap.get("serial")
  }


  form = {
    FechaMantenimiento: null,
    TipoMantenimiento: 'Correctivo',
    Descripcion: null,
    ClaseMantenimiento: 'General',
    IdActivo: null,
    ciclo: null,
    Codigo: null,
    IdUsuarioSoporte: this.Token.getUserId(),
    UsuarioAdministrador: null,
    Firma:null,
    Ticket:null,
    NombreUsuario: this.Token.getUserName()
  }


  volver() {
    this._location.back();
  }


  users;
  activo;
  constructor(
    private router: Router,
    private Token: TokenService,
    private route: ActivatedRoute,
    private mantenimiento: MantenimientosService,
    private _location: Location) { }

    nombreActivo:any;
    tickets;
  handleResponse(data) {
    this.tickets = data.tickets;
    console.log(data);
    console.log(this.date);
    console.log( "data que necesito");
    console.log(data);
    if(data.computador){
        this.nombreActivo = data.computador[0].NombreActivo;
        // alert(this.nombreActivo);
    } else {
      this.nombreActivo = data.id.Nombre;

    }
    // alert();
    this.form.Codigo = data.serial;
    this.form.FechaMantenimiento = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.form.UsuarioAdministrador = data.user[0].id_user;
    this.form.IdActivo = data.id[0].id;
    this.form.ciclo = data.id[0].ciclo;
    this.activo = data.id;
    console.log(this.activo);
    this.users = data.user;
  }

  tittle = "mantenimientos para el activo";
  onSubmit() {
    console.log( this.signatureImg);
    // return;
    this.form.Firma = this.signatureImg;
    this.mantenimiento.createMantenimiento(this.form).subscribe(
      data => {
        swal.fire({
          title: 'Digita el correo electrónico para envíar una copia de mantenimiento',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Envíar por correo',
          cancelButtonText: 'Solo quiero guardar',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            return fetch(`https://quar.com.co/api/send/mantenimiento/${login}/${this.form.IdActivo}/${this.nombreActivo}/${this.user.serial}/${this.form.Codigo}`)
              .then(response => {
                if (!response.ok) {
                  throw new Error(response.statusText)
                }
              })
              .catch(error => {
                swal.showValidationMessage(
                  `Debes llenar el campo correo electrónico: ${error}`
                )
              })
          },
          allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            swal.fire(
              'Excelente!',
              'Se ha enviado la novedad y se agregó el registro',
              'success'
            ),
              this._location.back();


          } else {

            swal.fire(
              'Excelente!',
              'Se ha registrado la novedad',
              'success'
            ),
              this._location.back();
          }


        })

      },
      error => {
        swal.fire(
          'Lo sentimos!',
          'Estamos trabajando para solucionar los inconvenientes',
          'error'
        )
      }
    );

    // console.log(this.form)


  }

  ngOnInit() {
    this.mantenimiento.getInformation(this.user).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 2);
    this.signaturePad.clear();
  }

  drawComplete() {
    console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }


}
