<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item" routerLink="/tickets">Tickets</li>
                                <li class="breadcrumb-item active" aria-current="page">Tickets</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Mesa de ayuda</h4>
                                    <h5 class="card-subtitle">Información del ticket 000{{numeroTicket}}</h5>
                                </div>
                               
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <div class="card card-tasks panel-ticket">
                                <div class="row card-body panel-ticket__body">
                                    <div class="col-sm-12 col-md-2 panel-ticket__body__info" *ngFor="let d of ticketdetail">
                                        <select (change)="onChange($event.target.value, d.UsuarioTicket, d.NumeroTIcket)" class="form-control" [(ngModel)]="d.Estado">
                                            <option value="Pendiente">Pendiente</option>
                                            <option value="Proceso">Proceso</option>
                                            <option value="Terminado">Terminado</option>
                                        </select>
                                   
                                        <h5>Asunto: {{d.Asunto}}</h5>
                                        <hr>
                                        <h6>Mensaje</h6>
                                        <textarea disabled class="form-control">{{d.Mensaje}}</textarea>
                                        <hr>
                                        <h6>Correo del Cliente</h6>
                                        <input type="text" disabled class="form-control" value="{{d.CorreoCliente}}">
                                    </div>
                                    <div class="col-md-9 col-sm-12 panel-ticket__body__notas" *ngFor="let d of ticketdetail">
                                        <div class="alert alert-info" style="color:white" role="alert" *ngIf="d.Estado == 'Terminado'">
                                            Esta solicitud ha sido notificada como terminada
                                        </div>
                                        
                                        <h2>Vista del Ticket</h2>
                                        
                                        <div class="panel-ticket__body__notas__nuevo">
                                            <button class="btn cell btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                Responder
                                              </button>
                            
                                            <div class="collapse" id="collapseExample">
                                                <div class="card card-body">
                                                    <form #notasForm=ngForm (ngSubmit)="sendNot()">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <h6>Nombre</h6>
                                                                <input disabled type="text" required class="form-control" name="NombreUsuario" [(ngModel)]="sendNota.NombreUsuario">
                                                            </div>
                                                            <div class="col-md-6">
                                                                <h6>Correo</h6>
                                                                <input disabled type="text" required class="form-control" name="correo" [(ngModel)]="sendNota.correo">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <h6>Mensaje</h6>
                                                        <textarea name="NotaMensaje" [(ngModel)]="sendNota.NotaMensaje" required class="form-control"></textarea>
                                                        <div class="col-md-12"></div>
                                                        <button type="submit" [disabled]="!notasForm.valid" class="cell btn btn-secondary">Enviar Nota</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- {{ticketnotas | json}} -->
                                        <ng-container ([ngModel])="ticketnotas" *ngFor="let n of ticketnotas">
                                            <div class="panel-ticket__body__notas__message card">
                                                <div class="panel-ticket__body__notas__message__name">
                                                    <!-- John Nelson Florez Calderon -->
                                                    {{n.NombreUsuario}}
                                                </div>
                                                <div class="panel-ticket__body__notas__message__nota">
                                                    <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime corporis pariatur nisi inventore, non excepturi architecto fuga nam earum minus id quia minima ex nulla molestias vel eaque iste porro! -->
                                                    {{n.NotaMensaje}}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            
                                <div class="card-footer ">
                                    <hr>
                                    <div class="stats ">
                                        <i class="now-ui-icons loader_refresh spin "></i>Actualizando
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>