import { TokenService } from 'src/app/Services/token.service';
import { TicketsService } from './../Services/tickets.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  tittle:string = "GESTIÓN DE TICKETS";
  data = {
    id: this.Token.getUserId(),
  }
  constructor(private Token: TokenService ,private tickets: TicketsService) { }
  ticketList:any;
  users:any;
  loading=false;
  UsuarioAsignado:string = "Asignar Usuario";
  usuario = {
    ticket:null,
    user:null
  }
  term : null;
  numberperpage = 10;
  p = 1;


  filtrar(event) {

    console.log(event);
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  handleResponse(data){
    // alert(data.ticket);
    console.log(data.ticket)
      this.ticketList = data.ticket;
      this.users = data.users;
      console.log(this.users)
      console.log(this.ticketList.ticket)
  }

  ngOnInit() {
      this.tickets.getTickets(this.data).subscribe(
        data => this.handleResponse(data),
        error => console.log(error)
      )
  }

  onChange(value,id){
    this.usuario.ticket= id;
    this.usuario.user = value;
    console.log(this.usuario)
    this.tickets.usuario(this.usuario).subscribe(
      data => console.log(data),
      error => console.log(error)
    )
  }

}
