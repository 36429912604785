import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { TokenService } from 'src/app/Services/token.service';
import { CronogramaService } from 'src/app/Services/cronograma.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
declare var jQuery: any;

@Component({
  selector: 'app-cronograma',
  templateUrl: './cronograma.component.html',
  styleUrls: ['./cronograma.component.scss']
})
export class CronogramaComponent implements OnInit {
  calendarOptions: CalendarOptions = {
    weekNumbers:true,
    initialView: localStorage.getItem('tipocalendario'),
    eventDisplay:'block',
    locale: 'ES',
    nowIndicator: true,
    dateClick: function(info) {
      alert('Clicked on: ' + info.dateStr);
      // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      // alert('Current view: ' + info.view.type);
      // change the day's background color just for fun
      info.dayEl.style.backgroundColor = 'red';
    },
    views: {
      timeGrid: {
        dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
      }
    }
  };
  eventoscalendar: any = [];
  calendarioview = 'timeGridWeek';

  data = {
    IdEvento: null,
    Descripcion: "",
    HoraInicio: null,
    HoraFinal: null,
    Nombre: "",
    UsuarioNotificacion: null,
    UsuarioAsignado: 0,
    UsuarioAdministrador: 0,
    ActivoAsignado: null,
    ColorEvento: "#005870"

  }

  loading = false;
  title = "CRONOGRAMA DE ACTIVIDADES"

  handleDateClick(info) {

    // alert(this.calendarOptions.initialView);
    jQuery("#staticBackdrop").modal("show");
    // alert(,)  
    this.data.Nombre = info.event.title;
    this.data.Descripcion = info.event.extendedProps.description;
    this.data.ColorEvento = info.event.backgroundColor;
    // this.data.UsuarioAsignado = 0;
    this.data.IdEvento = info.event.id;
    this.data.HoraInicio = info.event.start;
    this.data.HoraFinal = info.event.end;
  }
  codigos;
  eventos: any;

  onSubmit() {
    this.data.IdEvento = null;
    this.data.UsuarioNotificacion = this.data.UsuarioAsignado;
    // alert();
    this.calendario.create(this.data).subscribe(
      data => {
        this.toastr.success('Evento agregado', "Se ha actualizado la información");
        // this.codigos = data['codigos'];
        this.eventos = data['eventos'];
        // this.usuarios = data['usuarios'];
        this.eventos.forEach(element => {
          // console.log(element.Descripcion);
          this.eventoscalendar.push({
            title: element.Nombre,
            description: element.Descripcion,
            start: element.HoraInicio,
            // color: ,
            textColor: '#ffffff',
            backgroundColor: element.ColorEvento,
            id: element.IdEvento,
          })
        });
        // console.log(this.eventoscalendar)
        this.redirectTo('/cronograma');
      },
      error => console.log(error)
    )
  }

  actualizarEvento() {
    
    this.data.UsuarioNotificacion = this.data.UsuarioAsignado;
    // alert();
    this.calendario.actualizar(this.data).subscribe(
      data => {
        this.toastr.success('Evento actualizado', "Se ha actualizado la información");
        // this.codigos = data['codigos'];
        this.eventos = data['eventos'];
        // this.usuarios = data['usuarios'];
          
          this.redirectTo('/cronograma');
        },
        
          error => console.log(error)
    )
  }


  redirectTo(uri:string){
    this.router.navigateByUrl('/home', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));}

    tipocalendario = localStorage.getItem('tipocalendario');;
  toggleWeekends() {
    // this.calendarOptions.initialView = 'listDay';
    localStorage.setItem('tipocalendario', this.tipocalendario)
    this.tipocalendario = localStorage.getItem('tipocalendario');
    this.redirectTo('/cronograma');

    // this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
  }
  constructor(
    private router:Router,
    private cd: ChangeDetectorRef, private toastr: ToastrService, private token: TokenService, private calendario: CronogramaService) { }
  idUser;
  usuarios;
  ngOnInit(): void {
    jQuery("#staticBackdrop").modal("hide");
    jQuery("#modalEventoNuevo").modal("hide");
    if(localStorage.getItem('tipocalendario')){
      this.tipocalendario = localStorage.getItem('tipocalendario');
    } else {
      localStorage.setItem('tipocalendario', 'listDay')
      this.tipocalendario = localStorage.getItem('tipocalendario');

    }
    // alert();
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.idUser = this.token.getUserAdministrador();
    } else {
      // this.admin = true;
      this.idUser = this.token.getUserId();
    }
    this.data.UsuarioAdministrador = this.idUser;
    // alert(this.data.UsuarioAdministrador);
    this.calendario.getEvento(this.data).subscribe(
      data => {
        this.codigos = data['codigos'];
        this.eventos = data['eventos'];
        this.usuarios = data['usuarios'];
        this.eventos.forEach(element => {
          // console.log(element.Descripcion);
          this.eventoscalendar.push({
            title: element.Nombre,
            description: element.Descripcion,
            start: element.HoraInicio,
            // color: ,
            textColor: '#ffffff',
            backgroundColor: element.ColorEvento,
            id: element.IdEvento,
          })
        });
        console.log(this.eventoscalendar)
        
          
        
        console.log(this.codigos)
        this.calendarOptions = {
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,basicWeek'
            // right: 'month,basicWeek,basicDay'
          },
          
          // buttonIcons:,
          // initialView: 'listDay',
          // defaultDate: '2019-07-09',
          // weekNumbers: false,
          // editable: true,
          // eventLimit: true,
          locale: 'CO',
          weekNumbers:true,
          nowIndicator: true,
          events: this.eventoscalendar,
          eventDrop: this.handleDateClick.bind(this),
          eventClick: this.handleDateClick.bind(this), // bind is important!
          // dayClick: this.handleDateClick.bind(this), // bind is important!
          
          // dayClick: function (date, jsEvent, view) {
            //   alert('Has hecho click en: ' + date.format());
          // },
        }
        // alert("llego")
      }
    )
  }
  


}
