import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CorreoService {

  constructor(private http:HttpClient) { }

  private baseurl = "https://quar.com.co/api";
  correo(data) {
    return this.http.post(`${this.baseurl}/get/correo`, data)
  }

  create(data) {
    return this.http.post(`${this.baseurl}/create/correo`, data)
  }

  update(data) {
    return this.http.post(`${this.baseurl}/update/correo`, data)
  }

}
