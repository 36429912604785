import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  private baseurl = "https://quar.com.co/api"
  deleteActivo(data){
      return this.http.post(`${this.baseurl}/delete/otheractivos`,data)
  }

  getTickets(data){
    return this.http.post(`${this.baseurl}/tickets`,data)
  }


  changeEstado(data){
    return this.http.post(`${this.baseurl}/change-estado`,data)
  }


  getObservaciones(data){
    return this.http.post(`${this.baseurl}/novedades`,data)
  }

  getImpresoras(data){
    return this.http.post(`${this.baseurl}/tabla/impresoras`,data)
  }

  create(data){
    return this.http.post(`${this.baseurl}/send/tickets`,data)
  }

  usuario(data){
    return this.http.post(`${this.baseurl}/asignar/usuario`,data)
  }


  estado(data){
    return this.http.post(`${this.baseurl}/asignar/estado`,data)
  }

  editar(data){
    return this.http.post(`${this.baseurl}/update/impresoras`,data)
  }


  actualizar(data){
    return this.http.post(`${this.baseurl}/editar/computador`,data)
  }

  detail(data){
    console.log(data);
    return this.http.post(`${this.baseurl}/detail/ticket`,data)
  }

  detailNovedad(data){
    console.log(data);
    console.log("función")
    return this.http.post(`${this.baseurl}/detail/novedad`,data)
  }

  notas(data){
    console.log(data);
    return this.http.post(`${this.baseurl}/notas`,data)
  }

  sgr(data){
    console.log(data);
    return this.http.post(`${this.baseurl}/sgr/detail`,data)
  }

  constructor(private http:HttpClient) {  }
}


