import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CronogramaService {
  private baseurl = "https://quar.com.co/api"
  // private baseurl = "https://quar.com.co/api"
  getEvento(data) {
    return this.http.post(`${this.baseurl}/obtener/cronograma`, data)
  }


  actualizar(data) {
    return this.http.post(`${this.baseurl}/actualizar/evento`, data)
  }


  create(data) {
    return this.http.post(`${this.baseurl}/create/evento`, data)
  }
  constructor(private http: HttpClient) { }
}
