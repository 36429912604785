import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
// import { SedesComponent } from './sedes/sedes.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DispositivosComponent } from './dispositivos/dispositivos.component';
import { ProfileactivoComponent } from './profileactivo/profileactivo.component';
import { ImpresorasComponent } from './impresoras/impresoras.component';
import { MantenimientosComponent } from './mantenimientos/mantenimientos.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketsdetailComponent } from './ticketsdetail/ticketsdetail.component';
import { CuentausuarioComponent } from './cuentausuario/cuentausuario.component';
import { RegisterComponent } from './register/register.component';
import { ComputadorComponent } from './computador/computador.component';
import { OtrosdispositivosComponent } from './otrosdispositivos/otrosdispositivos.component';
import { JarwisService } from './Services/jarwis.service';
import { TokenService } from './Services/token.service';
import { AuthService } from './Services/auth.service';
import { NavbarComponent } from './navbar/navbar.component';
import { CrearmantenimientosComponent } from './gestion/crearmantenimientos/crearmantenimientos.component';
import { EditarimpresorasComponent } from './impresoras/editarimpresoras/editarimpresoras.component';
import { MaquinasComponent } from './maquinas/maquinas.component';
import { ConfigComponent } from './config/config.component';
import { EditardispositivosComponent } from './dispositivos/editardispositivos/editardispositivos.component';
import { InfoComponent } from './info/info.component';
import { EmpleadosComponent } from './empleados/empleados.component';
import { EditarcomputadorComponent } from './computador/editarcomputador/editarcomputador.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { BiomedicasComponent } from './biomedicas/biomedicas.component';
import { VisitantesComponent } from './visitantes/visitantes.component';
import { RegistroVisitantesComponent } from './registro-visitantes/registro-visitantes.component';
import { EmpleadoprofiComponent } from './empleadoprofi/empleadoprofi.component';
import { SearchComponent } from './search/search.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UsuarioscompaniaComponent } from './usuarioscompania/usuarioscompania.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { NgxPrintModule } from 'ngx-print';
import { MaquinasindustrialComponent } from './maquinasindustrial/maquinasindustrial.component';
import { NgSelect2Module } from 'ng-select2';
import { InsumosComponent } from './insumos/insumos.component';
import { CorrespondenciaComponent } from './correspondencia/correspondencia.component';
import { SedecompanyComponent } from './sedecompany/sedecompany.component';
import { VigilantesComponent } from './Vigilancia/vigilantes/vigilantes.component';
import { VigilanteobservacionComponent } from './Vigilancia/vigilanteobservacion/vigilanteobservacion.component';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CronogramaComponent } from './Calendario/cronograma/cronograma.component';
import { UnidadNegocioComponent } from './unidad-negocio/unidad-negocio.component';
import { DetalleUnidadComponent } from './detalle-unidad/detalle-unidad.component';
import { ReferenciaUnidadComponent } from './referencia-unidad/referencia-unidad.component';
import { DetallemaquinaComponent } from './maquinasindustriales/detallemaquina/detallemaquina.component';
import { MantenimientomaquinaComponent } from './maquinasindustriales/mantenimientomaquina/mantenimientomaquina.component';
import { HistoricoMaquinasComponent } from './historico-maquinas/historico-maquinas.component';
import { VisitasPorGrupoComponent } from './visitas-por-grupo/visitas-por-grupo.component';
import { VisitasPorGrupoFormularioComponent } from './visitas-por-grupo-formulario/visitas-por-grupo-formulario.component'; // a plugin
import { VisitaInfosComponent } from './visita-infos/visita-infos.component';
import { VsitanteObservacionesComponent } from './vsitante-observaciones/vsitante-observaciones.component';
import localeEs from '@angular/common/locales/es';
import { SupervisoresComponent } from './supervisores/supervisores.component';
import { TiempoRealComponent } from './tiempo-real/tiempo-real.component';
import { LocationUsersComponent } from './location-users/location-users.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
// import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SgrComponent } from './sgr/sgr.component';
import { SgrDetalleComponent } from './sgr-detalle/sgr-detalle.component';
import { SgrDetalleSupervisorComponent } from './sgr-detalle-supervisor/sgr-detalle-supervisor.component';
import { SgrDetalleNovedadComponent } from './sgr-detalle-novedad/sgr-detalle-novedad.component';
import { Flashlight } from '@awesome-cordova-plugins/flashlight/ngx';




const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'biomedicas', component: BiomedicasComponent },
  { path: 'maquinas', component: MaquinasComponent },
  { path: 'profile/:serial', component: ProfileactivoComponent },
  { path: 'info', component: InfoComponent },
  { path: 'empleados', component: EmpleadosComponent },
  { path: 'info/:email', component: InfoComponent },
  { path: 'editar/computador/:serial', component: EditarcomputadorComponent },
  { path: 'mantenimiento/:serial', component: CrearmantenimientosComponent },
  { path: 'mantenimientomaquina/:serial', component: MantenimientomaquinaComponent },
  { path: 'computadores', component: ComputadorComponent },
  { path: 'impresoras', component: ImpresorasComponent },
  { path: 'editar/impresora/:serial', component: EditarimpresorasComponent },
  { path: 'dispositivos-red', component: DispositivosComponent },
  { path: 'dispositivos-otros', component: OtrosdispositivosComponent },
  { path: 'mantenimientos/:serial', component: MantenimientosComponent },
  { path: 'tickets', component: TicketsComponent },
  { path: 'sgr', component: SgrComponent },
  { path: 'sgr-supervisor', component: SgrDetalleSupervisorComponent },
  { path: 'detallenovedad/:idreferencia/:unidad', component: SgrDetalleComponent },
  { path: 'detallesupervisor/:idreferencia/:unidad', component: SgrDetalleNovedadComponent },
  { path: 'micuenta', component: CuentausuarioComponent },
  { path: 'config', component: ConfigComponent },
  { path: 'tickets/:numero/:asunto/:user', component: TicketsdetailComponent },
  { path: 'home', component: DashboardComponent },
  { path: 'editar/dispositivosred/:serial', component: EditardispositivosComponent },
  { path: 'visita/:user', component: RegistroVisitantesComponent },
  { path: 'historicomaquina/:serial', component: HistoricoMaquinasComponent },
  { path: 'empleado/:user', component: EmpleadoprofiComponent },
  { path: 'vigilante/:user', component: VigilanteobservacionComponent },
  { path: 'buscar/:serial', component: SearchComponent },
  { path: 'usuarios', component: UsuarioscompaniaComponent },
  { path: 'maquinaria-industrial', component: MaquinasindustrialComponent },
  { path: 'maquinaria-industrial/:id', component: DetallemaquinaComponent },
  { path: 'visitantes', component: VisitantesComponent },
  { path: 'correspondencia', component: CorrespondenciaComponent },
  { path: 'insumos/:serial', component: InsumosComponent },
  { path: 'sedes', component: SedecompanyComponent },
  { path: 'vigilantes', component: VigilantesComponent },
  { path: 'vigilantes/tiemporeal', component: TiempoRealComponent },
  { path: 'vigilantes/supervisores', component: SupervisoresComponent },
  { path: 'unidad-negocio', component: UnidadNegocioComponent },
  { path: 'unidad-negocio/:id', component: DetalleUnidadComponent },
  { path: 'referencia/:serial', component: ReferenciaUnidadComponent },
  { path: 'cronograma', component: CronogramaComponent },
  { path: 'visitasgrupo', component: VisitasPorGrupoComponent },
  { path: 'formulario/ingreso/:id', component: VisitasPorGrupoFormularioComponent },
  { path: 'visita/info/:id', component: VsitanteObservacionesComponent },
  { path: 'locations', component: LocationUsersComponent },



];

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  listPlugin,
  timeGridPlugin,
  interactionPlugin
]);






@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    DashboardComponent,
    DispositivosComponent,
    ProfileactivoComponent,
    ImpresorasComponent,
    MantenimientosComponent,
    TicketsComponent,
    TicketsdetailComponent,
    CuentausuarioComponent,
    RegisterComponent,
    ComputadorComponent,
    OtrosdispositivosComponent,
    NavbarComponent,
    CrearmantenimientosComponent,
    EditarimpresorasComponent,
    MaquinasComponent,
    ConfigComponent,
    EditardispositivosComponent,
    InfoComponent,
    EmpleadosComponent,
    EditarcomputadorComponent,
    WelcomeComponent,
    BiomedicasComponent,
    VisitantesComponent,
    RegistroVisitantesComponent,
    EmpleadoprofiComponent,
    SearchComponent,
    UsuarioscompaniaComponent,
    MaquinasindustrialComponent,
    InsumosComponent,
    CorrespondenciaComponent,
    SedecompanyComponent,
    VigilantesComponent,
    VigilanteobservacionComponent,
    CronogramaComponent,
    UnidadNegocioComponent,
    DetalleUnidadComponent,
    ReferenciaUnidadComponent,
    DetallemaquinaComponent,
    MantenimientomaquinaComponent,
    HistoricoMaquinasComponent,
    VisitasPorGrupoComponent,
    VisitasPorGrupoFormularioComponent,
    VsitanteObservacionesComponent,
    SupervisoresComponent,
    TiempoRealComponent,
    LocationUsersComponent,
    SgrComponent,
    SgrDetalleComponent,
    SgrDetalleSupervisorComponent,
    SgrDetalleNovedadComponent,
  ],
  imports: [

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDvpFZhEufIPcQ0qKEcG3E39pjnJ3y6Q2o'
      /* apiKey is required, unless you are a
      premium customer, in which case you can
      use clientId
      */
    }),
    SignaturePadModule,

    BrowserModule,
    NgxPrintModule,
    RouterModule.forRoot(routes),
    Ng2SearchPipeModule, NgQrScannerModule,
    ToastrModule.forRoot(
      {
        "positionClass": "toast-bottom-left",
        closeButton: true,
      }
    ),
    FormsModule,
    FullCalendarModule,
    HttpClientModule,
    NgxPaginationModule,
    ChartsModule,
    NgSelect2Module,
    BrowserAnimationsModule,
    LeafletModule



  ],
  providers: [JarwisService, TokenService, AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  estado: string;
}

