import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ComputadorService {

  private baseurl = "https://quar.com.co/api"
  deleteComputador(data) {
    return this.http.post(`${this.baseurl}/delete/computador`, data)
  }

 duplicar(data) {
    return this.http.post(`${this.baseurl}/duplicar/activo`, data)
  }

  discos(data) {
    return this.http.post(`${this.baseurl}/register/discos`, data)
  }

  discosActivo(data) {
    return this.http.post(`${this.baseurl}/ver/discos`, data)
  }

  discodelete(data) {
    return this.http.post(`${this.baseurl}/delete/disco`, data)
  }

  serial(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/verificar/serial`, data)
  }

  create(data) {
    return this.http.post(`${this.baseurl}/create/computador`, data)
  }

  cambiarAsignado(data) {
    return this.http.post(`${this.baseurl}/asignado/computador`, data)
  }

  cambiarSede(data) {
    return this.http.post(`${this.baseurl}/sede/computador`, data)
  }

  upload(data) {
    return this.http.post(`${this.baseurl}/file/upload`, data)
  }


  actualizar(data) {
    return this.http.post(`${this.baseurl}/editar/computador`, data)
  }

  detail(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/ficha/computador`, data)
  }

  constructor(private http: HttpClient) { }
}


