import swal from 'sweetalert2';
import { TicketsService } from './../Services/tickets.service';
import { ProfilesService } from './../Services/profiles.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
declare var H: any;
@Component({
  selector: 'app-profileactivo',
  templateUrl: './profileactivo.component.html',
  styleUrls: ['./profileactivo.component.scss']
})
export class ProfileactivoComponent implements OnInit {
  normal = true;
  found = true;
  loading = true;
  activoinfo;
  activogeneral;

  serial = {
    serial: null
  };
  Serial: string;
  UsuarioTicket: string;
  NumeroTicket: string;
  tipo: string;
  users: any
  empleados: any
  sedes: any
  handleReponse(data) {
    // this.found= true;

    // console.log();
    this.users = data.users;
    this.empleados = data.empleados;
    this.sedes = data.sedes;
    console.log(this.empleados);

    if (data.serial) {
      console.log(serial)
      var serial = data.serial
      if (serial[0].estado == 'b') {
        this.found = false;
        this.loading = false;
      } else {
        // alert(this.loading)
        this.router.navigateByUrl(data.serial.replace('https://app.quar.com.co', ''));
        this.loading = false;
      }
    } else {
      // this.loading = false;
      if (data.activo[0]) {
        console.log("Si hay activo")
        this.loading = false;
        this.found = true;
      } else {
        this.found = false;
        this.loading = false;
        console.log("No hay activo")
      }
      // alert(data)
      // console.log(data);
      this.tipo = data.tipo;
      this.UsuarioTicket = data.UsuarioTicket;
      // this.Serial = data.serial;
      this.Serial = this.route.snapshot.paramMap.get("serial")
      this.NumeroTicket = data.NumeroTIcket;
      console.log("Usuario Ticket" + this.UsuarioTicket);
      console.log("Usuario Ticket" + this.Serial);
      console.log("Usuario Ticket" + this.NumeroTicket);
      this.activoinfo = data;
      this.loading = false;
    }
  }

  soporte = {
    NombreCliente: null,
    CorreoCliente: null,
    Asunto: null,
    UsuarioAsignado: 0,
    Estado: "Sin Asignar",
    SerialActivo: null,
    UsuarioTicket: null,
    NumeroTIcket: null,
    Mensaje: null
  };

  createResponse(data) {
    console.log(data);
  }

  onSubmit() {
    this.soporte.SerialActivo = this.Serial;
    this.soporte.UsuarioTicket = this.UsuarioTicket;
    this.soporte.NumeroTIcket = this.NumeroTicket;
    console.log(this.soporte)
    this.tickets.create(this.soporte).subscribe(
      data => {
        this.createResponse(data);
        this.loading = false;
        swal.fire(
          'Excelente!',
          'Se ha enviado tu solicitud',
          'success',
        )
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      error => {
        // alert(error.error.message)
        this.loading = false
      }
    );
  }

  constructor(private router: Router, private tickets: TicketsService, private route: ActivatedRoute, private http: HttpClient,
    private Activo: ProfilesService
  ) {


  }

  private platform: any;
  @ViewChild("map")
  public mapElement: ElementRef;

  ngOnInit() {
    // this.loading = true;
    this.serial.serial = this.route.snapshot.paramMap.get("serial")
    if (this.serial.serial == '1qaz2wsx' || this.serial.serial == '1QAZ2WSX') {
      // alert("Hola mundo");
      console.log("demo");
      this.found = true;
      this.normal = false;
      this.loading = false;
    } else {
      this.Activo.getProfile(this.serial).subscribe(
        data => this.handleReponse(data),
        error => {
          this.found = false

          this.loading = false
        }
      )
    }
    // alert(this.activo);
    this.http.post("https://quar.com.co/api/tickets/get", {

      "Codigo":  this.serial.serial,
      
    }).subscribe(
      data => this.processTicketGet(data),
      error => console.log(error)
    )
  }


  allTickets:any 
  processTicketGet(data: any) {

   

    if (!localStorage.getItem("idTicket")) {
      if (data.ticketsBySerial) {
        this.allTickets = data.ticketsBySerial
      }
    }
  }
  

  lat = 6.244203;
  lng = -75.581215;


  public ngAfterViewInit() {
    let platform = new H.service.Platform({
      "app_id": "dk2S4SeiYlC0QYH6WOXW",
      "app_code": "ThyolBHHYlJHDP6rsN_pkgsIn5H716ieE47MGLbmMBw"
    });
    let defaultLayers = platform.createDefaultLayers();
    let map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.normal.map,
      {
        zoom: 10,
        center: { lat: this.lat, lng: this.lng }
      }
    );
  }

}


