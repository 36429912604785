<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li routerLink="/visitantes" class="breadcrumb-item active">Visitantes</li>
                                <li class="breadcrumb-item active">Registro de Visitantes</li>
                                <!-- <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li> -->
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                   
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Visitas del día <h1 style="color:#C5D211; font-weight: bold;">{{count}}</h1> </h4>

                                    <h5 class="card-subtitle">Registro de visitas</h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <div class="scanner">
                                        <!-- app.component.html -->
                                        <qr-scanner [debug]="true" [canvasWidth]="720" [canvasHeight]="720" [stopAfterScan]="true" [updateTime]="500">
        
                                        </qr-scanner>
                                    </div>
                                    <input type="text" class="escaner" (change)="cedulaScan($event)" [(ngModel)]="cedulaSca" #input>
                                    <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="">
                                                    Tipo documento
                                                </label>
                                                <select name="TipoDocumento" class="form-control" [(ngModel)]="data.TipoDocumento">
                                                <option value="Registro Civil"> RC - Registro Civil</option>
                                                <option value="Tarjeta de identidad"> TI - Tarjeta de identidad.</option>
                                                <option value="Cédula de ciudadanía"> CC - Cédula de ciudadanía</option>
                                                <option value="Cédula de extranjería"> CE - Cédula de extranjería</option>
                                                <option value="Pasaporte"> PA - Pasaporte</option>
                                                <option value="Menor sin identificación"> MS - Menor sin identificación</option>
                                                <option value="Adulto sin identidad"> AS - Adulto sin identidad.</option>
                                                </select>
                                                <!-- <input name="Nombre" type="text" [(ngModel)]="data.Nombre" class="form-control"> -->
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Número de identificación
                                                </label>
                                                <input name="Cedula" type="text" (change)="cedula()" [(ngModel)]="data.Cedula" class="form-control">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Nombres
                                                </label>
                                                <input name="Nombre" type="text" [(ngModel)]="data.Nombre" class="form-control">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Apellidos
                                                </label>
                                                <input type="text" class="form-control" name="Apellido" [(ngModel)]="data.Apellido">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Teléfono
                                                </label>
                                                <input type="text" class="form-control" name="telefonocopia" [(ngModel)]="data.Telefono">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Empresa
                                                </label>
                                                <input type="text" class="form-control" name="Empresa" [(ngModel)]="data.Empresa">
                                            </div>
        
                                            <div class="col-md-6">
                                                <label for="">
                                                    Motivo de la visita
                                                </label>
                                                <input type="text" class="form-control" name="Direccion" [(ngModel)]="data.Direccion">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Ciudad
                                                </label>
                                                <input type="text" class="form-control" name="Ciudad" [(ngModel)]="data.Ciudad">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    ARL
                                                </label>
                                                <input type="text" class="form-control" name="Arl" [(ngModel)]="data.Arl">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    EPS
                                                </label>
                                                <input type="text" class="form-control" name="Eps" [(ngModel)]="data.Eps">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    RH
                                                </label>
                                                <input type="text" class="form-control" name="RH" [(ngModel)]="data.RH">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Nombre de Contacto
                                                </label>
                                                <input type="text" class="form-control" name="NombreContacto" [(ngModel)]="data.NombreContacto">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Teléfono Contacto
                                                </label>
                                                <input type="number" class="form-control" name="TelefonoContacto" [(ngModel)]="data.TelefonoContacto">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">
                                                    Temperatura
                                                </label>
                                                <input type="number" min="34" max="44" required class="form-control" name="Temperatura" (keyup)="temperatura($event)" [(ngModel)]="data.Temperatura">
        
                                            </div>
                                            <div class="col-lg-6">
                                                <br>
                                                <label for=""> <b style="color:red"> ¿Ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19?:</b></label>
                                                <select name="observacion" (change)="observacionesAlerta($event)" class="form-control" [(ngModel)]="data.observacion">
                                                    <option value="No ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19">No</option>
                                                    <option value="Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19">Sí</option>
                                                </select>
                                            </div>
        
                                            <div class="col-lg-6">
                                                <br>
                                                <label for=""> <b style="color:red"> ¿Tiene tos, fiebre, dificultad para respirar?</b></label>
                                                <br>
                                                <br>
                                                <select name="observacion22" (change)="observaciones2Alerta($event)" class="form-control">
                                                    <option value="No">No</option>
                                                    <option value="Sí">Sí</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-6" *ngIf="sintomas">
                                                <br>
                                                <label for=""> <b style="color:red"> Seccione los sintomas</b></label>
                                                <br>
                                                <select name="observacion" multiple class="form-control" [(ngModel)]="data.observacion2">
                                                    <option value="Tos">Tos</option>
                                                    <option value="Fiebre">Fiebre</option>
                                                    <option value="Dificultad para respirar">Dificultad para respirar</option>
                                                </select>
                                            </div>
        
                                            <div class="col-lg-12">
                                                <br>
                                                <button class="btn cell btn-primary">
                                                    Registrar Visitante
                                                </button>
                                            </div>
                                        </div>
                                    </form>
        


                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>





<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Duplicar activo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="">
                    Si tienes un seríal ingresalo en el siguiente campo
                </label>
                <input type="text" class="form-control cell" placeholder="Digite un serial" name="serial"
                    [(ngModel)]="activodupl.nuevoserial" (change)="serialVerifyDuplicar()">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" (click)="duplicar()" class="btn btn-secondary">No tengo serial</button>
                <button type="button" [disabled]="activodupl.nuevoserial == null" (click)="duplicar()"
                    class="btn btn-primary">Duplicar con el serial</button>
            </div>
        </div>
    </div>
</div> -->