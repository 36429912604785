import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImpresorasService {
  private baseurl = "https://quar.com.co/api"
  deleteActivo(data){
      return this.http.post(`${this.baseurl}/delete/otheractivos`,data)
  }

  getImpresora(data){
    return this.http.post(`${this.baseurl}/editar/activo`,data)
  }

  cambiarSede(data) {
    return this.http.post(`${this.baseurl}/sede/impresora`, data)
  }

  getImpresoras(data){
    return this.http.post(`${this.baseurl}/tabla/impresoras`,data)
  }

  create(data){
    return this.http.post(`${this.baseurl}/create/impresoras`,data)
  }


  editar(data){
    return this.http.post(`${this.baseurl}/update/impresoras`,data)
  }


  actualizar(data){
    return this.http.post(`${this.baseurl}/editar/computador`,data)
  }

  detail(data){
    console.log(data);
    return this.http.post(`${this.baseurl}/ficha/activo`,data)
  }

  constructor(private http:HttpClient) {  }
}
