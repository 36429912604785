import { ToastrService } from 'ngx-toastr';
import { EmpleadosService } from './../Services/empleados.service';
import { TokenService } from './../Services/token.service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
declare var jQuery:any;
@Component({
  selector: 'app-empleados',
  templateUrl: './empleados.component.html',
  styleUrls: ['./empleados.component.scss']
})
export class EmpleadosComponent implements OnInit {

  abajo(){
    jQuery('html,body').animate({
      scrollTop: jQuery("#reportesseccion").offset().top
  }, 'slow');
  }

  idUser;
  fechainicial;
  fechafinal;
  loading;
  form = {
    IdEmpleado: null,
    Cedula: null,
    Nombre: null,
    Apellido: null,
    Cargo: null,
    Telefono: null,
    Direccion: null,
    Ciudad: null,
    Arl: null,
    Eps: null,
    RH: null,
    NombreContacto: null,
    TelefonoContacto: null,
    Codigo: null,
    Tipo: 'empleado',
    Compania: this.idUser,
    id_user: this.idUser
  }

  detalle = true;

  detailResponse(data) {
    this.observaciones = data.observaciones;
  }

  exportarvisitas() {
    // alert(this.fechainicial);
      if(this.fechainicial == "dd/mm/aaaa" || this.fechainicial == ""  || this.fechainicial == "undefined" || this.fechafinal == "dd/mm/aaaa" ){
        this.toastr.error("Debes seleccionar un rango de fechas", "Error");
      } else {
        // alert();
    // var fecha = new Date();



    var fecha = new Date(this.fechainicial);
    var day = fecha.getDate() + 1;
    var month = fecha.getMonth() + 1;
    if(day.toString().length == 1){
      var dayu = "0"+day;
    } else {
      dayu = day.toString();
    }
    // alert(month.toString().length)
    if(month.toString().length == 1){
      var monthu = "0"+month;
    } else {
      monthu = month.toString();
    }

    this.fechainicial = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);
    // alert(this.fechainicial);


    var fecha = new Date(this.fechafinal);
    // alert(fecha.getMonth()+1);
    var day = fecha.getDate() + 2;
    // alert(day.toString().length)
    if(day.toString().length == 1){
      var dayu = "0"+day;
    } else {
      dayu = day.toString();
    }

    if(month.toString().length == 1){
      var monthu = "0"+month;
    } else {
      monthu = month.toString();
    }
    // alert(mon);
    var month = fecha.getMonth() + 1;
    this.fechafinal = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);

    // alert(this.fechafinal);



  // alert(this.fechafinal);
  // alert(this.fechainicial);
  location.href = "https://quar.com.co/api/excel/empleados/visitas/"+this.idUser+"/"+this.fechainicial+"/"+this.fechafinal
      }
  }

  observaciones: any;

  detail(IdEmpleado, Cedula, Nombre, Apellido, Cargo, Telefono, Direccion, Ciudad, Arl, Eps, RH, NombreContacto, TelefonoContacto) {
    // this.form.IdEmpleado = IdEmpleado;
    // alert(IdEmpleado);
    this.form.IdEmpleado = IdEmpleado;
    this.form.Cedula = Cedula;
    this.form.Nombre = Nombre;
    this.form.Apellido = Apellido;
    this.form.Cargo = Cargo;
    this.form.Telefono = Telefono;
    this.form.Direccion = Direccion;
    this.form.Ciudad = Ciudad;
    this.form.Arl = Arl;
    this.form.Eps = Eps;
    this.form.RH = RH;
    this.form.NombreContacto = NombreContacto;
    this.form.TelefonoContacto = TelefonoContacto;

    this.empleado.detail(this.form).subscribe(data => this.detailResponse(data), error => this.toastr.error(error.message, 'Error'));
  }
  numberperpage = 10;
  term: string;
  mostrar(event) {
    // alert(event.target.value);
    this.numberperpage = event.target.value;
  }
  view = 'lista';

  id;
  empleados: any;
  visitascount;
  generarCodigo;
  codigo = false;
  tittle = "Control de empleados";
  data = {
    id_user: this.Token.getUserId(),
    userEncriptado: this.Token.utf8_to_b64(this.Token.getUserId()),
    tipo : 'empleado'
  }

  filtrar(event){
    console.log(event);
    if(event.target.value != ""){
      this.numberperpage =  50000000;
    } else{
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  generaCod(data) {
    if (data.data) {
      this.codigo = true;
    } else {

    }
  }

  generatecodigo() {
    this.Token.generarVisitasCodigo(this.data).subscribe(
      data => {
        this.generaCod(data);
      },
      error => console.log(error)
    )
  }

  onSubmit() {
    this.loading = true;
    console.log(this.data);
    this.empleado.create(this.form).subscribe(
      data => {
        this.loading = false;
        this.view = 'lista';
        this.toastr.success("Se han actualizado tus empleados en el sistema", "Mensaje")
        this.handleResponse(data);
      },
      error => {
        this.loading = false;
        this.toastr.error(error.message, "Error");
      }
    )
  }

  verificar = {
    serial: null
  }



  deleteempleado(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.form.IdEmpleado = id;
        this.form.Codigo = serial;
        this.loading = true;
        this.empleado.deleteActivo(this.form).subscribe(
          data => {
            this.toastr.success("Se ha eliminado exitosamente el empleado", "Eliminar empleado")
            this.loading = false;
            this.handleResponse(data)
          },
          error => { this.loading = false; this.toastr.error(error.message, 'Error en el proceso') }
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })
  }

  cerrar() {
    this.form.IdEmpleado = "";
    this.form.Cedula = "";
    this.form.Nombre = "";
    this.form.Apellido = "";
    this.form.Cargo = "";
    this.form.Telefono = "";
    this.form.Direccion = "";
    this.form.Ciudad = "";
    this.form.Arl = "";
    this.form.Eps = "";
    this.form.RH = "";
    this.form.NombreContacto = "";
    this.form.TelefonoContacto = "";
  }

  handleResponse(data) {
    jQuery("#modalempleado").modal("hide");
    this.empleados = data.empleados;
  }

  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }

  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.empleado.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }
  admin;

  constructor(
    private toastr: ToastrService,
    private empleado: EmpleadosService, private Token: TokenService) { }
  ngOnInit(): void {
    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.data.id_user = this.Token.getUserAdministrador();
      this.idUser = this.Token.getUserAdministrador();
      this.form.Compania = this.Token.getUserAdministrador();
      this.form.id_user = this.Token.getUserAdministrador();
      this.data.userEncriptado = this.Token.utf8_to_b64(this.Token.getUserAdministrador());
    } else {
      this.admin = true;
      this.idUser = this.Token.getUserId();
      this.form.Compania = this.Token.getUserId();
      this.form.id_user = this.Token.getUserId();
      this.data.userEncriptado = this.Token.utf8_to_b64(this.Token.getUserId());
    }
    this.empleado.getEmpleados(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )
  }

  cedula() {

  }


  // tittle = ""
  detailempleado: any;
  sidebaractive = "ficha";
  p = 1;
}
