<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">Panel admnistrativo</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Panel de control</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="jumbotron card" style="background: white !important;">

                <h1 class="display-4">Sedes</h1>
                <p class="lead">Registrar nueva sede</p>
                <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <input type="text" name="email" placeholder="Escribe el nombre de la sede" class="form-control"
                            id="inputEmail3" [(ngModel)]="form.NombreSede" required>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary cell" [disabled]="!loginForm.valid">Registrar
                            sede</button>
                    </div>

                    <!-- <button type="button" routerLink="/register" class="btn btn-secondary">Registrate</button> -->

                </form>

                <div class="input-group mb-2 mr-sm-2">
                    <input type="search" class="form-control" [(ngModel)]="term" id="inlineFormInputGroupUsername2"
                        placeholder="Buscar sede">
                    <div class="input-group-prepend">
                        <div class="input-group-text btn-primary text"
                            style="width: 50px; text-align: center !important;">  <i class="fas fa-search"></i></div>
                    </div>
                </div>
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Nombre de sede</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of sedes | filter:term">
                            <th scope="row">{{item.NombreSede}}</th>
                            <td> <button type="button" rel="tooltip" title="" (click)="deleteSede(item.IdSede)"
                                    class="btn btn btn-danger" data-original-title="Remove">
                                    <i class="icon-REMOVE"></i>
                                </button></td>
                            <!-- <td>Otto</td> -->

                        </tr>

                    </tbody>
                </table>
            </div>


        </div>
        <!-- ============================================================== -->
        <!-- Sales chart -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Table -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Table -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Recent comment and chats -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Recent comment and chats -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer text-center">
        Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Page wrapper  -->
<!-- ============================================================== -->