import { TokenService } from './../Services/token.service';
import { ToastrService } from 'ngx-toastr';
import { ProfilesService } from './../Services/profiles.service';
import { TicketsService } from './../Services/tickets.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
// import { swal } from 'sweetalert2';
import swal from 'sweetalert2';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  tittle = "Busqueda de activos"
  loading = false;
  activoinfo;
  activogeneral;

  serial = {
    serial: null
  };
  Serial: string;
  UsuarioTicket: string;
  NumeroTicket: string;
  tipo:string;
  general:any;
  buscar;
  handleReponse(data) {
    this.loading = false;
    console.log(data);
    // if(data.serial){
      // window.location = data.serial;  
    // }else {
    // alert(data)
    // console.log(data);
    this.tipo = data.tipo;
    this.UsuarioTicket = data.UsuarioTicket;
    // this.Serial = data.serial;
    this.Serial = this.route.snapshot.paramMap.get("serial")
    this.NumeroTicket = data.NumeroTIcket;
    console.log("Usuario Ticket" + this.UsuarioTicket);
    console.log("Usuario Ticket" + this.Serial);
    console.log("Usuario Ticket" + this.NumeroTicket);
    this.activoinfo = data;
    this.general = data.activo;
    this.buscar = true;
    
    // }
  }
  soporte = {
    NombreCliente: null,
    CorreoCliente: null,
    Asunto: null,
    UsuarioAsignado: 0,
    Estado: "Sin Asignar",
    SerialActivo: null,
    UsuarioTicket: null,
    NumeroTIcket: null,
    Mensaje:null
  };

  createResponse(data){
    console.log(data);
  }

  onSubmit() {
    this.loading = true;
    this.soporte.SerialActivo = this.Serial;
    this.soporte.UsuarioTicket = this.UsuarioTicket;
    this.soporte.NumeroTIcket = this.NumeroTicket;
    console.log(this.soporte)
    this.tickets.create(this.soporte).subscribe(
      data => {
        this.createResponse(data);
        this.loading = false;
        swal.fire(
          'Excelente!',
          'Se ha enviado tu solicitud',
          'success',
        )
        setTimeout(() => {
          window.location.reload();
         }, 1500);
      },
      error => {this.loading=false}
    );
  }

  constructor(private router:Router, private tickets:TicketsService,private route: ActivatedRoute,
    private Activo: ProfilesService,
    private toastr: ToastrService,
    private token:TokenService
  
  ) { }

  ngOnInit() {
    this.loading = true;
    this.serial.serial = this.route.snapshot.paramMap.get("serial")
    this.Activo.getProfile(this.serial).subscribe(
      data => this.handleReponse(data),
      error => {
        
        console.log(error);
        this.buscar = false;
        this.loading = false;
        // this.toastr.error(error,"Mensaje de error");

      }
    )
    // alert(this.activo);
  }
}
