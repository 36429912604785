import { AuthService } from './../Services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JarwisService } from '../Services/jarwis.service';
import { TokenService } from '../Services/token.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loading = false;
  public error = null;
  public form = {
    email:null,
    name:null,
    password:null,
    Estado: 2,
    password_confirmation:null,
  }


  onSubmit(){
    this.loading = true;
    console.log(this.form);
    this.Jarwis.signup(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
}

handleError(error){
  this.toastr.error("Se ha presentado un error", 'El correo electrónico ya existe')
  // this.error.message
  this.loading = false;
  console.log(error);
  this.error = error.errors;
}

handleResponse(data){
  this.loading = false;
      this.Token.handle(data.access_token);
      console.log(btoa(data.access_token));
      this.Token.handleUser(data.id);
      this.Token.handleEmail(data.email);
      this.Token.handleName(data.name);
      this.Token.handleAdmin(data.administrador);
      this.Auth.changeAuthStatus(true);
      this.route.navigateByUrl('/home');
}


  constructor(
    private toastr: ToastrService ,  
    private Auth:AuthService,
    private Jarwis: JarwisService, private Token:TokenService, private route:Router) { }

  ngOnInit() {
  }

}
