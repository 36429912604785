<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5"
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Tickets</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->

            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Mesa de ayuda</h4>
                                    <h5 class="card-subtitle">Solicitudes de tus clientes</h5>
                                </div>
                                <div class="ml-auto">
                                  <div class="form-group">
                                    <label for="">Buscar ticket<input type="search" [(ngModel)]="term"
                                            (keydown)="filtrar($event)" class="form-control"></label>
                                </div>
                                    <!-- <div class="dl">
                                        <select class="custom-select">
                                            <option value="0" selected>Mes</option>
                                            <option value="1">D</option>
                                            <option value="2">Weekly</option>
                                            <option value="3">Yearly</option>
                                        </select>
                                    </div> -->
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <!-- <th class="border-top-0">Products</th> -->
                                        <th>
                                            # ticket
                                        </th>
                                        <th>
                                            Asunto
                                        </th>
                                        <th>
                                            Fecha de Solicitud
                                        </th>
                                        <th>
                                            Usuario asignado
                                        </th>
                                        <th class="text-right">

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let general of ticketList  | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <td>
                                            00{{general.NumeroTIcket}}
                                        </td>
                                        <td>
                                            {{general.Asunto}}
                                        </td>
                                        <td>
                                            {{general.FechaSolicitud}}
                                        </td>
                                        <td class="">
                                            <ng-container *ngIf="general.UsuarioAsignado == '0'">
                                                <select name="UsuarioAsignado" (change)="onChange($event.target.value, general.IdTicket)" [(ngModel)]="UsuarioAsignado" class="form-control">
                                                    <option value="Asignar Usuario">Asignar Usuario</option>
                                                    <ng-container *ngFor="let u of users">
                                                        <option value="{{u.id}}">{{u.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </ng-container>
                                            <ng-container *ngIf="general.UsuarioAsignado != '0'">
                                                <select name="UsuarioAsignado" (change)="onChange($event.target.value, general.IdTicket)" value="{{general.UsuarioAsignado}}" class="form-control">
                                                    <ng-container *ngFor="let u of users">
                                                        <option value="{{u.id}}">{{u.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </ng-container>
                                        </td>
                                        <td class="">
                                            <button routerLink="/tickets/{{general.NumeroTIcket}}/{{general.Asunto}}/{{general.UsuarioTicket}}" class="btn btn-primary">Ver información</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="panel-activos__body__pagination text-center">
                              <pagination-controls (pageChange)="p = $event"></pagination-controls>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>
