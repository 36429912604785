import { Component, OnInit } from '@angular/core';
import { TicketsService } from '../Services/tickets.service';
import { TokenService } from '../Services/token.service';

@Component({
  selector: 'app-sgr',
  templateUrl: './sgr.component.html',
  styleUrls: ['./sgr.component.scss']
})
export class SgrComponent implements OnInit {
  tittle:string = "GESTIÓN DE TICKETS";
  data = {
    idUser: this.Token.getUserId()
  }
  constructor(private Token: TokenService ,private tickets: TicketsService) { }
  ticketList:any;
  term:string;
  users:any;
  loading=false;
  UsuarioAsignado:string = "Asignar Usuario";
  usuario = {
    ticket:null,
    user:null
  }


  // novedades(data){
  //   this.loading = true;
  //   this.data[0].novedades = data;
  //   this.tickets.getObservaciones(this.data).subscribe(
  //     data => this.handleResponse(data),
  //     error => {
  //     this.loading = false;
  //     }
  //   )
  // }


  p= 1;
  numberperpage = 25;

  filtrar(event) {
    console.log(event);
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  handleResponse(data){
    // alert();
    console.log(data)
      this.ticketList = data.novedades;
      this.users = data.users;
      console.log(this.users)
      console.log(this.ticketList.ticket)
      this.loading = false;
  }

  ngOnInit() {
    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.data.idUser = this.Token.getUserAdministrador();
    } else {
      // this.admin = true;
      this.data.idUser = this.Token.getUserId();
    }
      this.tickets.getObservaciones(this.data).subscribe(
        data => this.handleResponse(data),
        error => console.log(error)
      )
  }

  onChange(value,id){
    this.usuario.ticket= id;
    this.usuario.user = value;
    console.log(this.usuario)
    this.tickets.usuario(this.usuario).subscribe(
      data => console.log(data),
      error => console.log(error)
    )
  }

}
