import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmpleadosService {
  private baseurl = "https://quar.com.co/api"
  // private baseurl = "https://quar.com.co/api"
  deleteActivo(data){
      return this.http.post(`${this.baseurl}/delete/empleado`,data)
  }


  getdatosrealtime(data){
    return this.http.post(`${this.baseurl}/observaciones/tiemporeal`,data)

  }

  serial(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/verificar/serial`, data)
  }


  getEmpleado(data){
    return this.http.post(`${this.baseurl}/empleado`,data)
  }

  getEmpleados(data){
    return this.http.post(`${this.baseurl}/empleados`,data)
  }

  create(data){
    return this.http.post(`${this.baseurl}/create/empleado`,data)
  }


  observacion(data){
    return this.http.post(`${this.baseurl}/observacion/empleado`,data)
  }


  actualizar(data){
    return this.http.post(`${this.baseurl}/editar/computador`,data)
  }

  detail(data){
    console.log(data);
    return this.http.post(`${this.baseurl}/observaciones/empleado`,data)
  }

  upload(data) {
    return this.http.post(`${this.baseurl}/file/vigilante`, data)
  }

  constructor(private http:HttpClient) {  }
}
