import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JarwisService {
  private baseurl = "https://quar.com.co/api"
  signup(data){
      return this.http.post(`${this.baseurl}/signup`,data)
  }


  forgotPassword(data){
    return this.http.post(`${this.baseurl}/forgotpassword`,data)
  }


  login(data){
    return this.http.post(`${this.baseurl}/login`,data)
  }

  constructor( private http:HttpClient ) {

  }
}
