import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TicketsService } from '../Services/tickets.service';
import { TokenService } from '../Services/token.service';
import { UploadService } from '../Services/upload.service';

@Component({
  selector: 'app-sgr-detalle',
  templateUrl: './sgr-detalle.component.html',
  styleUrls: ['./sgr-detalle.component.scss']
})
export class SgrDetalleComponent implements OnInit {

  estados = {
    Estado: null,
    FechaRevision:null
  }

  toFile;

  archivo = {
    // id:this.detailactivo[0].idActivo,
    serial: null,
    observacion: null,
    nombre: "archivo",
    nombreArchivo: null,
    base64textString: null
  };

  src = "";
  idUser = ""

  submit() {
    const file = this.toFile.item(0);
    this.archivo.nombreArchivo = file.name;

    // alert(file.name)
    this.uploadService.fileUpload(file, this.idUser)
    this.src = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + file.name;
    this.sendNota.Fotos = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + file.name;
    this.archivo.base64textString = this.src;
    this.loading = false;
    // alert(this.src)
  }

  onChange2(event) {
    this.loading = true;
    this.toFile = event.target.files;
    this.submit();

  }
  

  loggedIn = false;
  tittle = "Información del ticket"
  loading= false;
  numeroTicket = this.router.snapshot.paramMap.get("idreferencia")
  unidad = this.router.snapshot.paramMap.get("unidad")

  constructor(private router: ActivatedRoute, private tickets: TicketsService, private Token: TokenService, private toastr:ToastrService, private uploadService: UploadService) { }
  ticket = {
    idReferencia: this.numeroTicket,
    unidad: this.unidad
  }
  nombreUsuario = "";
  ticketdetail:any;
  ticketnotas:any;
  correoCliente: "";
  sendNota = {
    usuarioNotificacion: null,
    NombreUsuario: this.Token.getUserName(),
    NumeroTicket: this.numeroTicket,
    correo:null,
    NotaMensaje:null,
    IdNovedad: this.numeroTicket,
    Tipo: "",
    Fotos:""
  }
  
  unidadDetalle : [];

  changeEstado(){
    this.tickets.changeEstado(this.estados).subscribe(data=> {
      this.toastr.success("Se ha actualizado la información de la novedad")
    }, error => console.log(error))
  }

  handleResponse(data){
    console.log(data);
    this.ticketdetail = data.novedad;
    this.ticketnotas = data.notas;
    this.unidadDetalle = data.unidad;
    this.sendNota.correo = data.correo;
    // alert(this.sendNota.correo)
    // console.log(this.unidadDetalle)
  }

  estado = {
    ticket:null,
    estado:null,
    usuarioTicket:null
  }
  

  responseEstado(data){
      console.log(data);
  }

  onChange(estado,usuario,ticket){
    this.estado.estado = estado;
    this.estado.usuarioTicket = usuario;
    this.estado.ticket = ticket;
    this.tickets.estado(this.estado).subscribe(data => this.responseEstado(data),
    error => console.log(error));

  }
  
  handleResponse2(data){
    this.sendNota.NotaMensaje = "";
    console.log(data);
    this.loading = false;
    this.ticketnotas = data.notas;

  }
  
  sendNot(){
    console.log(this.sendNota.Tipo)
    this.loading = true;
    this.tickets.sgr(this.sendNota).subscribe(data => this.handleResponse2(data), error => {console.log(error)})
  }

  error(error){
    this.loading = false;
  }

  admin;

  ngOnInit() {
    if(this.Token.getUserId()){
      this.loggedIn = true;
    }

    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.idUser = this.Token.getUserAdministrador();
    } else {
      this.admin = true;
      this.idUser = this.Token.getUserId();
    }


    this.tickets.detailNovedad(this.ticket).subscribe(data => {
      console.log(data);
      this.handleResponse(data)
    
    },
    error => console.log(error))
  }

}
