<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{title}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Tickets</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a *ngIf="view =='lista'" (click)="cerrar()" (click)="view = 'serial'"
                            class="btn btn-danger text-white">Agregar nuevo vigilante
                        </a>
                        <a href="http://qaractivo.local/api/excel/vigilantes/hoy/{{idUser}}"><button
                                class="btn btn-primary">Exportar informe de hoy</button></a>


                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-secondary" (click)="active = true">
                            Informes
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <div [class.sidebar-reportes]="active" style="display: none;">
                <div>
                    <div class="text-right">
                        <button style="float: right;" class="btn btn-danger" (click)="active=false">X</button>

                    </div>
                    <h1>REPORTES</h1>
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h3>Vigilantes</h3>
                            <a href="http://qaractivo.local/api/excel/vigilantes/{{idUser}}">
                                <button class="btn btn-primary">
                                    Exportar vigilantes
                                </button>
                            </a>
                        </div>
                        <hr>
                        <div class="col-md-12">
                            <h3>Reporte de observaciones</h3>
                            <div class="form-group">
                                <!-- <label for="">Empleados</label>
                                <select class="form-control" name="reporte" [(ngModel)]="visitas" id="reporte">
                                    <option value="TODOS">Todos los empleados</option>
                                    <ng-container *ngFor="let item32 of empleados">
                                        <option value="{{item32.IdEmpleado}}">{{item32.Nombre}} {{item32.Apellido}}
                                        </option>
                                    </ng-container>
                                </select> -->
                            </div>
                            <div class="form-group">
                                <label for="">Fecha inicial</label>
                                <input type="date" [(ngModel)]="fechainicial" class="form-control">
                                <!-- {{fechainicial}} -->
                            </div>
                            <div class="form-group">
                                <label for="">Fecha final</label>
                                <input type="date" [(ngModel)]="fechafinal" class="form-control">
                                <!-- {{fechafinal}} -->
                            </div>
                            <br>
                            <!-- {{src}} -->
                            <button (click)="visitaporEmpleado()" class="cell btn btn-secondary">
                                Exportar visitas
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->

            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="col-md-12" *ngIf="view != 'lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro de vigilante</h4>
                                    <h5 class="card-subtitle">Lista de vigilantes</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <!-- <select class="form-control select-custom" name="fechas" id="">
                                            <option value="dayGridDay">Día</option>
                                            <option value="dayGridWeek">Semana</option>
                                            <option value="dayGridMonth">Mes</option>
                                            <option value="">Año</option> 
                                            <option value="listWeek">Agenda</option>

                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <!-- <p>cronograma works!</p> -->
                            <br>
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <ng-container *ngIf="view == 'serial'">
                                <div class="content-serial" *ngIf="view == 'serial'">
                                    <div class="" role="alert">
                                        <div class="">
                                            <br>
                                            <hr>
                                            <h3>Verificación de serial</h3>
                                            <input type="text" placeholder="Serial del código" class="form-control"
                                                name="Codigo" [(ngModel)]="form.Codigo">
                                            <br>
                                            <br>
                                            <div class="form-group">

                                                <button class="btn btn-danger cell" (click)="serialVerify()">Verificar
                                                    serial</button>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a
                                                vigilantes</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-primary cell" (click)="view='registro'">Quiero
                                                continuar sin serial</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="view == 'registro'">
                                <div class="content-registro">
                                    <div class="content-registro__container ">
                                        <form #loginForm=ngForm (ngSubmit)="onSubmit()" autocomplete="off">
                                            <div class="row">
                                                <div class="col-md-12 text-center">
                                                    <img src="{{src}}" style="max-width: 250px" alt="">
                                                    <br>
                                                </div>
                                                <div class="col-md-12 fieldset">
                                                    <!-- <label for="">Subir foto del vigilante</label> -->
                                                    <hr>
                                                    <div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                                                        <input (change)="change($event)" type="file"
                                                            id="fichero-tarifas" class="input-file" value="">
                                                        Seleccionar foto para el vigilante...
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Cedula
                                                    </label>
                                                    <input name="Cedula" type="text" (change)="cedula()"
                                                        [(ngModel)]="form.Cedula" class="form-control">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Nombres
                                                    </label>
                                                    <input name="Nombre" (change)="cedula()" type="text"
                                                        [(ngModel)]="form.Nombre" class="form-control">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Apellidos
                                                    </label>
                                                    <input type="text" (change)="cedula()" class="form-control"
                                                        name="Apellido" [(ngModel)]="form.Apellido">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Puesto
                                                    </label>
                                                    <input type="text" class="form-control" name="Puesto"
                                                        [(ngModel)]="form.Puesto">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Teléfono
                                                    </label>
                                                    <input type="text" class="form-control" name="Telefono"
                                                        [(ngModel)]="form.Telefono">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Dirección
                                                    </label>
                                                    <input type="text" class="form-control" name="Direccion"
                                                        [(ngModel)]="form.Direccion">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Ciudad
                                                    </label>
                                                    <input type="text" class="form-control" name="Ciudad"
                                                        [(ngModel)]="form.Ciudad">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        ARL
                                                    </label>
                                                    <input type="text" class="form-control" name="Arl"
                                                        [(ngModel)]="form.Arl">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        EPS
                                                    </label>
                                                    <input type="text" class="form-control" name="Eps"
                                                        [(ngModel)]="form.Eps">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        RH
                                                    </label>
                                                    <input type="text" class="form-control" name="RH"
                                                        [(ngModel)]="form.RH">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Nombre de Contacto
                                                    </label>
                                                    <input type="text" class="form-control" name="NombreContacto"
                                                        [(ngModel)]="form.NombreContacto">
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="">
                                                        Teléfono Contacto
                                                    </label>
                                                    <input type="text" class="form-control" name="TelefonoContacto"
                                                        [(ngModel)]="form.TelefonoContacto">
                                                </div>
                                                <div class="col-md-12">
                                                    <br>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <input type="email" name="email" placeholder="Usuario"
                                                        class="form-control" id="inputEmail3" autocomplete="off"
                                                        [(ngModel)]="user.email" required>
                                                </div>

                                                <div class="form-group col-md-4">
                                                    <input [type]="inputType" name="password" placeholder="Contraseña"
                                                        class="form-control" id="inputP45454assword3"
                                                        [(ngModel)]="user.password" required>
                                                </div>


                                                <div class="form-group col-md-4">
                                                    <input [type]="inputType" name="password_confirmation"
                                                        placeholder="Repite la contraseña" class="form-control" id="445"
                                                        [(ngModel)]="user.password_confirmation" required>
                                                </div>
                                                <!-- <div class="form-gorup col-md-12">
                                                    <ng-select2  [options]="options" name="modulosusuario" [(ngModel)]="user.accesos" [data]="accesos"
                                                        [placeholder]="'Selecciona los modulos'">
                                                    </ng-select2>
                                                </div> -->

                                                <div class="col-lg-12"> <br> <button class="btn cell btn-primary">
                                                        Registrar Empleado
                                                    </button>
                                                </div>

                                            </div>
                                        </form>



                                    </div>
                                </div>
                            </ng-container>
                            <hr>

                        </div>

                    </div>
                </div>
                <!-- column -->
                <div class="col-12" *ngIf="view == 'lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Vigilantes</h4>
                                    <h5 class="card-subtitle">Lista de vigilantes</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl row">
                                        <div class="col-md-6">
                                            <label for="">Mostrar</label>
                                            <select class="form-control select-custom" name="fechas" placeholder="Hola"
                                                (change)="mostrar($event)">
                                                <!-- <option value="10"></option> -->
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="todos">Todos</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="">Buscar vigilante</label>
                                            <input type="search" [(ngModel)]="term" (keydown)="filtrar($event)"
                                                class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <!-- <p>cronograma works!</p> -->
                            <br>
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->

                            <div class="table-responsive">
                                <table class="table v-middle">
                                    <thead>
                                        <tr class="bg-light">
                                            <th class="border-top-0">Serial</th>
                                            <th class="border-top-0">Nombres</th>
                                            <th class="border-top-0">Apellidos</th>
                                            <th class="border-top-0">Puesto</th>

                                            <!-- <th class="border-top-0">Puesto</th> -->
                                            <th class="border-top-0">Teléfono</th>
                                            <!-- <th class="border-top-0">ARL</th> -->
                                            <th class="border-top-0">Foto</th>
                                            <th class="border-top-0">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody class="scroll">

                                        <tr ([ngModel])="empleados"
                                            class="table-activo__container table-striped __head__body__item"
                                            *ngFor="let item of empleados | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                            <ng-container>
                                                <td>
                                                    {{item.Serial}}

                                                </td>
                                                <td>
                                                    {{item.Nombre}}
                                                </td>
                                                <td>
                                                    {{item.Apellido}}
                                                </td>
                                                <td>
                                                    {{item.Puesto}}
                                                </td>
                                                <td>
                                                    {{item.Telefono}}
                                                </td>
                                                <td>
                                                    <img src="http://qaractivo.local/files/vigilantes/{{item.Foto}}"
                                                        style="width: 150px;" alt="">
                                                </td>

                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn btn-secondary dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            Acciones
                                                        </button>
                                                        <div style="text-transform: uppercase;" class="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton">
                                                            <a (click)="detail(item.IdEmpleado,item.Cedula,item.Nombre,item.Apellido,item.Puesto,item.Telefono,item.Direccion,item.Ciudad,item.Arl,item.Eps,item.RH,item.NombreContacto,item.TelefonoContacto,item.Foto)"
                                                                class="dropdown-item" data-toggle="modal"
                                                                data-target="#modalempleadovisitas"><i
                                                                    class="icon-DETAILS"></i> Visitas</a>
                                                            <a (click)="detail(item.IdEmpleado,item.Cedula,item.Nombre,item.Apellido,item.Puesto,item.Telefono,item.Direccion,item.Ciudad,item.Arl,item.Eps,item.RH,item.NombreContacto,item.TelefonoContacto,item.Foto)"
                                                                class="dropdown-item" data-toggle="modal"
                                                                data-target="#modalempleado"> <i
                                                                    class="icon-DETAILS"></i> Editar</a>
                                                            <a class="dropdown-item" style="color:red"
                                                                (click)="deleteempleado(item.IdEmpleado, item.Serial)">
                                                                <i class="icon-REMOVE"></i> Eliminar</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="panel-activos__body__pagination text-center">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>

                            <hr>

                        </div>

                    </div>
                </div>

            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co ">Quar</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>




<ng-container *ngIf="view == 'lista'">

    <div class="panel-activos__body__pagination">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="modalempleado" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detalle del empleado</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form #loginForm=ngForm (ngSubmit)="onSubmit3()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <img src="{{src}}" style="max-width: 100%" alt="">
                                <br>
                            </div>
                            <div class="col-md-12 fieldset">
                                <!-- <label for="">Subir foto del vigilante</label> -->
                                <hr>
                                <div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                                    <input (change)="change($event)" type="file" id="fichero-tarifas" class="input-file"
                                        value="">
                                    Seleccionar foto para el vigilante...
                                </div>
                            </div>
                            <div class="col-md-12">
                                <br>
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Cedula
                                </label>
                                <input name="Cedula" type="text" (change)="cedula()" [(ngModel)]="form.Cedula"
                                    class="form-control">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Nombres
                                </label>
                                <input name="Nombre" type="text" (change)="cedula()" [(ngModel)]="form.Nombre"
                                    class="form-control">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Apellidos
                                </label>
                                <input type="text" (change)="cedula()" class="form-control" name="Apellido"
                                    [(ngModel)]="form.Apellido">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Puesto
                                </label>
                                <input type="text" class="form-control" name="Puesto" [(ngModel)]="form.Puesto">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Teléfono
                                </label>
                                <input type="text" class="form-control" name="Telefono" [(ngModel)]="form.Telefono">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Dirección
                                </label>
                                <input type="text" class="form-control" name="Direccion" [(ngModel)]="form.Direccion">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Ciudad
                                </label>
                                <input type="text" class="form-control" name="Ciudad" [(ngModel)]="form.Ciudad">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    ARL
                                </label>
                                <input type="text" class="form-control" name="Arl" [(ngModel)]="form.Arl">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    EPS
                                </label>
                                <input type="text" class="form-control" name="Eps" [(ngModel)]="form.Eps">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    RH
                                </label>
                                <input type="text" class="form-control" name="RH" [(ngModel)]="form.RH">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Nombre de Contacto
                                </label>
                                <input type="text" class="form-control" name="NombreContacto"
                                    [(ngModel)]="form.NombreContacto">
                            </div>
                            <div class="col-md-4">
                                <label for="">
                                    Teléfono Contacto
                                </label>
                                <input type="text" class="form-control" name="TelefonoContacto"
                                    [(ngModel)]="form.TelefonoContacto">
                            </div>

                            <div class="col-md-12">
                                <hr>
                                <input type="checkbox" (change)="onChangeRole($event)" name="Vigilante"
                                > ¿Es un supervisor?
                                <hr>
                            </div>

                            <div class="col-lg-12">
                                <br>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="">Usuario</label>
                                <input type="text" name="email" placeholder="Usuario" class="form-control"
                                    id="inputEmail3" [(ngModel)]="user.email" required>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="">Contraseña</label>
                                <label class="sr-only" for="inlineFormInputGroup">Contraseña</label>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text btn btn-primary">
                                            <i style="display: block;" class="fas fa-eye" (click)="inputType='text'"
                                                *ngIf="inputType == 'password'"></i>
                                            <i style="display: block;" class="far fa-eye-slash"
                                                (click)="inputType='password'" *ngIf="inputType == 'text'"></i>
                                        </div>
                                    </div>
                                    <input [type]="inputType" name="contrasena" [(ngModel)]="user.password"
                                        class="form-control" id="inlineFormInputGroup" placeholder="Contraseña">
                                </div>
                            </div>

                            <div class="col-md-12 form-group">

                            </div>

                            <div class="form-group col-md-12">
                                <label for="">Confirmación de contraseña</label>
                                <input [type]="inputType" name="password_confirmation"
                                    placeholder="Repite la contraseña" class="form-control" id="445"
                                    [(ngModel)]="user.password_confirmation" required>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="cerrar()"
                            data-dismiss="modal">Cerrar</button>
                        <button class="btn btn-primary">Actualizar información</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalempleadovisitas" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Visitas del empleado el día de hoy</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <ng-container ([ngModel])="observaciones" *ngFor="let item of observaciones">
                        <div class="col-md-12">
                            <div class="card container text-center">
                                <div class="text-left card-header-date">
                                    {{item.FechaRegistro}}
                                </div>
                                <h1 for="">Temperatura</h1>
                                <p>{{item.Temperatura}}</p>
                                <hr>
                                <h3 for="">Observación</h3>
                                <p>{{item.Observacion}}</p>
                                <hr>
                                <ng-container *ngIf="item.observacion2">

                                    <h3 for="">Sintomas</h3>
                                    <p>{{item.observacion2}}</p>
                                </ng-container>

                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="cerrar()" data-dismiss="modal">Cerrar
                        historial de visitas</button>
                    <!-- <button class="btn btn-primary">Actualizar información</button> -->
                </div>
            </div>
        </div>
    </div>
</ng-container>



<!-- Modal -->
<div class="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Informes por vigilante</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-md-flex align-items-center">
                    <div>
                        <h4 class="card-title">Exportar</h4>

                    </div>

                </div>
                <div class="row">
                    <!-- column -->
                    <div class="col-lg-12">
                        <div *ngIf="view == 'lista'">
                            <a *ngIf="view =='lista'" href="">
                                <button class="btn cell btn-secondary cell">Exportar vigilantes</button></a>
                            <!-- <hr> -->
                            <h3>Reporte de ingresos</h3>
                            <div class="form-group">
                                <label for="">Fecha inicial</label>
                                <input type="date" [(ngModel)]="fechainicial" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="">Fecha final</label>
                                <input type="date" [(ngModel)]="fechafinal" class="form-control">
                            </div>

                            <button (click)="exportarvisitas()" class="btn btn-primary">Exportar registro de
                                ingresos</button>
                        </div>

                    </div>
                    <!-- column -->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>