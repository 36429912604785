import { TokenService } from 'src/app/Services/token.service';
import { TicketsService } from './../Services/tickets.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ticketsdetail',
  templateUrl: './ticketsdetail.component.html',
  styleUrls: ['./ticketsdetail.component.scss']
})
export class TicketsdetailComponent implements OnInit {
  loggedIn = false;
  tittle = "Información del ticket"
  loading= false;
  numeroTicket = this.router.snapshot.paramMap.get("numero")
  userTicket = this.router.snapshot.paramMap.get("user")
  asuntoTicket = this.router.snapshot.paramMap.get("asunto")
  constructor(private router: ActivatedRoute, private tickets: TicketsService, private Token: TokenService) { }
  ticket = {
    numero: this.numeroTicket,
    user: this.userTicket
  }
  nombreUsuario = "";
  ticketdetail:any;
  ticketnotas:any;
  correoCliente: "";
  sendNota = {
    usuarioNotificacion: null,
    usuarioticket: this.userTicket,
    IdChat: this.numeroTicket+this.userTicket,
    Asunto: this.asuntoTicket,
    NombreUsuario: this.nombreUsuario,
    NumeroTicket: this.numeroTicket,
    correo:null,
    NotaMensaje:null,
  }
  handleResponse(data){
    console.log(data);
    this.ticketdetail = data.ticket;
    this.ticketnotas = data.notas;
    this.correoCliente = data.ticket[0].CorreoCliente
    if(this.Token.getUserId()){
      this.sendNota.usuarioNotificacion = data.ticket[0].CorreoCliente;
      this.sendNota.NombreUsuario = data.user[0].name;
      this.sendNota.correo =  data.user[0].email;
      // console.log(this.correoCliente)
    } else {
      this.sendNota.usuarioNotificacion =  data.user[0].email;
      this.sendNota.correo = data.ticket[0].CorreoCliente
      this.sendNota.NombreUsuario = data.ticket[0].NombreCliente;
    }
    console.log(this.ticketdetail)
  }

  estado = {
    ticket:null,
    estado:null,
    usuarioTicket:null
  }
  

  responseEstado(data){
      console.log(data);
  }

  onChange(estado,usuario,ticket){
    this.estado.estado = estado;
    this.estado.usuarioTicket = usuario;
    this.estado.ticket = ticket;
    this.tickets.estado(this.estado).subscribe(data => this.responseEstado(data),
    error => console.log(error));

  }
  
  handleResponse2(data){
    this.sendNota.NotaMensaje = "";
    console.log(data);
    this.loading = false;
    this.ticketnotas = data.notas;
    // this.ticketdetail = data.ticket;
    
    // this.ticketnotas = data.notas;
    // this.correoCliente = data.ticket[0].CorreoCliente
    // this.sendNota.correo = data.ticket[0].CorreoCliente
    // // console.log(this.correoCliente)
    // console.log(this.ticketdetail)
  }
  
  sendNot(){
    console.log(this.sendNota)
    this.loading = true;
    this.tickets.notas(this.sendNota).subscribe(data => this.handleResponse2(data), error=> error(error))
  }
  error(error){
    this.loading = false;
  }

  ngOnInit() {
    if(this.Token.getUserId()){
      this.loggedIn = true;
    }
    this.tickets.detail(this.ticket).subscribe(data => this.handleResponse(data),
    error => console.log(error))
  }

}
