import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuentausuario',
  templateUrl: './cuentausuario.component.html',
  styleUrls: ['./cuentausuario.component.scss']
})
export class CuentausuarioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
