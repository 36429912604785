import { ToastrService } from 'ngx-toastr';
import { SedesService } from './../Services/sedes.service';
import { Router } from '@angular/router';
import { TokenService } from './../Services/token.service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';


@Component({
  selector: 'app-sedecompany',
  templateUrl: './sedecompany.component.html',
  styleUrls: ['./sedecompany.component.scss']
})
export class SedecompanyComponent implements OnInit {
  tittle = "SEDES DE LA COMPAÑIA"

  form = {
    NombreSede: null,
    IdUsuarioInmo: this.token.getUserId()
  }

  options = {
    NombreSede: null,
    IdUsuarioInmo: this.token.getUserId(),
    idSede: null

  }

  term;

  loading = false;
  onSubmit() {
    this.loading = true;
    this.sede.create(this.form).subscribe(data => {
      this.sedes = data['sedes'];
      this.loading = false;
      this.toastr.success("Se ha registrado la sede", "Mensaje")
    },
      error => {
        console.log(error);
        this.loading = false;
      })
  }

  deleteSede(id) {
    this.loading = true;
    swal.fire({
      title: 'Esta acción no será reversible?',
      text: "Confirmar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.options.idSede = id;
        this.sede.delete(this.options).subscribe(
          data => {
            this.loading = false; this.sedes = data['sedes']
            this.toastr.success('Se ha eliminado correctamente');
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        )
      }
    })


  }
  sedes;
  constructor(private toastr: ToastrService, private token: TokenService, private router: Router, private sede: SedesService) { }
  admin;
  ngOnInit(): void {
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.router.navigateByUrl('/home');

    } else {
      this.admin = true;
      // this.codigo = this.token.utf8_to_b64(this.token.getUserId());
    }

    this.sede.getSedes(this.form).subscribe(
      data => {
        this.sedes = data['sedes'];
      }, error => {
        console.log(error);
      }
    )
  }
}
