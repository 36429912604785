import { ToastrService } from 'ngx-toastr';
import { ImpresorasService } from './../Services/impresoras.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { UserService } from '../Services/user.service';
import { TokenService } from '../Services/token.service';
import { ComputadorService } from '../Services/Activos/computador.service';

import swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-impresoras',
  templateUrl: './impresoras.component.html',
  styleUrls: ['./impresoras.component.scss']
})
export class ImpresorasComponent implements OnInit {
  numberperpage = 10;
  term: string;
  mostrar(event){
      // alert(event.target.value);
      this.numberperpage = event.target.value;
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  exportexcel(): void
    {
       /* table id is passed over here */
       let element = document.getElementById('excel-table');
       const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.computador);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, "impresoras.xlsx");

    }

    onChange(serial, event) {
      const swalWithBootstrapButtons = swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Estás seguro?',
        text: "Se realizará el cambio de la sede",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar!',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.dataActivo = {
            serial: serial,
            IdSede: event.target.value
          }
          this.activo.cambiarSede(this.dataActivo).subscribe(
            data => this.toastr.success("Se actualizó exitosamente"),
            error => {
              this.toastr.error("Ha ocurrido un error", 'Sede activo', {
                timeOut: 3000
              });
            }
          )

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelado',
            'Se ha salvado tu activo',
            'error'
          )
        }
      })

    }



  filtrar(event) {
    console.log(event);
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }


  loading = false;
  mantenimientosactivo:any;
  sidebaractive:string = 'ficha';
  filesToUpload: Array<File> = [];
  url = "https://quar.com.co/apiupload"
  detail = {
    id_user:this.Token.getUserId(),
    serial: null,
    tipoPC:null,
    tipoMemoria:null

  };
  detailactivo:any;
  p: number = 1;
  tittle: string = "Gestión de impresoras";
  idActivo: number;
  serial: string;
  mensaje:string;

  pasos:number = 1;

  licencias:any;
  tipocomputador:any;
  ciclosmantenimiento:any;
  sistemaoperativo:any;
  tipomemorias:any;
  data = {
    id: this.Token.getUserId(),
    idactivo: this.idActivo,
    tipo: "Impresora",
    Sede: localStorage.getItem('IdSede')
  }
  view:string = 'lista';
  computador: any;
  tickets: number;
  dataActivo: any;


  verificar = {
    serial: null
  }
  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }
  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.serialdom.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }

  public form = {
    Codigo:null,
  id_user:this.Token.getUserId(),
  NombreActivo:null,
Marca:null,
Modelo:null,
TipoImpresora:null,
Color:null,
Multifuncion:null,
Red:null,
Serial:null,
FechaCompra:null,
NumeroFactura:null,
ValorActivo:null,
NumeroPoliza:null,
Observaciones:null,
CicloMantenimiento:null,
ProximoMantenimiento:null,
Sede: localStorage.getItem('IdSede')
}




  public loggedIn;
  constructor(
    private toastr:ToastrService,
    private Auth: AuthService, private user: UserService, private Token: TokenService, private activo: ImpresorasService, private serialdom:ComputadorService) { }

    detailInfo(data){


        console.log(data)
        this.detailactivo = data;
    }

  details(id){
    this.detail.serial = id;
    this.activo.detail(this.detail).subscribe(
        data => this.detailInfo(data),
        error => console.log(error)
    )

  }



  onSubmit(){
    this.loading = true;
        this.activo.create(this.form).subscribe(
          data => {
            this.createcomputadores(data)
          },
          error => console.log(error)
        );

  }

  deleteComputadorResponse(data) {
    this.computador = data.activo;
  }
  admin = false;

  ngOnInit() {

    // Ver user adImin
if (parseInt(this.Token.getUserAdministrador()) > 0) {
  this.data.id = this.Token.getUserAdministrador();
  this.form.id_user = this.Token.getUserAdministrador();
} else {
  this.admin = true;
  this.data.id= this.Token.getUserId();

}

    this.activo.getImpresoras(this.data).subscribe(
      data => this.computadores(data),
      erro => console.log(erro)
    );
    this.loggedIn = this.Auth.authStatus.subscribe(value => this.loggedIn = value);
  }


  createcomputadores(data) {
    this.loading = false;
    this.pasos=1;
    this.toastr.success("Registro de impresoras",'Registro de impresoras',{
      timeOut:3000
    });
    // alert(data.mensaje);
    this.view = 'lista';

    this.computador = data.activo;

  }
  sedes: any;
  computadores(data) {
    console.log(data);
    this.sedes = data.sedes;
    this.computador = data.activo;

  }

  deleteImpresora(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        console.log(id);
        this.dataActivo = {
          id: id,
          serial: serial,
          idUser: this.Token.getUserId(),
          tipo: "Impresora"
        }
        this.activo.deleteActivo(this.dataActivo).subscribe(
          data => this.deleteComputadorResponse(data),
          error => console.log(error)
        )
       this.toastr.success("Eliminar Activo","Se eliminó el activo");
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })

  }
}

