import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisitantesService {

  // private baseurl = "http://qaractivo.local/api";
  private baseurl = "http://qaractivo.local/api";
  getVisitantes(data) {
    return this.http.post(`${this.baseurl}/visitantes`, data)
  }

  getData(data) {
    return this.http.post(`${this.baseurl}/visita/info`, data)
  }

  registroPost(data){
    return this.http.post(`${this.baseurl}/observacion/visitagrupo`, data)
  }

  cedula(data) {
    return this.http.post(`${this.baseurl}/visitantes/cedula`, data);
  }

  getDato(data) {
    return this.http.post(`${this.baseurl}/info/grupo`, data);
  }

  registroform(data) {
    return this.http.post(`${this.baseurl}/registro/grupo`, data);
  }


  getParticipantes(data) {
    return this.http.post(`${this.baseurl}/get/participantes`, data);
  }


  create(data) {
    return this.http.post(`${this.baseurl}/visitantes/create`, data);
  }

  
  delete(data) {
    return this.http.post(`${this.baseurl}/delete/visitantegrupo`, data);
  }

  

  update(data) {
    return this.http.post(`${this.baseurl}/visitantes/update`, data);
  }


  count(data) {
    return this.http.post(`${this.baseurl}/visitantes/count`, data);
  }

  constructor(private http: HttpClient) { }
}
