import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  private baseUrl = "https://quar.com.co/api";
  getProfile(data){
      return this.http.post(`${this.baseUrl}/profile`,data);
  }
  constructor(private http: HttpClient) { }
}
