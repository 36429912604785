<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Mi Cuenta</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Actualización de información</h4>
                                    <h5 class="card-subtitle"></h5>
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <div class="alert alert-danger" *ngIf="mensaje != ''">
                                        {{mensaje}}
                                    </div>
                                    <form #userUpdate=ngForm class="form-horizontal form-material"
                                        (ngSubmit)="onSubmit()">
                                        <div class="row" style="padding:2em">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="Nombre">Nombre</label>
                                                    <input type="text" class="form-control" name="name"
                                                        [(ngModel)]="user.name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="Nombre">Email</label>
                                                <input type="text" class="form-control" name="email"
                                                    [(ngModel)]="user.email">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="Nombre">Contraseña</label>
                                                <input type="password" class="form-control" name="password"
                                                    [(ngModel)]="user.password">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="Nombre">Confirmación de contraseña</label>
                                                <input (change)="change()" [class.error]="mensaje != ''" type="password"
                                                    class="form-control" name="confirmation"
                                                    [(ngModel)]="user.confirmation">
                                            </div>
                                            <div class="col-md-12">
                                                <hr>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-gorup">
                                                    <button type="submit" class="cell btn btn-primary">Actualizar
                                                        información</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Usuario en sesión</h4>
                            <div class="feed-widget">
                                <ul class="list-style-none feed-body m-0 p-b-20">
                                    <li class="feed-item">
                                        <div class="feed-icon bg-info"><i class="ti-user"></i></div> {{user.name}}
                                        <!-- <span class="ml-auto font-12 text-muted">Just Now</span> -->
                                    </li>
                                    <li class="feed-item">
                                        <div class="feed-icon bg-success"><i class="ti-server"></i></div> {{user.email}}
                                        <!-- overloaded.<span class="ml-auto font-12 text-muted">2 Hours ago</span> -->
                                    </li>
                                    <li class="feed-item">
                                        <div class="feed-icon bg-warning"><i class="ti-shopping-cart"></i></div> Tipo
                                        Usuario
                                        <span class="ml-auto font-12 text-muted" *ngIf="admin">Administrador</span>
                                        <span class="ml-auto font-12 text-muted" *ngIf="!admin">Editor</span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row" style="display: none ;">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Top Selling Products</h4>
                                    <h5 class="card-subtitle">Overview of Top Selling Items</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <select class="custom-select">
                                            <option value="0" selected>Monthly</option>
                                            <option value="1">Daily</option>
                                            <option value="2">Weekly</option>
                                            <option value="3">Yearly</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Products</th>
                                        <th class="border-top-0">License</th>
                                        <th class="border-top-0">Support Agent</th>
                                        <th class="border-top-0">Technology</th>
                                        <th class="border-top-0">Tickets</th>
                                        <th class="border-top-0">Sales</th>
                                        <th class="border-top-0">Earnings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="m-r-10"><a class="btn btn-circle btn-info text-white">EA</a>
                                                </div>
                                                <div class="">
                                                    <h4 class="m-b-0 font-16">Elite Admin</h4>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Single Use</td>
                                        <td>John Doe</td>
                                        <td>
                                            <label class="label label-danger">Angular</label>
                                        </td>
                                        <td>46</td>
                                        <td>356</td>
                                        <td>
                                            <h5 class="m-b-0">$2850.06</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="m-r-10"><a
                                                        class="btn btn-circle btn-orange text-white">MA</a></div>
                                                <div class="">
                                                    <h4 class="m-b-0 font-16">Monster Admin</h4>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Single Use</td>
                                        <td>Venessa Fern</td>
                                        <td>
                                            <label class="label label-info">Vue Js</label>
                                        </td>
                                        <td>46</td>
                                        <td>356</td>
                                        <td>
                                            <h5 class="m-b-0">$2850.06</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="m-r-10"><a
                                                        class="btn btn-circle btn-success text-white">MP</a></div>
                                                <div class="">
                                                    <h4 class="m-b-0 font-16">Material Pro Admin</h4>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Single Use</td>
                                        <td>John Doe</td>
                                        <td>
                                            <label class="label label-success">Bootstrap</label>
                                        </td>
                                        <td>46</td>
                                        <td>356</td>
                                        <td>
                                            <h5 class="m-b-0">$2850.06</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="m-r-10"><a
                                                        class="btn btn-circle btn-purple text-white">AA</a></div>
                                                <div class="">
                                                    <h4 class="m-b-0 font-16">Ample Admin</h4>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Single Use</td>
                                        <td>John Doe</td>
                                        <td>
                                            <label class="label label-purple">React</label>
                                        </td>
                                        <td>46</td>
                                        <td>356</td>
                                        <td>
                                            <h5 class="m-b-0">$2850.06</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>