<header class="topbar" data-navbarbg="skin5">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <div class="navbar-header" data-logobg="skin5">
            <!-- ============================================================== -->
            <!-- Logo -->
            <!-- ============================================================== -->
            <a class="navbar-brand text-center" routerLink="/home">
                <!-- Logo icon -->
                <b class="logo-icon text-center">
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="../../assets/img/logo.png" style="width: 60px !important;" alt="homepage"
                        class="dark-logo" />
                    <!-- Light Logo icon -->
                    <img src="../../assets/img/logo.png" style="width: 80px !important;" alt="homepage"
                        class="light-logo" />
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span class="logo-text">
                    <!-- dark Logo text -->
                    <!-- <img src="../../assets/images/logo-text.png" alt="homepage" class="dark-logo" /> -->
                    <!-- Light Logo text -->
                    <!-- <img src="../../assets/images/logo-light-text.png" class="light-logo" alt="homepage" /> -->

                </span>
            </a>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <!-- This is for the sidebar toggle which is visible on mobile only -->
            <a class="d-block d-md-none" *ngIf="!view" (click)="menu()" style="color:black; margin-right: 2em;"><i
                    class="fas fa-bars"></i></a>
            <a class="d-block d-md-none" *ngIf="view" (click)="menu2()" style="color:black; margin-right: 2em;"><i
                    class="fas fa-times"></i></a>
            <!-- <a class="nav-toggler waves-effect waves-light d-block d-md-none" (click)="menu()" style="color:white"><i
                    class="ti-close"></i></a> -->

        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav float-left mr-auto">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <li class="nav-item search-box">

                </li>
            </ul>
            <!-- ============================================================== -->
            <!-- Right side toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav float-right">

                <!-- ============================================================== -->
                <!-- User profile and search -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href=""
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img
                            src="../../assets/images/users/config.png" alt="user" class="rounded-circle" width="31"></a>
                    <div class="dropdown-menu dropdown-menu-right user-dd animated">
                        <a class="dropdown-item" routerLink="/sedes"><i class="fas fa-map-marker-alt"></i> Sedes</a>
                        <a class="dropdown-item" routerLink="/config"><i class="far fa-user-circle"></i> Cuenta</a>


                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- User profile and search -->
                <!-- ============================================================== -->
            </ul>
        </div>
    </nav>
</header>