<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{title}}</h4>
                    <div class="d-flex align-items-center">

                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <!-- <li class="breadcrumb-item"><a href="#">Inicio</a></li> -->
                                <!-- <li class="breadcrumb-item active" routerLink='/empleados'>Gestión de empleados</li> -->
                                <!-- <li class="breadcrumb-item active">Observacion {{form.Nombre}} {{form.Apellido}}</li> -->
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <!-- <a (click)="view='serial'" class="btn btn-primary text-white">Agregar empleado</a> -->
                        <!-- <a (click)="abajo()" class="btn btn-danger text-white">EXPORTAR</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <!-- <h4 class="card-title">Observación al empleado</h4> -->
                                    <!-- <h5 class="card-subtitle" *ngIf="view=='serial'">Formulario de observación</h5> -->
                                    <!-- <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario de empleado</h5> -->
                                </div>

                            </div>
                            <!-- title -->

                            <form #loginForm="ngForm" (ngSubmit)="submit()">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <label for="">Número de identificación</label>
                                        <input type="text" class="form-control" disabled name="NumeroIdentificacion"
                                            [(ngModel)]="form.NumeroIdentificacion">
                                    </div>

                                    <div class=" col-md-6">
                                        <label for="">Nombre completo</label>
                                        <input type="text" class="form-control" disabled name="Nombre"
                                            [(ngModel)]="form.Nombre">
                                    </div>
                                    <div class=" col-md-6">
                                        <label for="">Correo</label>
                                        <input type="text" class="form-control" disabled name="Correo"
                                            [(ngModel)]="form.Correo">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Teléfono</label>
                                        <input type="text" class="form-control" disabled name="Telefono"
                                            [(ngModel)]="form.Telefono">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">Celebración</label>
                                        <input type="text" class="form-control" disabled name="Celebracion"
                                            [(ngModel)]="form.Servicio">
                                    </div>
                                    <div class="col-lg-12">
                                        <br>
                                        <br>
                                        <!-- <div class="alert alert-danger">
                                            Estos valores serán utilizado para tener control
                                        </div> -->
                                        <label for="">Temperatura:  <span style="color:red">Opcional</span></label>
                                        <input type="number" required (keyup)="temperatura($event)" class="form-control"
                                            name="Temperatura" [(ngModel)]="form.Temperatura"> 
                                    </div>
                                    <div class="col-lg-12">
                                        <label for=""> <b style="color:red"> ¿Ha tenido contacto estrecho con personas
                                                con un caso de investigacion o diagnósticada con COVID 19?:</b></label>
                                        <select name="observacion" (change)="observacionesAlerta($event)"
                                            class="form-control" [(ngModel)]="form.observacion">
                                            <option
                                                value="No ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19">
                                                No</option>
                                            <option
                                                value="Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19">
                                                Sí</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for=""> <b style="color:red"> ¿Tiene tos, fiebre, dificultad para
                                                respirar?</b></label>
                                        <select name="observacion" [(ngModel)]="sintomaselect"
                                            (change)="observaciones2Alerta($event)" class="form-control">
                                            <option value="Nono"></option>
                                            <option value="No">No</option>
                                            <option value="Sí">Sí</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6" *ngIf="sintomas">
                                        <label for=""> <b style="color:red"> Seccione los sintomas</b></label>
                                        <select name="observacion" multiple class="form-control"
                                            [(ngModel)]="form.observacion2">
                                            <option value="Tos">Tos</option>
                                            <option value="Fiebre">Fiebre</option>
                                            <option value="Dificultad para respirar">Dificultad para respirar</option>
                                        </select>
                                    </div>
                                </div>
                                <br>
                                <button class="btn btn-primary">Registrar observacion</button>
                            </form>

                            <!-- <ng-container ([ngModel])="observaciones" *ngFor="let item of observaciones">
                                <div class="card container text-center">
                                    <div class="text-left card-header-date">
                                        {{item.FechaRegistro}}
                                    </div>
                                    <h1 for="">Temperatura</h1>
                                    <p>{{item.Temperatura}}</p>
                                    <hr>
                                    <h3 for="">Observación</h3>
                                    <p>{{item.Observacion}}</p>
                                    <hr>
                                    <ng-container *ngIf="item.observacion2">
        
                                        <h3 for="">Sintomas</h3>
                                        <p>{{item.observacion2}}</p>
                                    </ng-container>
        
                                </div>
                            </ng-container> -->
                        </div>
                    </div>
                </div>

            </div>



            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>