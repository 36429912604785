<!-- CSS Just for demo purpose, don't include it in your project -->
<div class="wrapper">
    <app-layout></app-layout>

    <div class="main-panel">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary fixed-top">
            <div class="container-fluid">
                <div class="navbar-wrapper">
                    <div class="navbar-toggle">
                        <button type="button" class="navbar-toggler">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <a class="navbar-brand">{{tittle}}</a>
                </div>
                <app-navbar></app-navbar>
            </div>
        </nav>
        <!-- End Navbar -->
        <div class="panel-header panel-header-lg">
            <!-- <canvas id="bigDashboardChart"></canvas -->
        </div>
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-tasks panel-activos">

                        <div class="card-header">
                            <h2 class="card-category">Insumos y componentes</h2>
                            <h1>Maquinas</h1>

                        </div>
                        <div class="card-body panel-activos__body">
                            <ng-container *ngIf="view == 'registro'">
                                <div class="content-registro">
                                    <div class="content-registro__container ">
                                        <form class="content-registro__container__form" #computadorForm="ngForm" (ngSubmit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-md-12 container__datos_generales" *ngIf="pasos=='uno'">
                                                    <div class="row">
                                                        <div class="col-lg-12 ">
                                                            <h2>¿Estás listo? ...Arranquemos</h2>
                                                            <div class="progress" style="height:4px">
                                                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 10%">
                                                                </div>
                                                            </div>
                                                            <br>

                                                            <br>
                                                            <h4>Datos Generales</h4>
                                                            <hr>
                                                        </div>

                                                        <div class="col-md-12">
                                                            <label for="">Serial</label>
                                                            <input type="text" (change)="serialVerify()" placeholder="Serial" class="form-control" [(ngModel)]="data.Codigo" name="NombreActivo" required>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Nombre</label>
                                                            <input type="text" placeholder="Nombre del Activo" class="form-control" [(ngModel)]="data.Nombre" name="Nombre" required>

                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Tipo Insumo</label>
                                                            <input type="text" placeholder="Marca" class="form-control" [(ngModel)]="data.TipoInsumo" name="TipoInsumo">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Fecha Compra</label>
                                                            <input type="date" placeholder="Modelo" [(ngModel)]="data.FechaCompra" class="form-control" name="FechaCompra">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Número Factura</label>
                                                            <input type="text" placeholder="Serial" class="form-control" [(ngModel)]="data.NumeroFactura" name="NumeroFactura">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Valor</label>
                                                            <input type="text" placeholder="Fecha de Compra" [(ngModel)]="data.Valor" class=" form-control " name=Valor>
                                                        </div>
                                                        <div class="col-md-6 ">
                                                            <label for="">Cantidad Uso Máximo</label>
                                                            <input type="text " placeholder="Número de Factura" class="form-control " [(ngModel)]="data.CantidadUsoMaximo" name="CantidadUsoMaximo">
                                                        </div>
                                                        <div class="col-md-6 ">
                                                            <label for="">Número Usos</label>
                                                            <input type="text " placeholder="Valor del Activo" class="form-control " [(ngModel)]="data.NumeroUsos" name="NumeroUsos">
                                                        </div>

                                                        <div class="col-md-6">
                                                            <label for="">Ciclos de Mantenimiento</label>
                                                            <select [(ngModel)]="data.CicloMantenimiento" name="CicloMantenimiento" class="form-control">
                                                                <ng-container *ngFor="let tipo of ciclosmantenimiento;">
                                                                    <option value="{{tipo.IdCiclo}}">
                                                                        {{tipo.NombreCiclo}}</option>
                                                                </ng-container>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Fecha Proximo Mantenimiento</label>
                                                            <input type="date" [(ngModel)]="data.FechaMantenimiento" name="FechaMantenimiento" placeholder="Proximo Mantenimiento" class="form-control">
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label for="">Observaciones:</label>
                                                            <textarea name="Observaciones" [(ngModel)]="data.Observaciones" id="" cols="30" rows="10" class="form-control"></textarea>
                                                        </div>
                                                    </div>


                                                </div>

                                                <!-- Datos de Memoria -->

                                                <!-- Fin datos de memoria -->
                                                <!-- Software -->


                                                <div class="col-lg-12">
                                                    <button class="btn btn-primary cell">Registrar insumo</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-container>



                            <div class="panel-activos__body__create">


                                <button *ngIf="view == 'registro'" class="btn btn-secondary" routerLink="/maquinaria-industrial"><i class="fas fa-arrow-circle-left"></i>Volver A Maquinas</button>

                            </div>



                        </div>
                        <div class="card-footer ">
                            <hr>
                            <div class="stats ">
                                <i class="now-ui-icons loader_refresh spin "></i>Actualizando
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>




<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Duplicar activo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <label for="">
                    Si tienes un seríal ingresalo en el siguiente campo
                </label>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>


            </div>
        </div>
    </div>
</div>