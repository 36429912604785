import { SedesService } from './../Services/sedes.service';
import  swal  from 'sweetalert2';
import { TokenService } from './../Services/token.service';
import { UserService } from './../Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuarioscompania',
  templateUrl: './usuarioscompania.component.html',
  styleUrls: ['./usuarioscompania.component.scss']
})
export class UsuarioscompaniaComponent implements OnInit {
  idUser = this.Token.getUserId();
  fechainicial;
  fechafinal;
  loading;

  public form= {
    name: null,
    email:null,
    password: null,
    password_confirmation: null,
    UserAdmin: this.Token.getUserId(),
    Estado: "2",
    Sede:null
  }

  admin = true;

  
  options = {
    IdUsuarioInmo: this.Token.getUserId()
  }


  detalle = true;

  detailResponse(data){
      this.observaciones = data.observaciones;
  }

  exportarvisitas(){
    // alert();
    var fecha = new Date();
    var dias = 1; // Número de días a agregar
    var fechaactual = fecha.setDate(fecha.getDate() + dias);

    // alert(fecha.getDate());
    if(this.fechainicial == "" || this.fechainicial == "dd/mm/aaaa" || this.fechainicial == null){
      this.fechainicial = "2020-01-01";
    }
    if(this.fechafinal == "" || this.fechafinal == "dd/mm/aaaa" || this.fechafinal == null){
      this.fechafinal = fecha.getFullYear()+"-"+fecha.getMonth()+"-"+fecha.getDate();
      // alert(this.fechafinal);
    }
    // alert(this.fechafinal);
    // alert(this.fechainicial);
    location.href = "http://qaractivo.local/api/excel/empleados/visitas/"+this.idUser+"/"+this.fechainicial+"/"+this.fechafinal
  }

  observaciones:any;

  detail(IdEmpleado,Cedula,Nombre,Apellido,Cargo,Telefono,Direccion,Ciudad,Arl,Eps,RH,NombreContacto,TelefonoContacto){

    
    // this.empleado.detail(this.form).subscribe(data=>this.detailResponse(data),error=>this.toastr.error(error.message, 'Error'));
  }
  numberperpage = 10;
  term: string;
  mostrar(event){
      // alert(event.target.value);
      this.numberperpage = event.target.value;
  }
  view = 'lista';

  id;
  empleados: any;
  visitascount;
  generarCodigo;
  codigo = false;
  tittle = "Control de usuarios";
  data = {
    id_user: this.Token.getUserId(),
    userEncriptado: this.Token.utf8_to_b64(this.Token.getUserId())
  }

  generaCod(data){
   if(data.data){
     this.codigo = true;
   } else {

   }
  }

  generatecodigo(){
      this.Token.generarVisitasCodigo(this.data).subscribe(
        data => {
          this.generaCod(data);
        },
        error => console.log(error)
      )
  }

  updateSede = {
    id:null,
    sede:null
  }

  updateUserSede(id,sede){
    this.updateSede.id = id;
    this.updateSede.sede = sede.target.value;

    this.sede.updateUser(this.updateSede).subscribe(
      data => {
        this.toastr.success("Se actualizó la sede al usuario");
      }, 
      error => {
        this.toastr.error("No se ha podido actualizar"); 
      }
    )

  }

  onSubmit(){
    if(this.form.password != this.form.password_confirmation){
        this.toastr.error("Las contraseñas no coinciden por favor verificalas");
    } else {
    this.loading = true;
    console.log(this.data);
    this.empleado.createUsuariosCompania(this.form).subscribe(
      data=> {
        this.loading = false;
      this.handleResponse(data);
    },
      error=>{
        this.loading = false;
        this.toastr.error(error.message, "Error");
      }
    )
    }
  }

  verificar = {
    serial:null
  }



  deleteempleado(id,serial){
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
     
        this.loading = true;
        this.empleado.deleteUsuariosCompania(this.form).subscribe(
          data => {
            this.toastr.success("Se ha eliminado exitosamente el empleado", "Eliminar empleado")
            this.loading = false;
          this.handleResponse(data)},
          error => {this.loading = false; this.toastr.error(error.message, 'Error en el proceso')}
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })    
  }
  
  cerrar(){

  }

  handleResponse2(data) {
    if(data.mensaje){
      this.toastr.info(data.mensaje, 'No se han encontrado usuarios aún');
    } else {
      this.view = 'lista';
      // this.toastr.success("Se han actualizado tus usuarios en el sistema", "Mensaje")
      this.empleados = data.users;
    }
  }

  handleResponse(data) {
    if(data.mensaje){
      this.toastr.error(data.mensaje, 'No se puede crear el usuario');
    } else {
      this.form.name = "";
      this.form.name = "";
      this.form.password = ""
      this.form.password_confirmation = ""
      this.view = 'lista';
      this.toastr.success("Se han actualizado tus usuarios en el sistema", "Mensaje")
      this.empleados = data.users;
    }
  }

  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      // 
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // 
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      
    }
  }

  serialVerify() {

    // this.empleado.serial(this.verificar).subscribe(data => {
      // this.alerts(data);
    // })
  }
  sedes;

  constructor(
    private sede:SedesService,
    private toastr: ToastrService,
    private empleado: UserService, private Token: TokenService) { }
  ngOnInit(): void {
    this.empleado.getUsuariosCompania(this.form).subscribe(
      data => this.handleResponse2(data),
      error => console.log(error)
    )
    this.sede.getSedes(this.options).subscribe(
      data => {
        this.sedes = data['sedes'];
      }, error => {
        console.log(error);
      }
    )
  }

  activarUsuario(email,estado){
    this.form.email = email;
    this.form.Estado = estado;
      this.empleado.activarUsuario(this.form).subscribe(
         data=> this.handleResponse2(data),
         error => {
            console.log(error)
         }
      )
  }

  cedula(){

  }


  // tittle = ""
  detailempleado:any;
  sidebaractive = "ficha";
  p = 1;
}
