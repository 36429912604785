<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view='lista'">Maquinas</li>
                                <li class="breadcrumb-item active">Detalle de la maquina</li>
                                <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a routerLink="/maquinaria-industrial" class="btn btn-primary text-white">Volver</a>
                        <a href="https://quar.com.co/api/excel/mantenimientos/{{form.id}}" class="btn btn-danger text-white">Exportar historial de
                            mantenimiento</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div *ngIf="view!='lista'" class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro de activo</h4>
                                    <h5 class="card-subtitle" *ngIf="view=='serial'">Asignar Serial</h5>
                                    <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario registro de impresora
                                    </h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <form *ngIf="view=='registro'" class="content-registro__container__form"
                                        #impresorasForm="ngForm" (ngSubmit)="onSubmit()">
                                        <div class="col-md-12 container__datos_generales">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <h2>¿Estás listo? ...Arranquemos</h2>
                                                    <div class="progress" style="height:4px">
                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                            role="progressbar" aria-valuenow="10" aria-valuemin="0"
                                                            aria-valuemax="100" style="width: 10%">
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <h4>Datos Generales</h4>
                                                    <h4>Información de maquina</h4>

                                                </div>


                                                <div class="row">
                                                    <input type="hidden" id="serial" name="codigo">
                                                    <div class="col-md-4">
                                                        <label>Nombre del Activo</label>
                                                        <input type="text" class="form-control" name="NombreActivo"
                                                            [(ngModel)]="form.NombreActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Fabricante</label>
                                                        <input type="text" class="form-control" name="Marca"
                                                            [(ngModel)]="form.Marca">

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Propietario
                                                        </label>
                                                        <input type="text" class="form-control" name="Modelo"
                                                            [(ngModel)]="form.Modelo">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tipo de maquina</label>
                                                        <input class="form-control" name="TipoDispositivo"
                                                            [(ngModel)]="form.TipoDispositivo" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Serial</label>
                                                        <input type="text" class="form-control" name="Serial"
                                                            [(ngModel)]="form.Serial">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Fecha compra</label>
                                                        <input type="date" class="form-control" name="FechaCompra"
                                                            [(ngModel)]="form.FechaCompra">
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label>Número Factura</label>
                                                        <input type="text" class="form-control" name="NumeroFactura"
                                                            [(ngModel)]="form.NumeroFactura">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Valor del Activo</label>
                                                        <input type="text" class="form-control" name="ValorActivo"
                                                            [(ngModel)]="form.ValorActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número de Póliza</label>
                                                        <input type="text" class="form-control" name="NumeroPoliza"
                                                            [(ngModel)]="form.NumeroPoliza">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Contacto soporte</label>
                                                        <input type="text" class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.ContactoSoporte" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número soporte</label>
                                                        <input class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.NumeroSoporte" />
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.Observaciones"></textarea>
                                                    </div>
                                                    <div class="col-md-12 card" style="padding:2em;">
                                                        <h1>Tipo y clase de mantenimiento</h1>
                                                        <div class="row">
                                                            <div class="col-md-6">

                                                                <label>Ciclo de mantenimiento general</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.CiclosMantenimiento"
                                                                    class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.ProximoMantenimiento">
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento lubricación</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.Lubricacion" class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.LubricacionFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento mecánico</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.Mecanico" class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.MecanicoFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento eléctrico</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.Electrico" class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.ElectricoFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento electromecánico</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.ElectroMecanico"
                                                                    class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.ElectroMecanicoFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary">Registrar Maquina</button>
                                        </div>
                                    </form>
                                    <div class="content-serial" *ngIf="view == 'serial'">

                                        <div class="container">
                                            <div class="form group">
                                                <input type="text" placeholder="Serial del código" class="form-control"
                                                    name="Codigo" [(ngModel)]="form.Codigo">
                                            </div>
                                            <br>
                                            <div class="form-group">
                                                <button class="btn btn-danger cell" (click)="serialVerify()">Verificar
                                                    serial</button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a
                                                    Dispositivos</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button class="btn btn-primary cell" (click)="view='registro'">Quiero
                                                    continuar sin serial</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-md-8" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Maquinaria industrial</h4>
                                    <h5 class="card-subtitle">Información de maquinas</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">

                                    </div>
                                </div>
                            </div>
                            <!-- title -->


                            <div ([ngModel])="maquinaria" class="row"
                                *ngFor="let item of maquinaria | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">

                                <div class="col-md-6">
                                    <label for="">Codigo de identificación</label>
                                    <input type="text" class="form-control" value="{{item.Serial}}" disabled>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Nombre de Activo </label>
                                    <input type="text" class="form-control" value="{{item.NombreActivo}}" disabled>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Fabricante</label>
                                    <input type="text" value="{{item.Marca}}" class="form-control" disabled>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Propietario</label>
                                    <input type="text" value="{{item.Modelo}}" class="form-control" disabled>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Fecha Compra</label>
                                    <input type="text" value="{{item.FechaCompra}}" class="form-control" disabled>
                                </div>

                                <div class="col-md-6">
                                    <label for="">Fecha proximo mantenimiento</label>
                                    <input type="text" value="{{item.ProximoMantenimiento}}" disabled
                                        class="form-control">
                                </div>

                                <div class="col-md-12">
                                    <br>
                                    <!-- <button (click)="editar(item.id,
                                                item.NombreActivo,
                                                item.TipoDispositivo,
                                                item.ContactoSoporte,
                                                item.NumeroSoporte,
                                                item.Marca,
                                                item.Modelo,
                                                item.Serial,
                                                item.FechaCompra,
                                                item.NumeroFactura,
                                                item.ValorActivo,
                                                item.NumeroPoliza,
                                                item.Observaciones,
                                                item.CiclosMantenimiento,
                                                item.ProximoMantenimiento,
                                                item.Lubricacion,item.LubricacionFecha,
                                                item.Mecanico,
                                                item.MecanicoFecha,
                                                item.Electrico,
                                                item.ElectricoFecha,
                                                item.ElectroMecanico,
                                                item.ElectroMecanicoFecha
                                                )" type="button" data-toggle="modal" data-target="#editarMaquina"
                                        class="btn btn-primary cell" style="color:white"><i class="icon-edit2"></i>
                                        Editar</button> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Fechas de Mantenimientos</h4>
                                    <!-- <h5 class="card-subtitle">Lista proximo mantenimiento</h5> -->
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">

                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <div class="feed-widget">
                                <ul ([ngModel])="maquinaria" class="row"
                                    *ngFor="let item of maquinaria | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term"
                                    class="list-style-none feed-body m-0 p-b-20">
                                    <li class="feed-item">
                                        <div class="feed-icon bg-info"><i class="ti-user"></i></div>Mantenimiento
                                        general
                                        <span class="ml-auto font-12 text-muted">{{item.MecanicoFecha}}</span>
                                    </li>
                                    <li class="feed-item">
                                        <div class="feed-icon bg-info"><i class="ti-user"></i></div>Mantenimiento
                                        mecánico
                                        <span class="ml-auto font-12 text-muted">{{item.MecanicoFecha}}</span>
                                    </li>
                                    <li class="feed-item">
                                        <div class="feed-icon bg-info"><i class="ti-user"></i></div>Mantenimiento
                                        lubricación
                                        <span class="ml-auto font-12 text-muted">{{item.LubricacionFecha}}</span>
                                    </li>
                                    <li class="feed-item">
                                        <div class="feed-icon bg-info"><i class="ti-user"></i></div> Mantenimiento
                                        eléctrico
                                        <span class="ml-auto font-12 text-muted">{{item.ElectricoFecha}}</span>
                                    </li>
                                    <li class="feed-item">
                                        <div class="feed-icon bg-info"><i class="ti-user"></i></div> Mantenimiento
                                        electromecánico
                                        <span class="ml-auto font-12 text-muted">{{item.ElectroMecanicoFecha}}</span>
                                    </li>
                                    <!-- <button (click)="editar(item.id,
                                    item.NombreActivo,
                                    item.TipoDispositivo,
                                    item.ContactoSoporte,
                                    item.NumeroSoporte,
                                    item.Marca,
                                    item.Modelo,
                                    item.Serial,
                                    item.FechaCompra,
                                    item.NumeroFactura,
                                    item.ValorActivo,
                                    item.NumeroPoliza,
                                    item.Observaciones,
                                    item.CiclosMantenimiento,
                                    item.ProximoMantenimiento,
                                    item.Lubricacion,item.LubricacionFecha,
                                    item.Mecanico,
                                    item.MecanicoFecha,
                                    item.Electrico,
                                    item.ElectricoFecha,
                                    item.ElectroMecanico,
                                    item.ElectroMecanicoFecha
                                    )" class="cell btn btn-danger" type="button" data-toggle="modal"
                                        data-target="#maquininfo">Editar fechas</button> -->

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- column -->
                <div class="col-md-12" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Historial de mantenimientos</h4>
                                    <h5 class="card-subtitle">Lista</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">

                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <div class="form-gorup">
                                <input type="text" class="form-control" placeholder="Buscar mantenimiento" name="term"
                                    [(ngModel)]="term">
                                <br>
                            </div>
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Fecha Mantenimiento</th>
                                        <th class="border-top-0">Descripción</th>
                                        <th class="border-top-0">Registrado por</th>
                                        <th class="border-top-0">Tipo Mantenimiento</th>
                                        <th class="border-top-0">Clase Mantenimiento</th>
                                        <th class="border-top-0">Repuestos cambiados</th>
                                        <th class="border-top-0">Valor de repuestos</th>
                                    </tr>
                                </thead>
                                <tbody class="scroll">

                                    <ng-container *ngFor="let item of mantenimientosactivo| filter:term">
                                        <tr>
                                            <th>{{item.FechaMantenimiento}}</th>
                                            <td>{{item.Descripcion}}</td>
                                            <td>{{item.nombre}}</td>
                                            <td>{{item.TipoMantenimiento}}</td>
                                            <td>{{item.ClaseMantenimiento}}</td>
                                            <td>{{item.RepuestosCambiados}}</td>
                                            <td>{{item.ValorMantenimiento}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>









<div class="modal fade" id="editarMaquina" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Editar maquina</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="content-registro__container__form" #impresorasForm="ngForm" (ngSubmit)="onSubmitEdit()">
                    <div class="col-md-12 container__datos_generales">
                        <div class="row">


                            <!-- {{form.id}} -->
                            <div class="row">
                                <input type="hidden" id="serial" name="codigo">
                                <div class="col-md-6">
                                    <label>Nombre del Activo</label>
                                    <input type="text" class="form-control" name="NombreActivo"
                                        [(ngModel)]="form.NombreActivo">
                                </div>
                                <div class="col-md-6">
                                    <label>Fabricante</label>
                                    <input type="text" class="form-control" name="Marca" [(ngModel)]="form.Marca">

                                </div>
                                <div class="col-md-6">
                                    <label>Propietario
                                    </label>
                                    <input type="text" class="form-control" name="Modelo" [(ngModel)]="form.Modelo">
                                </div>

                                <div class="col-md-6">
                                    <label>Tipo de maquina</label>
                                    <input class="form-control" name="TipoDispositivo"
                                        [(ngModel)]="form.TipoDispositivo" />
                                </div>
                                <div class="col-md-6">
                                    <label>Serial</label>
                                    <input type="text" class="form-control" name="Serial" [(ngModel)]="form.Serial">
                                </div>
                                <div class="col-md-6">
                                    <label>Fecha compra</label>
                                    <input type="date" class="form-control" name="FechaCompra"
                                        [(ngModel)]="form.FechaCompra">
                                </div>

                                <div class="col-md-6">
                                    <label>Número Factura</label>
                                    <input type="text" class="form-control" name="NumeroFactura"
                                        [(ngModel)]="form.NumeroFactura">
                                </div>
                                <div class="col-md-6">
                                    <label>Valor del Activo</label>
                                    <input type="text" class="form-control" name="ValorActivo"
                                        [(ngModel)]="form.ValorActivo">
                                </div>
                                <div class="col-md-6">
                                    <label>Número de Póliza</label>
                                    <input type="text" class="form-control" name="NumeroPoliza"
                                        [(ngModel)]="form.NumeroPoliza">
                                </div>
                                <div class="col-md-6">
                                    <label>Contacto soporte</label>
                                    <input type="text" class="form-control" name="Observaciones"
                                        [(ngModel)]="form.ContactoSoporte" />
                                </div>
                                <div class="col-md-6">
                                    <label>Número soporte</label>
                                    <input class="form-control" name="Observaciones" [(ngModel)]="form.NumeroSoporte" />
                                </div>
                                <div class="col-md-12">
                                    <label>Observaciones</label>
                                    <textarea class="form-control" name="Observaciones"
                                        [(ngModel)]="form.Observaciones"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <br>
                                </div>
                                <!-- {{form | json}} -->
                                <br>
                            </div>
                        </div>
                        <br>

                        <button type="submit" class="btn btn-primary cell">Actualizar Maquina</button>
                        <button type="button" class="btn btn-secondary cell" data-dismiss="modal">Cancelar</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="maquininfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información maquina {{form.Codigo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="content-registro__container__form" #impresorasForm="ngForm" (ngSubmit)="onSubmitEdit()">
                    <div class="col-md-12 container__datos_generales">
                        <div class="row">

                            <!-- {{form.id}} -->
                            <div>
                                <h1>Tipo y clase de mantenimiento</h1>
                                <div class="row">
                                    <div class="col-md-6">

                                        <label>Ciclo de mantenimiento general</label>
                                        <select required id="Ciclo" name="CicloMantenimiento"
                                            [(ngModel)]="form.CiclosMantenimiento" class="form-control">
                                            <option value="" disabled selected>Selecciona una opción
                                            </option>
                                            <option value="1">MENSUAL</option>
                                            <option value="2">BIMESTRAL</option>
                                            <option value="3">TRIMESTRAL</option>
                                            <option value="4">SEMESTRAL</option>
                                            <option value="5">ANUAL</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Proximo Mantenimiento</label>
                                        <input type="date" class="form-control" name="ProximoMantenimiento"
                                            [(ngModel)]="form.ProximoMantenimiento">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Ciclo de mantenimiento lubricación</label>
                                        <select required id="Ciclo" name="CicloMantenimiento"
                                            [(ngModel)]="form.Lubricacion" class="form-control">
                                            <option value="" disabled selected>Selecciona una opción
                                            </option>
                                            <option value="1">MENSUAL</option>
                                            <option value="2">BIMESTRAL</option>
                                            <option value="3">TRIMESTRAL</option>
                                            <option value="4">SEMESTRAL</option>
                                            <option value="5">ANUAL</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Proximo Mantenimiento</label>
                                        <input type="date" class="form-control" name="ProximoMantenimiento"
                                            [(ngModel)]="form.LubricacionFecha">
                                    </div>
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Ciclo de mantenimiento mecánico</label>
                                        <select required id="Ciclo" name="Mecanico" [(ngModel)]="form.Mecanico"
                                            class="form-control">
                                            <option value="" disabled selected>Selecciona una opción
                                            </option>
                                            <option value="1">MENSUAL</option>
                                            <option value="2">BIMESTRAL</option>
                                            <option value="3">TRIMESTRAL</option>
                                            <option value="4">SEMESTRAL</option>
                                            <option value="5">ANUAL</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Proximo Mantenimiento</label>
                                        <input type="date" class="form-control" name="MecanicoFecha"
                                            [(ngModel)]="form.MecanicoFecha">
                                    </div>
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Ciclo de mantenimiento eléctrico</label>
                                        <select required id="Ciclo" name="Electrico" [(ngModel)]="form.Electrico"
                                            class="form-control">
                                            <option value="" disabled selected>Selecciona una opción
                                            </option>
                                            <option value="1">MENSUAL</option>
                                            <option value="2">BIMESTRAL</option>
                                            <option value="3">TRIMESTRAL</option>
                                            <option value="4">SEMESTRAL</option>
                                            <option value="5">ANUAL</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Proximo Mantenimiento</label>
                                        <input type="date" class="form-control" name="ElectricoFecha"
                                            [(ngModel)]="form.ElectricoFecha">
                                    </div>
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Ciclo de mantenimiento electromecánico</label>
                                        <select required id="Ciclo" name="ElectroMecanico"
                                            [(ngModel)]="form.ElectroMecanico" class="form-control">
                                            <option value="" disabled selected>Selecciona una opción
                                            </option>
                                            <option value="1">MENSUAL</option>
                                            <option value="2">BIMESTRAL</option>
                                            <option value="3">TRIMESTRAL</option>
                                            <option value="4">SEMESTRAL</option>
                                            <option value="5">ANUAL</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Proximo Mantenimiento</label>
                                        <input type="date" class="form-control" name="ElectroMecanicoFecha"
                                            [(ngModel)]="form.ElectroMecanicoFecha">
                                    </div>
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>

                        <button type="submit" class="btn btn-secondary cell" data-dismiss="modal">Cerrar
                            información</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
