<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{title}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view = 'lista'" aria-current="page">Unidad
                                    de negocio</li>
                                <li class="breadcrumb-item active" *ngIf="view == 'registro'" aria-current="page">Crear
                                    unidad</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <div class="dropdown" *ngIf="view == 'lista'">
                            <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Exportar puntos de referencia
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item"
                                    href="http://qaractivo.local/api/excel/referencias/hoy/{{idUser}}">Hoy</a>
                                <!-- <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal"
                                        data-target="#rangoFechas">Rango de fecha</a> -->
                                <a class="dropdown-item"
                                    href="http://qaractivo.local/api/excel/referencias/{{idUser}}">Todas las
                                    observaciones</a>
                            </div>
                            <button (click)="view ='registro'" class="btn btn-secondary">Agregar unidad de
                                negocio</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->

            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Unidadades de negocio</h4>
                                    <h5 class="card-subtitle">Lista</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl row">
                                        <div class="form-group">
                                            <label for="">Mostrar</label>
                                            <select class="form-control" (change)="mostrar($event)" id="">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                            </select>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="">Buscar unidad</label><input type="search"
                                                    [(ngModel)]="term" (keydown)="filtrar($event)" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form *ngIf="view == 'registro'" class="row" #updateForm=ngForm (ngSubmit)="onSubmit()">
                                <hr>
                                <div class="from-group col-md-12">
                                    <h5 for="">Sube una foto</h5>
                                    <div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                                        <input (change)="onChange($event)" type="file" id="fichero-tarifas"
                                            class="input-file" value="">
                                        Seleccionar foto...
                                    </div>
                                </div>
                                <div class="col-md-12 text-center" *ngIf="src">
                                    <p>Archivo seleccionado:</p>
                                    <!-- {{archivo | json}} -->
                                    <img style="width: 150px;" src="{{src}}" alt="">
                                    {{archivo.nombreArchivo}}
                                </div>
                                <div class="from-group col-md-12">
                                    <label for="">Tienes un serial?</label>
                                    <input (change)="serialVerify()" name="Serial" [(ngModel)]="form.Serial" type="text"
                                        class="form-control">
                                    <br>
                                </div>

                                <div class="from-group col-md-12">

                                    <!-- <br> -->
                                    <label for="">Nombre unidad</label>
                                    <input type="text" name="unidad" [(ngModel)]="form.NombreUnidad"
                                        class="form-control">
                                </div>
                                <div class="from-group col-md-12">
                                    <label for="">NIT</label>
                                    <input type="text" name="unidad" [(ngModel)]="form.NIT" class="form-control">
                                </div>
                                <div class="from-group col-md-12">
                                <fieldset class="from-group scheduler-border col-md-12">
                                    <legend class="scheduler-border">Datos de administrador</legend>
                                        <div class="from-group col-md-12">
                                            <label for="">Administrador</label>
                                            <input type="text" name="admin" [(ngModel)]="form.Administrador"
                                                class="form-control">
                                        </div>
                                        <div class="from-group col-md-12">
                                            <label for="">Correo electrónico administrador</label>
                                            <input type="text" name="admin" [(ngModel)]="form.Correo"
                                                class="form-control">
                                        </div>
                                        <div class="from-group col-md-12">
                                            <label for="">Teléfono</label>
                                            <input type="text" name="telefono" [(ngModel)]="form.Telefono" class="form-control">
                                        </div>
                                </fieldset></div>

                                <div class="form-group col-md-12 text-center register_form">
                                    <br>
                                    <button type="submit" class="cell btn btn-primary">REGISTRAR</button>
                                    <!-- <button type="button" routerLink="/login" class="btn btn-secondary">Ya tengo cuenta</button> -->
                                </div>
                            </form>

                            <hr>
                            <div *ngIf="view == 'lista'" class="table-responsive">
                                <table class="table v-middle">
                                    <thead>
                                        <tr class="bg-light">
                                            <th class="border-top-0">Código Serial</th>
                                            <th class="border-top-0">Foto</th>
                                            <th class="border-top-0">Nombre</th>
                                            <th class="border-top-0">Nit</th>
                                            <th class="border-top-0">Administrador</th>
                                            <th class="border-top-0">Teléfono</th>
                                            <th class="border-top-0">Acciones</th>

                                        </tr>
                                    </thead>
                                    <tbody class="scroll">

                                        <ng-container
                                            *ngFor="let item of unidades | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                            <tr>
                                                <th routerLink="/unidad-negocio/{{item.IdUnidad}}" scope="row">
                                                    {{item.Serial}}</th>
                                                <td style="cursor: pointer;">
                                                    <img (click)="srcUpdate(item.Foto)" data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        src="https://quar-vigilantes.s3.us-east-2.amazonaws.com/{{idUser}}/{{item.Foto}}"
                                                        style="width:120px" alt="">
                                                    <img (click)="srcUpdate(item.Foto)" data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        src="http://qaractivo.local/files/temp/{{item.Foto}}"
                                                        style="width:120px" alt="">
                                                </td>
                                                <td routerLink="/unidad-negocio/{{item.IdUnidad}}">{{item.NombreUnidad}}
                                                </td>
                                                <td routerLink="/unidad-negocio/{{item.IdUnidad}}">{{item.NIT}}</td>
                                                <td routerLink="/unidad-negocio/{{item.IdUnidad}}">
                                                    {{item.Administrador}}</td>
                                                <td routerLink="/unidad-negocio/{{item.IdUnidad}}">{{item.Telefono}}
                                                </td>
                                                <td>
                                                    <button data-toggle="modal" data-target="#staticBackdrop"
                                                        (click)="detail(item.IdUnidad,item.NombreUnidad, item.NIT,item.Serial, item.Administrador,item.Telefono,item.Foto,item.Correo)"
                                                        class="btn btn-primary">EDITAR</button>
                                                        <!-- <button 
                                                        (click)="deleteUnidad(item.IdUnidad,item.Serial)"
                                                        class="btn btn-danger">ELIMINAR</button> -->
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="panel-activos__body__pagination text-center">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co ">Quar</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>






<!-- Modal -->




<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Actualización de unidad de
                    negocio</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="row" #updateForm=ngForm (ngSubmit)="onSubmit()">

                    <div class="from-group col-md-12">
                        <img src="http://qaractivo.local/files/temp/{{form.Foto}}" alt="">
                        <h5 for="">Sube una foto</h5>

                        <div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                            <input (change)="onChange($event)" type="file" id="fichero-tarifas" class="input-file"
                                value="">
                            Seleccionar foto...
                        </div>
                    </div>
                    <div class="col-md-12 text-center" *ngIf="src">
                        <p>Archivo seleccionado:</p>
                        <!-- {{archivo | json}} -->
                        <img style="width: 150px;" src="{{src}}" alt="">
                    </div>
                    <div class="from-group col-md-12">

                        <!-- <br> -->
                        <label for="">Nombre unidad</label>
                        <input type="text" name="unidad" [(ngModel)]="form.NombreUnidad" class="form-control">
                    </div>
                    <div class="from-group col-md-12">
                        <label for="">NIT</label>
                        <input type="text" name="NIT" [(ngModel)]="form.NIT" class="form-control">
                    </div>
                    <div class="from-group col-md-12">
                        <label for="">Administrador</label>
                        <input type="text" name="admin" [(ngModel)]="form.Administrador" class="form-control">
                    </div>
                    <div class="from-group col-md-12">
                        <label for="">Correo electrónico administrador</label>
                        <input type="text" name="Correo" [(ngModel)]="form.Correo" class="form-control">
                    </div>
                    <div class="from-group col-md-12">
                        <label for="">Teléfono</label>
                        <input type="text" name="telefono" [(ngModel)]="form.Telefono" class="form-control">
                    </div>
                    <div class="form-group col-md-12 text-center register_form">
                        <button type="submit" class="btn btn-primary" class="cell btn btn-primary">Actualizar</button>
                        <button (click)="format()" type="button" class="btn btn-secondary"
                            data-dismiss="modal">Cancelar</button>
                        <!-- <button type="button" routerLink="/login" class="btn btn-secondary">Ya tengo cuenta</button> -->
                    </div>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>



<!-- Modal -->
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Imagen de detalle</h5>
                <button (click)="src = ''" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- {{src}} -->
            <div class="modal-body">
                <img src="{{src}}" alt="" style="width: 100%;">
                <img src="{{src2}}" style="width: 100%;" alt="">
            </div>
            <div class="modal-footer">
                <button type="button" (click)="src = ''" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- </div> -->