import { ToastrService } from 'ngx-toastr';
import { ComputadorService } from './../../Services/Activos/computador.service';
import { TokenService } from './../../Services/token.service';
import { UserService } from './../../Services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
@Component({
  selector: 'app-editarcomputador',
  templateUrl: './editarcomputador.component.html',
  styleUrls: ['./editarcomputador.component.scss']
})
export class EditarcomputadorComponent implements OnInit {
  loading = false;
  computador:any;
  mantenimientosactivo:any;
  sidebaractive:string = 'ficha';
  filesToUpload: Array<File> = [];
  
  detail = {
    id_user:this.Token.getUserId(),
    serial: null,
    tipoPC:null,
    tipoMemoria:null

  };
  detailactivo:any;
  p: number = 1;
  tittle: string = "gestión de computadores";
  idActivo: number;
  serial: string;
  mensaje:string;

  pasos = "uno";

  licencias:any;
  tipocomputador:any;
  ciclosmantenimiento:any;
  sistemaoperativo:any;
  tipomemorias:any;

  view:string = 'lista';
  tickets: number;
  dataActivo: any;


  discos = {
    IdComputador: this.Token.getUserId(),
    TipoDisco: "REGIDO",
    serial: this.route.snapshot.paramMap.get("serial"),
    CapacidadDisco: "0"
  }
  discodata = {
    id_user: this.Token.getUserId(),
    tipo: "editar",
    serial: this.route.snapshot.paramMap.get("serial"),
    id:null
  }
  discosarray: any;
  getDiscos(data) {
    console.log(data)
    this.discosarray = data.discos;
  }

  eliminardisco(id){
      this.discodata.id = id;
      console.log(this.discodata);
      this.activo.discodelete(this.discodata).subscribe(
        data => this.getDiscos(data),
        error => {
          this.toastr.error("Ha ocurrido un error", "No se ha podido eliminar el disco");
        }
      )
  }
  addDisk() {
    if (this.discos.CapacidadDisco == "0" || this.discos.CapacidadDisco == null) {
      this.toastr.info("Campos vacios", 'Debes agregar mínimo 1 en la cantidad');
    } else {

      console.log(this.discos);
      this.activo.discos(this.discos).subscribe(
        data => {
          this.toastr.success("Registro de discos", "Se registro el disco exitosamente");
          this.getDiscos(data);
        }
      )
    }
  }



 
  public form = {
    id_user:this.Token.getUserId(),
    NombreActivo:null,
    NumeroFactura:null,
    FechaCompra:null,
    NumeroPoliza:null,
    Valor:null,
    Marca:null,
    Modelo:null,
    Serial:null,
    idTipoPc:1,
    Procesador:null,
    TamanoPantalla:null,
    idTipoMemoria:null,
    CantidadModulos:null,
    CantMemoria1:null,
    CantMemoria2:null,
    CantMemoria3:null,
    CantMemoria4:null,
    IdCicloMantenimiento:1,
    ProximoMantenimiento	:null,
    FechaUltimoMantenimiento	:null,
    Codigo	:null,
    Red_Nombre_PC	:null,
    MAC	:null,
    IP	:null,
    Password:null,
    Puerta_de_Enlace:null,
    IdComputador:null,
    IdSistemaOperativo:11,
    VersionOs:null,
    IdTipoLicenciaOs:4,
    NumeroLicenciaOs:null,
    IdTipoOffice:null,
    IdLicenciaOffice:null,
    NumeroLicenciaOffice:null,
    FechaVenOffce:null,
    Antivirus:null,
    OtrosProgrmas:null,
    Office:null,
    VencimientoAntivirus:null
  }

  data = {
    id: this.Token.getUserId(),
    serial: this.route.snapshot.paramMap.get("serial")
  }

  handleResponse(data){
    console.log(data);
      this.form.id_user=this.Token.getUserId();
      this.form.NombreActivo= data.activo[0].NombreActivo;
      this.form.NumeroFactura=data.activo[0].NumeroFactura;
      this.form.FechaCompra=data.activo[0].FechaCompra;
      this.form.NumeroPoliza=data.activo[0].NumeroPoliza;
      this.form.Marca=data.activo[0].Marca;
      this.form.Modelo=data.activo[0].Modelo;
      this.form.Serial=data.activo[0].Serial;
      this.form.idTipoPc=data.activo[0].idTipoPc;
      this.form.Procesador=data.activo[0].Procesador;
      this.form.TamanoPantalla=data.activo[0].TamanoPantalla;
      this.form.idTipoMemoria=data.activo[0].idTipoMemoria;
      this.form.CantidadModulos=data.activo[0].CantidadModulos;
      this.form.CantMemoria1=data.activo[0].CantMemoria1;
      this.form.CantMemoria2=data.activo[0].CantMemoria2;
      this.form.CantMemoria3=data.activo[0].CantMemoria3;
      this.form.CantMemoria4=data.activo[0].CantMemoria4;
      this.form.IdCicloMantenimiento=data.activo[0].IdCicloMantenimiento;
      this.form.ProximoMantenimiento	=data.activo[0].ProximoMantenimiento	;
      this.form.FechaUltimoMantenimiento	=data.activo[0].FechaUltimoMantenimiento	;
      this.form.Codigo	=data.activo[0].Codigo	;
      this.form.Red_Nombre_PC	=data.activo[0].Red_Nombre_PC	;
      this.form.MAC	=data.activo[0].MAC	;
      this.form.IP	=data.activo[0].IP	;
      this.form.Valor	=data.activo[0].Valor	;
      this.form.Password=data.activo[0].Password;
      this.form.Puerta_de_Enlace=data.activo[0].Puerta_de_Enlace;
      this.form.IdComputador=data.softwaredetail[0].IdComputador;
      this.form.IdSistemaOperativo=data.softwaredetail[0].IdSistemaOperativo;
      this.form.VersionOs=data.softwaredetail[0].VersionOs;
      this.form.IdTipoLicenciaOs=4;
      this.form.NumeroLicenciaOs=data.softwaredetail[0].NumeroLicenciaOs;
      this.form.IdTipoOffice=data.softwaredetail[0].IdTipoOffice;
      this.form.IdLicenciaOffice=data.softwaredetail[0].IdLicenciaOffice;
      this.form.NumeroLicenciaOffice=data.softwaredetail[0].NumeroLicenciaOffice;
      this.form.FechaVenOffce=data.softwaredetail[0].FechaVenOffce;
      this.form.Antivirus=data.softwaredetail[0].Antivirus;
      this.form.OtrosProgrmas=data.softwaredetail[0].OtrosProgrmas;
      this.form.Office=data.softwaredetail[0].Office;
      this.form.VencimientoAntivirus=data.softwaredetail[0].VencimientoAntivirus;
    
    
  }


  
  
  onSubmit(){
    this.loading = true;
    // alert(this.form.IdSistemaOperativo)
    
    // console.log(submit);
    // console.log(this.form)
        this.activo.actualizar(this.form).subscribe(
          data => {
            console.log(data)
            this.toastr.success("Edición de activo", "Edición de activos exitosa")
            this.router.navigate(['/computadores']);
          },
          error => {this.loading = false}
        );
        
  }

  computadores(data) {
    console.log(data);
    this.computador = data.activo;
    this.licencias = data.licencias;
    this.tipocomputador = data.tipopc;
    this.ciclosmantenimiento = data.ciclosmantenimientos;
    this.sistemaoperativo = data.sistemaoperativos;
    this.tipomemorias = data.tipomemorias;
  }

  constructor(private router:Router,private user: UserService,private Token:TokenService, private route:ActivatedRoute, private activo: ComputadorService,
  private toastr: ToastrService) { }

  ngOnInit() {
    this.activo.discosActivo(this.data).subscribe(data => this.getDiscos(data));
      this.activo.detail(this.data).subscribe(
        data => this.handleResponse(data),
        error => console.log(error)
      );
      this.user.computadorModule(this.data).subscribe(
        data => this.computadores(data),
        erro => console.log(erro)
      );
  }

}
