<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Mi Cuenta</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->

            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Crear Mantenimiento</h4>
                                    <h5 class="card-subtitle">Mantenimiento para maquina</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <button (click)="volver()" class="btn btn-primary">Volver</button>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                            <div class="content-r">
                                <!-- Aqui va el contendio -->
                                <div class="row">

                                    <!-- Area Chart -->
                                    <div class="col-lg-12">
                                        <div class="card shadow mb-4">
                                            <!-- Card Header - Dropdown -->
                                            <!-- Card Body -->
                                            <div class="card-body">
                                                <script
                                                    src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>

                                                <script>
                                                    jQuery(document).ready(function () {
                                                        var now = new Date();

                                                        var day = ("0" + now.getDate()).slice(-2);
                                                        var month = ("0" + (now.getMonth() + 1)).slice(-2);

                                                        var today = now.getFullYear() + "-" + (month) + "-" + (day);
                                                        $("#fecha").val(today);
                                                    });
                                                </script>
                                                <div class="">

                                                    <form #mantenimientoForm (ngSubmit)="onSubmit()">
                                                        <div class="">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="fecha">Fecha del mantenimiento
                                                                        </label>
                                                                        <input disabled name="FechaMantenimiento"
                                                                            [(ngModel)]="form.FechaMantenimiento"
                                                                            type="date" id="fecha" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="fecha">Tipo de Mantenimiento
                                                                        </label>
                                                                        <select name="TipoMantenimiento"
                                                                            [(ngModel)]="form.TipoMantenimiento"
                                                                            class="form-control" id="validate">
                                                                            <option value="Preventivo">Preventivo
                                                                            </option>
                                                                            <option value="Correctivo">Correctivo
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="fecha">Clase de Mantenimiento
                                                                        </label>
                                                                        <select name="ClaseMantenimiento"
                                                                            [(ngModel)]="form.ClaseMantenimiento"
                                                                            class="form-control" id="validate">
                                                                            <option value="General">General
                                                                            </option>
                                                                            <option value="Eléctrico">Eléctrico
                                                                            </option>
                                                                            <option value="Lubricación">Lubricación
                                                                            </option>
                                                                            <option value="Electomecánico">
                                                                                Electomecánico
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="fecha">Repuestos cambiados
                                                                        </label>
                                                                        <textarea name="RepuestosCambiados"
                                                                            [(ngModel)]="form.RepuestosCambiados"
                                                                            type="text" class="form-control"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="fecha">Valor de los repuestos e
                                                                            insumos del mantenimiento
                                                                        </label>
                                                                        <input name="ValorMantenimiento"
                                                                            [(ngModel)]="form.ValorMantenimiento"
                                                                            type="text" class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="fecha">Descripcion del mantenimiento
                                                            </label>
                                                            <textarea required class="form-control" name="Descripcion"
                                                                [(ngModel)]="form.Descripcion"></textarea>
                                                        </div>
                                                        <ng-container *ngFor="let idac of activo">
                                                            <input type="hidden" name="IdActivo"
                                                                [(ngModel)]="form.IdActivo">
                                                            <input type="hidden" name="ciclo" value="{{idac.ciclo}}"
                                                                [(ngModel)]="form.ciclo">
                                                        </ng-container>
                                                        <input type="hidden" id="codigo" name="Codigo"
                                                            value="{{user.serial}}" [(ngModel)]="form.Codigo">
                                                        <input type="hidden" id="Administrador" name="IdUsuarioSoporte"
                                                            value="{{user.id_user}}"
                                                            [(ngModel)]="form.IdUsuarioSoporte">
                                                        <ng-container *ngFor="let ac of users">
                                                            <input type="hidden" id="Administrador"
                                                                name="UsuarioAdministrador" value="{{ac.id_user}}"
                                                                [(ngModel)]="form.UsuarioAdministrador">
                                                        </ng-container>
                                                        <button type="submit" class="btn btn-outline-primary">Registrar
                                                            mantenimiento</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>