<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5"
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active">Dispositivos</li>
                                <li class="breadcrumb-item active">Editar Dispositivo</li>
                                <!-- <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li> -->
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                   
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Actualizar activo</h4>

                                    <h5 class="card-subtitle">Información dispositivo</h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <form class="content-registro__container__form" #dispositivosForm="ngForm" (ngSubmit)="onSubmit()">
                                        <div class="col-md-12 container__datos_generales">
                                            <div class="row">
                                                
                                                <div class="row">
                                                    <input type="hidden" id="serial" name="codigo">
                                                    <div class="col-md-4">
                                                        <label>Nombre del Activo</label>
                                                        <input type="text" class="form-control" name="NombreActivo" [(ngModel)]="form.NombreActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tipo de dispositivo</label>
                                                        <input type="text" class="form-control" name="TipoDispositivo" [(ngModel)]="form.TipoDispositivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Marca</label>
                                                        <input type="text" class="form-control" name="Marca" [(ngModel)]="form.Marca">

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Modelo
                                                    </label>
                                                        <input type="text" class="form-control" name="Modelo" [(ngModel)]="form.Modelo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Serial
                                                    </label>
                                                        <input type="text" class="form-control" name="Serial" [(ngModel)]="form.Serial">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Fecha compra</label>
                                                        <input type="date" class="form-control" name="FechaCompra" [(ngModel)]="form.FechaCompra">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número Factura</label>
                                                        <input type="text" class="form-control" name="NumeroFactura" [(ngModel)]="form.NumeroFactura">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Valor del Activo</label>
                                                        <input type="text" class="form-control" name="ValorActivo" [(ngModel)]="form.ValorActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número de Póliza</label>
                                                        <input type="text" class="form-control" name="NumeroPoliza" [(ngModel)]="form.NumeroPoliza">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Dirección IP</label>
                                                        <input type="text" class="form-control" name="DireccionIp" [(ngModel)]="form.DireccionIp">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" name="Observaciones" [(ngModel)]="form.Observaciones"></textarea>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Contraseñas</label>
                                                        <textarea class="form-control" name="Contrasenas" [(ngModel)]="form.Contrasenas"></textarea>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Ciclos de mantenimiento</label>
                                                        <select required id="Ciclo" name="CiclosMantenimiento" [(ngModel)]="form.CiclosMantenimiento" class="form-control">
                                                            <option value="" disabled selected>Selecciona una opción
                                                            </option>
                                                            <option value="1">MENSUAL</option>
                                                            <option value="2">BIMESTRAL</option>
                                                            <option value="3">TRIMESTRAL</option>
                                                            <option value="4">SEMESTRAL</option>
                                                            <option value="5">ANUAL</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Proximo Mantenimiento</label>
                                                        <input type="date" class="form-control" name="ProximoMantenimiento" [(ngModel)]="form.ProximoMantenimiento">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                        <button class="btn btn-primary">Actualizar dispositivo</button>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </form>


                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>





<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Duplicar activo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="">
                    Si tienes un seríal ingresalo en el siguiente campo
                </label>
                <input type="text" class="form-control cell" placeholder="Digite un serial" name="serial"
                    [(ngModel)]="activodupl.nuevoserial" (change)="serialVerifyDuplicar()">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" (click)="duplicar()" class="btn btn-secondary">No tengo serial</button>
                <button type="button" [disabled]="activodupl.nuevoserial == null" (click)="duplicar()"
                    class="btn btn-primary">Duplicar con el serial</button>
            </div>
        </div>
    </div>
</div> -->