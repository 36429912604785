import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventoformService {

  private baseurl = "https://quar.com.co/api"
  createEvento(data) {
    return this.http.post(`${this.baseurl}/create/evento`, data)
  }

  editarEvento(data) {
    return this.http.post(`${this.baseurl}/editar/evento`, data)
  }

  getEventos2(data) {
    return this.http.post(`${this.baseurl}/get/eventos-dos`, data)
  }

  getEventos(data) {
    return this.http.post(`${this.baseurl}/get/eventos`, data)
  }


  deleteEvento(data) {
    return this.http.post(`${this.baseurl}/delete/eventos`, data)
  }






  constructor(private http: HttpClient) { }
}
