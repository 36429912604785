import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JarwisService } from '../Services/jarwis.service';
import { TokenService } from '../Services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../Services/auth.service';
import { QrScannerComponent } from 'angular2-qrscanner';
declare var jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
loading=false;
  public form= {
    email:null,
    password: null
  }


  ngAfterViewInit(){
    this.qrScannerComponent.getMediaDevices().then(devices => {
      console.log(devices);
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
          if (device.kind.toString() === 'videoinput') {
              videoDevices.push(device);
          }
      }
      if (videoDevices.length > 0){
          let choosenDev;
          for (const dev of videoDevices){
              if (dev.label.includes('front')){
                  choosenDev = dev;
                  break;
              }
          }
          if (choosenDev) {
              this.qrScannerComponent.chooseCamera.next(choosenDev);
          } else {
              this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
          }
      }
  });

  this.qrScannerComponent.capturedQr.subscribe(result => {
      console.log(result);
  });
  
  }

  public error = null;

  constructor(
    private Jarwis:JarwisService, 
    private Token:TokenService,
    private route:Router,
    private Auth:AuthService
    ) { }

  onSubmit(){
    this.loading = true;
      console.log(this.form);
      this.Jarwis.login(this.form).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      )
  }

  formPassword = {
    email:null
  }

  onSubmitPassword(){
    this.loading = true;
    console.log(this.formPassword);
    this.Jarwis.forgotPassword(this.formPassword).subscribe(
      data =>{
        this.loading = false;
        console.log(data);
      },
      error => this.handleError(error)
    )
  }


  handleResponse(data){
    console.log(data);
    this.loading = false;
      this.Token.handle(data.access_token);
      console.log(btoa(data.access_token));
      this.Token.handleUser(data.id);
      this.Token.handleEmail(data.email);
      this.Token.handleName(data.name);
      this.Token.handleAdmin(data.administrador);
      this.Token.handleSede(data.Sede);
      this.Auth.changeAuthStatus(true);
      if(localStorage.getItem('url')){
      this.route.navigateByUrl(localStorage.getItem('url'));
      }else{
        this.route.navigateByUrl('/home');
      }
  }

  handleError(error){
    this.loading = false;
    console.log(error);
    this.error = error.error.error;
  }
   showPosition(position) {
     console.log(position)
     localStorage.setItem('latitude', position.coords.latitude)
     localStorage.setItem('longitude', position.coords.longitude)
     console.log("login")

  }

  ngOnInit() {
    if(this.Token.getUserId()){
      this.route.navigateByUrl('/home');
    }
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.showPosition);
    // } else { 
    //  console.log("Geolocation is not supported by this browser.");
    // }
  

}
}
