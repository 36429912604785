<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{title}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Tickets</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a routerLink="/unidad-negocio" class="btn btn-danger text-white">Volver
                        </a>
                        <a type="button" data-toggle="modal" data-target="#informes"
                            class="btn btn-primary text-white">Exportar observaciones
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->

            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Unidad de negocio</h4>
                                    <h5 class="card-subtitle">Detalle</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <!-- <select class="form-control select-custom" name="fechas" id="">
                                            <option value="dayGridDay">Día</option>
                                            <option value="dayGridWeek">Semana</option>
                                            <option value="dayGridMonth">Mes</option>
                                            <option value="">Año</option>
                                            <option value="listWeek">Agenda</option>

                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <!-- <p>cronograma works!</p> -->
                            <br>
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <div class="table-responsive row">
                                <div class="col-md-8">
                                    <ng-container *ngFor="let item of unidades" class="col-md-6">
                                        <div class="card mb-3">
                                            <div class="row g-0">
                                                <div class="col-md-4">
                                                    <img (click)="srcUpdate(item.Foto)" data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        src="https://quar-vigilantes.s3.us-east-2.amazonaws.com/{{idUser}}/{{item.Foto}}"
                                                        class="img-fluid" alt="">
                                                    <img (click)="srcUpdate(item.Foto)" data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        src="http://localhost:8000/files/temp/{{item.Foto}}"
                                                        class="img-fluid" alt="">
                                                    <hr>
                                                    <p> Serial:
                                                        <b> {{item.Serial}} </b>
                                                    </p>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="card-body">
                                                        <h5 class="card-title">{{item.NombreUnidad}}</h5>
                                                        <p class="card-text">
                                                            Administrador:
                                                            <b> {{item.Administrador}} </b>
                                                        </p>
                                                        <p> Teléfono:
                                                            <b> {{item.Telefono}} </b>
                                                        </p>
                                                        <p> Nit:
                                                            <b> {{item.NIT}} </b>
                                                        </p>
                                                        <!-- <p class="card-text">
                                                            <small class="text-muted">Last updated 3 mins ago</small>
                                                        </p> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="col-md-4">
                                    <div class="card text-center">
                                        <div class="card-header">Conteo de visitas a la unidad</div>
                                        <div class="card-body">
                                            <!-- <h5 class="card-title">Número de visitas</h5> -->
                                            <p class="card-text" style="font-size: 4em;">
                                                    {{conteovisitas}}
                                            </p>
                                            <!-- <select name="" class="form-control" id=""></select> -->
                                        </div>
                                        <!-- <div class="card-footer text-muted">2 days ago</div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <hr> -->

                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Puntos de referencia</h4>
                                    <h5 class="card-subtitle"></h5>
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <div class="col-md-12">

                                        <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <input type="text" (change)="serialVerify()"
                                                    [disabled]="view == 'update'" class="form-control" name="QR"
                                                    [(ngModel)]="form.Serial" placeholder="Serial QR">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="NombreReferencia"
                                                    [(ngModel)]="form.NombreReferencia"
                                                    placeholder="Nombre punto de referencia" required>
                                            </div>
                                            <div class="register_form">
                                                <button *ngIf="view =='registro'" class="btn btn-primary"
                                                    [disabled]="!loginForm.valid">
                                                    Agregar punto de referencia
                                                </button>
                                                <button *ngIf="view =='update'" class="btn btn-primary"
                                                    [disabled]="!loginForm.valid">
                                                    Actualizar punto de referencia
                                                </button>
                                                <br>
                                                <br>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Puntos de referencia</h4>
                            <input type="search" name="punto" class="form-control"
                                placeholder="Buscar punto de referencia" [(ngModel)]="term">
                            <div class="feed-widget">
                                <ul class="list-style-none feed-body m-0 p-b-20">
                                    <li class="feed-item" *ngFor="let item of referencias | filter:term">
                                        <div class="feed-icon bg-info"><i class="icon-cancel_qr"></i></div>
                                        {{item.Serial}} - {{item.NombreReferencia}}
                                        <span class="ml-auto font-12 text-muted"><button
                                                (click)="detail(item.IdReferencia,item.Serial, item.NombreReferencia)"
                                                class="btn btn-primary"><i class="icon-edit"></i> </button></span>
                                        <span class="ml-auto font-12 text-muted"><button
                                                (click)="deleteUnidad(item.IdReferencia,item.Serial,item.IdUnidad)"
                                                class="btn btn-danger"><i class="icon-trash"></i>ELIMINAR
                                            </button></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Detalle de información</h4>
                                    <h5 class="card-subtitle">Novedades a la unidad y a los puntos de referencia</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <!-- <select class="form-control select-custom" name="fechas" id="">
                                            <option value="dayGridDay">Día</option>
                                            <option value="dayGridWeek">Semana</option>
                                            <option value="dayGridMonth">Mes</option>
                                            <option value="">Año</option>
                                            <option value="listWeek">Agenda</option>

                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <!-- <p>cronograma works!</p> -->
                            <br>
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                            <div class="form-gorup">
                                <input type="text" class="form-control" (keydown)="filtrar($event)"
                                    placeholder="Buscar novedad" name="term2" [(ngModel)]="term2">
                            </div>
                            <label for="">Mostrar</label>
                            <select class="form-control" (change)="mostrar($event)" id="">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>
                            <br>
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Código Serial</th>
                                        <th class="border-top-0">Punto de referencia</th>
                                        <th class="border-top-0">Registrado por</th>
                                        <th class="border-top-0">Foto</th>
                                        <th class="border-top-0">Novedad</th>
                                        <th class="border-top-0">Fecha Registro</th>
                                        <th class="border-top-0"></th>
                                        <th class="border-top-0">Tipo</th>
                                    </tr>
                                </thead>
                                <tbody class="scroll">

                                    <ng-container
                                        *ngFor="let item of novedades | filter:term2 | paginate: { itemsPerPage: numberperpage, currentPage: p }">
                                        <tr>
                                            <th scope="row">{{item.CodigoReferencia}}</th>
                                            <td>{{item.NombrePuntoReferencia}}</td>
                                            <td>{{item.QuienRegistro}}</td>
                                            <td style="cursor: pointer;">
                                                <img (click)="srcUpdate(item.Foto)" data-toggle="modal"
                                                    data-target="#exampleModal"
                                                    src="https://quar-vigilantes.s3.us-east-2.amazonaws.com/{{idUser}}/{{item.Foto}}"
                                                    style="width:120px" alt="">
                                                <img (click)="srcUpdate(item.Foto)" data-toggle="modal"
                                                    data-target="#exampleModal"
                                                    src="http://localhost:8000/files/temp/{{item.Foto}}"
                                                    style="width:120px" alt="">
                                            </td>
                                            <td colspan="1">{{item.Novedad}}</td>
                                            <td colspan="2">{{item.FechaRegistro}}</td>
                                            <td>{{item.Tipo}}</td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <div class="panel-activos__body__pagination text-center">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                            <hr>
                            <div class="row">

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co ">Quar</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>






<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Imagen de detalle
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <img width="100%" src="{{src}}" alt="">
                <img width="100%" src="{{src2}}" alt="">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>






<!-- Modal -->
<div class="modal fade" id="informes" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Exportar observaciones</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <label>Fecha inicial</label>
                        <input type="date" [(ngModel)]="inicio" class="form-control" name="inicio">
                    </div>
                    <div class="col-md-6">
                        <label>Fecha final</label>
                        <input type="date" class="form-control" [(ngModel)]="final" name="final">
                    </div>
                    <div class="col-md-12">
                        <br>
                        <button (click)="observaciones()" class="cell btn btn-primary">
                            Exportar a excel
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
