<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Mi Cuenta</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Crear un nuevo usuario</h4>
                                    <h5 class="card-subtitle"></h5>
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">

                                    <form #loginForm=ngForm (ngSubmit)="onSubmit()" style="padding: 1em;">
                                        <div class="row">

                                            <div class="form-group col-md-6">
                                                <input type="text" name="name" placeholder="¿Cómo te llamas?"
                                                    class="form-control" id="nombre" [(ngModel)]="form.name" required>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <input type="email" name="email" placeholder="Cuál es tu mail"
                                                    class="form-control" id="inputEmail3" [(ngModel)]="form.email"
                                                    required>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <input type="password" name="password" placeholder="Contraseña"
                                                    class="form-control" id="inputP45454assword3"
                                                    [(ngModel)]="form.password" required>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <input type="password" name="password_confirmation"
                                                    placeholder="Repite tu contraseña" class="form-control" id="445"
                                                    [(ngModel)]="form.password_confirmation" required>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <!-- <label for="Nombre">Sede</label> -->
                                                <select class="form-control" name="Sede" [(ngModel)]="form.Sede">
                                                    <option value="null">Selecciona una sede</option>
                                                    <ng-container *ngFor="let item of sedes">
                                                        <option value="{{item.IdSede}}">{{item.NombreSede}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <div class="form-group col-12 text-center">
                                                <br>
                                                <button type="submit" class="btn btn-primary"
                                                    [disabled]="!loginForm.valid">Registrar usuario</button>
                                                <!-- <button type="button" routerLink="/login" class="btn btn-secondary">Ya tengo cuenta</button> -->
                                            </div>
                                        </div>

                                    </form>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title"></h4>
                            <div class="feed-widget">
                                <ul class="list-style-none feed-body m-0 p-b-20">
                                    <img src="../../assets/img/good.png" style="width: 300px;" alt="">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Lista de usuarios</h4>
                                    <h5 class="card-subtitle"></h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <select class="custom-select">
                                            <option value="0" selected>Monthly</option>
                                            <option value="1">Daily</option>
                                            <option value="2">Weekly</option>
                                            <option value="3">Yearly</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">

                                        <!-- <th>Código</th> -->
                                        <th class="border-top-0">Nombres</th>
                                        <!-- <th>Apellidos</th> -->
                                        <th class="border-top-0">Email</th>
                                        <th class="border-top-0">Estado</th>
                                        <th class="border-top-0">Sede</th>
                                        <!-- <th>Dirección</th> -->
                                        <!-- <th>Ciudad</th> -->
                                        <!-- <th>ARL</th> -->
                                        <th class="border-top-0">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container ([ngModel])="empleados"
                                        class="table-activo__container table-striped __head__body__item"
                                        *ngFor="let item of empleados | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <tr>
                                            <td>{{item.name}}</td>
                                            <td>{{item.email}}</td>
                                            <td>
                                                <ng-container *ngIf="item.Estado == '2'">
                                                    <p style="color:green;">Usuario Activo</p>
                                                </ng-container>
                                                <ng-container *ngIf="item.Estado == '0'">
                                                    <p style="color:red">Usuario Inactivo</p>

                                                </ng-container>
                                            </td>
                                            <td>
                                                <select class="form-control" (change)="updateUserSede(item.id, $event)"
                                                    name="Sede" [(ngModel)]="item.Sede">
                                                    <!-- <option value="">Selecciona una sede</option> -->
                                                    <ng-container *ngFor="let item of sedes">
                                                        <option value="{{item.IdSede}}">{{item.NombreSede}}</option>
                                                    </ng-container>
                                                    <option value="TODAS">Todas las sedes</option>
                                                </select>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="item.Estado == '0'">
                                                    <button class="btn-primary btn"
                                                        (click)="activarUsuario(item.email, '2')">Activar
                                                        Usuario</button>
                                                </ng-container>
                                                <ng-container *ngIf="item.Estado == '2'">
                                                    <button class="btn btn-danger"
                                                        (click)="activarUsuario(item.email, '0')">Inactivar
                                                        Usuario</button>
                                                </ng-container>
                                                <!-- <button class="btn btn-secundary">Actualizar</button> -->
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>