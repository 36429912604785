<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{title}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Tickets</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a data-toggle="modal" data-target="#modalEventoNuevo" class="btn btn-danger text-white">Agregar
                            evento</a>
                    </div>





                    <!-- Modal -->
                    <div class="modal fade" id="modalEventoNuevo" data-backdrop="static" data-keyboard="false"
                        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdropLabel">Agregar evento</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">

                                    <form #eventoFrom=ngForm (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Asignar un usuario</label>
                                                    <select class="form-control" name="Usuarios"
                                                        [(ngModel)]="data.UsuarioAsignado">
                                                        <option value="0">Seleccione un usuario</option>
                                                        <ng-container *ngFor="let item of usuarios">
                                                            <option value="{{item.id}}">{{item.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <a class="nav-link active" id="home-tab" data-toggle="tab"
                                                            href="#home" role="tab" aria-controls="home"
                                                            aria-selected="true">Todo el día</a>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <a class="nav-link" id="profile-tab" data-toggle="tab"
                                                            href="#profile" role="tab" aria-controls="profile"
                                                            aria-selected="false">Hora especifica</a>
                                                    </li>

                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="home" role="tabpanel"
                                                        aria-labelledby="home-tab">
                                                        <div class="form-group">
                                                            <label for="">Fecha del inicial</label>
                                                            <input type="date" class="form-control"
                                                                name="HoraInicio" [(ngModel)]="data.HoraInicio">
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="profile" role="tabpanel"
                                                        aria-labelledby="profile-tab">
                                                        <div class="form-group">
                                                            <label for="">Fecha del inicial</label>
                                                            <input type="datetime-local" class="form-control"
                                                                name="HoraInicio" [(ngModel)]="data.HoraInicio">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Nombre evento</label>
                                                    <input type="text" name="Nombre" class="form-control"
                                                        [(ngModel)]="data.Nombre" id="">
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Descripción</label>
                                                    <textarea name="Descripcion" class="form-control"
                                                        [(ngModel)]="data.Descripcion" id=""> </textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Puedes seleccionar otro color</label>
                                                    <input type="color" name="Color" class="form-control"
                                                        value="{{data.ColorEvento}}" [(ngModel)]="data.ColorEvento"
                                                        id="">
                                                </div>
                                                <hr>
                                                <div class="form-group color" [style.background]="data.ColorEvento">
                                                    {{data.Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" class="btn btn-secondary"
                                                    data-dismiss="modal">Cancelar</button>
                                                <button type="submit" class="btn btn-primary">Registrar evento</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div class="modal-footer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->

            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Cronograma de actividades</h4>
                                    <h5 class="card-subtitle">Calendario</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <select class="form-control select-custom" name="fechas"
                                            (change)="toggleWeekends()" [(ngModel)]="tipocalendario" id="">
                                            <option value="dayGridDay">Día</option>
                                            <option value="dayGridWeek">Semana</option>
                                            <option value="dayGridMonth">Mes</option>
                                            <!-- <option value="">Año</option> -->
                                            <option value="listWeek">Agenda</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->

                            <!-- <p>cronograma works!</p> -->
                            <br>
                            <full-calendar [options]="calendarOptions"></full-calendar>
                            <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
                        </div>

                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co ">Quar</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>




<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">{{data.Nombre}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Asignar un usuario</label>
                                <select class="form-control" name="Usuarios" [(ngModel)]="data.UsuarioAsignado">
                                    <option value="0">Seleccione un usuario</option>
                                    <ng-container *ngFor="let item of usuarios">
                                        <option value="{{item.id}}">{{item.name}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab"
                                        href="#home" role="tab" aria-controls="home"
                                        aria-selected="true">Todo el día</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab"
                                        href="#profile" role="tab" aria-controls="profile"
                                        aria-selected="false">Hora especifica</a>
                                </li>

                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="form-group">
                                        <label for="">Fecha del inicial</label>
                                        <input type="date" class="form-control"
                                            name="HoraInicio" [(ngModel)]="data.HoraInicio">
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel"
                                    aria-labelledby="profile-tab">
                                    <div class="form-group">
                                        <label for="">Fecha del inicial</label>
                                        <input type="datetime-local" class="form-control"
                                            name="HoraInicio" [(ngModel)]="data.HoraInicio">
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Nombre evento</label>
                                <input required type="text" name="Nombre" class="form-control" [(ngModel)]="data.Nombre"
                                    id="">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Descripción</label>
                                <textarea name="Descripcion" class="form-control" [(ngModel)]="data.Descripcion"
                                    id=""> </textarea>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group color" [style.background]="data.ColorEvento">
                                {{data.Nombre}}
                            </div>
                            <div class="form-group">
                                <label for="">Puedes seleccionar otro color</label>
                                <input type="color" name="Color" class="form-control" value="{{data.ColorEvento}}"
                                    [(ngModel)]="data.ColorEvento" id="">
                            </div>
                        </div>
                    </div>

                </form>
                <!-- {{data | json}} -->
            </div>
            <div class="modal-footer">
                <button type="button" (click)="actualizarEvento()" class="btn btn-primary" data-dismiss="modal">Cerrar
                    información</button>
                <!-- <button type="button" class="btn btn-primary"></button> -->
            </div>
        </div>
    </div>
</div>