<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view='lista'">Gestión de empleados</li>
                                <li class="breadcrumb-item active" *ngIf="view!='lista'" (click)="view='serial'">Serial
                                </li>
                                <li class="breadcrumb-item active" *ngIf="view=='registro'" (click)="view='registro'">
                                    Registro</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a (click)="view='serial'" class="btn btn-primary text-white">Agregar empleado</a>
                        <a (click)="abajo()" class="btn btn-danger text-white">EXPORTAR</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row" *ngIf="view!='lista'">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro empleado</h4>
                                    <h5 class="card-subtitle" *ngIf="view=='serial'">Serial de empleados</h5>
                                    <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario de empleado</h5>
                                </div>

                            </div>
                            <!-- title -->


                            <ng-container *ngIf="view == 'serial'">
                                <div class="content-serial" *ngIf="view == 'serial'">
                                    <br>
                                    <hr>
                                    <h3>Verificación de serial</h3>
                                    <input type="text" placeholder="Serial del código" class="form-control"
                                        name="Codigo" [(ngModel)]="form.Codigo">
                                    <br>
                                    <button class="btn btn-danger cell" (click)="serialVerify()">Verificar
                                        serial</button>
                                    <br>



                                    <div class="row">
                                        <div class="col-md-12">
                                            <br>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a
                                                empleados</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-primary cell" (click)="view='registro'">Quiero
                                                continuar sin serial</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <form *ngIf="view=='registro'" #loginForm=ngForm (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">
                                            Cedula
                                        </label>
                                        <input name="Cedula" type="text" (change)="cedula()" [(ngModel)]="form.Cedula"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Nombres
                                        </label>
                                        <input name="Nombre" type="text" [(ngModel)]="form.Nombre" class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Apellidos
                                        </label>
                                        <input type="text" class="form-control" name="Apellido"
                                            [(ngModel)]="form.Apellido">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Cargo
                                        </label>
                                        <input type="text" class="form-control" name="Cargo" [(ngModel)]="form.Cargo">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Teléfono
                                        </label>
                                        <input type="text" class="form-control" name="Telefono"
                                            [(ngModel)]="form.Telefono">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Dirección
                                        </label>
                                        <input type="text" class="form-control" name="Direccion"
                                            [(ngModel)]="form.Direccion">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Ciudad
                                        </label>
                                        <input type="text" class="form-control" name="Ciudad" [(ngModel)]="form.Ciudad">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            ARL
                                        </label>
                                        <input type="text" class="form-control" name="Arl" [(ngModel)]="form.Arl">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            EPS
                                        </label>
                                        <input type="text" class="form-control" name="Eps" [(ngModel)]="form.Eps">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            RH
                                        </label>
                                        <input type="text" class="form-control" name="RH" [(ngModel)]="form.RH">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Nombre de Contacto
                                        </label>
                                        <input type="text" class="form-control" name="NombreContacto"
                                            [(ngModel)]="form.NombreContacto">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="">
                                            Teléfono Contacto
                                        </label>
                                        <input type="text" class="form-control" name="TelefonoContacto"
                                            [(ngModel)]="form.TelefonoContacto">
                                    </div>
                                    <div class="col-lg-12"><br> <button class="btn cell btn-primary">
                                            Registrar Empleado
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" *ngIf="view=='lista'">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Gestión de empleados</h4>
                                    <h5 class="card-subtitle">Lista de empleados</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl row">
                                        <div class="form-group col-md-6">
                                            <label for="">Buscar empleado</label>
                                            <input type="text" name="search" [(ngModel)]="term" (keydown)="filtrar($event)" class="form-control">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="">Mostrar</label>
                                            <select name="mostrar" class="form-control"  [(ngModel)]="numberperpage" id="">
                                                <option value="10">10</option>
                                                <option value="20">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Código</th>
                                        <th class="border-top-0">Nombres</th>
                                        <th class="border-top-0">Apellidos</th>
                                        <th class="border-top-0">Cargo</th>
                                        <th class="border-top-0">Teléfono</th>
                                        <th class="border-top-0">Dirección</th>
                                        <th class="border-top-0">Ciudad</th>
                                        <th class="border-top-0">ARL</th>
                                        <th class="border-top-0">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ([ngModel])="empleados"
                                        class="table-activo__container table-striped __head__body__item"
                                        *ngFor="let item of empleados | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <ng-container>
                                            <td>
                                                {{item.Serial}}
                                            </td>
                                            <td>
                                                {{item.Nombre}}
                                            </td>
                                            <td>
                                                {{item.Apellido}}
                                            </td>
                                            <td>
                                                {{item.Cargo}}
                                            </td>
                                            <td>
                                                {{item.Telefono}}
                                            </td>
                                            <td>
                                                {{item.Direccion}}
                                            </td>
                                            <td>
                                                {{item.Ciudad}}
                                            </td>
                                            <td>
                                                {{item.Arl}}
                                            </td>
                                            <td>
                                                <button
                                                    (click)="detail(item.IdEmpleado,item.Cedula,item.Nombre,item.Apellido,item.Cargo,item.Telefono,item.Direccion,item.Ciudad,item.Arl,item.Eps,item.RH,item.NombreContacto,item.TelefonoContacto)"
                                                    class="btn btn-outline-secondary" type="button" data-toggle="modal"
                                                    data-target="#modalempleadovisitas"> <i
                                                        class="icon-DETAILS">Visitas</i></button>

                                                <button
                                                    (click)="detail(item.IdEmpleado,item.Cedula,item.Nombre,item.Apellido,item.Cargo,item.Telefono,item.Direccion,item.Ciudad,item.Arl,item.Eps,item.RH,item.NombreContacto,item.TelefonoContacto)"
                                                    class="btn btn-outline-secondary" type="button" data-toggle="modal"
                                                    data-target="#modalempleado"> <i class="icon-DETAILS"></i></button>
                                                <button class="btn btn-delete"
                                                    (click)="deleteempleado(item.IdEmpleado, item.Serial)"> <i
                                                        class="icon-REMOVE"></i></button>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="panel-activos__body__pagination">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" *ngIf="view=='lista'" id="reportesseccion">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Exportar</h4>
                                    <h5 class="card-subtitle">Exportar empleados o control de ingreso de los empleados
                                    </h5>
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <div *ngIf="view == 'lista'">
                                        <a *ngIf="view =='lista'"
                                            href="https://quar.com.co/api/excel/empleados/{{idUser}}">
                                            <button class="btn cell btn-secondary cell">Exportar empleados</button></a>
                                        <!-- <hr> -->
                                        <h3>Reporte de ingresos</h3>
                                        <div class="form-group">
                                            <label for="">Fecha inicial</label>
                                            <input type="date" [(ngModel)]="fechainicial" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <label for="">Fecha final</label>
                                            <input type="date" [(ngModel)]="fechafinal" class="form-control">
                                        </div>

                                        <button (click)="exportarvisitas()" class="btn btn-primary">Exportar registro de
                                            ingresos</button>
                                    </div>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>




<div class="modal fade" id="modalempleadovisitas" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Visitas del empleado el día de hoy</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <ng-container ([ngModel])="observaciones" *ngFor="let item of observaciones">
                    <div class="col-md-12">
                        <div class="card container text-center">
                            <div class="text-left card-header-date">
                                {{item.FechaRegistro}}
                            </div>
                            <h1 for="">Temperatura</h1>
                            <p>{{item.Temperatura}}</p>
                            <hr>
                            <h3 for="">Observación</h3>
                            <p>{{item.Observacion}}</p>
                            <hr>
                            <ng-container *ngIf="item.observacion2">

                                <h3 for="">Sintomas</h3>
                                <p>{{item.observacion2}}</p>
                            </ng-container>

                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cerrar()" data-dismiss="modal">Cerrar historial
                    de visitas</button>
                <!-- <button class="btn btn-primary">Actualizar información</button> -->
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalempleado" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detalle del empleado</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="">
                                Cedula
                            </label>
                            <input name="Cedula" type="text" (change)="cedula()" [(ngModel)]="form.Cedula"
                                class="form-control">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Nombres
                            </label>
                            <input name="Nombre" type="text" [(ngModel)]="form.Nombre" class="form-control">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Apellidos
                            </label>
                            <input type="text" class="form-control" name="Apellido" [(ngModel)]="form.Apellido">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Cargo
                            </label>
                            <input type="text" class="form-control" name="Cargo" [(ngModel)]="form.Cargo">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Teléfono
                            </label>
                            <input type="text" class="form-control" name="Telefono" [(ngModel)]="form.Telefono">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Dirección
                            </label>
                            <input type="text" class="form-control" name="Direccion" [(ngModel)]="form.Direccion">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Ciudad
                            </label>
                            <input type="text" class="form-control" name="Ciudad" [(ngModel)]="form.Ciudad">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                ARL
                            </label>
                            <input type="text" class="form-control" name="Arl" [(ngModel)]="form.Arl">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                EPS
                            </label>
                            <input type="text" class="form-control" name="Eps" [(ngModel)]="form.Eps">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                RH
                            </label>
                            <input type="text" class="form-control" name="RH" [(ngModel)]="form.RH">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Nombre de Contacto
                            </label>
                            <input type="text" class="form-control" name="NombreContacto"
                                [(ngModel)]="form.NombreContacto">
                        </div>
                        <div class="col-md-6">
                            <label for="">
                                Teléfono Contacto
                            </label>
                            <input type="text" class="form-control" name="TelefonoContacto"
                                [(ngModel)]="form.TelefonoContacto">
                        </div>
                        <div class="col-lg-12">

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="cerrar()"
                        data-dismiss="modal">Cerrar</button>
                    <button class="btn btn-primary">Actualizar información</button>
                </div>
            </form>
        </div>
    </div>
</div>
