<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <h4 class="page-title">Panel admnistrativo</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Panel de control</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <!-- <div class="text-right upgrade-btn">
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Upgrade to Pro</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div>
                <div class="card">

                    <div class="card-header">
                        <h2 class="card-category">Mantenimientos</h2>
                        <h1>Registro de novedad</h1>
                    </div>
                    <div class="card-body panel-activos__body">



                        <div class="table-full-width table-responsive scroll table-striped table-hover">
                            <!-- Aqui va el contendio -->


                            <!-- Area Chart -->
                            <div class="col-lg-12">

                                <!-- Card Header - Dropdown -->
                                <!-- Card Body -->
                                <div class="card-body">
                                    <script
                                        src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>

                                    <script>
                                        jQuery(document).ready(function () {
                                            var now = new Date();

                                            var day = ("0" + now.getDate()).slice(-2);
                                            var month = ("0" + (now.getMonth() + 1)).slice(-2);

                                            var today = now.getFullYear() + "-" + (month) + "-" + (day);
                                            $("#fecha").val(today);
                                        });
                                    </script>
                                    <div class="">
                                        <button (click)="volver()" class="btn btn-primary cell">Volver</button>
                                        <hr>
                                        <br>

                                        <form #mantenimientoForm (ngSubmit)="onSubmit()">

                                            <div class="">
                                                <div class="row">
                                                  <div class="col-md-12">
                                                      <label for="">Selecciona el ticket que deseas revisar:</label>
                                                      <select name="Ticket" class="form-control" id="ticket" [(ngModel)]="form.Ticket">
                                                          <option value="">Seleccionar una solicitud</option>
                                                          <option *ngFor="let i of tickets" value="{{i.NumeroTIcket}}">{{i.Asunto}}</option>
                                                      </select>
                                                  </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="fecha">Fecha del mantenimiento
                                                            </label>
                                                            <input name="FechaMantenimiento"
                                                                [(ngModel)]="form.FechaMantenimiento" type="date"
                                                                id="fecha" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="fecha">Tipo de Mantenimiento
                                                            </label>
                                                            <select name="TipoMantenimiento"
                                                                [(ngModel)]="form.TipoMantenimiento"
                                                                class="form-control" id="validate">
                                                                <option value="Preventivo">Preventivo
                                                                </option>
                                                                <option value="Correctivo">Correctivo
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="fecha">Descripcion del mantenimiento
                                                </label>
                                                <textarea required class="form-control" name="Descripcion"
                                                    [(ngModel)]="form.Descripcion" id=""></textarea>
                                            </div>


                                            <ng-container *ngFor="let idac of activo">


                                                <input type="hidden" name="IdActivo" value="{{idac.id}}"
                                                    [(ngModel)]="form.IdActivo">
                                                <input type="hidden" name="ciclo" value="{{idac.ciclo}}"
                                                    [(ngModel)]="form.ciclo">
                                            </ng-container>
                                            <input type="hidden" id="codigo" name="Codigo" value="{{user.serial}}"
                                                [(ngModel)]="form.Codigo">
                                            <input type="hidden" id="Administrador" name="IdUsuarioSoporte"
                                                value="{{user.id_user}}" [(ngModel)]="form.IdUsuarioSoporte">
                                            <ng-container *ngFor="let ac of users">
                                                <input type="hidden" id="Administrador" name="UsuarioAdministrador"
                                                    value="{{ac.id_user}}" [(ngModel)]="form.UsuarioAdministrador">
                                            </ng-container>
                                            <div class="signature-container">
                                              <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
                                            </div>
                                            <div class="buttons">
                                              <button type="button" (click)="clearSignature()">Limpiar firma</button>
                                            </div>



                                            <div class="signature-image" *ngIf="signatureImg">
                                              <img src='{{ signatureImg }}' />
                                            </div>
                                            <button type="submit" (click)="savePad()" class="btn btn-outline-primary">Registrar
                                                mantenimiento</button>


                                        </form>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="card-footer ">
                            <hr>
                            <div class="stats ">
                                <i class="now-ui-icons loader_refresh spin "></i>Actualizando
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->

            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->
