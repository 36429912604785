import { ToastrService } from 'ngx-toastr';
import { VisitantesService } from './../Services/usuarios/visitantes.service';
import { TokenService } from './../Services/token.service';
import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { QrScannerComponent } from 'angular2-qrscanner';
import { error } from 'protractor';
@Component({
  selector: 'app-registro-visitantes',
  templateUrl: './registro-visitantes.component.html',
  styleUrls: ['./registro-visitantes.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class RegistroVisitantesComponent implements OnInit {
  @ViewChild('input') inputEl: ElementRef;
  id = this.route.snapshot.paramMap.get("user");
  constructor(private route: ActivatedRoute, private Token: TokenService,
    private visitas: VisitantesService,
    private toastr: ToastrService) { }

  data = {
    TipoDocumento:"Cédula de ciudadanía",
    Cedula: null,
    Nombre: null,
    Apellido: null,
    Empresa: null,
    Telefono: null,
    Direccion: null,
    Ciudad: null,
    Arl: null,
    Eps: null,
    RH: null,
    NombreContacto: null,
    TelefonoContacto: null,
    Temperatura: null,
    Compania: this.Token.b64_to_utf8(this.id),
    observacion: null,
    observacion2: null,
    telefonocopia:null,
  }

  onSubmit() {
    this.data.telefonocopia = this.data.Telefono;
    
    if (this.data.observacion2 != null) {
      this.data.observacion2 = this.data.observacion2.toString();
    } else {
      this.data.observacion2 = "No presenta sintomas";
    }

    if (this.data.Temperatura == null) {
      this.toastr.error("La temperatura es obligatoria", "Campos obligatorios")
    } else {
      this.loading = true;
      console.log(this.data);
      this.visitas.create(this.data).subscribe(
        data => {
          this.sintoma = "No";
          this.sintomas = false;
          this.loading = false;
          this.inputEl.nativeElement.focus();
          this.toastr.success("Se ha registrado una vista al sistema", "Mensaje")
          this.data.Cedula = "";
          this.data.Nombre = "";
          this.data.Apellido = "";
          this.data.Empresa = "";
          this.data.Telefono = "";
          this.data.Direccion = "";
          this.data.telefonocopia = "";
          this.data.Ciudad = "";
          this.data.Arl = "";
          this.data.Eps = "";
          this.data.RH = "";
          this.data.NombreContacto = "";
          this.data.TelefonoContacto = "";
          this.data.Temperatura = "";
          this.visitas.count(this.data).subscribe(
            data => {
              this.data.observacion2 = null;
              this.count = data['count']},
            error => console.log(error)
          )
        },
        error => {
          this.inputEl.nativeElement.focus();
          this.loading = false;
        }
      )
    }
  }

  temperatura(event) {
    console.log(event);
    var temp = (this.data.Temperatura / 10).toFixed(1);
    console.log(parseFloat(temp))
    if (event.target.value < 3 || event.target.value > 4) {
      if (parseFloat(temp) < 3.4 || parseFloat(temp) > 41.5) {
        this.data.Temperatura = "";
      } else {
        if (parseFloat(temp) > 41.0) {
          this.data.Temperatura = "";
        }
        if (parseFloat(temp) > 34.0) {
          this.data.Temperatura = temp;
        }
        if (parseFloat(temp) === 3.7) {
          this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
        } else {
          if (parseFloat(temp) >= 37.0 && parseFloat(temp) < 37.9) {
            this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
          }
        }
        if (parseFloat(temp) === 3.8) {
          this.toastr.warning(this.data.Nombre + ' tiene un nivel alto de temperatura');
        } else {
          if (parseFloat(temp) > 38.0 && parseFloat(temp) < 38.9) {
            this.toastr.warning(this.data.Nombre + ' tiene un nivel alto de temperatura');

          }

        }
        if (parseFloat(temp) === 3.9) {
          this.toastr.error(this.data.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
        } else {
          if (parseFloat(temp) > 39.0) {
            this.toastr.error(this.data.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');


          }
        }
      }
    } else {

    }


  }
  enviar = false;

  observacionesAlerta(event) {
    if (event.target.value === "Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19") {
      this.toastr.error("INFORMAR AL COORDINADOR SST", "IMPORTANTE");
    }
    // this.enviar = true;1020493468;FLOREZ;CALDERON;JOHN;NELSON;M;19990703;O+

    
  }

  sintomas = false;
  observaciones2Alerta(event) {
    if (event.target.value === "Sí") {
      this.toastr.info("Seleccione cuales son los sintomas", "IMPORTANTE");
      // this.sintoma = "Sí";
      this.sintomas = true;
    } else {
      this.sintomas = false;
      // this.sintoma = "No";
    }
    // this.enviar = true;
  }


  sintoma = "";


  loading = false;

  tittle = "Registro de visitantes";

  handleResponse(info) {
        if (info.visitante[0]) {
      this.toastr.info("Se ha cargado la información asociada al documento, debes actualizar la temperatura", "Información")
      // this.sintomas = false;
      console.log(info.visitante[0].Nombre);
      this.data.Nombre = info.visitante[0].Nombre
      this.data.Apellido = info.visitante[0].Apellido
      this.data.Empresa = info.visitante[0].Empresa
      this.data.Telefono = info.visitante[0].Telefono
      this.data.Direccion = info.visitante[0].Direccion
      this.data.Ciudad = info.visitante[0].Ciudad
      this.data.Arl = info.visitante[0].Arl
      this.data.Eps = info.visitante[0].Eps
      this.data.RH = info.visitante[0].RH
      this.data.NombreContacto = info.visitante[0].NombreContacto
      this.data.TelefonoContacto = info.visitante[0].TelefonoContacto
      console.log(this.data);
    } else {
      this.toastr.info("Se registrara una nueva persona al sistema", "Información");
      this.data.Apellido = this.resultado[1] + " " + this.resultado[2];
      this.data.Nombre = this.resultado[3] + " " + this.resultado[4];
      this.data.RH = this.resultado[7];
    }
  }

  cedula() {
    this.visitas.cedula(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )
  }

  // @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
  ngOnInit(): void {
    this.visitas.count(this.data).subscribe(
      data => {this.count = data['count']},
      error => console.log(error)
    )
    console.log(this.Token.b64_to_utf8(this.id))

  }

  sintomasarray = {
    "Tos":"Tos",
    "Fiebre": "Fiebre",
    "Dificutal para respirar": "Dificutal para respirar",
  }

  count= 0;
  resultado: any;
  cedulaSca = "";
  cedulaScan(event) {
    // alert(event.target.value)
    // if(event.target.value == ""){
    this.resultado = event.target.value.split(";");
    this.data.Cedula = this.resultado[0];
    this.cedulaSca = "";
    // alert(this.data.Cedula);
    this.visitas.cedula(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )
    // this.data.Apellido = this.resultado[1]+ " "+ this.resultado[2];
    // this.data.Nombre = this.resultado[3]+ " "+ this.resultado[4];
    // this.data.RH = this.resultado[7];
    this.cedulaSca = "";
    // }


  }

  ngAfterViewInit(): void {
    this.inputEl.nativeElement.focus();
    //   this.qrScannerComponent.getMediaDevices().then(devices => {
    //     console.log(devices);
    //     const videoDevices: MediaDeviceInfo[] = [];
    //     for (const device of devices) {
    //         if (device.kind.toString() === 'audioinput') {
    //             videoDevices.push(device);
    //         }
    //     }
    //     if (videoDevices.length > 0){
    //         let choosenDev;
    //         for (const dev of videoDevices){
    //           this.resultado  = dev;
    //             this.qrScannerComponent.chooseCamera.next(videoDevices[8]);
    //     }
    //   }
    // });

    // this.qrScannerComponent.capturedQr.subscribe(result => {
    //     console.log(result);
    //     this.resultado = result;
    // });
  }

}
