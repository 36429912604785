import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmpleadosService } from '../Services/empleados.service';
import { TokenService } from '../Services/token.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-tiempo-real',
  templateUrl: './tiempo-real.component.html',
  styleUrls: ['./tiempo-real.component.scss']
})

export class TiempoRealComponent implements AfterViewInit {
  form = {
    userId: null
  }

  private map;

  private initMap(): void {
    this.map = L.map('map', {
      center: [ 39.8282, -98.5795 ],
      zoom: 3
    });
  }

  codigo;
  admin;

  constructor(private toastr: ToastrService, private token: TokenService, private http: HttpClient, private empleado: EmpleadosService) { }

  ngOnInit(): void {
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.codigo = this.token.utf8_to_b64(this.token.getUserAdministrador());
    } else {
      this.admin = true;
      this.codigo = this.token.utf8_to_b64(this.token.getUserId());
    }

    this.form.userId = this.token.b64_to_utf8(this.codigo);

    setInterval(() => {
      this.getData()
     }, 25000);

  }

  getData(){
    console.log("data information real time")
    this.empleado.getdatosrealtime(this.form).subscribe(data => this.detailResponse(data), error => this.toastr.error(error.message, 'Error'));
  }

  data:null;
  detailResponse(data) {
    this.data = data.observaciones
  }

  ngAfterViewInit(): void {
    this.initMap();
  }
}
