
    <!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<app-navbar></app-navbar>

<app-layout></app-layout>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Page wrapper  -->
<!-- ============================================================== -->
<div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="page-breadcrumb">
        <div class="row align-items-center">
            <div class="col-12">
                <h4 class="page-title">{{tittle}}</h4>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                            <li class="breadcrumb-item active" routerLink='/empleados'>Gestión de maquinas</li>
                            <!-- <li class="breadcrumb-item active" >Observacion {{form.Nombre}} {{form.Apellido}}</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="col-7">
                <div class="text-right upgrade-btn">
                    <!-- <a (click)="view='serial'" class="btn btn-primary text-white">Agregar empleado</a> -->
                    <!-- <a (click)="abajo()" class="btn btn-danger text-white">EXPORTAR</a> -->
                </div>
            </div>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Sales chart -->
        <!-- ============================================================== -->
        <!-- Table -->
        <!-- ============================================================== -->
        <div class="row">
            <!-- column -->
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <!-- title -->
                        <div class="d-md-flex align-items-center">
                            <div>
                                <h4 class="card-title">Observación a la maquina</h4>
                                <h5 class="card-subtitle" *ngIf="view=='serial'">Formulario de observación</h5>
                                <!-- <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario de empleado</h5> -->
                            </div>

                        </div>
                        <!-- title -->


                        <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label for="">
                                            Referencia producto operado
                                        </label>
                                        <input  name="Cedula" type="text" (change)="cedula()"
                                            [(ngModel)]="form.Cedula" class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="">
                                            Fecha Montaje
                                        </label>
                                        <input  name="Nombre" type="text" [(ngModel)]="form.Nombre"
                                            class="form-control">
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="">
                                            Operaciones realizadas
                                        </label>
                                        <textarea  type="text" class="form-control" name="Apellido"
                                            [(ngModel)]="form.Apellido"></textarea>
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="">
                                            Unidades fabricadas
                                        </label>
                                        <input  type="text" class="form-control" name="Puesto"
                                            [(ngModel)]="form.Puesto">
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="">
                                            Orden de producción
                                        </label>
                                        <input  type="number" class="form-control" name="Telefono"
                                            [(ngModel)]="form.Telefono">
                                    </div>
                                    
    
                                </div>
                            </div>
                            <!-- {{obser | json}} -->
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-secondary" (click)="cerrar()" data-dismiss="modal">Cerrar</button> -->
                                <button disabled class="btn btn-primary cell">Enviar actualización</button>
                            </div>
                        </form>
                        <ng-container ([ngModel])="observaciones" *ngFor="let item of observaciones">
                            <div class="card container text-center">
                                <div class="text-left card-header-date">
                                    {{item.FechaRegistro}}
                                </div>
                                <h1 for="">Temperatura</h1>
                                <p>{{item.Temperatura}}</p>
                                <hr>
                                <h3 for="">Observación</h3>
                                <p>{{item.Observacion}}</p>
                                <hr>
                                <ng-container *ngIf="item.observacion2">
    
                                    <h3 for="">Sintomas</h3>
                                    <p>{{item.observacion2}}</p>
                                </ng-container>
    
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>
        
        

        <!-- ============================================================== -->
        <!-- Recent comment and chats -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer text-center">
        Todos los derechos reservados <a
            href="https://quar.com.co.com">Quar.com.co</a>.
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Page wrapper  -->
<!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
<!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>



