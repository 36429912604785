<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5" 
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view='lista'">Maquinas</li>
                                <li class="breadcrumb-item active" *ngIf="view!='lista'">Serial</li>
                                <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a (click)="view='serial'" class="btn btn-primary text-white" target="_blank">Agregar
                            Maquina</a>
                        <a href="https://quar.com.co.com/templates/xtremeadmin/" class="btn btn-danger text-white"
                            target="_blank">Exportar excel</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div *ngIf="view!='lista'" class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro de activo</h4>
                                    <h5 class="card-subtitle" *ngIf="view=='serial'">Asignar Serial</h5>
                                    <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario registro de impresora
                                    </h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <form *ngIf="view=='registro'" class="content-registro__container__form"
                                        #impresorasForm="ngForm" (ngSubmit)="onSubmit()">
                                        <div class="col-md-12 container__datos_generales">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <h2>¿Estás listo? ...Arranquemos</h2>
                                                    <div class="progress" style="height:4px">
                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                            role="progressbar" aria-valuenow="10" aria-valuemin="0"
                                                            aria-valuemax="100" style="width: 10%">
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <h4>Datos Generales</h4>
                                                    <h4>Información de maquina</h4>

                                                </div>


                                                <div class="row">
                                                    <input type="hidden" id="serial" name="codigo">
                                                    <div class="col-md-4">
                                                        <label>Nombre del Activo</label>
                                                        <input type="text" class="form-control" name="NombreActivo"
                                                            [(ngModel)]="form.NombreActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Fabricante</label>
                                                        <input type="text" class="form-control" name="Marca"
                                                            [(ngModel)]="form.Marca">

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Propietario
                                                        </label>
                                                        <input type="text" class="form-control" name="Modelo"
                                                            [(ngModel)]="form.Modelo">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tipo de maquina</label>
                                                        <input class="form-control" name="TipoDispositivo"
                                                            [(ngModel)]="form.TipoDispositivo" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Serial</label>
                                                        <input type="text" class="form-control" name="Serial"
                                                            [(ngModel)]="form.Serial">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Fecha compra</label>
                                                        <input type="date" class="form-control" name="FechaCompra"
                                                            [(ngModel)]="form.FechaCompra">
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label>Número Factura</label>
                                                        <input type="text" class="form-control" name="NumeroFactura"
                                                            [(ngModel)]="form.NumeroFactura">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Valor del Activo</label>
                                                        <input type="text" class="form-control" name="ValorActivo"
                                                            [(ngModel)]="form.ValorActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número de Póliza</label>
                                                        <input type="text" class="form-control" name="NumeroPoliza"
                                                            [(ngModel)]="form.NumeroPoliza">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Contacto soporte</label>
                                                        <input type="text" class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.ContactoSoporte" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número soporte</label>
                                                        <input class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.NumeroSoporte" />
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.Observaciones"></textarea>
                                                    </div>
                                                    <div class="col-md-12 card" style="padding:2em;">
                                                        <h1>Tipo y clase de mantenimiento</h1>
                                                        <div class="row">
                                                            <div class="col-md-6">

                                                                <label>Ciclo de mantenimiento general</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.CiclosMantenimiento"
                                                                    class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.ProximoMantenimiento">
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento lubricación</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.Lubricacion" class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.LubricacionFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento mecánico</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.Mecanico" class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.MecanicoFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento eléctrico</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.Electrico" class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.ElectricoFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Ciclo de mantenimiento electromecánico</label>
                                                                <select required id="Ciclo" name="CicloMantenimiento"
                                                                    [(ngModel)]="form.ElectroMecanico"
                                                                    class="form-control">
                                                                    <option value="" disabled selected>Selecciona una
                                                                        opción
                                                                    </option>
                                                                    <option value="1">MENSUAL</option>
                                                                    <option value="2">BIMESTRAL</option>
                                                                    <option value="3">TRIMESTRAL</option>
                                                                    <option value="4">SEMESTRAL</option>
                                                                    <option value="5">ANUAL</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Proximo Mantenimiento</label>
                                                                <input type="date" class="form-control"
                                                                    name="ProximoMantenimiento"
                                                                    [(ngModel)]="form.ElectroMecanicoFecha">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary">Registrar Maquina</button>
                                        </div>
                                    </form>
                                    <div class="content-serial" *ngIf="view == 'serial'">

                                        <div class="container">
                                            <div class="form group">
                                                <input type="text" placeholder="Serial del código" class="form-control"
                                                    name="Codigo" [(ngModel)]="form.Codigo">
                                            </div>
                                            <br>
                                            <div class="form-group">
                                                <button class="btn btn-danger cell" (click)="serialVerify()">Verificar
                                                    serial</button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a
                                                    Dispositivos</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button class="btn btn-primary cell" (click)="view='registro'">Quiero
                                                    continuar sin serial</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Activos informáticos</h4>
                                    <h5 class="card-subtitle">Lista de Dispositivos</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <select class="custom-select form-control" (change)="mostrar($event)" id="">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <table class="table v-middle">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Código</th>
                                        <th class="border-top-0">Nombre Activo</th>
                                        <th class="border-top-0">Fabricante</th>
                                        <th class="border-top-0">Estado</th>
                                        <th class="border-top-0">Proximo Mantenimiento</th>
                                        <th class="border-top-0">ACCIONES</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr ([ngModel])="maquinaria"
                                        class="table-activo__container table-striped __head__body__item"
                                        *ngFor="let item of maquinaria | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <ng-container>
                                            <td>
                                                <button class="btn btn-primary cell" routerLink="/profile/{{item.Serial}}">{{item.Serial}}</button>
                                            </td>
                                            <td>
                                                {{item.NombreActivo}}
                                            </td>
                                            <td>
                                                {{item.Marca}}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="item.Estado == 'a'">
                                                    <p style="color:green">Activo</p>
                                                </ng-container>
                                                <ng-container *ngIf="item.Estado == 'b'">
                                                    <p style="color:green">Inactivo</p>
                                                </ng-container>
                                            </td>
                                            <td>
                                                {{item.ProximoMantenimiento}}
                                            </td>

                                            <td>
                                                <button (click)="editar(item.id,
                                                item.NombreActivo,
                                                item.TipoDispositivo,
                                                item.ContactoSoporte,
                                                item.NumeroSoporte,
                                                item.Marca,
                                                item.Modelo,
                                                item.Serial,
                                                item.FechaCompra,
                                                item.NumeroFactura,
                                                item.ValorActivo,
                                                item.NumeroPoliza,
                                                item.Observaciones,
                                                item.CiclosMantenimiento,
                                                item.ProximoMantenimiento,
                                                item.Lubricacion,item.LubricacionFecha,
                                                item.Mecanico,
                                                item.MecanicoFecha,
                                                item.Electrico,
                                                item.ElectricoFecha,
                                                item.ElectroMecanico,
                                                item.ElectroMecanicoFecha
                                                )" type="button" data-toggle="modal" data-target="#editarMaquina"
                                                    class="btn btn-warning" style="color:white"><i
                                                        class="icon-edit2"></i> Editar</button>
                                                <button (click)="eliminar(item.id, item.Serial)"
                                                    class="btn btn-danger">Eliminar</button>
                                                <!-- <button (click)="serialInsumos(item.id, item.Serial)"
                                                    class="btn btn-danger">Insumo</button> -->
                                                <button (click)="editar(item.id,
                                                item.NombreActivo,
                                                item.TipoDispositivo,
                                                item.ContactoSoporte,
                                                item.NumeroSoporte,
                                                item.Marca,
                                                item.Modelo,
                                                item.Serial,
                                                item.FechaCompra,
                                                item.NumeroFactura,
                                                item.ValorActivo,
                                                item.NumeroPoliza,
                                                item.Observaciones,
                                                item.CiclosMantenimiento,
                                                item.ProximoMantenimiento,
                                                item.Lubricacion,
                                                item.LubricacionFecha,
                                                item.Mecanico,
                                                item.MecanicoFecha,
                                                item.Electrico,
                                                item.ElectricoFecha,
                                                item.ElectroMecanico,
                                                item.ElectroMecanicoFecha)" type="button" 
                                                    routerLink="/maquinaria-industrial/{{item.id}}" class="btn btn-primary"
                                                    style="color:white">Ver información</button>
                                                <!-- <button (click)="detail(item.Idmaquinaria,item.Cedula,item.Nombre,item.Apellido,item.Cargo,item.Telefono,item.Direccion,item.Ciudad,item.Arl,item.Eps,item.RH,item.NombreContacto,item.TelefonoContacto)" class="btn btn-outline-secondary" type="button" data-toggle="modal"
                                                    data-target="#modalmaquinariavisitas"> <i class="icon-DETAILS">Visitas</i></button>

                                                <button (click)="detail(item.Idmaquinaria,item.Cedula,item.Nombre,item.Apellido,item.Cargo,item.Telefono,item.Direccion,item.Ciudad,item.Arl,item.Eps,item.RH,item.NombreContacto,item.TelefonoContacto)" class="btn btn-outline-secondary" type="button" data-toggle="modal"
                                                    data-target="#modalmaquinaria"> <i class="icon-DETAILS"></i></button>
                                                <button class="btn btn-delete" (click)="deletemaquinaria(item.Idmaquinaria, item.Serial)"> <i class="icon-REMOVE"></i></button> -->
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>









<div class="modal fade" id="editarMaquina" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Editar maquina</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="content-registro__container__form" #impresorasForm="ngForm" (ngSubmit)="onSubmitEdit()">
                    <div class="col-md-12 container__datos_generales">
                        <div class="row">


                            <!-- {{form.id}} -->
                            <div class="row">
                                <input type="hidden" id="serial" name="codigo">
                                <div class="col-md-6">
                                    <label>Nombre del Activo</label>
                                    <input type="text" class="form-control" name="NombreActivo"
                                        [(ngModel)]="form.NombreActivo">
                                </div>
                                <div class="col-md-6">
                                    <label>Fabricante</label>
                                    <input type="text" class="form-control" name="Marca" [(ngModel)]="form.Marca">

                                </div>
                                <div class="col-md-6">
                                    <label>Propietario
                                    </label>
                                    <input type="text" class="form-control" name="Modelo" [(ngModel)]="form.Modelo">
                                </div>

                                <div class="col-md-6">
                                    <label>Tipo de maquina</label>
                                    <input class="form-control" name="TipoDispositivo"
                                        [(ngModel)]="form.TipoDispositivo" />
                                </div>
                                <div class="col-md-6">
                                    <label>Serial</label>
                                    <input type="text" class="form-control" name="Serial" [(ngModel)]="form.Serial">
                                </div>
                                <div class="col-md-6">
                                    <label>Fecha compra</label>
                                    <input type="date" class="form-control" name="FechaCompra"
                                        [(ngModel)]="form.FechaCompra">
                                </div>

                                <div class="col-md-6">
                                    <label>Número Factura</label>
                                    <input type="text" class="form-control" name="NumeroFactura"
                                        [(ngModel)]="form.NumeroFactura">
                                </div>
                                <div class="col-md-6">
                                    <label>Valor del Activo</label>
                                    <input type="text" class="form-control" name="ValorActivo"
                                        [(ngModel)]="form.ValorActivo">
                                </div>
                                <div class="col-md-6">
                                    <label>Número de Póliza</label>
                                    <input type="text" class="form-control" name="NumeroPoliza"
                                        [(ngModel)]="form.NumeroPoliza">
                                </div>
                                <div class="col-md-6">
                                    <label>Contacto soporte</label>
                                    <input type="text" class="form-control" name="Observaciones"
                                        [(ngModel)]="form.ContactoSoporte" />
                                </div>
                                <div class="col-md-6">
                                    <label>Número soporte</label>
                                    <input class="form-control" name="Observaciones" [(ngModel)]="form.NumeroSoporte" />
                                </div>
                                <div class="col-md-12">
                                    <label>Observaciones</label>
                                    <textarea class="form-control" name="Observaciones"
                                        [(ngModel)]="form.Observaciones"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <br>
                                </div>
                                <div class="col-md-12 card" style="padding:2em;">
                                    <h1>Tipo y clase de mantenimiento</h1>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <label>Ciclo de mantenimiento general</label>
                                            <select required id="Ciclo" name="CicloMantenimiento"
                                                [(ngModel)]="form.CiclosMantenimiento" class="form-control">
                                                <option value="" disabled selected>Selecciona una opción
                                                </option>
                                                <option value="1">MENSUAL</option>
                                                <option value="2">BIMESTRAL</option>
                                                <option value="3">TRIMESTRAL</option>
                                                <option value="4">SEMESTRAL</option>
                                                <option value="5">ANUAL</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Proximo Mantenimiento</label>
                                            <input type="date" class="form-control" name="ProximoMantenimiento"
                                                [(ngModel)]="form.ProximoMantenimiento">
                                        </div>
                                        <div class="col-md-6">
                                            <label>Ciclo de mantenimiento lubricación</label>
                                            <select required id="Ciclo" name="CicloMantenimiento"
                                                [(ngModel)]="form.Lubricacion" class="form-control">
                                                <option value="" disabled selected>Selecciona una opción
                                                </option>
                                                <option value="1">MENSUAL</option>
                                                <option value="2">BIMESTRAL</option>
                                                <option value="3">TRIMESTRAL</option>
                                                <option value="4">SEMESTRAL</option>
                                                <option value="5">ANUAL</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Proximo Mantenimiento</label>
                                            <input type="date" class="form-control" name="ProximoMantenimiento"
                                                [(ngModel)]="form.LubricacionFecha">
                                        </div>
                                        <div class="col-md-12">
                                            <hr>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Ciclo de mantenimiento mecánico</label>
                                            <select required id="Ciclo" name="Mecanico" [(ngModel)]="form.Mecanico"
                                                class="form-control">
                                                <option value="" disabled selected>Selecciona una opción
                                                </option>
                                                <option value="1">MENSUAL</option>
                                                <option value="2">BIMESTRAL</option>
                                                <option value="3">TRIMESTRAL</option>
                                                <option value="4">SEMESTRAL</option>
                                                <option value="5">ANUAL</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Proximo Mantenimiento</label>
                                            <input type="date" class="form-control" name="MecanicoFecha"
                                                [(ngModel)]="form.MecanicoFecha">
                                        </div>
                                        <div class="col-md-12">
                                            <hr>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Ciclo de mantenimiento eléctrico</label>
                                            <select required id="Ciclo" name="Electrico" [(ngModel)]="form.Electrico"
                                                class="form-control">
                                                <option value="" disabled selected>Selecciona una opción
                                                </option>
                                                <option value="1">MENSUAL</option>
                                                <option value="2">BIMESTRAL</option>
                                                <option value="3">TRIMESTRAL</option>
                                                <option value="4">SEMESTRAL</option>
                                                <option value="5">ANUAL</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Proximo Mantenimiento</label>
                                            <input type="date" class="form-control" name="ElectricoFecha"
                                                [(ngModel)]="form.ElectricoFecha">
                                        </div>
                                        <div class="col-md-12">
                                            <hr>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Ciclo de mantenimiento electromecánico</label>
                                            <select required id="Ciclo" name="ElectroMecanico"
                                                [(ngModel)]="form.ElectroMecanico" class="form-control">
                                                <option value="" disabled selected>Selecciona una opción
                                                </option>
                                                <option value="1">MENSUAL</option>
                                                <option value="2">BIMESTRAL</option>
                                                <option value="3">TRIMESTRAL</option>
                                                <option value="4">SEMESTRAL</option>
                                                <option value="5">ANUAL</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Proximo Mantenimiento</label>
                                            <input type="date" class="form-control" name="ElectroMecanicoFecha"
                                                [(ngModel)]="form.ElectroMecanicoFecha">
                                        </div>
                                        <div class="col-md-12">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{form | json}} -->
                                <br>
                            </div>
                        </div>
                        <br>

                        <button type="submit" class="btn btn-primary cell">Actualizar Maquina</button>
                        <button type="button" class="btn btn-secondary cell" data-dismiss="modal">Cancelar</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="maquinariaInfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información maquina {{form.Codigo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="content-registro__container__form" #impresorasForm="ngForm" (ngSubmit)="onSubmitEdit()">
                    <div class="col-md-12 container__datos_generales">
                        <div class="row">


                            <!-- {{form.id}} -->
                            <div class="row">
                                <input type="hidden" id="serial" name="codigo">
                                <div class="col-md-6">
                                    <label>Nombre del Activo</label>
                                    <input disabled type="text" class="form-control" name="NombreActivo"
                                        [(ngModel)]="form.NombreActivo">
                                </div>
                                <div class="col-md-6">
                                    <label>Fabricante</label>
                                    <input disabled type="text" class="form-control" name="Marca"
                                        [(ngModel)]="form.Marca">

                                </div>
                                <div class="col-md-6">
                                    <label>Propietario
                                    </label>
                                    <input disabled type="text" class="form-control" name="Modelo"
                                        [(ngModel)]="form.Modelo">
                                </div>

                                <div class="col-md-6">
                                    <label>Tipo de maquina</label>
                                    <input disabled class="form-control" name="TipoDispositivo"
                                        [(ngModel)]="form.TipoDispositivo" />
                                </div>
                                <div class="col-md-6">
                                    <label>Serial</label>
                                    <input disabled type="text" class="form-control" name="Serial"
                                        [(ngModel)]="form.Serial">
                                </div>
                                <div class="col-md-6">
                                    <label>Fecha compra</label>
                                    <input disabled type="date" class="form-control" name="FechaCompra"
                                        [(ngModel)]="form.FechaCompra">
                                </div>

                                <div class="col-md-6">
                                    <label>Número Factura</label>
                                    <input disabled type="text" class="form-control" name="NumeroFactura"
                                        [(ngModel)]="form.NumeroFactura">
                                </div>
                                <div class="col-md-6">
                                    <label>Valor del Activo</label>
                                    <input disabled type="text" class="form-control" name="ValorActivo"
                                        [(ngModel)]="form.ValorActivo">
                                </div>
                                <div class="col-md-6">
                                    <label>Número de Póliza</label>
                                    <input disabled type="text" class="form-control" name="NumeroPoliza"
                                        [(ngModel)]="form.NumeroPoliza">
                                </div>
                                <div class="col-md-6">
                                    <label>Contacto soporte</label>
                                    <input disabled type="text" class="form-control" name="Observaciones"
                                        [(ngModel)]="form.ContactoSoporte" />
                                </div>
                                <div class="col-md-6">
                                    <label>Número soporte</label>
                                    <input disabled class="form-control" name="Observaciones"
                                        [(ngModel)]="form.NumeroSoporte" />
                                </div>
                                <div class="col-md-12">
                                    <label>Observaciones</label>
                                    <textarea disabled class="form-control" name="Observaciones"
                                        [(ngModel)]="form.Observaciones"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <br>
                                </div>
                                <div class="col-md-12">
                                    <br>
                                </div>
                                <div class="col-md-6">

                                    <label>Ciclo de mantenimiento general</label>
                                    <select required id="Ciclo" disabled name="CicloMantenimiento"
                                        [(ngModel)]="form.CiclosMantenimiento" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="ProximoMantenimiento"
                                        [(ngModel)]="form.ProximoMantenimiento">
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento lubricación</label>
                                    <select required disabled id="Ciclo" name="CicloMantenimiento"
                                        [(ngModel)]="form.Lubricacion" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled required class="form-control"
                                        name="ProximoMantenimiento" [(ngModel)]="form.LubricacionFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento mecánico</label>
                                    <select required id="Ciclo" disabled name="Mecanico" [(ngModel)]="form.Mecanico"
                                        class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="MecanicoFecha"
                                        [(ngModel)]="form.MecanicoFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento eléctrico</label>
                                    <select required disabled id="Ciclo" name="Electrico" [(ngModel)]="form.Electrico"
                                        class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="ElectricoFecha"
                                        [(ngModel)]="form.ElectricoFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Ciclo de mantenimiento electromecánico</label>
                                    <select required id="Ciclo" disabled name="ElectroMecanico"
                                        [(ngModel)]="form.ElectroMecanico" class="form-control">
                                        <option value="" disabled selected>Selecciona una opción
                                        </option>
                                        <option value="1">MENSUAL</option>
                                        <option value="2">BIMESTRAL</option>
                                        <option value="3">TRIMESTRAL</option>
                                        <option value="4">SEMESTRAL</option>
                                        <option value="5">ANUAL</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Proximo Mantenimiento</label>
                                    <input type="date" disabled class="form-control" name="ElectroMecanicoFecha"
                                        [(ngModel)]="form.ElectroMecanicoFecha">
                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <br>
                            </div>
                        </div>
                        <br>

                        <button type="button" class="btn btn-secondary cell" data-dismiss="modal">Cerrar
                            información</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>