import { SedesService } from './../Services/sedes.service';
import swal from 'sweetalert2';
import { UserService } from 'src/app/Services/user.service';
import { TokenService } from 'src/app/Services/token.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  tittle = "PANEL DE MI CUENTA";
  constructor(private sede: SedesService, private router:Router , private token:TokenService, private users: UserService) { }
  user = {
    id: this.token.getUserId(),
    name:this.token.getUserName(),
    email:this.token.getUserEmail(),
    password:null,
    confirmation:null,
    
  }

  options = {
    IdUsuarioInmo: this.token.getUserId()
  }

  mensaje = "";

  change(){
    if (this.user.password !== this.user.confirmation) {
        this.mensaje = "Las contraseñas no coinciden"
    } else {
      this.mensaje = "";
    }
  }
  loading =false;
  sedes;
  onSubmit(){
    console.log(this.user)
    if(this.user.password != this.user.confirmation){
        this.mensaje = "Las constraseñas no coinciden";
        if(this.user.name == null || this.user.name === ""){
          this.mensaje = "Debes colocar un nombre de usuario";
        }
        if(this.user.email == null || this.user.email === ""){
          this.mensaje = "Debes colocar un email para el usuario";
        }
    } 
    else{
      if(this.user.name == null || this.user.name === ""){
        this.mensaje = "Debes colocar un nombre de usuario";
      } else if(this.user.email == null || this.user.email === ""){
        this.mensaje = "Debes colocar un email para el usuario";
      } else{
        this.loading = true;
        this.users.updateUser(this.user).subscribe(data => {
          this.token.remove();
          swal.fire(
            'Excelente!',
            'Se ha actualizado tu información, por seguridad hemos enviado un correo electrónico para reactivar tu cuenta',
            'success'
          );

          this.router.navigate(['/login']);

        },error=>{this.loading=false})
      }

      
    } 
  }
  codigo;
  admin;

  ngOnInit() {
    if(parseInt(this.token.getUserAdministrador()) > 0){
      console.log("Has Admin");
      this.codigo = this.token.utf8_to_b64(this.token.getUserAdministrador());
    } else{
      this.admin = true;
      this.codigo = this.token.utf8_to_b64(this.token.getUserId());
    }
    this.sede.getSedes(this.options).subscribe(
      data => {
        this.sedes = data['sedes'];
      }, error => {
        console.log(error);
      }
    )
  
  }

}
