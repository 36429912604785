<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>

    <!-- {{unidades | json}} -->
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <!-- <div class="col-5"> -->
                <div class="col-12">
                    <h4 class="page-title">{{title}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li routerLink="/unidad-negocio" class="breadcrumb-item active">Puntos de referencia
                                </li>
                                <li class="breadcrumb-item active">Novedades punto de referencia</li>
                                <!-- <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li> -->
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">

                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title"> </h4>

                                    <h5 class="card-subtitle">Registro de novedades</h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12 text-center">


                                    <div  *ngFor="let item of unidades">
                                        <div class="text-center">
                                            <img src="https://quar-vigilantes.s3.us-east-2.amazonaws.com/{{idUser}}/{{item.Foto}}"
                                                    style="width:150px;" alt="">
                                            <img src="http://localhost:8000/files/temp/{{item.Foto}}"
                                                style="width:150px">
                                            <div class="container text-center">
                                                <ng-container *ngFor="let item2 of referencias">
                                                    <h4>Punto de referencia</h4>
                                                    <h2><b>{{item2.NombreReferencia}}</b></h2>
                                                </ng-container>
                                                <h5>Unidad de negocio:</h5>
                                                <h5>{{item.NombreUnidad}}</h5>
                                                <hr>
                                                <div>{{item.Administrador}}</div>
                                                <div>{{item.Telefono}}</div>
                                                {{item.Correo}}
                                            </div>
                                        </div>
                                        <!-- <ng-container *ngIf="referencias"> -->




                                        <!-- <td><button class="btn btn-danger">VER</button></td> -->
                                    </div>
                                    <form #novedad=ngForm (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Nombre de quién marca la novedad</label>
                                                    <input type="text" class="form-control" name="nombre"
                                                        [(ngModel)]="form.QuienRegistro" disabled>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">Foto de evidencia</label>
                                                    <div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                                                        <input accept="image/*" capture="camera"
                                                            (change)="onChange($event)" type="file" id="fichero-tarifas"
                                                            class="input-file" value="">
                                                        Captura la foto...
                                                    </div>
                                                    <img src="{{src}}" style="max-width: 250px;" alt="">
                                                    <img style="max-width: 250px;"
                                                        src="http://localhost:8000/files/temp/{{archivo.nombreArchivo}}"
                                                        alt="">
                                                    {{archivo.nombreArchivo}}
                                                </div>
                                                <div class="form-group">
                                                    <label for="">Novedad</label>
                                                    <textarea name="novedad" [(ngModel)]="form.Novedad"
                                                        class="form-control" id="" cols="20" rows="10"></textarea>
                                                </div>
                                                <div class="register_form">
                                                    <button [disabled]="!novedad.valid"
                                                        style="background: green !important;" class="btn btn-primary">
                                                        Agregar novedad
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <hr>
                                    <div class="stats">
                                        <ng-container ([ngModel])="novedades" *ngFor="let item of novedades">
                                            <div class="card container text-center">
                                                <div class="text-center card-header-date">
                                                    {{item.FechaRegistro}}
                                                </div>
                                                <h3 for="">Quien registró</h3>
                                                <p>{{item.QuienRegistro}}</p>
                                                <hr>
                                                <h1 for="">Novedad</h1>
                                                <p>{{item.Novedad}}</p>
                                                <img src="https://quar-vigilantes.s3.us-east-2.amazonaws.com/{{idUser}}/{{item.Foto}}"
                                                    style="min-width:250px;  width:100%;" alt="">
                                                <hr>

                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <footer class="footer text-center">
            {{form.Latitude}}
            {{form.Longitude}}
            Todos los derechos reservados <a href="https://quar.com.co ">Quar</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>





