import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ComputadorService } from '../Services/Activos/computador.service';
import { UnidadNegocioService } from '../Services/unidad-negocio.service';
import { TokenService } from '../Services/token.service';
import { ActivatedRoute } from '@angular/router';
declare var jQuery: any;
import swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-detalle-unidad',
  templateUrl: './detalle-unidad.component.html',
  styleUrls: ['./detalle-unidad.component.scss']
})
export class DetalleUnidadComponent implements OnInit {
  loading = false;
  title = "Detalle de unidad de negocio";
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private activo: UnidadNegocioService, private token: TokenService) { }


  mostrar(event) {
    // alert(event.target.value);
    this.numberperpage = event.target.value;
  }

  p: 1
  numberperpage = 25;

  data = {
    userEncriptado: null
  }

  verificar = {
    serial: null
  }



  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Serial = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Serial = "";
    }
  }
  serialVerify() {
    this.verificar.serial = this.form.Serial;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }

  public form = {
    IdUnidad: this.route.snapshot.paramMap.get("id"),
    IdReferencia: null,
    Serial: null,
    NombreReferencia: "",
    Usuario: null,
    UsuarioRegistro: this.token.getUserId()
  }

  agregar() {
    this.view = 'registro';
    this.form.IdReferencia = "";
    this.form.Serial = "";
    this.form.NombreReferencia = "";
  }

  view = 'registro';
  detail(id, serial, nombre) {
    this.view = 'update';
    this.form.IdReferencia = id;
    this.form.Serial = serial;
    this.form.NombreReferencia = nombre;

  }

  idUser;
  admin;
  unidades;
  novedades;
  conteovisitas;
  ngOnInit(): void {
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.idUser = this.token.getUserAdministrador()
    } else {
      this.admin = true;
      this.idUser = this.token.getUserId();
    }

    this.form.Usuario = this.idUser;
    this.activo.detail(this.form).subscribe(
      data => {
        console.log(data)
        this.conteovisitas = data['conteoVisitas'];
        this.unidades = data['unidad'];
        this.referencias = data['referencias'];
        this.novedades = data['novedades'];
      },
      error => {
        return console.log(error);
      }
    )
  }

  inicio;
  final;
  observaciones() {
    if (this.inicio == undefined) {
      this.toastr.error("Mensaje", "Debes seleccionar un rango de fechas")
    } else {

      // alert(this.inicio)
      location.href = "https://quar.com.co/api/excel/observaciones/" + this.inicio + "/" + this.final + "/" + this.route.snapshot.paramMap.get("id");
    }
  }

  filtrar(event) {
    console.log(event);
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  term2;
  src;
  referencias;
  term;
  onSubmit() {
    this.loading = true;
    this.form.Usuario = this.idUser;
    if (this.form.Serial == null || this.form.Serial == "") {
      swal.fire({
        title: 'No has colocado un serial?',
        text: "Estas seguro que deseas continuar!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Registrar punto de referencia!',
        cancelButtonText: 'Cancelar!'
      }).then((result) => {
        if (result.value) {
          // this.toastr.success("Excelente")
          this.activo.createref(this.form).subscribe(
            data => {
              this.referencias = data['referencias'],
                this.toastr.success("Mensaje", "Se ha registrado una nueva referencia");
              this.form.NombreReferencia = "";
              this.form.Serial = "";
            },
            error => console.log(error)
          )
        }
      })
    } else {
      this.activo.createref(this.form).subscribe(
        data => {
          this.referencias = data['referencias']
          this.loading = false;
          if (this.form.IdUnidad != null || this.form.IdUnidad == "") {
            this.toastr.success("Se actualizó el punto de referencia");
            this.view = 'registro';
            this.form.IdUnidad = null;
            this.form.Serial = "";
            this.form.NombreReferencia = "";


            // alert();
          }

        },
        error => {
          this.loading = false;
          console.log(error)
        }
      )
    }
  }

  src2 = ""

  delete = {
    id: null,
    serial: null,
    idUnidad: null
  }

  deleteUnidad(id, serial, idUnidad) {

    // alert(id);
    // alert(serial)
    swal.fire({
      title: 'Esta acción no será reversible?',
      text: "Confirmar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete.id = id;
        this.delete.serial = serial;
        this.delete.idUnidad = idUnidad
        this.activo.delete(this.delete).subscribe(
          data => {
            this.referencias = data['referencias'];
            console.log(data)
            this.toastr.success("Se eliminó el punto de referencia");
          },
          error => console.log(error)
        );
      }
    })

  }

  srcUpdate(nombreArchivo) {
    this.src = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + nombreArchivo;
    this.src2 = "http://localhost:8000/files/temp/" + nombreArchivo;
    console.log(this.src2)
  }

}
