import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/Services/token.service';
import { MaquinasService } from 'src/app/maquinas.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
declare var jQuery: any; 

@Component({
  selector: 'app-detallemaquina',
  templateUrl: './detallemaquina.component.html',
  styleUrls: ['./detallemaquina.component.scss']
})
export class DetallemaquinaComponent implements OnInit {
  tittle = "GESTIÓN DE MAQUINARIA";
  maquinaria: any;
  loading = false;
  view = "lista";
  numberperpage = 10;
  verificar = {
    serial: null
  }
  p = 1;
  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }
  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }
  term;
  public form = {
    Codigo: null,
    id: this.route.snapshot.paramMap.get("id"),
    id_user: this.Token.getUserId(),
    NombreActivo: null,
    TipoDispositivo: null,
    ContactoSoporte: null,
    NumeroSoporte: null,
    Marca: null,
    Modelo: null,
    Serial: null,
    FechaCompra: null,
    NumeroFactura: null,
    ValorActivo: null,
    NumeroPoliza: null,
    Observaciones: null,
    CiclosMantenimiento: null,
    ProximoMantenimiento: null,
    // Ubicacion:null,
    Lubricacion: 1,
    LubricacionFecha: "",
    Mecanico: 1,
    MecanicoFecha: "",
    Electrico: 1,
    ElectricoFecha: "",
    ElectroMecanico: 1,
    ElectroMecanicoFecha: ""

  }

  mostrar(event) {

  }


  mantenimientosactivo:any;

  dataActivo;

  eliminar(id, serial) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.dataActivo = {
          id: id,
          serial: serial,
          id_user: this.form.id_user
        }
        this.activo.deleteComputador(this.dataActivo).subscribe(
          data => this.handleResponse(data),
          error => {
            this.toastr.error("Ha ocurrido un error", 'Elimnar activo', {
              timeOut: 3000
            });
          }
        )

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })

  }

  cerrar() {

  }

  editar(id,
    NombreActivo,
    TipoDispositivo,
    ContactoSoporte,
    NumeroSoporte,
    Marca,
    Modelo,
    Serial,
    FechaCompra,
    NumeroFactura,
    ValorActivo,
    NumeroPoliza,
    Observaciones,
    CiclosMantenimiento,
    ProximoMantenimiento,
    Lubricacion, LubricacionFecha,
    Mecanico,
    MecanicoFecha,
    Electrico,
    ElectricoFecha,
    ElectroMecanico,
    ElectroMecanicoFecha) {

  // MecanicoFecha +
  // Electrico +
  // ElectricoFecha +
  // ElectroMecanico +
  // ElectroMecanicoFecha)
    this.form.id = id;
    this.form.NombreActivo = NombreActivo;
    this.form.TipoDispositivo = TipoDispositivo;
    this.form.ContactoSoporte = ContactoSoporte;
    this.form.NumeroSoporte = NumeroSoporte;
    this.form.Marca = Marca;
    this.form.Modelo = Modelo;
    this.form.Serial = Serial;
    this.form.FechaCompra = FechaCompra;
    this.form.NumeroFactura = NumeroFactura;
    this.form.ValorActivo = ValorActivo;
    this.form.NumeroPoliza = NumeroPoliza;
    this.form.Observaciones = Observaciones;
    this.form.CiclosMantenimiento = CiclosMantenimiento;
    this.form.ProximoMantenimiento = ProximoMantenimiento;
    this.form.Lubricacion = Lubricacion;
    this.form.LubricacionFecha = LubricacionFecha;
    this.form.Mecanico = Mecanico;
    // alert(this.form.Mecanico)
    this.form.MecanicoFecha = MecanicoFecha;
    this.form.Electrico = Electrico;
    this.form.ElectricoFecha = ElectricoFecha;
    this.form.ElectroMecanico = ElectroMecanico;
    this.form.ElectroMecanicoFecha = ElectroMecanicoFecha;
    // alert(Electrico);
  }

  handleResponse(data) {

    this.view = 'lista';
    this.toastr.success("Se ha registrado la maquina", "Exito");
    this.loading = false;
    this.maquinaria = data.activo;
  }

  onSubmit() {
    this.loading = true;
    console.log(this.form);
    this.activo.create(this.form).subscribe(
      data => { this.handleResponse(data) },
      error => console.log(error)
    )
  }

  onSubmitEdit() {
    this.loading = true;
    console.log(this.form);
    this.activo.editar(this.form).subscribe(
      data => {
        jQuery("#editarMaquina").modal("hide");
        this.loading = false;
        this.toastr.success("Se actualizo la maquina", "Excelente")
        this.init(data)
      },
      error => {
        this.loading = false;
        console.log(error);
      }
    )
  }

  admin;

  init(data) {
    // alert();
    this.mantenimientosactivo = data.mantenimientosactivo;
    if (!data.activo[0]) {
      this.toastr.info("No se ha encontrado ninguna maquina")
    }
    this.maquinaria = data.activo;
  }

//  term;

  serialInsumos(id,serial){
    this.router.navigate(['/insumos/'+serial]);
  }

  constructor(private Token: TokenService, private activo: MaquinasService, private toastr: ToastrService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.form.id_user = this.Token.getUserAdministrador();
    } else {
      this.admin = true;
      this.form.id_user = this.Token.getUserId();
    }


    this.activo.getActivoDetail(this.form).subscribe(
      data => this.init(data),
      error => this.toastr.info("No se ha encontrado ninguna maquina")
    )
  }

}
