// import { Flashlight } from '@awesome-cordova-plugins/flashlight/ngx';
import { ComputadorService } from './../Services/Activos/computador.service';
import { VisitantesService } from './../Services/usuarios/visitantes.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';
import { UserService } from '../Services/user.service';
import { TokenService } from '../Services/token.service';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
// import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';


declare var $: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  count = 0;
  admin = false; activos: any;
  tittle: string = "PANEL DE CONTROL ADMINISTRATIVO";
  data = {
    id: this.Token.getUserId(),
    IdSede: localStorage.getItem('IdSede')
  }
  codigo;
  users: number;
  tickets: number;

  public loggedIn;
  countvisitas = {
    Compania: null
  }
  mensaje =  false;
  constructor(
    // private flashlight: Flashlight,
    // private barcodeScanner: BarcodeScanner,
    private activo: ComputadorService,
    private visitas: VisitantesService,
    private toastr: ToastrService,
    private Auth: AuthService,
    private user: UserService,
    private Token: TokenService, private route: Router) {  }
  maquinaria = false;
  informaticos = false;

  serial(event){

    this.serialVerify(event.target.value);

  }

  verificar = {
    serial: null
  }
  unidades;
  alerts(data,serial) {
    if (data.Ocupado) {
      this.toastr.success("Será redireccionado", "El serial que ingresó existe");
      // if(!event.tar){
        // this.mensaje = true;

      this.unidades = JSON.parse(localStorage.getItem('unidades'))

      this.unidades = this.unidades['unidad'].filter(word => word.Serial  == serial );

    // } else{
      this.route.navigateByUrl('/referencia/'+serial)
    // }
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      // this.activodupl.nuevoserial = null;
    }
  }
  serialVerify(serial) {
    // this.verificar.serial = serial
    this.unidades = JSON.parse(localStorage.getItem('unidades'))

    this.unidades = this.unidades['unidad'].filter(word => word.Serial  == serial );
    console.log(this.unidades)
    if(this.unidades[0]){
      this.route.navigateByUrl('/referencia/'+serial)

    }

  // } else{
    // this.activo.serial(this.verificar).subscribe(data => {
    //   this.alerts(data,serial);
    // })
  }

  scan(){
    // this.flashlight.switchOn();
    // this.barcodeScanner.scan().then(barcodeData => {
    //   console.log('Barcode data', barcodeData);
    //  }).catch(err => {
    //      console.log('Error', err);
    //  });
  }

  ngOnInit() {



    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.codigo = this.Token.utf8_to_b64(this.Token.getUserAdministrador());
      this.countvisitas.Compania = this.Token.getUserAdministrador();
      this.visitas.count(this.countvisitas).subscribe(
        data => { this.count = data['count'] },
        error => console.log(error)
      )
    } else {
      this.admin = true;
      this.codigo = this.Token.utf8_to_b64(this.Token.getUserId());
      this.countvisitas.Compania = this.Token.getUserId();
      this.visitas.count(this.countvisitas).subscribe(
        data => { this.count = data['count'] },
        error => console.log(error)
      )
    }
    this.informaticos = true;
    // demo.initDashboardPageCharts();
    this.user.getUsers(this.data).subscribe(
      data => this.dashboard(data),
      erro => console.log(erro)
    );
    this.loggedIn = this.Auth.authStatus.subscribe(value => this.loggedIn = value);
  }




  public pieChartLabels = ['Computadores', 'Dispositivos', 'Impresoras'];
  public pieChartData = [0, 0, 0];
  public pieChartType = 'doughnut';


  public pieChartLabelsMaquinas = ['Biomedicas', 'Industriales'];
  public pieChartDataMaquinas = [50, 50];
  public pieChartTypeMaquinas = 'doughnut';


  // public pieChartLabelsTickets = ['Computadores','Otros Dipositivos','Impresoras', 'Otros Dispositivos'];
  // public pieChartDataTickets = [0,0,0, 0];
  // public pieChartTypeTickets = 'doughnut';

  //  Chart de tickets
  public barChartOptionsTickets = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  // public barChartLabelsTickets = ['Tickets','En proceso', 'Terminados'];
  // public barChartTypeTickets = 'radar';
  // public barChartLegendTickets = true;
  // public barChartDataTickets = [
  //   {data: [10, 20, 30]}
  // ];
  // final cahr de ticketsd

  pendientes = 0;
  sinasignar = 0;
  proceso = 0;
  terminados = 0;

  dashboard(data) {
    this.activos = data.countactivosinformaticos

    console.log(this.activos.computador);
    this.pieChartData = [this.activos.computador, this.activos.dispositivos, this.activos.impresoras];
    this.users = data;
    this.pendientes = data.pendientes;
    this.proceso = data.proceso;
    this.sinasignar = data.sinasignar;
    this.terminados = data.terminados;

    console.log(this.users)
  }

  scanResult: any = '';
  onCodeResult(result: string){
    var code = result.split("/");
    this.scanResult = code[code.length-1];
    this.route.navigateByUrl('/profile/'+this.scanResult)

  }




}
