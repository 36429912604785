<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper"  data-layout="vertical" data-navbarbg="skin5"
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view='lista'">Impresoras</li>
                                <li class="breadcrumb-item active" *ngIf="view!='lista'">Serial</li>
                                <li class="breadcrumb-item active" *ngIf="view=='registro'">Registro de activo</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <a (click)="view='serial'" class="btn btn-primary text-white" target="_blank">Agregar
                            Impresora</a>
                        <a  (click)="exportexcel()" class="btn btn-danger text-white"
                            target="_blank">Exportar tabla</a>
                            <!-- <a  (click)="exportAsExcelFile(computador, 'Impresoras')" class="btn btn-danger text-white"
                            target="_blank">Exportar todo a excel</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <div class="row">
                <div *ngIf="view!='lista'" class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro de activo</h4>
                                    <h5 class="card-subtitle" *ngIf="view=='serial'">Asignar Serial</h5>
                                    <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario registro de impresora</h5>
                                    <!-- <h5 class="card-subtitle"></h5> -->
                                </div>

                            </div>
                            <div class="row">
                                <!-- column -->
                                <div class="col-lg-12">
                                    <form *ngIf="view=='registro'" class="content-registro__container__form"
                                        #impresorasForm="ngForm" (ngSubmit)="onSubmit()">
                                        <div class="col-md-12 container__datos_generales">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <!-- <h4>Datos Generales</h4> -->
                                                    <!-- <h4>Información de impresoras</h4> -->

                                                </div>


                                                <div class="row">
                                                    <input type="hidden" id="serial" name="codigo">
                                                    <div class="col-md-4">
                                                        <label>Nombre del Activo</label>
                                                        <input type="text" class="form-control" name="NombreActivo"
                                                            [(ngModel)]="form.NombreActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Marca</label>
                                                        <input type="text" class="form-control" name="Marca"
                                                            [(ngModel)]="form.Marca">

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Modelo
                                                        </label>
                                                        <input type="text" class="form-control" name="Modelo"
                                                            [(ngModel)]="form.Modelo">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tipo de Impresora</label>
                                                        <select class="form-control" name="TipoImpresora"
                                                            [(ngModel)]="form.TipoImpresora">
                                                            <option value="">Seleccionar Tipo de impresora</option>
                                                            <option value="Láser">Láser</option>
                                                            <option value="Inkjet">Inkjet</option>
                                                            <option value="Contínua">Contínua</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Color</label>
                                                        <select class="form-control" name="Color"
                                                            [(ngModel)]="form.Color">
                                                            <option value="">Utiliza color</option>
                                                            <option value="Sí">Sí</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label>Multifunción</label>
                                                        <select class="form-control" name="Multifuncion"
                                                            [(ngModel)]="form.Multifuncion">
                                                            <option value="">Seleccione una opción</option>
                                                            <option value="Sí">Sí</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Maneja Red</label>
                                                        <select class="form-control" name="Red" [(ngModel)]="form.Red">
                                                            <option value="">Seleccione una opción</option>
                                                            <option value="Sí">Sí</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Serial</label>
                                                        <input type="text" class="form-control" name="Serial"
                                                            [(ngModel)]="form.Serial">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Fecha compra</label>
                                                        <input type="date" class="form-control" name="FechaCompra"
                                                            [(ngModel)]="form.FechaCompra">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número Factura</label>
                                                        <input type="text" class="form-control" name="NumeroFactura"
                                                            [(ngModel)]="form.NumeroFactura">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Valor del Activo</label>
                                                        <input type="text" class="form-control" name="ValorActivo"
                                                            [(ngModel)]="form.ValorActivo">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Número de Póliza</label>
                                                        <input type="text" class="form-control" name="NumeroPoliza"
                                                            [(ngModel)]="form.NumeroPoliza">
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" name="Observaciones"
                                                            [(ngModel)]="form.Observaciones"></textarea>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <br>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Ciclos de mantenimiento</label>
                                                        <select required id="Ciclo" name="CicloMantenimiento"
                                                            [(ngModel)]="form.CicloMantenimiento" class="form-control">
                                                            <option value="" disabled selected>Selecciona una opción
                                                            </option>
                                                            <option value="1">MENSUAL</option>
                                                            <option value="2">BIMESTRAL</option>
                                                            <option value="3">TRIMESTRAL</option>
                                                            <option value="4">SEMESTRAL</option>
                                                            <option value="5">ANUAL</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <label>Proximo Mantenimiento</label>
                                                        <input type="date" class="form-control"
                                                            name="ProximoMantenimiento"
                                                            [(ngModel)]="form.ProximoMantenimiento">
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <button class="btn btn-primary">Registrar Impresora</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="content-serial" *ngIf="view == 'serial'">

                                        <div class="container">
                                            <div class="form group">
                                                <input type="text" placeholder="Serial del código" class="form-control"
                                                    name="Codigo" [(ngModel)]="form.Codigo">
                                            </div>
                                            <br>
                                            <div class="form-group">
                                                <button class="btn btn-danger cell" (click)="serialVerify()">Verificar
                                                    serial</button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <button class="btn btn-secondary cell" (click)="view = 'lista'">Volver a
                                                    Impresoras</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button class="btn btn-primary cell" (click)="view='registro'">Quiero
                                                    continuar sin serial</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- column -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Activos informáticos</h4>
                                    <h5 class="card-subtitle">Lista de Impresoras</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <select class="custom-select form-control" (change)="mostrar($event)" id="">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                    <div class="col-md-8">
                                      <div class="form-group">
                                          <label for="">Buscar impresora<input type="search" [(ngModel)]="term"
                                                  (keydown)="filtrar($event)" class="form-control"></label>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="table-responsive">
                            <table class="table v-middle" id="excel-table">
                                <thead>
                                    <tr class="bg-light">
                                        <th class="border-top-0">Serial</th>
                                        <th class="border-top-0">Nombre del Activo</th>
                                        <th class="border-top-0">Estado</th>
                                        <th class="border-top-0">Proximo Mantenimiento</th>
                                        <th class="border-top-0">Sede</th>
                                        <th class="border-top-0">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ([ngModel])="computador"
                                        class="table-activo__container table-striped __head__body__item"
                                        *ngFor="let item of computador | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <ng-container>
                                            <td (click)="details(item.Codigo)">
                                                {{item.Codigo}}
                                            </td>
                                            <td (click)="details(item.Codigo)">
                                                {{item.NombreActivo}}
                                            </td>
                                            <td (click)="details(item.Codigo)">
                                                {{item.NombreActivo}}
                                            </td>
                                            <td (click)="details(item.Codigo)" class="text-left">
                                                {{item.ProximoMantenimiento}}</td>
                                            <td>

                                              <select name="sede" class="form-control" (change)="onChange(item.id,$event)" [(ngModel)]="item.Sede">
                                                      <option value="null">No tiene sede</option>
                                                      <option *ngFor="let sede of sedes"  value="{{sede.IdSede}}">{{sede.NombreSede}}</option>

                                              </select>
                                          </td>

                                            <td class="td-actions text-left">
                                                <button routerLink="/editar/impresora/{{item.Codigo}}" type="button"
                                                    rel="tooltip" title=""
                                                    class="btn btn-round btn-icon btn-icon-mini btn-edit"
                                                    data-original-title="Edit Task">
                                                    <i class="icon-edit"></i>
                                                </button>
                                                <button type="button" rel="tooltip" title=""
                                                    (click)="deleteImpresora(item.id, item.Codigo)"
                                                    class="btn btn-round btn-icon btn-icon-mini btn-delete"
                                                    data-original-title="Remove">
                                                    <i class="icon-REMOVE"></i>
                                                </button>
                                                <button type="button" title="remove" class="btn btn-primary"
                                                    routerLink="/mantenimiento/{{item.Codigo}}" data-toggle="modal"
                                                    data-target="#exampleModal">
                                                    <i class="icon-mantenimiento">Nuevo Mantenimiento</i>
                                                </button>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="panel-activos__body__pagination text-center">
                              <pagination-controls (pageChange)="p = $event"></pagination-controls>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a
                href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>





<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Duplicar activo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="">
                    Si tienes un seríal ingresalo en el siguiente campo
                </label>
                <input type="text" class="form-control cell" placeholder="Digite un serial" name="serial"
                    [(ngModel)]="activodupl.nuevoserial" (change)="serialVerifyDuplicar()">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" (click)="duplicar()" class="btn btn-secondary">No tengo serial</button>
                <button type="button" [disabled]="activodupl.nuevoserial == null" (click)="duplicar()"
                    class="btn btn-primary">Duplicar con el serial</button>
            </div>
        </div>
    </div>
</div> -->
