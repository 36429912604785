import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MantenimientosService } from 'src/app/Services/mantenimientos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from 'src/app/Services/token.service';
import { formatDate } from '@angular/common';
import {Location} from '@angular/common';

@Component({
  selector: 'app-mantenimientomaquina',
  templateUrl: './mantenimientomaquina.component.html',
  styleUrls: ['./mantenimientomaquina.component.scss']
})
export class MantenimientomaquinaComponent implements OnInit {
  date;
  user = {
    id_user:this.Token.getUserId(),
    serial: this.route.snapshot.paramMap.get("serial")
  }
  form = {
    FechaMantenimiento:null,
    TipoMantenimiento:'Preventivo',
    ClaseMantenimiento:'General',
    Descripcion:null,
    IdActivo:null,
    ciclo:null,
    Codigo:null,
    IdUsuarioSoporte: this.Token.getUserId(),
    UsuarioAdministrador:null,
    ValorMantenimiento:null,
    RepuestosCambiados:null
  }
  

  volver() {
    this._location.back();
  }


  users;
  activo;
  constructor(
    private router:Router,
    private Token:TokenService,
    private route: ActivatedRoute,
    private mantenimiento:MantenimientosService,
    private _location: Location) { }
    
    handleResponse(data){
      console.log(this.date);
      console.log(data);
      this.form.Codigo = data.serial;
      // formatDate
      this.form.FechaMantenimiento = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      this.form.UsuarioAdministrador = data.user[0].id_user;
      this.form.IdActivo = data.id[0].IdActivo;
      this.form.ciclo = data.id[0].CiclosMantenimiento;
      this.activo = data.id;
      console.log(this.activo);
      this.users = data.user;
    }

    tittle = "mantenimientos para el activo";
    onSubmit(){
      
      console.log(this.form)
          this.mantenimiento.createMantenimiento(this.form).subscribe(
            data => {
              Swal.fire(
                'Excelente!',
                'Se ha registrado la novedad',
                'success'
              )
              this._location.back();
            },
            error => {
              Swal.fire(
                'Lo sentimos!',
                'Estamos trabajando para solucionar los inconvenientes',
                'error'
              )
            }
          );
          
    }
    loading = false;
    
    ngOnInit() {
      this.mantenimiento.getInformation(this.user).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
      )
    }
    
    
  }
