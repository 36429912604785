import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../Services/user.service';
import { TokenService } from '../Services/token.service';
import { MaquinasService } from '../maquinas.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-insumos',
  templateUrl: './insumos.component.html',
  styleUrls: ['./insumos.component.scss']
})
export class InsumosComponent implements OnInit {
  loading = false;
  computador: any;
  mantenimientosactivo: any;
  sidebaractive: string = 'ficha';
  filesToUpload: Array<File> = [];

  detail = {
    id_user: this.Token.getUserId(),
    serial: null,
    tipoPC: null,
    tipoMemoria: null

  };
  detailactivo: any;
  p: number = 1;
  tittle: string = "gestión de computadores";
  idActivo: number;
  serial: string;
  mensaje: string;

  pasos = "uno";

  licencias: any;
  tipocomputador: any;
  ciclosmantenimiento: any;
  sistemaoperativo: any;
  tipomemorias: any;

  view: string = 'registro';
  tickets: number;
  dataActivo: any;


  discos = {
    IdComputador: this.Token.getUserId(),
    TipoDisco: "SSH",
    serial: this.route.snapshot.paramMap.get("serial"),
    CapacidadDisco: "0"
  }
  discodata = {
    id_user: this.Token.getUserId(),
    tipo: "editar",
    serial: this.route.snapshot.paramMap.get("serial"),
    id: null
  }
  discosarray: any;
  getDiscos(data) {
    console.log(data)
    this.discosarray = data.discos;
  }

  eliminardisco(id) {
    this.discodata.id = id;
    console.log(this.discodata);
    this.activo.discodelete(this.discodata).subscribe(
      data => this.getDiscos(data),
      error => {
        this.toastr.error("Ha ocurrido un error", "No se ha podido eliminar el disco");
      }
    )
  }
  addDisk() {
    if (this.discos.CapacidadDisco == "0" || this.discos.CapacidadDisco == null) {
      this.toastr.info("Campos vacios", 'Debes agregar mínimo 1 en la cantidad');
    } else {

      console.log(this.discos);
      this.activo.discos(this.discos).subscribe(
        data => {
          this.toastr.success("Registro de discos", "Se registro el disco exitosamente");
          this.getDiscos(data);
        }
      )
    }
  }






  data = {
    id_user: this.Token.getUserId(),
    SerialMaquina: this.route.snapshot.paramMap.get("serial"),
    Codigo:null,
    Nombre:null,
    TipoInsumo:null,
    FechaCompra:null,
    NumeroFactura:null,
    Valor:null,
    CantidadUsoMaximo:null,
    NumeroUsos:null,
    CicloMantenimiento:null,
    FechaMantenimiento:null,
    Observaciones:null
  }

  handleResponse(data) {
    console.log(data);


  }

  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.data.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.data.Codigo = "";
    }
  }

  verificar = {
    serial: null
  }
serialVerify() {
    this.verificar.serial = this.data.Codigo;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }


  onSubmit() {
    this.loading = true;
    // alert(this.form.IdSistemaOperativo)

    // console.log(submit);
    // console.log(this.form)
    this.activo.createInsumo(this.data).subscribe(
      data => {
        console.log(data);
        this.loading = false;
        this.toastr.success("Edición de activo", "Edición de activos exitosa")
        this.router.navigate(['/maquinaria-industrial']);
      },
      error => { this.loading = false }
    );

  }

  computadores(data) {
    console.log(data);
    this.computador = data.activo;
    this.licencias = data.licencias;
    this.tipocomputador = data.tipopc;
    this.ciclosmantenimiento = data.ciclosmantenimientos;
    this.sistemaoperativo = data.sistemaoperativos;
    this.tipomemorias = data.tipomemorias;
  }

  constructor(private router: Router, private user: UserService, private Token: TokenService, private route: ActivatedRoute, private activo: MaquinasService,
    private toastr: ToastrService) {  }
  admin;
  ngOnInit() {
    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.data.id_user = this.Token.getUserAdministrador();
    } else {
      this.admin = true;
      this.data.id_user = this.Token.getUserId();
    }
    this.activo.discosActivo(this.data).subscribe(data => this.getDiscos(data));
    this.activo.create(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    );
    this.user.computadorModule(this.data).subscribe(
      data => this.computadores(data),
      erro => console.log(erro)
    );
  }

}
