<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-12">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li class="breadcrumb-item active" routerLink='/empleados'>Gestión de empleados</li>
                                <li class="breadcrumb-item active">Observacion {{form.Nombre}} {{form.Apellido}}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-7">
                    <div class="text-right upgrade-btn">
                        <!-- <a (click)="view='serial'" class="btn btn-primary text-white">Agregar empleado</a> -->
                        <!-- <a (click)="abajo()" class="btn btn-danger text-white">EXPORTAR</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <!-- column -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Observación al empleado</h4>
                                    <h5 class="card-subtitle" *ngIf="view=='serial'">Formulario de observación</h5>
                                    <!-- <h5 class="card-subtitle" *ngIf="view=='registro'">Formulario de empleado</h5> -->
                                </div>

                            </div>
                            <!-- title -->


                            <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <img src="http://qaractivo.local/files/vigilantes/{{Foto}}" style="max-width:250px" alt="">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Cedula
                                            </label>
                                            <input disabled name="Cedula" type="text" (change)="cedula()"
                                                [(ngModel)]="form.Cedula" class="form-control">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Nombres
                                            </label>
                                            <input disabled name="Nombre" type="text" [(ngModel)]="form.Nombre"
                                                class="form-control">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Apellidos
                                            </label>
                                            <input disabled type="text" class="form-control" name="Apellido"
                                                [(ngModel)]="form.Apellido">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Puesto
                                            </label>
                                            <input disabled type="text" class="form-control" name="Puesto"
                                                [(ngModel)]="form.Puesto">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Teléfono
                                            </label>
                                            <input disabled type="number" class="form-control" name="Telefono"
                                                [(ngModel)]="form.Telefono">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Dirección
                                            </label>
                                            <input disabled type="text" class="form-control" name="Direccion"
                                                [(ngModel)]="form.Direccion">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Ciudad
                                            </label>
                                            <input disabled type="text" class="form-control" name="Ciudad"
                                                [(ngModel)]="form.Ciudad">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                ARL
                                            </label>
                                            <input disabled type="text" class="form-control" name="Arl"
                                                [(ngModel)]="form.Arl">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                EPS
                                            </label>
                                            <input disabled type="text" class="form-control" name="Eps"
                                                [(ngModel)]="form.Eps">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                RH
                                            </label>
                                            <input disabled type="text" class="form-control" name="RH"
                                                [(ngModel)]="form.RH">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Nombre de Contacto
                                            </label>
                                            <input disabled type="text" class="form-control" name="NombreContacto"
                                                [(ngModel)]="form.NombreContacto">
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">
                                                Teléfono Contacto
                                            </label>
                                            <input disabled type="number" class="form-control" name="TelefonoContacto"
                                                [(ngModel)]="form.TelefonoContacto">
                                        </div>
                                        <div class="col-lg-12">
                                            <br>
                                            <br>
                                            <div class="alert alert-warning" style="color:black">
                                                Estos valores serán utilizado para tener control
                                            </div>
                                            <!-- <label for="">Temperatura:</label>
                                            <input type="number" required (keyup)="temperatura($event)"
                                                class="form-control" name="Temperatura" [(ngModel)]="obser.temperatura"> -->
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Tiene placa?</label>
                                            <select name="Placa" [(ngModel)]="obser.Placa" class="form-control">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Sí">Sí</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="">Tiene Arma?</label>
                                            <select name="Arma" (change)="arma($event)" class="form-control">
                                                <option value="">Seleccione una opción</option>
                                                <option value="Sí">Sí</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        <ng-container *ngIf="seriearma">
                                            <div class="col-md-6">
                                                <label for="">Arma</label>
                                                <input type="text" class="form-control" [(ngModel)]="obser.Arma"
                                                    name="arma">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="">Serie arma</label>
                                                <input type="text" class="form-control" name="seriearma"
                                                    [(ngModel)]="obser.SerieArma">
                                            </div>
                                        </ng-container>
                                        <div class="col-md-6">
                                            <label for="">Radio</label>
                                            <input type="text" class="form-control" name="radio"
                                                [(ngModel)]="obser.Radio">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Serie radio</label>
                                            <input type="text" class="form-control" name="radio"
                                                [(ngModel)]="obser.SerieRadio">
                                        </div>

                                        <div class="col-md-6">
                                            <label for="">Presentación personal</label>
                                            <select class="form-control" name="PresentacionPersonal"
                                                [(ngModel)]="obser.PresentacionPersonal">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Bueno">Bueno</option>
                                                <option value="Bueno">Regular</option>
                                                <option value="Bueno">Malo</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Recursos</label>
                                            <select class="form-control" name="Recursos" [(ngModel)]="obser.Recursos">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Bueno">Bueno</option>
                                                <option value="Bueno">Regular</option>
                                                <option value="Bueno">Malo</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Estado del vigilante</label>
                                            <select class="form-control" name="vigilante"
                                                [(ngModel)]="obser.EstadoVigilante">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Bueno">Bueno</option>
                                                <option value="Bueno">Regular</option>
                                                <option value="Bueno">Malo</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Cumplimiento Funciones</label>
                                            <select class="form-control" name="funciones"
                                                [(ngModel)]="obser.CumplimientoFunciones">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Bueno">Bueno</option>
                                                <option value="Bueno">Regular</option>
                                                <option value="Bueno">Malo</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Cursos</label>
                                            <select class="form-control" name="cursos" [(ngModel)]="obser.Cursos">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Sí">Sí</option>
                                                <option value="No">No</option>
                                                <!-- <option value="Bueno">Malo</option> -->
                                            </select>
                                        </div>
<!-- 
                                        <div class="col-md-6">
                                            <label for="">Carnet</label>
                                            <select class="form-control" name="Carnet" [(ngModel)]="obser.Carnet">
                                                <option value="null">Seleccione una opción</option>
                                                <option value="Sí">Sí</option>
                                                <option value="No">No</option>
                                       
                                            </select>
                                        </div> -->
                                        <div class="col-md-12">
                                            <br>
                                        </div>
                                        <div class="col-md-6 text-center">
                                            <label for="">Seleccionar una foto de la observación</label>
                                            <div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                                                <input (change)="change($event)" type="file" id="fichero-tarifas"
                                                    class="input-file" value="">
                                                Seleccionar foto para el vigilante...
                                            </div>
                                            <br>
                                            <img src="{{src}}" style="max-width: 250px;" alt="">
                                        </div>
                                        

                                        <div class="col-md-6">
                                            <label for="">Observaciones</label>
                                            <textarea class="form-control" name="Observaciones"
                                                [(ngModel)]="obser.observacion2" cols="30" rows="10"></textarea>
                                        </div>



                                    </div>
                                </div>
                                <!-- {{obser | json}} -->
                                <div class="modal-footer">
                                    <!-- <button type="button" class="btn btn-secondary" (click)="cerrar()" data-dismiss="modal">Cerrar</button> -->
                                    <button class="btn btn-primary cell">Enviar actualización</button>
                                </div>
                            </form>
                            <ng-container ([ngModel])="observaciones" *ngFor="let item of observaciones">
                                <div class="card container text-center">
                                    <div class="text-left card-header-date">
                                        {{item.FechaRegistro}}
                                    </div>
                                    <!-- <h1 for="">Temperatura</h1> -->
                                    <p>{{item.Temperatura}}</p>
                                    <hr>
                                    <h3 for="">Observación</h3>
                                    <p>{{item.Observacion}}</p>
                                    <hr>
                                    <ng-container *ngIf="item.observacion2">
                                        <h3 for="">Fotografía</h3>
                                        <p><img style="max-width: 250px;" src="http://qaractivo.local/files/vigilantes/observaciones/{{item.Foto}}" alt=""></p>
                                    </ng-container>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>



            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://quar.com.co.com">Quar.com.co</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>