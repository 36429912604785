import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmpleadosService } from 'src/app/Services/empleados.service';
import { TokenService } from 'src/app/Services/token.service';
import swal from 'sweetalert2';
import { UserService } from 'src/app/Services/user.service';
declare var jQuery: any;
@Component({
  selector: 'app-vigilantes',
  templateUrl: './vigilantes.component.html',
  styleUrls: ['./vigilantes.component.scss']
})
export class VigilantesComponent implements OnInit {
  idUser;
  title = 'GESTIÓN DE VIGILANTES'
  fechainicial = "";
  fechafinal = "";
  loading;

  public user = {
    id: null,
    name: null,
    email: null,
    password: null,
    password_confirmation: null,
    UserAdmin: this.Token.getUserId(),
    Estado: "2",
    Sede: null,
    accesos: null
  }

  accesos = [
    {
      id: 'dyn1',
      text: 'Dynamic 1'
    },
    {
      id: 'dyn2',
      text: 'Dynamic 2'
    },
    {
      id: 'dyn3',
      text: 'Dynamic 3'
    },
    {
      id: 'dyn4',
      text: 'Dynamic 4'
    }
  ];


  options = {
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
    width: '300'
  };

  onSubmit2() {
    this.form.user = this.user.email;
    this.form.Foto = this.archivo.nombreArchivo;
    if (this.user.password) {
      if (this.user.password != this.user.password_confirmation) {
        this.toastr.error("Las contraseñas no coinciden por favor verificalas");
      } else {
        this.loading = true;
        console.log(this.data);
        this.users.createUsuariosCompania(this.user).subscribe(
          data => {
            this.loading = false;
            // this.handleResponse(data);
            if (data['mensaje']) {
              this.toastr.info("El correo ya existe", 'Por favor indica un nuevo correo electrónico')
            } else {
              this.loading = true;
              console.log(this.data);
              this.empleado.create(this.form).subscribe(
                data => {
                  this.loading = false;
                  this.view = 'lista';
                  this.toastr.success("Se han actualizado tus empleados en el sistema", "Mensaje")
                  this.handleResponse(data);
                },
                error => {
                  this.loading = false;
                  this.toastr.error(error.message, "Error");
                }
              )
            }
          },
          error => {
            this.loading = false;
            this.toastr.error(error.message, "Error");
          }
        )
      }
    } else {
      this.toastr.warning("Contraseña", "Debes especificar una contraseña")
    }
  }


  form = {
    usuario: null,
    user: null,
    password: null,
    Foto: null,
    IdEmpleado: null,
    Cedula: null,
    Nombre: null,
    Apellido: null,
    Cargo: null,
    Telefono: null,
    Direccion: null,
    Ciudad: null,
    Arl: null,
    Eps: null,
    RH: null,
    NombreContacto: null,
    TelefonoContacto: null,
    Codigo: null,
    Puesto: null,
    Tipo: "vigilante",
    Compania: this.idUser,
    Supervisor: this.Token.getUserName(),
    id_user: this.idUser,
    Vigilante: null
  }

  detalle = true;

  datas = {
    tipo: "vigilante"
  }

  usuario

  detailResponse(data) {
    this.usuario = data.user
    this.user.name = this.usuario[0].name;
    this.user.email = this.usuario[0].email;
    this.user.password = this.usuario[0].password;
    this.user.id = this.usuario[0].id;

    // alert(this.user.password);
    // alert(this.user.name)
    this.observaciones = data.observaciones;
  }

  inputType = "password";


  // Subir archivos 
  imagen;
  _handleReaderLoader(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.imagen = binaryString;
    this.archivo.base64textString = btoa(binaryString);
    // alert(window.atob(binaryString));
    this.imagenSubir();
  }

  archivo = {
    id_user: null,
    // id:this.detailactivo[0].idActivo,
    idEmpleado: null,
    serial: null,
    observacion: null,
    nombre: "archivo",
    nombreArchivo: null,
    base64textString: null
  };
  change(event) {
    this.loading = true;
    this.archivo.id_user = this.idUser;
    this.archivo.idEmpleado = this.form.IdEmpleado;
    // alert(this.archivo.idEmpleado)
    console.log(event);
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(this);
      reader.readAsBinaryString(file);

    }

  }

  src;
  imagenSubir() {
    this.loading = false;
    console.log("upload")
    this.empleado.upload(this.archivo).subscribe(res => {
      this.loading = false;
      this.empleados = res['empleados'];
      // this.toastr.success("Se regsitró exitosamente el cambio en el activo", "Historial de Archivos")
      // this.novedad(res);
      this.src = "http://localhost:8000/files/vigilantes/" + this.archivo.nombreArchivo
    },
      error => console.log(error));
  }


  visitas = "TODOS";

  visitaporEmpleado() {
    // alert(this.fechainicial);
    if (this.fechainicial == "" || this.fechainicial == "NaN-NaN-aN" || this.fechainicial == "undefined" || this.fechafinal == "dd/mm/aaaa") {
      this.toastr.error("Debes seleccionar un rango de fechas", "Error");
    } else {
      // this.fechainicial = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);
      this.src = "https://quar.com.co/api/visitas/vigilantes/" + this.visitas + "/" + this.fechainicial + "/" + this.fechafinal + "/" + this.idUser;
      location.href = this.src;
    }
  }

  active = false;

  exportarvisitas() {
    // alert(this.fechainicial);
    if (this.fechainicial == "dd/mm/aaaa" || this.fechainicial == "" || this.fechainicial == "undefined" || this.fechafinal == "dd/mm/aaaa") {
      this.toastr.error("Debes seleccionar un rango de fechas", "Error");
    } else {
      // alert();
      // var fecha = new Date();



      var fecha = new Date(this.fechainicial);
      var day = fecha.getDate() + 1;
      var month = fecha.getMonth() + 1;
      if (day.toString().length == 1) {
        var dayu = "0" + day;
      } else {
        dayu = day.toString();
      }
      // alert(month.toString().length)
      if (month.toString().length == 1) {
        var monthu = "0" + month;
      } else {
        monthu = month.toString();
      }

      this.fechainicial = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);
      // alert(this.fechainicial);


      var fecha = new Date(this.fechafinal);
      // alert(fecha.getMonth()+1);
      var day = fecha.getDate() + 2;
      // alert(day.toString().length)
      if (day.toString().length == 1) {
        var dayu = "0" + day;
      } else {
        dayu = day.toString();
      }

      if (month.toString().length == 1) {
        var monthu = "0" + month;
      } else {
        monthu = month.toString();
      }
      // alert(mon);
      var month = fecha.getMonth() + 1;
      this.fechafinal = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);

      // alert(this.fechafinal);



      // alert(this.fechafinal);
      // alert(this.fechainicial);
      location.href = "https://quar.com.co/api/excel/empleados/vigilantes/" + this.idUser + "/" + this.fechainicial + "/" + this.fechafinal
    }
  }

  observaciones: any;

  detail(IdEmpleado, Cedula, Nombre, Apellido, Cargo, Telefono, Direccion, Ciudad, Arl, Eps, RH, NombreContacto, TelefonoContacto, Foto) {
    // this.form.IdEmpleado = IdEmpleado;
    // alert(IdEmpleado);
    this.form.IdEmpleado = IdEmpleado;
    this.form.Cedula = Cedula;
    this.form.Nombre = Nombre;
    this.form.Apellido = Apellido;
    this.form.Puesto = Cargo;
    this.form.Telefono = Telefono;
    this.form.Direccion = Direccion;
    this.form.Ciudad = Ciudad;
    this.form.Arl = Arl;
    this.form.Eps = Eps;
    this.form.RH = RH;
    this.form.NombreContacto = NombreContacto;
    this.form.TelefonoContacto = TelefonoContacto;
    this.src = "http://localhost:8000/files/vigilantes/" + Foto

    this.empleado.detail(this.form).subscribe(data => this.detailResponse(data), error => this.toastr.error(error.message, 'Error'));
  }
  numberperpage = 10;
  term: string;
  mostrar(event) {
    // alert(event.target.value);
    this.numberperpage = event.target.value;
  }
  view = 'lista';

  id;
  empleados: any;
  visitascount;
  generarCodigo;
  codigo = false;
  tittle = "Control de empleados";
  data = {
    id_user: this.Token.getUserId(),
    userEncriptado: this.Token.utf8_to_b64(this.Token.getUserId()),
    tipo: "vigilante",
    Vigilante: null
  }

  onChangeRole(event){
    var isChecked = event.target.checked;
    if(isChecked){
      this.form.Vigilante = 1;
    } else{
      this.form.Vigilante = null;
    }
  }

  filtrar(event) {
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  generaCod(data) {
    if (data.data) {
      this.codigo = true;
    } else {

    }
  }

  generatecodigo() {
    this.Token.generarVisitasCodigo(this.data).subscribe(
      data => {
        this.generaCod(data);
      },
      error => console.log(error)
    )
  }


  onSubmit() {

    this.form.user = this.user.email;
    this.user.name = this.form.Nombre + " " + this.form.Apellido;
    if (this.user.email == "" || this.user.email == null) {
      swal.fire({
        title: 'No se completaron los datos de inicio de sesión?',
        text: "Si continuas deberás crear el usuario en otra ocasión",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#005870',
        cancelButtonColor: '#d33',
        confirmButtonText: 'CONTINUAR',
        cancelButtonText: 'QUIERO LLENAR LOS DATOS DE INICIO'
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          console.log(this.data);
          this.empleado.create(this.form).subscribe(
            data => {
              this.loading = false;
              this.view = 'lista';
              this.toastr.success("Se han actualizado tus empleados en el sistema", "Mensaje")
              this.handleResponse(data);
            },
            error => {
              this.loading = false;
              this.toastr.error(error.message, "Error");
            }
          )
        }
      })
    } else {
      this.onSubmit2()
    }

  }


  onSubmit3() {
    this.form.user = this.user.email;
    this.form.usuario = this.user.id;
    this.form.password = this.user.password;
    this.loading = true;
    console.log(this.data);
    this.empleado.create(this.form).subscribe(
      data => {
        this.loading = false;
        this.view = 'lista';
        this.toastr.success("Se han actualizado tus empleados en el sistema", "Mensaje")
        this.handleResponse(data);
      },
      error => {
        this.loading = false;
        this.toastr.error(error.message, "Error");
      }
    )
  }




  verificar = {
    serial: null
  }



  deleteempleado(id, serial) {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estás seguro?',
      text: "Esta acción no será reversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.form.IdEmpleado = id;
        this.form.Codigo = serial;
        this.loading = true;
        this.empleado.deleteActivo(this.form).subscribe(
          data => {
            this.toastr.success("Se ha eliminado exitosamente el empleado", "Eliminar empleado")
            this.loading = false;
            this.handleResponse(data)
          },
          error => { this.loading = false; this.toastr.error(error.message, 'Error en el proceso') }
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Se ha salvado tu activo',
          'error'
        )
      }
    })
  }

  cerrar() {
    this.form.IdEmpleado = "";
    this.form.Cedula = "";
    this.form.Nombre = "";
    this.form.Apellido = "";
    this.form.Cargo = "";
    this.form.Telefono = "";
    this.form.Direccion = "";
    this.form.Ciudad = "";
    this.form.Arl = "";
    this.form.Eps = "";
    this.form.RH = "";
    this.form.NombreContacto = "";
    this.form.TelefonoContacto = "";
  }

  handleResponse(data) {
    this.loading = false;
    this.user.email = "";
    this.user.password = "";
    this.user.password_confirmation = "";
    console.log(data.accesos)
    jQuery("#modalempleado").modal("hide");
    console.log(data.empleados)
    this.empleados = data.empleados;
  }

  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Codigo = "";
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      this.view = 'registro';
      // this.form.Codigo = "";
    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Codigo = "";
    }
  }

  serialVerify() {
    this.verificar.serial = this.form.Codigo;
    this.empleado.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }
  admin;

  constructor(
    private toastr: ToastrService,
    private users: UserService,
    private empleado: EmpleadosService, private Token: TokenService) { }
  ngOnInit(): void {
    this.loading = true;
    // In your Javascript (external .js resource or <script> tag)
    jQuery(document).ready(function () {
      jQuery('.js-example-basic-multiple').select2();
    });
    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.data.id_user = this.Token.getUserAdministrador();
      this.idUser = this.Token.getUserAdministrador();
      this.form.Compania = this.Token.getUserAdministrador();
      this.form.id_user = this.Token.getUserAdministrador();
      this.data.userEncriptado = this.Token.utf8_to_b64(this.Token.getUserAdministrador());
    } else {
      this.admin = true;
      this.idUser = this.Token.getUserId();
      this.form.Compania = this.Token.getUserId();
      this.form.id_user = this.Token.getUserId();
      this.data.userEncriptado = this.Token.utf8_to_b64(this.Token.getUserId());
    }
    this.empleado.getEmpleados(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )


  }

  cedula() {

    var usuario = this.form.Nombre + this.form.Apellido
    this.user.email = usuario.toUpperCase().replace(/ /g, "");
    this.user.password = this.form.Cedula;
    this.user.password_confirmation = this.form.Cedula;
  }




  // tittle = ""
  detailempleado: any;
  sidebaractive = "ficha";
  p = 1;
}
