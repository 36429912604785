<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebar-position="absolute"
    data-header-position="absolute" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-navbar></app-navbar>

    <app-layout></app-layout>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
            <div class="row align-items-center">
                <div class="col-5">
                    <h4 class="page-title">{{tittle}}</h4>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
                                <li class="breadcrumb-item active" (click)="view='lista'">Visitantes</li>
                            </ol>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Sales chart -->
            <!-- ============================================================== -->
            <!-- Table -->
            <!-- ============================================================== -->
            <div class="row">
                <div class="panel-activos__body__create">
                    <ng-container>
                        <ng-container>
                            <div class="dropdown">
                                <!-- <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Exportar
                                </button> -->
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item"
                                        href="http://qaractivo.local/api/excel/visitas/hoy/{{idUser}}">Hoy</a>
                                    <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal"
                                        data-target="#rangoFechas">Rango de fecha</a>
                                    <a class="dropdown-item"
                                        href="http://qaractivo.local/api/excel/visitas/{{idUser}}">Todas las
                                        visitas</a>
                                </div>
                            </div>
                        </ng-container>
                        <button data-toggle="modal" data-target="#exampleModal2" class="btn btn-secondary">Ver
                            dirección url</button>
                        <a href="http://qaractivo.local/api/reporte/ingreso"><button style="float: right;"
                                class="btn btn-danger">Exportar
                                información de agenda</button></a>
                    </ng-container>


                    <!-- Rango de fechas -->
                    <!-- Modal -->
                    <div class="modal fade" id="rangoFechas" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Exportar visitas por
                                        rango de fecha</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label for="">Fecha inicial</label>
                                        <input type="date" [(ngModel)]="fechainicial" class="form-control">
                                        <!-- <input type="date"  class="form-control"> -->
                                        <hr>
                                        <label for="">Fecha inicial</label>
                                        <input type="date" class="form-control" [(ngModel)]="fechafinal">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-dismiss="modal">Cancelar</button>
                                    <button type="button" (click)="exportarvisitas()" class="btn btn-primary">Generar
                                        excel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Rango de fechas -->
                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Código de agenda</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="print-section">
                                    <!-- <img src="http://qaractivo.local/img/codigos/{{data.userEncriptado}}.png" alt=""> -->
                                    http://qaractivo.local/formulario/ingreso/{{idUser}}
                                </div>
                                <div class="modal-footer">
                                    <button printSectionId="print-section" ngxPrint class="btn btn-primary">Imprimir
                                        código</button>
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- column -->
                <div class="col-12" *ngIf="view=='registro'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Registro de eventos</h4>
                                    <h5 class="card-subtitle">Agenda</h5>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <button class="btn btn-danger cell" (click)="view = 'lista'">Cancelar
                                            registro</button>

                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>

                        <div class="row container">
                            <div class="col-md-6">
                                <form #loginForm=ngForm (ngSubmit)="submitRegistro()">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="">Nombre del evento</label>
                                            <input type="text" class="form-control" name="NombreEvento"
                                                [(ngModel)]="formEventos.NombreEvento">

                                            {{formEventos.NombreEvento}}
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Fecha del evento</label>
                                            <input type="datetime-local" class="form-control" name="FechaEvento"
                                                [(ngModel)]="formEventos.FechaEvento">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Cantidad de personas</label>
                                            <input type="number" class="form-control" name="CantidadPersonas"
                                                [(ngModel)]="formEventos.CantidadPersonas">
                                        </div>
                                        <div class="col-md-12">
                                            <!-- <label for="">Registro</label> -->
                                            <br>
                                            <button class="btn btn-primary">Registrar evento</button>
                                            <br>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-6"></div>
                        </div>
                        <div class="container card">
                            <table class="table container">
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre evento</th>
                                        <th scope="col">Fecha Evento</th>
                                        <th scope="col">Cantidad de personas</th>
                                        <!-- <th scope="col">Teléfono</th> -->
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let p2 of listaEventos | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <tr>
                                            <th scope="row">{{p2.NombreEvento}}</th>
                                            <td>{{p2.FechaEvento | date:'mediumDate' }}</td>

                                            <td>{{p2.CantidadPersonas}}</td>
                                            <!-- <td>{{p.Telefono}}</td> -->
                                            <!-- <td>{{p.NombreServicio}}</td> -->
                                            <td><button type="button"
                                                    (click)="modaleditar(p2.NombreEvento, p2.FechaEvento, p2.CantidadPersonas, p2.IdEvento)"
                                                    class="btn btn-primary" data-toggle="modal"
                                                    data-target="#exampleModal">Editar</button>
                                                <button type="button"
                                                    (click)="eliminarModal(p2.NombreEvento, p2.FechaEvento, p2.CantidadPersonas, p2.IdEvento)"
                                                    class="btn btn-danger">Eliminar</button>
                                            </td>

                                        </tr>
                                    </ng-container>
                                    <!-- Modal -->
                                    <div class="modal fade" id="exampleModal" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Editar evento</h5>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <form #loginForm=ngForm (ngSubmit)="submitEditar()">
                                                        <div class="row container">
                                                            <div class="col-md-6">
                                                                <label for="">Nombre del evento</label>
                                                                <input type="text" class="form-control"
                                                                    name="NombreEvento"
                                                                    [(ngModel)]="formEventos.NombreEvento">

                                                                <!-- {{formEventos.NombreEvento}} -->
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="">Fecha del evento</label>
                                                                <input type="date" class="form-control"
                                                                    name="FechaEvento"
                                                                    [(ngModel)]="formEventos.FechaEvento">
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="">Cantidad de personas</label>
                                                                <input type="number" class="form-control"
                                                                    name="CantidadPersonas"
                                                                    [(ngModel)]="formEventos.CantidadPersonas">
                                                            </div>
                                                            <div class="col-md-12">
                                                                <!-- <label for="">Registro</label> -->
                                                                <br>
                                                                <button class="cell btn btn-primary">Actualizar evento
                                                                </button>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                        data-dismiss="modal">Cancelar</button>
                                                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tbody>
                            </table>
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="view=='lista'">
                    <div class="card">
                        <div class="card-body">
                            <!-- title -->
                            <div class="d-md-flex align-items-center">
                                <div>
                                    <h4 class="card-title">Agenda visita</h4>
                                    <h5 class="card-subtitle">Agenda</h5>
                                    <button class="btn btn-primary" (click)="view = 'registro'">Registrar
                                        eventos</button>
                                </div>
                                <div class="ml-auto">
                                    <div class="dl">
                                        <select *ngIf="view=='lista'" class="custom-select form-control"
                                            (change)="mostrar($event)" id="">
                                            <option value="10">Mostrar 10</option>
                                            <option value="25">Mostrar 25</option>
                                            <option value="50">Mostrar 50</option>
                                            <option value="100">Mostrar 100</option>
                                            <option value="200">Mostrar 200</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <!-- title -->
                        </div>
                        <div class="container">

                            <div class="">

                                <h4>Por favor selecciona el evento que deseas visualizar</h4>
                                <select name="Eventos" class="form-control" (change)="eventos($event)">
                                    <option value="">Selecciona una celebración</option>
                                    <ng-container *ngFor="let op of listaEventos">
                                        <option value="{{op.IdEvento}}">{{op.NombreEvento}}</option>
                                    </ng-container>
                                </select>
                                <br>
                            </div>
                        </div>




                        <div class="container card">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Cédula</th>
                                        <th scope="col">Nombre completo</th>
                                        <th scope="col">Correo</th>
                                        <th scope="col">Teléfono</th>
                                        <th scope="col">Celebración</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let p of lista | paginate: { itemsPerPage: numberperpage, currentPage: p } | filter:term">
                                        <tr>
                                            <th scope="row">{{p.NumeroIdentificacion}}</th>
                                            <td>{{p.Nombre}}</td>
                                            <td>{{p.Correo}}</td>
                                            <td>{{p.Telefono}}</td>
                                            <td>{{p.NombreServicio}}</td>
                                            <td><button (click)="deleteAgenda(p.Servicio,p.IdAgenda)"
                                                    class="btn btn-danger">Eliminar</button></td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Recent comment and chats -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            Todos los derechos reservados <a href="https://asistecomcolombia.com">asistecomcolombia</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>








<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex=" -1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Actualizar Visita</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #loginForm=ngForm (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="">
                                Tipo documento
                            </label>
                            <select name="TipoDocumento" class="form-control" [(ngModel)]="form.TipoDocumento">
                                <option value="Registro Civil"> RC - Registro Civil</option>
                                <option value="Tarjeta de identidad"> TI - Tarjeta de
                                    identidad.</option>
                                <option value="Cédula de ciudadanía"> CC - Cédula de
                                    ciudadanía</option>
                                <option value="Cédula de extranjería"> CE - Cédula de
                                    extranjería</option>
                                <option value="Pasaporte"> PA - Pasaporte</option>
                                <option value="Menor sin identificación"> MS - Menor sin
                                    identificación</option>
                                <option value="Adulto sin identidad"> AS - Adulto sin
                                    identidad.</option>
                            </select>
                            <!-- <input name="Nombre" type="text" [(ngModel)]="data.Nombre" class="form-control"> -->
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Número identificación
                            </label>
                            <input name="Cedula" type="text" [(ngModel)]="form.Cedula" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Nombres
                            </label>
                            <input name="Nombre" type="text" [(ngModel)]="form.Nombre" class="form-control">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Apellidos
                            </label>
                            <input type="text" class="form-control" name="Apellido" [(ngModel)]="form.Apellido">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Empresa
                            </label>
                            <input type="text" class="form-control" name="Empresa" [(ngModel)]="form.Empresa">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Teléfono
                            </label>
                            <input type="text" class="form-control" name="Telefono" [(ngModel)]="form.Telefono">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Motivo de la visita
                            </label>
                            <input type="text" class="form-control" name="Direccion" [(ngModel)]="form.Direccion">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Ciudad
                            </label>
                            <input type="text" class="form-control" name="Ciudad" [(ngModel)]="form.Ciudad">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                ARL
                            </label>
                            <input type="text" class="form-control" name="Arl" [(ngModel)]="form.Arl">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                EPS
                            </label>
                            <input type="text" class="form-control" name="Eps" [(ngModel)]="form.Eps">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                RH
                            </label>
                            <input type="text" class="form-control" name="RH" [(ngModel)]="form.RH">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Nombre de Contacto
                            </label>
                            <input type="text" class="form-control" name="NombreContacto"
                                [(ngModel)]="form.NombreContacto">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Teléfono Contacto
                            </label>
                            <input type="number" class="form-control" name="TelefonoContacto"
                                [(ngModel)]="form.TelefonoContacto">
                        </div>
                        <div class="col-md-4">
                            <label for="">
                                Temperatura
                            </label>
                            <input type="number" min="34" max="44" required class="form-control" name="Temperatura"
                                (keyup)="temperatura($event)" [(ngModel)]="form.Temperatura">

                        </div>
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-outline-warning cell" (click)="covid=true">
                                ¿Actualizar respuestas obligatorias?
                            </button>
                        </div>
                        <ng-container *ngIf="covid">

                            <div class="col-lg-6">
                                <label for=""> <b style="color:red"> ¿Ha tenido contacto
                                        estrecho con personas con un caso de investigacion o
                                        diagnósticada con COVID 19?:</b></label>
                                <select name="observacion" (change)="observacionesAlerta($event)" class="form-control"
                                    [(ngModel)]="form.observacion">
                                    <option
                                        value="Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19">
                                        Sí</option>
                                    <option
                                        value="No ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19">
                                        No</option>
                                </select>
                            </div>
                            <div class="col-lg-6">
                                <label for=""> <b style="color:red"> ¿Tiene tos, fiebre,
                                        dificultad para respirar?</b></label>
                                <select name="observacion" (change)="observaciones2Alerta($event)" [(ngModel)]="sintoma"
                                    class="form-control">
                                    <option value="No">No</option>
                                    <option value="Sí">Sí</option>
                                </select>
                            </div>
                            <div class="col-lg-6" *ngIf="sintomas">
                                <label for=""> <b style="color:red"> Seccione los
                                        sintomas</b></label>
                                <select name="observacion" multiple class="form-control"
                                    [(ngModel)]="form.observacion2">
                                    <option value="Tos">Tos</option>
                                    <option value="Fiebre">Fiebre</option>
                                    <option value="Dificultad para respirar">Dificultad para
                                        respirar</option>
                                </select>
                            </div>
                        </ng-container>
                        <div class="card text-center">
                            <h3>Observaciones anteriormente seleccionados</h3>
                            {{observacion1}} <br>
                            <hr> {{observacion2}}
                        </div>

                        <div class="col-lg-12">
                            <button class="btn cell btn-primary">
                                Actualizar Visitante
                            </button>
                            <button type="button" class="cell btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>



<!-- Modal -->
<div class="modal fade" id="modalparafecha" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Selecciona fecha</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <input type="date" class="form-control">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary">Cargar lista</button>
            </div>
        </div>
    </div>
</div>