import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MantenimientosService {



  private baseurl = "https://quar.com.co/api"

  getMantenimientos(data){
    return this.http.post(`${this.baseurl}/historial/mantenimientos`,data);
  }


  createMantenimiento(data){
    return this.http.post(`${this.baseurl}/registrar/mantenimiento`,data);
  }

  getInformation(data){
    return this.http.post(`${this.baseurl}/information/matenimiento`,data);
  }
  constructor( private http:HttpClient) { }
}
