<div class="login-container">
    <div class="login-container__form">
        <div class="login-container__form__contaner">
            <div class="login-container__form__header">
                <img src="../../assets/img/logo.png" alt="Logo">
                <h5>Estas a un paso de darle vida a tus activos... Regístrate aquí</h5>
            </div>
            <form #loginForm=ngForm (ngSubmit)="onSubmit()">

                <div class="form-group row">
                    <input type="text" name="name" placeholder="¿Cómo te llamas?" class="form-control" id="nombre"
                        [(ngModel)]="form.name" required>
                </div>

                <div class="form-group row">
                    <input type="email" name="email" placeholder="Cuál es tu mail" class="form-control" id="inputEmail3"
                        [(ngModel)]="form.email" required>
                </div>

                <div class="form-group row">
                    <input type="password" name="password" placeholder="Contraseña" class="form-control"
                        id="inputP45454assword3" [(ngModel)]="form.password" required>
                </div>

                <div class="form-group row">
                    <input type="password" name="password_confirmation" placeholder="Repite tu contraseña"
                        class="form-control" id="445" [(ngModel)]="form.password_confirmation" required>
                </div>
                <div class="row text-center">
                    <div class="col-md-12">
                        <h4>SELECCIONA LO QUE QUIERES HACER CON QUAR</h4>
                    </div>
                    <label class="col-md-6">
                        <input type="checkbox" name="demo" class="card-input-element d-none" value="demo2">
                        <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                            Control de activos
                        </div>
                    </label>
                    <label class="col-md-6">
                        <input type="checkbox" name="demo" class="card-input-element d-none" value="demo2">
                        <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                            Control de vigilancia
                        </div>
                    </label>
                    <label class="col-md-6">
                        <input type="checkbox" name="demo" class="card-input-element d-none" value="demo2">
                        <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                            Cronograma de mantenimiento
                        </div>
                    </label>
                    <label class="col-md-6">
                        <input type="checkbox" name="demo" class="card-input-element d-none" value="demo2">
                        <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                            Control de ingreso (Personas)
                        </div>
                    </label>
                </div>


                <div class="form-group text-center register_form">
                    <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Registrate</button>
                    <button type="button" routerLink="/login" class="btn btn-secondary">Ya tengo cuenta</button>
                </div>

            </form>
        </div>
    </div>

    <div class="loading" *ngIf="loading">
        <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
    </div>