import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UnidadNegocioService {

  private baseurl = "https://quar.com.co/api";
  deleteComputador(data) {
    return this.http.post(`${this.baseurl}/delete/computador`, data)
  }

 duplicar(data) {
    return this.http.post(`${this.baseurl}/duplicar/activo`, data)
  }

  discos(data) {
    return this.http.post(`${this.baseurl}/register/discos`, data)
  }

  discosActivo(data) {
    return this.http.post(`${this.baseurl}/ver/discos`, data)
  }

  discodelete(data) {
    return this.http.post(`${this.baseurl}/delete/disco`, data)
  }

  serial(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/verificar/serial`, data)
  }

  delete(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/delete/unidad`, data)
  }

  create(data) {
    return this.http.post(`${this.baseurl}/create/unidad`, data)
  }


  createref(data) {
    return this.http.post(`${this.baseurl}/create/unidadreferencia`, data)
  }

  upload(data) {
    return this.http.post(`${this.baseurl}/file/upload`, data)
  }


  actualizar(data) {
    return this.http.post(`${this.baseurl}/editar/computador`, data)
  }

  detail(data) {
    return this.http.post(`${this.baseurl}/detail/unidad`, data)
  }

  detailUp(data) {
    return this.http.post(`${this.baseurl}/detail/referencia`, data)
  }

  get(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/get/unidad`, data)
  }

  novedad(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/create/novreferencia`, data)
  }



  constructor(private http:HttpClient) { }
}
