import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaquinasService {



  private baseurl = "https://quar.com.co/api"
  deleteComputador(data) {
    return this.http.post(`${this.baseurl}/delete/maquina`, data)
  }

  getActivo(data){
    return this.http.post(`${this.baseurl}/get/maquinas`, data)
  }

  sendHistorico(data){
    return this.http.post(`${this.baseurl}/historico/maquinas`, data)
  }

  getActivoDetail(data){
    return this.http.post(`${this.baseurl}/editar/maquinas`, data)
  }

  create(data) {
    return this.http.post(`${this.baseurl}/create/maquina`, data)
  }

  createInsumo(data) {
    return this.http.post(`${this.baseurl}/create/insumo`, data)
  }

  editar(data) {
    return this.http.post(`${this.baseurl}/editar/maquina`, data)
  }

 duplicar(data) {
    return this.http.post(`${this.baseurl}/duplicar/activo`, data)
  }

  discos(data) {
    return this.http.post(`${this.baseurl}/register/discos`, data)
  }

  discosActivo(data) {
    return this.http.post(`${this.baseurl}/ver/discos`, data)
  }

  discodelete(data) {
    return this.http.post(`${this.baseurl}/delete/disco`, data)
  }

  serial(data) {
    console.log(data);
    return this.http.post(`${this.baseurl}/verificar/serial`, data)
  }


  constructor(private http:HttpClient) { }
}
