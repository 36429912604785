import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SedesService {

  constructor(private http:HttpClient) { }


  private baseurl = "https://quar.com.co/api"
  getSedes(data){
      return this.http.post(`${this.baseurl}/get/sedes`,data)
  }

  create(data){
    return this.http.post(`${this.baseurl}/create/sedes`,data)
}
    delete(data){
      return this.http.post(`${this.baseurl}/delete/sedes`,data)
    }

    updateUser(data){
      return this.http.post(`${this.baseurl}/update/sedeuser`,data)
    }
}
