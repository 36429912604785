import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../Services/token.service';
import { VisitantesService } from '../Services/usuarios/visitantes.service';
declare var jQuery: any;
import swal from 'sweetalert2';
import { EventoformService } from '../Services/eventos/eventoform.service';
@Component({
  selector: 'app-visitas-por-grupo',
  templateUrl: './visitas-por-grupo.component.html',
  styleUrls: ['./visitas-por-grupo.component.scss']
})
export class VisitasPorGrupoComponent implements OnInit {
  numberperpage = 10;
  id;
  idUser;
  visitantes: any;
  visitascount;
  generarCodigo;
  codigo = false;
  tittle = "Control de visitantes";
  data = {
    id_user: this.Token.getUserId(),
    userEncriptado: null,
    observacion2: null
  }

  mostrar(event) {
    // alert(event.target.value);
    this.numberperpage = event.target.value;
  }
  filtrar(event) {
    if (event.target.value != "") {
      this.numberperpage = 50000000;

    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }


  datae2 = {
    Servicio: null,
    IdAgenda: null,
    id_user: null
  }


  deleteAgenda(Servicio, Id) {
    this.datae2.id_user = this.idUser;
    this.datae2.IdAgenda = Id;
    this.datae2.Servicio = Servicio;
    swal.fire({
      title: 'Realmente desea eliminar el registro',
      text: "Esta acción es permanente",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, eliminar'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.visitante.delete(this.datae2).subscribe(
          data => {
            // console.log(data)
            this.lista = data['lista'];
            this.loading = false;
          },
          error => {
            console.log(error)
            this.loading = false;

          }
        )

      }
    })
  }




  generaCod(data) {
    if (data.data) {
      this.codigo = true;
    } else {
      location.reload()
    }
  }

  enviar = false;
  sintomas = false;


  observacionesAlerta(event) {
    if (event.target.value === "Sí, ha tenido contacto estrecho con personas con un caso de investigacion o diagnósticada con COVID 19") {
      this.toastr.error("INFORMAR AL COORDINADOR SST", "IMPORTANTE");
    }
    this.enviar = true;
  }

  formcol = {
    id: null
  }

  lista;

  load(id) {
    this.loading = true;
    this.formcol.id = id;
    this.visitante.getParticipantes(this.formcol).subscribe(
      data => {
        // console.log(data)
        this.lista = data['lista'];
        this.loading = false;
      },
      error => {
        console.log(error)
        this.loading = false;

      }
    )
  }



  temperatura(event) {
    console.log(event);
    var temp = (this.form.Temperatura / 10).toFixed(1);
    console.log(parseFloat(temp))
    if (event.target.value < 3 || event.target.value > 4) {
      if (parseFloat(temp) < 3.4 || parseFloat(temp) > 41.5) {
        this.form.Temperatura = "";
      } else {
        if (parseFloat(temp) > 41.0) {
          this.form.Temperatura = "";
        }
        if (parseFloat(temp) > 34.0) {
          this.form.Temperatura = temp;
        }
        if (parseFloat(temp) === 3.7) {
          this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
        } else {
          if (parseFloat(temp) >= 37.0 && parseFloat(temp) < 37.9) {
            this.toastr.info('Algo no esta bien con esta temperatura', 'Posible fiebre');
          }
        }
        if (parseFloat(temp) === 3.8) {
          this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');
        } else {
          if (parseFloat(temp) > 38.0 && parseFloat(temp) < 38.9) {
            this.toastr.warning(this.form.Nombre + ' tiene un nivel alto de temperatura');

          }

        }
        if (parseFloat(temp) === 3.9) {
          this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');
        } else {
          if (parseFloat(temp) > 39.0) {
            this.toastr.error(this.form.Nombre + ' tiene un nivel alto de temperatura se recomienda una revisión');


          }
        }
      }
    } else {

    }


  }

  onSubmit() {
    if (this.form.observacion2 != null) {
      this.form.observacion2 = this.form.observacion2.toString();
    } else {
      this.form.observacion2 = "";
    }

    if (!this.sintomas) {
      this.form.observacion2 = "";
    }


    if (this.form.Temperatura == null) {
      this.toastr.error("La temperatura es obligatoria", "Campos obligatorios")
    } else {
      console.log(this.data);
      this.loading = true;
      this.visitante.update(this.form).subscribe(
        data => {
          this.handleEdit(data)

          // this.form.Cedula = "";
          // this.form.Nombre = "";
          // this.form.Apellido = "";
          // this.form.Empresa = "";
          // this.form.Telefono = "";
          // this.form.Direccion = "";
          // this.form.Ciudad = "";
          // this.form.Arl = "";
          // this.form.Eps = "";
          // this.form.RH = "";
          // this.form.NombreContacto = "";
          // this.form.TelefonoContacto = "";
          // this.form.Temperatura = "";
        },
        error => console.log(error)
      )
    }
  }

  itemnumber: 10;

  term;

  observaciones2Alerta(event) {
    if (event.target.value === "Sí") {
      this.toastr.info("Seleccione cuales son los sintomas", "IMPORTANTE");
      this.sintomas = true;
    } else {
      this.sintomas = false;
    }
    this.enviar = true;
  }



  form = {
    id: null,
    TipoDocumento: "Cédula de ciudadanía",
    Cedula: null,
    Nombre: null,
    Apellido: null,
    Empresa: null,
    Telefono: null,
    Direccion: null,
    Ciudad: null,
    Arl: null,
    Eps: null,
    RH: null,
    NombreContacto: null,
    TelefonoContacto: null,
    Temperatura: null,
    Compania: null,
    observacion: null,
    observacion2: null
  }


  generatecodigo() {
    this.Token.generarVisitasCodigo(this.data).subscribe(
      data => {
        this.generaCod(data);
      },
      error => console.log(error)
    )
  }


  handleResponse(data) {
    console.log(this.data);
    if (data.existe == "Si") {
      this.codigo = true;

    } else {
      this.codigo = false;
    }


    if (data.countvisitas > 0) {
      this.visitascount = true;
      this.visitantes = data.visitantes;
    } else {
      this.visitascount = false;
    }
  }


  hideModal = false;

  handleEdit(data) {
    this.loading = false;
    jQuery("#exampleModal").modal("hide");
    this.hideModal = true;
    this.toastr.success("Se ha actualizado una vista al sistema", "Mensaje");
    this.visitantes = data.visitantes;
  }


  fechainicial;
  fechafinal;

  exportarvisitas() {
    // alert(this.fechainicial);
    if (this.fechainicial == "dd/mm/aaaa" || this.fechainicial == "" || this.fechainicial == "undefined" || this.fechafinal == "dd/mm/aaaa") {
      this.toastr.error("Debes seleccionar un rango de fechas", "Error");
    } else {
      // alert();
      // var fecha = new Date();



      var fecha = new Date(this.fechainicial);
      var day = fecha.getDate() + 1;
      var month = fecha.getMonth() + 1;
      if (day.toString().length == 1) {
        var dayu = "0" + day;
      } else {
        dayu = day.toString();
      }
      // alert(month.toString().length)
      if (month.toString().length == 1) {
        var monthu = "0" + month;
      } else {
        monthu = month.toString();
      }

      this.fechainicial = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);
      // alert(this.fechainicial);


      var fecha = new Date(this.fechafinal);
      // alert(fecha.getMonth()+1);
      var day = fecha.getDate() + 2;
      // alert(day.toString().length)
      if (day.toString().length == 1) {
        var dayu = "0" + day;
      } else {
        dayu = day.toString();
      }

      if (month.toString().length == 1) {
        var monthu = "0" + month;
      } else {
        monthu = month.toString();
      }
      // alert(mon);
      var month = fecha.getMonth() + 1;
      this.fechafinal = monthu + "-" + dayu + "-" + fecha.getFullYear().toString().substr(-2);

      // alert(this.fechafinal);



      // alert(this.fechafinal);
      // alert(this.fechainicial);
      location.href = "https://quar.com.co/api/excel/visitas/" + this.idUser + "/" + this.fechainicial + "/" + this.fechafinal
    }
  }



  constructor(private toastr: ToastrService, private visitante: VisitantesService, private Token: TokenService, private evento: EventoformService) { }
  view = 'lista';
  codigoad;
  admin;

  modaleditar(NombreEvento, FechaEvento, CantidadPersonas, IdEvento) {
    // alert(NombreEvento)
    this.formEventos.NombreEvento = NombreEvento;
    this.formEventos.FechaEvento = FechaEvento;
    this.formEventos.CantidadPersonas = CantidadPersonas;
    this.formEventos.idUser = IdEvento;
  }

  eliminarModal(NombreEvento, FechaEvento, CantidadPersonas, IdEvento) {
    // alert(NombreEvento)
    // this.loading = true;
    this.formEventos.NombreEvento = IdEvento;
    this.formEventos.FechaEvento = FechaEvento;
    this.formEventos.CantidadPersonas = CantidadPersonas;
    this.formEventos.idUser = this.idUser;

    swal.fire({
      title: 'Realmente desea eliminar el registro',
      text: "Esta acción es permanente",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, eliminar'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.evento.deleteEvento(this.formEventos).subscribe(
          data => {
            // console.log(data)
            this.listaEventos = data['lista'];
            this.loading = false;
            this.toastr.success("Se ha eliminado correctamente el evento")
          },
          error => {
            console.log(error)
            this.loading = false;

          }
        )

      }
    })
  }


  ngOnInit(): void {
    jQuery('[data-toggle="tooltip"]').tooltip()
    if (parseInt(this.Token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.form.Compania = this.Token.getUserAdministrador();
      this.data.id_user = this.Token.getUserAdministrador();
      this.idUser = this.Token.getUserAdministrador();
      this.form.Compania = this.Token.getUserAdministrador();
      this.data.userEncriptado = this.Token.utf8_to_b64(this.Token.getUserAdministrador());
    } else {
      this.admin = true;
      this.data.userEncriptado = this.Token.utf8_to_b64(this.Token.getUserId());
      this.idUser = this.Token.getUserId();
      this.form.Compania = this.Token.getUserId();
      // this.form.Compania = this.Token.();
    }
    this.visitante.getVisitantes(this.data).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    )



    this.formEventos.idUser = this.idUser;
    // alert();
    console.log(this.formEventos)
    this.evento.getEventos(this.formEventos).subscribe(
      data => {
        console.log(data)
        this.listaEventos = data['lista']
        this.toastr.success("Se han obenido los eventos")
      },
      error => { console.log(error) }
    )
  }

  eventos(event) {
    this.load(event.target.value)
  }

  listaEventos;

  public formEventos = {
    NombreEvento: null,
    FechaEvento: null,
    CantidadPersonas: null,
    id_user: null,
    idUser: null
  }

  submitRegistro() {
    this.formEventos.id_user = this.idUser;
    // alert();
    console.log(this.formEventos)
    this.evento.createEvento(this.formEventos).subscribe(
      data => {
        console.log(data)
        this.listaEventos = data['lista']
        this.toastr.success("Se ha registrado el evento")
      },
      error => { console.log(error) }
    )

  }


  submitEditar() {
    this.formEventos.id_user = this.idUser;
    // alert();
    console.log(this.formEventos)
    this.evento.editarEvento(this.formEventos).subscribe(
      data => {
        jQuery("#exampleModal").modal("hide");
        console.log(data)
        this.listaEventos = data['lista']
        this.toastr.success("Perfecto!", "Registro actualizado exitosamente")
        this.formEventos.NombreEvento = "";
        this.formEventos.FechaEvento = "";
        this.formEventos.CantidadPersonas = "";
        this.formEventos.idUser = "";
      },
      error => { console.log(error) }
    )

  }


  observacion1;
  observacion2;

  sintoma;
  editar(id, Cedula, Nombre, Apellido, Empresa, Telefono, Direccion, Eps, RH, NombreContacto, TelefonoContacto, Temperatura, Observacion, observacion2) {
    this.form.id = id;
    // alert(id);
    this.form.Cedula = Cedula;
    this.form.Nombre = Nombre;
    this.form.Apellido = Apellido;
    this.form.Empresa = Empresa;
    this.form.Telefono = Telefono;
    this.form.Direccion = Direccion;
    this.form.Eps = Eps;
    this.form.RH = RH;
    this.form.NombreContacto = NombreContacto;
    this.form.TelefonoContacto = TelefonoContacto;
    this.form.Temperatura = Temperatura;
    this.observacion1 = Observacion;
    // alert(Observacion)
    this.form.observacion = Observacion;
    // alert(this.form.observacion);
    this.observacion2 = observacion2;
    this.form.observacion2 = observacion2;
    if (this.form.observacion2 == null) {
      this.sintoma = "No";
      this.sintomas = false;
    } else {
      this.sintoma = "Sí";
      this.sintomas = true;
    }
  }
  covid = false;

  loading = false;
  p = 1;
}
