import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { ReferenciaUnidadComponent } from '../referencia-unidad/referencia-unidad.component';
import { s3 } from 'fine-uploader/lib/s3';

@Injectable({
  providedIn: 'root'
})

export class UploadService {


  fileUpload(file, idUser) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAXQFPTP7U7WYYJWUK',
        secretAccessKey: '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',
        region: 'us-east-2',

      }
    );
    const params = {
      Bucket: 'quar-vigilantes',
      Key: idUser + "/" + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: ', JSON.stringify(err));
        return false;
      }
      console.log('File Uploaded.', data);
      return true;
    });
  }

  constructor() { }
}
