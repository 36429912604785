import { Component, OnInit } from '@angular/core';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ComputadorService } from '../Services/Activos/computador.service';
import { read } from 'fs';
import { UnidadNegocioService } from '../Services/unidad-negocio.service';
import { TokenService } from '../Services/token.service';
import { UploadService } from '../Services/upload.service';
declare var jQuery: any;
import swal from 'sweetalert2';
@Component({
  selector: 'app-unidad-negocio',
  templateUrl: './unidad-negocio.component.html',
  styleUrls: ['./unidad-negocio.component.scss']
})
export class UnidadNegocioComponent implements OnInit {
  loading = false;
  title = "Unidades de negocio";
  constructor(private toastr: ToastrService, private activo: UnidadNegocioService, private token: TokenService, private uploadService: UploadService) { }
  view = 'lista';
  idUser;
  form = {
    IdUnidad: null,
    Serial: null,
    NombreUnidad: null,
    Administrador: null,
    NIT: null,
    Telefono: null,
    Foto: null,
    Correo:null,
    Usuario: null
  }
  data = {
    userEncriptado: null
  }

  verificar = {
    serial: null
  }


  toFile;

  submit() {
    const file = this.toFile.item(0);
    this.archivo.nombreArchivo = file.name;

    // alert(file.name)
    this.uploadService.fileUpload(file, this.idUser)
    this.src = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + file.name;
    this.src2 = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + file.name;
    this.archivo.base64textString = this.src;
    this.form.Foto = this.archivo.nombreArchivo;
    this.loading = false;
  }

  onChange(event) {
    this.loading = true;
    this.toFile = event.target.files;
    this.submit();

  }


  alerts(data) {
    if (data.Ocupado) {
      this.toastr.error("No disponible", "El serial que ingresó esta en uso");
      this.form.Serial = "";
      // this.serial = true;
    }
    if (data.Disponible) {
      this.toastr.success("Perfecto!", "El serial que ingresó esta disponible");
      // this.form.Serial = "";
      // this.serial = true;
      this.form.Foto = this.archivo.nombreArchivo;
      this.form.Usuario = this.idUser;

    }
    if (data.Mensaje) {
      this.toastr.error("No disponible", "El serial que ingresó no existe");
      this.form.Serial = "";
      this.serial = false;
    }
  }
  serialVerify() {
    this.verificar.serial = this.form.Serial;
    this.activo.serial(this.verificar).subscribe(data => {
      this.alerts(data);
    })
  }

  p = 1;

  filtrar(event) {
    console.log(event);
    if (event.target.value != "") {
      this.numberperpage = 50000000;
    } else {
      // alert("hola mundo");
      this.numberperpage = 10;
    }
  }

  mostrar(event) {
    // alert(event.target.value);
    this.numberperpage = event.target.value;
  }

  format() {
    this.archivo.nombreArchivo = "";
    this.form.IdUnidad = "";
    this.form.Serial = "";
    this.form.Telefono = "";
    this.form.Administrador = "";
    this.form.NIT = "";
    this.form.Foto = "";
  }

  numberperpage = 10;
  term = "";

  onSubmit() {
    this.loading = true;
    if (this.archivo.nombreArchivo) {
      this.form.Foto = this.archivo.nombreArchivo;
    }


    this.activo.create(this.form).subscribe(
      data => {
        this.unidades = data['unidad'];
        this.view = 'lista';
        this.toastr.success("Completado")        // this.archivo.nombreArchivo = "";
        this.form.NombreUnidad = "";
        this.form.IdUnidad = "";
        this.form.Serial = "";
        this.form.Telefono = "";
        this.form.Administrador = "";
        this.form.NIT = "";
        this.form.Foto = "";
        this.loading = false
        jQuery("#staticBackdrop").modal("hide");
        // jQuery("#serialUnidad").modal("hide");
      },
      error => {
        console.log(error)
        this.loading = false;
      }
    )



  }

  serial = false;

  detail(id, NombreUnidad, NIT, Serial, Administrador, Telefono, Foto, Correo) {
    this.form.Serial = Serial;
    this.form.IdUnidad = id;
    this.form.NombreUnidad = NombreUnidad;
    this.form.NIT = NIT;

    // alert(this.form.NombreUnidad);
    this.form.Administrador = Administrador;
    this.form.Correo = Correo;
    this.form.Telefono = Telefono;
    this.form.Foto = Foto;
    // console.log(this.form)

  }

  file() {

  }





  // Subir archivos 
  imagen;
  _handleReaderLoader(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.imagen = binaryString;
    this.archivo.base64textString = btoa(binaryString);
    // alert(window.atob(binaryString));
    this.imagenSubir();
  }

  archivo = {
    // id:this.detailactivo[0].idActivo,
    serial: null,
    observacion: null,
    nombre: "archivo",
    nombreArchivo: null,
    base64textString: null
  };
  change(event) {
    console.log(event);
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoader.bind(this);
      reader.readAsBinaryString(file);

    }

  }

  src;
  src2;
  imagenSubir() {
    console.log("upload")
    this.activo.upload(this.archivo).subscribe(res => {
      this.loading = false;
      // this.toastr.success("Se regsitró exitosamente el cambio en el activo", "Historial de Archivos")
      // this.novedad(res);
      this.src = "http://qaractivo.local/files/temp/" + this.archivo.nombreArchivo
    },
      error => console.log(error));
  }

  srcUpdate(nombreArchivo) {
    this.src = "http://qaractivo.local/files/temp/" + nombreArchivo;
    this.src2 = "https://quar-vigilantes.s3.us-east-2.amazonaws.com/" + this.idUser + "/" + nombreArchivo;
  }

  delete = {
    id: null,
    serial: null,
    idUser: null
  }
  deleteUnidad(id, serial) {
    // alert(id);
    // alert(serial)
    swal.fire({
      title: 'Esta acción no será reversible?',
      text: "Confirmar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete.id = id;
        this.delete.serial = serial;
        this.delete.idUser = this.idUser
        this.activo.delete(this.delete).subscribe(
          data => {
            this.unidades = data['unidad'];
            // console.log(data)
            this.toastr.success("Se eliminó la unidad externa");
          },
          error => console.log(error)
        );
      }
    })

  }


  readURL(event) {
    var files = event.target.files;
    console.log(files)
    // var file = files[0];
    // if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      console.log(e.target.result)
    };

    reader.readAsDataURL(event.files[0]);
  }

  admin;

  unidades;
  ngOnInit(): void {
    this.loading = true;
    if (parseInt(this.token.getUserAdministrador()) > 0) {
      console.log("Has Admin");
      this.idUser = this.token.getUserAdministrador()
    } else {
      this.admin = true;
      this.idUser = this.token.getUserId();
    }
    this.form.Usuario = this.idUser;
    this.activo.get(this.form).subscribe(
      data => {
        this.unidades = data['unidad'];
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log(error)
        this.toastr.error(error.message)
      }
    )
  }

}
