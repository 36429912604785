<div class="container card">
    <ng-container *ngIf="data.idUser === '415'">
        <img src="../../assets/img/foto.jpeg" style="width: 150px;" alt="">
        <h1>Formulario para reservar un lugar en las celebraciones de Comunidad Cristiana de Fe Bello</h1>
        <br>
        <h5>LEE ESTO CON CUIDADO POR FAVOR</h5>
        <br>
        <div class="container">
            <span>Por favor, no asistas a la celebraci&oacute;n si:</span>
            <ol>

                <li>Has tenido fiebre, gripe, malestar general, dolor de garganta y cabeza, v&oacute;mito, malestar
                    estomacal, dificultad para respirar, tos seca, falta de sentido del gusto o olfato.</li>

                <li>Has estado en contacto con personas que presentan s&iacute;ntomas de gripa y/o has tenido contacto
                    con
                    personas diagnosticadas con coronavirus.</li>
            </ol>
            <p>Recuerda que, si al momento de asistir tienes los s&iacute;ntomas arriba mencionados o tienes contacto
                estrecho con alguien diagnosticado con Coronavirus, no asistas.</p>
            <p>Ten en cuenta que al reservar est&aacute;s tomando un cupo de asistencia, impidiendo que otras personas
                vayan
                a la celebraci&oacute;n. Es por esta raz&oacute;n que te pedimos que solo en caso de estricta fuerza
                mayor,
                dejes de asistir. Nos puedes contactar en el tel&eacute;fono 3193259125.<br /><br />Al llenar el
                formulario
                se generar&aacute; un c&oacute;digo QR. Por favor, haz una captura de pantalla de ese c&oacute;digo QR
                para
                que lo muestres en tu celular al ingresar a la celebraci&oacute;n que agendaste. Te recomendamos estar
                15
                minutos antes del inicio de la celebraci&oacute;n. No olvides llevar tapabocas y evitar los abrazos,
                besos y
                apretones de mano.</p>
        </div>
    </ng-container>
    <ng-container *ngIf="data.idUser === '663'">
        <img src="../../assets/img/Logo_copa.jpeg" style="width: 250px;" alt="">
        <h1>Formulario para reservar un lugar en las celebraciones de Comunidad Cristiana de Fe Copacabana</h1>
        <br>
        <h5>LEE ESTO CON CUIDADO POR FAVOR</h5>
        <br>
        <div class="container">
            <span>Por favor, no asistas a la celebraci&oacute;n si:</span>
            <ol>

                <li>Has tenido fiebre, gripe, malestar general, dolor de garganta y cabeza, v&oacute;mito, malestar
                    estomacal, dificultad para respirar, tos seca, falta de sentido del gusto o olfato.</li>

                <li>Has estado en contacto con personas que presentan s&iacute;ntomas de gripa y/o has tenido contacto
                    con
                    personas diagnosticadas con coronavirus.</li>
            </ol>
            <p>Recuerda que, si al momento de asistir tienes los s&iacute;ntomas arriba mencionados o tienes contacto
                estrecho con alguien diagnosticado con Coronavirus, no asistas.</p>
            <p>Ten en cuenta que al reservar est&aacute;s tomando un cupo de asistencia, impidiendo que otras personas
                vayan
                a la celebraci&oacute;n. Es por esta raz&oacute;n que te pedimos que solo en caso de estricta fuerza
                mayor,
                dejes de asistir. Nos puedes contactar en el tel&eacute;fono 322 5269422.<br /><br />Al llenar el
                formulario
                se generar&aacute; un c&oacute;digo QR. Por favor, haz una captura de pantalla de ese c&oacute;digo QR
                para
                que lo muestres en tu celular al ingresar a la celebraci&oacute;n que agendaste. Te recomendamos estar
                15
                minutos antes del inicio de la celebraci&oacute;n. No olvides llevar tapabocas y evitar los abrazos,
                besos y
                apretones de mano.</p>

                <hr>
                <!-- <h5>Visión</h5> -->
                <p>Somos una iglesia con un amor contagioso, amantes de Dios y su presencia; una iglesia que ama y cuida cada persona, que conquista las generaciones para Jesús  y las ve caminar en el propósito de Dios para sus vidas.</p>
        </div>
    </ng-container>
    <p class="btn btn-primary" (click)="codigo()">Consulta QR si estás inscrito</p>

    <br><br>

    <div class="card-title">


    </div>
    <form #loginForm=ngForm (ngSubmit)="registro()" class="card-content">
      
        <div class="form-group">
            <label for="">Número de identificación</label>
            <input type="text" [(ngModel)]="form.NumeroIdentificacion" (change)="getCedula()" required
                class="form-control" name="Cedula" id="Cedula">
        </div>

        <div class="form-group">
            <label for="">Nombre completo</label>
            <input type="text" class="form-control" name="Nombre" [(ngModel)]="form.Nombre" id="Cedula" required>
        </div>

        <div class="form-group">
            <label for="">Teléfono</label>
            <input type="text" class="form-control" name="Telefono" id="Cedula" [(ngModel)]="form.Telefono" required>
        </div>
        <div class="form-group">
            <label for="">Correo</label>
            <input type="text" class="form-control" name="Correo" [(ngModel)]="form.Correo" id="Cedula">
        </div>
        <div class="form-group">
            <label for="">Seleccione la celebración</label>
            <select name="Servicio" (change)="clickOption($event)" [(ngModel)]="form.Servicio" id="Servicio"
                class="form-control" required>
                <option value="">Selecciona una celebración</option>
                <ng-container *ngFor="let op of lista">
                    <option value="{{op.IdEvento}}">{{op.FechaEvento | date:'mediumDate' }} / {{op.NombreEvento}}
                    </option>
                </ng-container>

            </select>
        </div>
        <div class="form-group">
            <div class="alert alert-success" *ngIf="mensaje" role="alert">
                Se ha enviado un correo electrónico con tu código qr
                <br>
                <button (click)="mensaje = false" class="btn btn-danger">Aceptar</button>
            </div>
            <button class="btn btn-primary" [disabled]="!loginForm.valid">Enviar mis datos</button>
        </div>

        <br>
    </form>
</div>



<div class="loading" *ngIf="loading">
    <!-- <img class="loading__img" src="../../assets/img/loading.gif" alt=""> -->
</div>

